import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import storage from "../../../../packages/framework/src/StorageProvider";

import { getStorageData,setStorageData ,removeStorageData} from "../../../framework/src/Utilities";
import  { isValidPhoneNumber } from "react-phone-number-input";
import React from 'react'
interface IRequest {
  id: string;
  type: string;
  attributes: {
    sender_id: number;
    status: string;
    rejection_reason: string | null;
    request_text: string;
    created_at: string;
    updated_at: string;
    reviewer_group_id: number;
    sender_full_name: string;
  };
}
interface MyComponentState {
  
  country: string
  animalType: string
  breed: string
  postType: any;

  PostArr: any;
  postTypeOption: any;
  postTypeMenuRef: any;
  postTypeMenu: boolean;

  typeArr: any;
  TypeOption: any;
  TypeMenuRef: any;
  TypeMenu: boolean;
  typeString: any;

  statusArr: any;
  statusOption: any;
  statusMenuRef: any;
  statusMenu: boolean;
  statusOptionStringArr: any;

  sortMenuItemRef: any
  sortMenuItem: any;
  menu: any;

}
export interface HowToContactInterFace{
  id?:number,
  name:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  howToContactArr:string[];
  howToContactErr:boolean;
  receivedRequests: IRequest[];
  rejectText: string;
  rejectTextError: string | null;
  selectedId: string | null;
  viewRequest: IRequest | null;
  filterKey: string;
  phoneNumError:boolean;
  fomatForMObileNumber: any;
  dateofbirthErr:boolean;
  dateofbirth: any;
  dateofbirthForRef: string;
  isDatePickerOpen:boolean;
  dateOfBirthMessage:string;
  countryError: boolean;
  country:any;
  countryData:any;
  howLongLived:any;
  howLongLivedErr:boolean;
  doYou:any;
  doYouErr:boolean;
  descAccommodation:any;
  descAccommodationErr:boolean;
  accessToPrivateGarden:boolean|null;
  reasonForAdopt:any;
  reasonForAdoptErr:boolean;
  reasonValue:any;
  otherError:boolean;
  expPetOwner:any;
  expPetOwnerErr:boolean;
  allowOutSide:any;
  allowOutSideErr:boolean;
  reLocationCost:any;
  reLocationCostErr:boolean;
  householdActivityLevel:any;
  householdActivityLevelErr:boolean;
  livingAlone:boolean|null;
  livingAloneErr:boolean
  first_Name:string;
  last_Name:string;
  email:string;
  phone_Number:any;
  first_Name_Err:boolean;
  last_Name_Err:boolean;
  email_Err:boolean;
  age:string;
  age_Err:boolean;
  address1:string;
  address1_Err:boolean
  city:string,
  city_Err:boolean
  county:string;
  county_Err:boolean;
  countryList:any;
  hours:any;
  hours_Err:boolean;
  newPersonEntry:any;
  errorsArr:any
  newEntry:any
  whatsAppNumber:any;
  whatsAppNumberErr:boolean;
  howLongLivedName:string;
  newPetEntry :any;
  errorsPetArr: any;
  newPet: any;
  animalType: any;
  animalType_Err: boolean;
  animalTypeList: any;
  breed: any;
  breed_Err: boolean;
  breedList: any;
  currentIndex: any
  selectedSterilized: any
  pleaseExplain: string,
  pleaseExplainErr: boolean,
  pleaseExplain2: string,
  pleaseExplainErr2: boolean,
  isButtonDisabled: boolean,
  anchorEl: any;
  postType: any;

  PostArr: any;
  postTypeOption: any;
  postTypeMenuRef: any;
  postTypeMenu: boolean;

  typeArr: any;
  TypeOption: any;
  TypeMenuRef: any;
  TypeMenu: boolean;
  typeString: any;

  statusArr: any;
  statusOption: any;
  statusMenuRef: any;
  statusMenu: boolean;
  statusOptionStringArr: any;

  sortMenuItemRef: any
  sortMenuItem: any;
  menu: any;

  tableData: any;
  tableLastPage: any;
  tableTotalRequest: any;
  currentTablePage: any;

  sortValue: any;
  searchKeyword: any;
  showEmtyImage: boolean;
  showEmtyImageForNoSubmittedReq: boolean;
  per_page:any
  isLoading:boolean
  pathName: string;
  heading: string;
  detailsArray: string[];
  fosterTermsConditon: boolean;
  fosterapplicable: boolean|null;
  fosterappointments: boolean|null;
  fosteranimalshome: boolean|null;
  fosteranimalshomeErr:boolean;
  fosterchildrenattimes: boolean|null;
  fosterbalconygarden: boolean|null;
  fosterbalconygardenErr:boolean
  fosterenclosed: boolean|null;
  fosterenclosedErr:boolean;
  fosterenLive: any;
  howLongLivedFosterErr: boolean;
  apartMent: any,
  apartMentErr: boolean,
  isPersonErr: boolean,
  address2: string,
  address2_Err: boolean,
  postalCode: string,
  postalCode_Err: boolean,
  postalCode_Err_value:string
  doYouName: string
  apartMentName: string
  expPetOwnerName: string
  allowOutSideName: string
  reLocationCostName: string
  descAccommodationName: string
  householdActivityLevelName: string
  foseterGenericAge: any;
  foseterGenericAgeName: any;
  foseterGenericAgeErr: boolean;
  foseterGenericGender: any;
  foseterGenericGenderName: any;
  foseterGenericGenderErr: boolean;
  willingFosterAnimal: boolean|null
  termsAndConditonPopUpOpen: boolean;
  petId:any;
  postId:any;
RequestDetails:any;
  fosterForName: string
  fosterTermsConditonErr:boolean,
  isShowTemplateMess:boolean,
  templateMess:any,
  isModalOpen:boolean,
  isShowMarkMess:boolean,
  markMess:any,
  historyPath:any,
  isRejectModalOpen:boolean,
  rejectRequestValue:any,
  rejectRequestValue_Err:boolean,
  animalTypeListForAdoptedPets:any,
  myAdoptedData:any,
  breedListForMyAdoptedPets:any,
  showEmtyImageForNoAdoptedPets:boolean

  isTrialPeriodModalOpen:any,
  user_role:any,
  continueAdoption:any,
  option_Err:boolean,
  reason_Cancelling_Adoption:any,
  reason_Cancelling_Adoption_Err:boolean,
  isAnsReceiveModal:boolean,
  already_answer:any,
  adoption_trial_period_requester:any,
  adoption_trial_period_owner:any,
  showSuccessMessPop:any,
  SuccessMess:any,
  isOwner:boolean,
  trial_period:boolean,
  showEmpty_ForNoAdoptedPets:boolean,
  all_subCategory:any
  showReceiveReq:boolean
  emptyNoSearchImgReq:any;
  emptyNoSearchImgSub:any;
  emptyPageSearchImgSub:any;
  emptyPageSearchImgReq:any;
  idUpdatedTemp:any;
  isOtherChecked:boolean
  removedPersonArr:any
  removedPetsArr:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllReceivedRequestCallId: string = "";
  updateRequestReviewCallId: string = "";
  deleteRequestCallId: string = "";
  getUserProFileApiId:string='';
  getCountryListApiId:string='';
  postAdoptionApiId:string='';
  getAnimalTypeApiId:string='';
  getBreedApiId:string='';
  postGenericFosterApiId:string='';
  getRequestDetailsApiId:string='';
  getMySubmittedRequestApiId:string='';
  getPostDropdownListApiId:string='';
  getTemplateApiId:string='';
  globalCategoryData:any=[];
  postTemplateApiId:string='';
  markStatusApi:string='';
  markRejectStatusApi:string='';
  getMyAdoptedPetsPostApiId:string='';
  getBreedApiIdForAdoptedPets:string='';
  afterTrialPeriodApi:string='';
  getAnimalTypedForMyAdoptedPets:string='';
  getSetMyAdoptedAnimalTypeApi:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      termsAndConditonPopUpOpen:false,
      howToContactArr: [],
      howToContactErr: false,
      petId:'',
      token: "",
      receivedRequests: [],
      rejectText: "",
      rejectTextError: null,
      selectedId: null,
      viewRequest: null,
      filterKey: '',
      phoneNumError:false,
      emptyPageSearchImgSub:"",
  emptyPageSearchImgReq:"",
      fomatForMObileNumber: '',
      emptyNoSearchImgReq:"",
      emptyNoSearchImgSub:"",
      dateofbirthErr:false,
      dateofbirth:'',
      dateofbirthForRef:'',
      isDatePickerOpen:false,
      dateOfBirthMessage: "Please select a date",
      countryError:false,
      country:"Select",
      countryData: [],
      howLongLived:'',
      howLongLivedErr:false,
      doYou:'',
      doYouErr:false,
      apartMent:'',
      apartMentErr:false,
      descAccommodation:'',
      descAccommodationErr:false,
      accessToPrivateGarden:null,
      reasonForAdopt:[],
      reasonForAdoptErr:false,
      reasonValue:'',
      otherError:false,
      expPetOwner:'',
      expPetOwnerErr:false,
      allowOutSide:'',
      allowOutSideErr:false,
      reLocationCost:'',
      reLocationCostErr:false,
      householdActivityLevel:'',
      householdActivityLevelErr: false,
      livingAlone: null,
      livingAloneErr:false,
      first_Name: '',
      last_Name: '',
      email: '',
      phone_Number: '',
      first_Name_Err: false,
      last_Name_Err: false,
      email_Err: false,
      age: '',
      age_Err: false,
      address1: '',
      address1_Err: false,
      city: '',
      city_Err: false,
      county: '',
      county_Err: false,
      countryList: [],
      hours: '',
      hours_Err: false,
      newPersonEntry: [{
        name: "",
        relationship: "",
        age: "",
      },],
      errorsArr: [
        {
          name_Err: false,
          relationship_Err: false,
          age_Err: false,
        },

      ],
      newEntry: {
        name: "",
        relationship: "",
        age: "",
      },
      newPetEntry: [{
        age: '',
        category_id: '',
        sub_category_id: '',
        any_health_condition: '',
        sterilized: ''
      },],
      errorsPetArr: [
        {
          age_Err: false,
          category_id_Err: false,
          sub_category_id_Err: false,
          any_health_condition_Err: false,
          sterilized_Err: false
        },

      ],
      newPet: {
        age: '',
        category_id: '',
        sub_category_id: '',
        any_health_condition: '',
        sterilized: ''
      },
      whatsAppNumber: '',
      whatsAppNumberErr: false,
      isPersonErr: false,
      address2: '',
      address2_Err: false,
      postalCode: '',
      postalCode_Err: false,
      postalCode_Err_value:'',
      doYouName: '',
      apartMentName: '',
      expPetOwnerName: '',
      allowOutSideName: '',
      reLocationCostName: '',
      descAccommodationName: '',
      householdActivityLevelName: '',
      howLongLivedName: '',
      animalType: 'Select',
      animalType_Err: false,
      animalTypeList: [],
      breed: 'Select',
      breed_Err: false,
      breedList: [],
      currentIndex: '',
      selectedSterilized: '',
      pleaseExplain: '',
      pleaseExplainErr: false,
      pleaseExplain2: '',
      pleaseExplainErr2: false,
      isButtonDisabled: false,

      anchorEl: null,
      postType: ['Post'],
      PostArr: [
        {
          id: 0,
          name: "All",
        },

      ],

      postTypeOption: [0],
      postTypeMenuRef: React.createRef(),
      postTypeMenu: false,
      typeArr: [
        {
          id: 0,
          name: "All",
        },
        { id: 1, name: "Adoption" },
        { id: 2, name: "Foster" },

      ],


      TypeOption: [0],
      TypeMenuRef: React.createRef(),
      TypeMenu: false,
      typeString: '',

      statusArr: [
        {
          id: 0,
          name: "All",
        },
        { id: 1, name: "Approved" },
        { id: 2, name: "Pending" },
        { id: 3, name: "Rejected" },
        { id: 4, name: "Archived" },

      ],
      statusOption: [0],
      statusMenuRef: React.createRef(),
      statusMenu: false,
      statusOptionStringArr: [],

      sortMenuItemRef: React.createRef(),
      sortMenuItem: null,
      menu: 0,

      tableData: [],
      tableLastPage: '',
      tableTotalRequest: '',
      currentTablePage: 1,

      sortValue: 'newest',
      searchKeyword: '',

      showEmtyImage: false,
      showEmtyImageForNoSubmittedReq: true,
      isLoading:false,
      pathName: "",
      detailsArray: [],
      heading: "",
      fosterTermsConditon: false,
      fosterTermsConditonErr: false,
      fosterapplicable: null,
      fosterappointments: null,
      fosteranimalshome: null,
      fosteranimalshomeErr:false,
      fosterchildrenattimes: null,
      fosterbalconygarden: null,
      fosterbalconygardenErr:false,
      fosterenclosed: null,
      fosterenclosedErr:false,
      fosterenLive: '',
      howLongLivedFosterErr: false,

    foseterGenericAge:'',
    foseterGenericAgeName:'',
    foseterGenericAgeErr:false,
    foseterGenericGender:'',
    foseterGenericGenderName:'',
    foseterGenericGenderErr:false,
    willingFosterAnimal:null,
      RequestDetails:'',
      postId:'',
      per_page:10,
  fosterForName:'',
  isShowTemplateMess:false,
  templateMess:'',
  user_role:'',
  isModalOpen:false,
  isShowMarkMess:false,
  markMess:'',
  historyPath:'',
  isRejectModalOpen:false,
  rejectRequestValue:'',
  rejectRequestValue_Err:false,
  animalTypeListForAdoptedPets: [
    {
      id: 0,
      name: "All",
    },

  ],
  
  myAdoptedData:[],
  breedListForMyAdoptedPets: [
    {
      id: 0,
      name: "All",
    },

  ],
  showEmtyImageForNoAdoptedPets:false,
  isTrialPeriodModalOpen:false,
  continueAdoption:'',
  option_Err:false,
  reason_Cancelling_Adoption:'',
  reason_Cancelling_Adoption_Err:false,
  isAnsReceiveModal:false,
  already_answer:false,
  adoption_trial_period_requester:false,
  adoption_trial_period_owner:false,
  showSuccessMessPop:false,
  SuccessMess:'',
  isOwner:false,
  trial_period:false,
  showEmpty_ForNoAdoptedPets:false,
  all_subCategory:[],
  showReceiveReq:false,
  idUpdatedTemp:'',
  isOtherChecked:false,
  removedPersonArr:[],
  removedPetsArr:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const itemData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      this.setState({isShowTemplateMess:itemData?.isShowTemplateMess,templateMess:itemData?.message})
       this.setState({petId:itemData?.petId})
       setStorageData('petPostId',itemData?.petId)
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (token) {
        this.setState({ token: token }, () => {
          this.getAllReceivedRequest();
        });
      } else {
        this.showAlert("Alert", configJSON.loginAlertMessage);
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.getUserProFileApiId:
            this.setUserData(responseJson)
            break;
          case this.getCountryListApiId:
            this.setCountryListFun(responseJson)
            break;
          case this.getAnimalTypeApiId:
            this.setAnimalType(responseJson)
            break;
          case this.getBreedApiId:
            this.setBreed(responseJson)
            break;
          case this.postAdoptionApiId:
            this.handleNavAfterSuccess(responseJson)
            break;
          case this.postGenericFosterApiId:
            this.handleNavAfterSuccess(responseJson)
            break;
          case this.getMySubmittedRequestApiId:
            this.setState({isLoading:false})
            this.handleSetTableData(responseJson)
            break;
          case this.getPostDropdownListApiId:
            this.handleSetDropdownList(responseJson)
            break;
            case this.getRequestDetailsApiId:
              this.handleSetRequestDetails(responseJson)
              break;
          case this.getTemplateApiId:
            this.setTemplateData(responseJson)
            break;
            case this.postTemplateApiId:
               this.handleResponseForTemplate(responseJson)
              break;
          case this.markStatusApi:
            this.handleMarkStatusMessage(responseJson)
            break;
          case this.markRejectStatusApi:
            this.handleMarkRejectedMessage(responseJson)
            break;
        
          case this.getMyAdoptedPetsPostApiId:
            this.setMyadoptedPetsData(responseJson)
            break;
          case this.getBreedApiIdForAdoptedPets:
            this.setBreedListMyadoptedPetsData(responseJson)
            break;   
          case this.afterTrialPeriodApi:
            this.afterTrialPeriodResPonse(responseJson)
            break;
            case this.getAnimalTypedForMyAdoptedPets:
              this.setAnimalTypedForMyAdoptedPets(responseJson)
              break;
        }
      }
    }
  }

  componentDidMount = async () => {
    this.handleFetchUserProFile()
    this.handleFetchAnimalType()
    let urlParams = new URLSearchParams(window?.location?.search);
    const already_answer_given = urlParams.get('already_answer_given');
    this.setState({isAnsReceiveModal:already_answer_given?true:false})
    this.setState({pathName:window.location.pathname})
   this.handleHisPath()

    this.handlePathNameHeading(window.location.pathname)
    this.getMySubmittedRequest()
    this.getPostDropdownList()
    const emptyreqImg=await storage.get("received_requests_screen_with_no_requests_image")
    const emptySubmittedImg=await storage.get("submitted_requests_screen_with_no_requests_image")
    const emptySearchSubmittedImg=await storage.get("submitted_requests_screen_with_no_results_based_on_search_image")
    const emptySearchReqImg=await storage.get("received_requests_screen_with_no_results_based_on_search_image")
    this.setState({
      emptyNoSearchImgReq:emptyreqImg,
      emptyNoSearchImgSub:emptySubmittedImg,
      emptyPageSearchImgReq:emptySearchReqImg,
      emptyPageSearchImgSub:emptySearchSubmittedImg

    })
    const user_role=await getStorageData('auth_user_role')
    this.setState({user_role:user_role})

    this.fetchCountryList()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    let postId=await getStorageData('petPostId')||''
    this.setState({petId:postId})

   
    const postPath=window.location.pathname.split("/")
    const post_Id=postPath[postPath.length - 1]
    this.setState({postId:post_Id},()=>this.getRequestDetails())

    

    const adoption_trial_period_requester = urlParams.get('adoption_trial_period_requester');
    const adoption_trial_period_owner = urlParams.get('adoption_trial_period_owner');
    const trial_period=urlParams.get('trial_period');
    let showReceiveReqdHeading_on_request_detail=window?.location?.search?.includes('adoption_trial_period_owner')
    this.setState({showReceiveReq:showReceiveReqdHeading_on_request_detail})
    const isTrialPeriodModalOpen= adoption_trial_period_requester=="false"||adoption_trial_period_owner=="false"
    let isPathTemplate=this.props?.navigation?.history?.location?.pathname
    if(isPathTemplate=='/adoption-template'||isPathTemplate=='/foster-template'||isPathTemplate=='/foster-form'||isPathTemplate=='/adoption-form'){
      const  request_type=isPathTemplate=='/adoption-template'||isPathTemplate=='/adoption-form'?'Adoption':'Foster'
      this.handleMyTemplateFunction(request_type)
    }
    if(adoption_trial_period_owner=='true'||adoption_trial_period_requester=='true'){
      this.setState({showSuccessMessPop:true,SuccessMess:'Thank you for your response!'})
      this.props?.navigation?.history?.replace()
      this.handlnav('RequestDetails',this.state.postId)
    }
    else if(trial_period=='true'){
      this.setState({isTrialPeriodModalOpen:true,trial_period:true})
    }
    else{
      let isOwner=adoption_trial_period_owner=="false"
      this.setState({trial_period:false,isOwner:isOwner,continueAdoption:isTrialPeriodModalOpen?2:'',isAnsReceiveModal: already_answer_given=="true", showSuccessMessPop:already_answer_given=="true",SuccessMess:"Thanks for your response. We've previously received an answer on this adoption.",
      isTrialPeriodModalOpen: isTrialPeriodModalOpen})
    }

    
   
  
   this.call_getMyAdoptedPetsPost()
    window.scrollTo(0, 0)
  };
  handleFetchUserProFile=async()=>{
    const header = {
    "token": await getStorageData('auth_token')
  };
 
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getUserProFileApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.userProfileEndPoints
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
  
}

  fetchCountryList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryListApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryListEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleFetchAnimalType= async () => {
   

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAnimalTypeApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.Animal_TypeEndPoint
    );

    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  hanldeFetchBreed= async (id:any) => {
   
     
      
    let arrId=id==''?null:[id]
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBreedApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.breedEndPoint(arrId)
    );

    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  setCountryListFun=(data:any)=>{
   
     this.setState({countryList:data})
  }
  setAnimalType=(data:any)=>{
   
    this.setState({animalTypeList:data})
  }
  setBreed=(data:any)=>{
    let newArr=this.state.breedList
    newArr[this.state.currentIndex]=data
    this.setState({breedList:newArr},()=>{
       if(this.state.currentIndex+1<this.globalCategoryData.length){
    
         this.handleNewPetChange (this.state.currentIndex+1, 'category_id',  this.globalCategoryData[this.state.currentIndex+1]?.attributes?.category?.id)
  }

})}
handleNavAfterSuccess=async(data:any)=>{
let isErr=data?.errors
let IsErrInter=data?.error
let isPath= this.state.pathName=='/generic-foster-form'?'LandingPageWeb':'ProductDescriptionPublic'
let messageIs=data?.errors?data?.errors[0]?.post:'Your request has been successfully submitted.'
if(this.state.pathName=='/generic-foster-form'){
  messageIs="FOSTER REQUEST SUBMITTED! Your request to foster has been sent! We'll review it and get back to you faster than a cat chasing a mouse."
}
if(data?.data?.attributes?.errors?.postal_code?.length>0){
this.setState({postalCode_Err:true,postalCode_Err_value:data?.data?.attributes?.errors?.postal_code[0],isButtonDisabled:false})
return
}
if(data?.data?.attributes?.errors?.base?.length>0&&data?.data?.attributes?.errors?.base[0]){

  messageIs=data?.data?.attributes?.errors?.base[0]
}

this.setState({isButtonDisabled:false})

if(!IsErrInter){
  removeStorageData('petPostId');
   this.props.navigation.navigate(isPath,{isSnackBarOpen:true,msg:messageIs,id:this.state.petId})
}
}
setUserData=(data:any)=>{
  let user =data?.data?.attributes
  this.setState({
    first_Name:user?.first_name,
    last_Name:user?.last_name,
    email:user?.email,
    phone_Number:this.correctNumber(user?.full_phone_number),
    whatsAppNumber:this.correctNumber(user?.full_phone_number),
    dateofbirthForRef:moment.parseZone(user?.date_of_birth).format('MM/DD/YYYY'),
    dateofbirth:user?.date_of_birth,
    user_role:user?.user_role
  })
  
}
correctNumber=(number:any)=>{
   let isPresent=number?.includes('+')
   if(isPresent){
    return number
   }else{
    return '+'+number
   }
}
hanldeChange=(field: string, value: string, errorName: string)=>{
  if(errorName=='hours_Err'&&Number(value)>24){
   this.setState({hours_Err:true,hours:value})
   
  }
 else if(errorName=='postalCode_Err'){
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
      [errorName]: false
    }));
  }else{
    this.setState((prevState) => ({
    ...prevState,
    [field]: value,
    [errorName]: value !== "" ? false : true,
  })); 
  }
   
 }

 checkFosterErr=()=>{
  let error={
    howLongLivedFosterErr:this.state.fosterenLive==='',
    fosterTermsConditonErr:!this.state.fosterTermsConditon,
   
  } 
   this.setState(error)
  let allErrors=[error]
  const hasErrors = allErrors.some((errorsObject) =>
    Object.values(errorsObject).some((error) => error)
  );
  return hasErrors
 }
 checkForPleaseExplainErr=()=>{
   return this.state.pleaseExplain==''&&this.state.householdActivityLevel==4
 }
 checkGenFosterErr=()=>{
  let error={
   
    foseterGenericGenderErr:this.state.foseterGenericGender==='',
    dateofbirthErr:this.state.dateofbirth==''||this.state.dateofbirth==null,
    first_Name_Err:this.state.first_Name==''||this.state.first_Name==null,
    last_Name_Err:this.state.last_Name==''||this.state.last_Name==null,
    email_Err:this.state.email==''||this.state.email==null,

  } 

   this.setState(error)
  let allErrors=[error]
  const hasErrors = allErrors.some((errorsObject) =>
    Object.values(errorsObject).some((error) => error)
  );
  return hasErrors
  
 }
 handleHowLongLivedErr=()=>{
  return this.state.howLongLived===''&&(this.state.pathName=='/adoption-form'||this.state.pathName=='/adoption-template')
 }
 handleExpPetOwnerErr=()=>{
  return this.state.expPetOwner===''&&(this.state.pathName=='/adoption-form'||this.state.pathName=='/adoption-template')
 }
 handlReasonForAdoptErr=()=>{
  return this.state.reasonForAdopt.length===0&&(this.state.pathName=='/adoption-form'||this.state.pathName=='/adoption-template')
 }
 handleAllowOutSideErr=()=>{
  return this.state.allowOutSide===''&&(this.state.pathName=='/adoption-form'||this.state.pathName=='/adoption-template')
 }
 handleReLocationCostErr=()=>{
  return this.state.reLocationCost===''&&(this.state.pathName=='/adoption-form'||this.state.pathName=='/adoption-template')
 } 
 handleApartMentErr=()=>{
 return this.state.apartMent===''&&this.state.descAccommodation===2
 }
 handleOtherError=()=>{
  const isOtherChecked = this.state.reasonForAdopt?.some((item: any) => item === 'Other')
  this.setState({isOtherChecked:isOtherChecked})
  return this.state.reasonValue===''&&isOtherChecked
 }
 handlePleaseExplainErr2=()=>{
  return this.state.pleaseExplain2==''&&this.state.expPetOwner==4
 }
 handleCheckForPhoneNumber=(phoneNum:any)=>{
 if(phoneNum==""||phoneNum==undefined||!isValidPhoneNumber(phoneNum.toString())){
  return true
 }else{
  return false
 }
 }
 isFosterErrFun=()=>{
  return (this.state.pathName=="/foster-template"||this.state.pathName=="/foster-form" ||this.state.pathName=="/generic-foster-form")?this.checkFosterErr():false
 }
 isGeneFoterErrFun=()=>{
  return this.state.pathName=="/generic-foster-form"?this.checkGenFosterErr():false
 }
 checkErrForField=(err:any)=>{
  return err==''||err==null
 }
 handleSubmit=()=>{
  const errors = {
    first_Name_Err:this.checkErrForField(this.state.first_Name),
    last_Name_Err:this.checkErrForField(this.state.last_Name),
    email_Err:this.checkErrForField(this.state.email),
    address1_Err: this.handleErrCheck(this.state.address1),
    city_Err:this.state.city==='',
    county_Err:this.state.county==='',
    countryError:this.state.country==='Select',
    hours_Err:this.state.hours===''||this.state.hours>24,
    howLongLivedErr:this.handleHowLongLivedErr(),
    doYouErr:this.state.doYou==='',
    descAccommodationErr:this.state.descAccommodation==='',
    householdActivityLevelErr:this.state.householdActivityLevel==='',
    expPetOwnerErr:this.handleExpPetOwnerErr(),
    reasonForAdoptErr:this.handlReasonForAdoptErr() ,
    allowOutSideErr:this.handleAllowOutSideErr(),
    reLocationCostErr:this.handleReLocationCostErr(),
    apartMentErr:this.handleApartMentErr() ,
    phoneNumError:this.handleCheckForPhoneNumber(this.state.phone_Number),
    whatsAppNumberErr:this.handleCheckForPhoneNumber(this.state.whatsAppNumber),
    otherError:this.handleOtherError() ,
    pleaseExplainErr2:this.handlePleaseExplainErr2() ,
    pleaseExplainErr:this.checkForPleaseExplainErr(),
    howToContactErr:this.handleHowToContactErr(),
    fosterbalconygardenErr:this.handleCheckCommomRadioButton2(this.state.fosterbalconygarden),
    fosterenclosedErr:this.handleCheckForErr(this.state.fosterenclosed,this.state.fosterbalconygarden),
    livingAloneErr:this.handleCheckCommomRadioButton(this.state.livingAlone),
    fosteranimalshomeErr:this.handleCheckCommomRadioButton2(this.state.fosteranimalshome)
    
  };
  let isErrFoster=this.isFosterErrFun()
  let isGeneFoterErr=this.isGeneFoterErrFun()
 let isErrArr= this.handlePersonErr()
 let isPetErr=this.handlePetErr()
  const allErrors = [...isErrArr,...isPetErr, errors];
  const hasErrors = allErrors.some((errorsObject) =>
    Object.values(errorsObject).some((error) => error)
  );
  this.setState(errors);
  if(this.state.pathName=='/foster-template'||this.state.pathName=='/adoption-template'){
    if (!hasErrors&&!isErrFoster) {
        this.handleTemplateSubmit()
    }
  }
 else if(this.state.pathName==='/adoption-form'||this.state.pathName==='/foster-form'){
    if (!hasErrors&&!isErrFoster) {
     
       this.handlePostAdoption()
      }
     
    }else{
      if (!hasErrors&&!isErrFoster&&!isGeneFoterErr) {
this.handlePostGenericFoster()
      }
        
      }
 }
 handlePersonErr=()=>{
  const { newPersonEntry } = this.state;
  const errorsToUpdate = newPersonEntry.map((entry: any) => ({
    name_Err: entry.name === ""&&!this.state.livingAlone,
    relationship_Err: entry.relationship === ""&&!this.state.livingAlone,
    age_Err: entry.age === ""&&!this.state.livingAlone,
 }));

  this.setState({
    errorsArr: errorsToUpdate,
  });
  return errorsToUpdate
 }
 handlePetErr=()=>{
  const { newPetEntry } = this.state;
  const errorsToUpdate = newPetEntry.map((entry: any) => ({
    age_Err: (entry.age === ""&&this.state.expPetOwner==3)||(entry.age === ""&&this.state.fosteranimalshome),
    category_id_Err: (entry.category_id === ""&&this.state.expPetOwner==3)||(entry.category_id === ""&&this.state.fosteranimalshome),
    sub_category_id_Err: (entry.sub_category_id === ""&&this.state.expPetOwner==3)||( entry.sub_category_id === ""&&this.state.fosteranimalshome),
    sterilized_Err: entry.sterilized === ""&&this.state.expPetOwner==3,
    
 }));

  this.setState({
    errorsPetArr: errorsToUpdate,
  });
  return errorsToUpdate
 }
  returnBody=()=>{
    const body={
      "how_would_you_like_to_be_contacted": this.state.howToContactArr,
      ...(this.state.petId!==''&&{'post_id':this.state.petId}),
      "do_you": this.state.doYouName, 
      "accommodation": this.state.descAccommodationName, 
      "apartment_type": this.state.apartMentName, 
      "house_hold_activaty_level": this.state.householdActivityLevelName, 
      "hours_in_a_day_would_the_foster_be_left_alone": this.state.hours,
     ...(!this.state.livingAlone&&this.state.livingAlone!==null&&{"users_other_members_attributes":this.state.newPersonEntry}),
      ...(this.state.expPetOwner==3&&{"users_other_pets_attributes":this.state.newPetEntry}),
      'have_private_garden':this.state.accessToPrivateGarden!==null?this.state.accessToPrivateGarden:null,
      "how_long_you_lived_your_address": this.state.howLongLivedName,
      "living_alone": this.state.livingAlone,
      "experience_as_a_pet_owner": this.state.expPetOwnerName, 
      ...(this.state.expPetOwner==4&&{"other_please_explain_experience_as_a_pet_owner":this.state.pleaseExplain2}),
      "reason_for_wanting_to_adopt_a_new_pet":this.state.reasonForAdopt,
      ...(this.state.isOtherChecked&&{"other_please_explain_reason_for_wanting_to_adopt_a_new_pet": this.state.reasonValue}),
      "pet_allowed_outside": this.state.allowOutSideName, 
      "have_you_considered_the_relocation_cost_for_your_new_pet": this.state.reLocationCost===1 ,
      ...(this.state.householdActivityLevel==4 && {"other_please_explain_activity_level": this.state.pleaseExplain}),
      "request_type" :'Adoption', 
      "address_line_1":this.state.address1,
      "full_phone_number": this.state.phone_Number,
      "whatsapp_number" :this.state.whatsAppNumber,
      "date_of_birth": this.state.dateofbirth,
      "city":  this.state.city,
      "county":  this.state.county,
      "postal_code":  this.state.postalCode,
      "country_id": this.state.country,
      "address_line_2": this.state.address2,
      "first_name": this.state.first_Name,
      "last_name": this.state.last_Name,
      "email":  this.state.email,
  
  }
  return body
  }
  returnTemplateBody=()=>{
    let newPersonEntryArr=[...this.state.newPersonEntry,...this.state.removedPersonArr]
    let newPetEntryArr=[...this.state.newPetEntry,...this.state.removedPetsArr]

    const body={
      "do_you": this.state.doYouName, 
      "apartment_type": this.state.apartMentName, 
      "accommodation": this.state.descAccommodationName, 
      "house_hold_activaty_level": this.state.householdActivityLevelName, 
      ...(!this.state.livingAlone&&this.state.livingAlone!==null&&{"users_other_members_attributes":newPersonEntryArr}),
      "hours_in_a_day_would_the_foster_be_left_alone": this.state.hours,
      ...(this.state.expPetOwner==3&&{"users_other_pets_attributes":newPetEntryArr}),
      "how_long_you_lived_your_address": this.state.howLongLivedName, 
      'have_private_garden':this.state.accessToPrivateGarden!==null?this.state.accessToPrivateGarden:null,
      "living_alone": this.state.livingAlone,
      ...(this.state.expPetOwner==4&&{"other_please_explain_experience_as_a_pet_owner":this.state.pleaseExplain2}),
      "experience_as_a_pet_owner": this.state.expPetOwnerName, 
      "reason_for_wanting_to_adopt_a_new_pet":this.state.reasonForAdopt,
      "pet_allowed_outside": this.state.allowOutSideName, 
      ...(this.state.isOtherChecked&&{"other_please_explain_reason_for_wanting_to_adopt_a_new_pet": this.state.reasonValue}),
      "have_you_considered_the_relocation_cost_for_your_new_pet": this.state.reLocationCost===1 ,
      ...(this.state.householdActivityLevel==4 && {"other_please_explain_activity_level": this.state.pleaseExplain}),
      "address_line_1":this.state.address1,
      "request_type" :'Adoption', 
      "whatsapp_number" :this.state.whatsAppNumber,
      "date_of_birth": this.state.dateofbirth,
      "full_phone_number": this.state.phone_Number,
      "city":  this.state.city,
      "county":  this.state.county,
      "postal_code":  this.state.postalCode,
      "country_id": this.state.country,
      "email":  this.state.email,
      "address_line_2": this.state.address2,
      "first_name": this.state.first_Name,
      "last_name": this.state.last_Name,
      "how_would_you_like_to_be_contacted": this.state.howToContactArr,
  "other_animal_at_home":this.state.expPetOwner==3
  }
  return body
  }
  returnBody2=()=>{
    const body={
      "house_hold_activaty_level": this.state.householdActivityLevelName, 
      ...(this.state.householdActivityLevel==4 && {"other_please_explain_activity_level": this.state.pleaseExplain}),
   "if_children_pet_will_need_space_away_from_the_children_at_times":this.state.fosterchildrenattimes!==null?this.state.fosterchildrenattimes:null,
      "other_animal_at_home": this.state.fosteranimalshome,
      "how_long_can_you_foster_for": this.state.fosterForName,   
      "do_you": this.state.doYouName, 
      "accommodation": this.state.descAccommodationName, 
      "not_then_extra_care_when_open_close_door":this.handleConditionForPayload() ,
      "apartment_type": this.state.apartMentName, 
      "is_the_balcony_garden_enclosed_or_cat_proofed": this.state.fosterbalconygarden,
      ...(this.state.petId!==''&&{'post_id':this.state.petId}),
       "request_type" : 'Foster',
      "address_line_1":this.state.address1,
      "how_would_you_like_to_be_contacted": this.state.howToContactArr,
      "full_phone_number": this.state.phone_Number,
      "date_of_birth": this.state.dateofbirth,
      "whatsapp_number" :this.state.whatsAppNumber,
      "county":  this.state.county,
      
      "terms_accepted": this.state.fosterTermsConditon,
      "email":  this.state.email,
      "hours_in_a_day_would_the_foster_be_left_alone": this.state.hours,
      ...(this.state.fosteranimalshome&&{"users_other_pets_attributes":this.state.newPetEntry}),

      "postal_code":  this.state.postalCode,
      "address_line_2": this.state.address2,
      "first_name": this.state.first_Name,
      "last_name": this.state.last_Name,
      
      ...(!this.state.livingAlone&&this.state.livingAlone!==null&&{"users_other_members_attributes":this.state.newPersonEntry}),
      "how_long_you_lived_your_address": this.state.howLongLivedName,
      "living_alone": this.state.livingAlone,
      "reason_for_wanting_to_adopt_a_new_pet":this.state.reasonForAdopt,
      "experience_as_a_pet_owner": this.state.expPetOwnerName,
      "pet_allowed_outside": this.state.allowOutSideName, 
      ...(this.state.isOtherChecked&&{"other_please_explain_reason_for_wanting_to_adopt_a_new_pet": this.state.reasonValue}),
      "country_id": this.state.country,
      "have_you_considered_the_relocation_cost_for_your_new_pet": this.state.reLocationCost===1 ,
      "vehicle_to_bring_your_foster_cat_to_events_and_appointments":this.state.fosterappointments!==null? this.state.fosterappointments:null,
      "city":  this.state.city,
      "are_you_willing_and_able_to_medicate_your_foster_cat_if_applica": this.state.fosterapplicable!==null?this.state.fosterapplicable:null,
  }
  return body
  }
  returnTemplateBody2=()=>{
    let newPetEntryArr=[...this.state.newPetEntry,...this.state.removedPetsArr]
    let newPersonEntryArr=[...this.state.newPersonEntry,...this.state.removedPersonArr]
    const body={
      "house_hold_activaty_level": this.state.householdActivityLevelName, 
      ...(this.state.householdActivityLevel==4 && {"other_please_explain_activity_level": this.state.pleaseExplain}),
      "other_animal_at_home": this.state.fosteranimalshome,
      "how_would_you_like_to_be_contacted": this.state.howToContactArr,
      "if_children_pet_will_need_space_away_from_the_children_at_times":this.state.fosterchildrenattimes!==null?this.state.fosterchildrenattimes:null,
      "do_you": this.state.doYouName, 
      "how_long_can_you_foster_for": this.state.fosterForName,  
      "apartment_type": this.state.apartMentName, 
      "accommodation": this.state.descAccommodationName, 
      "not_then_extra_care_when_open_close_door": this.handleConditionForPayload(),
      "request_type" : 'Foster',
      "is_the_balcony_garden_enclosed_or_cat_proofed": this.state.fosterbalconygarden,
       
       "whatsapp_number" :this.state.whatsAppNumber,
       "address_line_1":this.state.address1,
      "full_phone_number": this.state.phone_Number,
      "date_of_birth": this.state.dateofbirth,
      "county":  this.state.county,
      "address_line_2": this.state.address2,
      "postal_code":  this.state.postalCode,
      "last_name": this.state.last_Name,
      "first_name": this.state.first_Name,
      "email":  this.state.email,
     
      "hours_in_a_day_would_the_foster_be_left_alone": this.state.hours,
      "terms_accepted": this.state.fosterTermsConditon,
     ...(!this.state.livingAlone&&this.state.livingAlone!==null&&{"users_other_members_attributes":newPersonEntryArr}),
      ...(this.state.fosteranimalshome&&{"users_other_pets_attributes":newPetEntryArr}),
      "how_long_you_lived_your_address": this.state.howLongLivedName, 
      "living_alone": this.state.livingAlone,
      "experience_as_a_pet_owner": this.state.expPetOwnerName,
      "reason_for_wanting_to_adopt_a_new_pet":this.state.reasonForAdopt,
      ...(this.state.isOtherChecked&&{"other_please_explain_reason_for_wanting_to_adopt_a_new_pet": this.state.reasonValue}),

      "pet_allowed_outside": this.state.allowOutSideName, 
      "have_you_considered_the_relocation_cost_for_your_new_pet": this.state.reLocationCost===1 ,
      "country_id": this.state.country,
      "city":  this.state.city,
      "vehicle_to_bring_your_foster_cat_to_events_and_appointments":this.state.fosterappointments!==null? this.state.fosterappointments:null,
       "are_you_willing_and_able_to_medicate_your_foster_cat_if_applica": this.state.fosterapplicable!==null?this.state.fosterapplicable:null,
    
  }
  return body
  }
  returnBody3=()=>{
    const body={
      "how_would_you_like_to_be_contacted": this.state.howToContactArr,
      "first_name": this.state.first_Name,
      "last_name": this.state.last_Name,
      "email":  this.state.email,
      "full_phone_number": this.state.phone_Number,
      "whatsapp_number" :this.state.whatsAppNumber,
      "date_of_birth": this.state.dateofbirth,
      "city":  this.state.city,
      "county":  this.state.county,
      "postal_code":  this.state.postalCode,
      "country_id": this.state.country,
      "how_long_can_you_foster_for": this.state.fosterForName,   
      "request_type" : 'Generic Foster',
      "address_line_1":this.state.address1,
      "address_line_2": this.state.address2,
      "do_you": this.state.doYouName, 
     "what_age_would_you_consider": !this.state.willingFosterAnimal&&this.state.willingFosterAnimal!==null&&this.state.foseterGenericAgeName!==''?this.state.foseterGenericAgeName:null, 
     "vehicle_to_bring_your_foster_cat_to_events_and_appointments":this.state.fosterappointments!==null? this.state.fosterappointments:null,
       "are_you_willing_and_able_to_medicate_your_foster_cat_if_applica": this.state.fosterapplicable!==null?this.state.fosterapplicable:null,
      "accommodation": this.state.descAccommodationName, 
      "apartment_type": this.state.apartMentName, 
      "is_the_balcony_garden_enclosed_or_cat_proofed": this.state.fosterbalconygarden,
      "not_then_extra_care_when_open_close_door": this.handleConditionForPayload(),
      "house_hold_activaty_level": this.state.householdActivityLevelName, 
      ...(this.state.householdActivityLevel==4 && {"other_please_explain_activity_level": this.state.pleaseExplain}),
      "if_children_pet_will_need_space_away_from_the_children_at_times":this.state.fosterchildrenattimes!==null?this.state.fosterchildrenattimes:null,

      "other_animal_at_home": this.state.fosteranimalshome,
      "preference_in_gender_to_foster": this.state.foseterGenericGenderName, 
    "are_you_willing_to_foster_a_pet_of_any_age":this.state.willingFosterAnimal!==null? this.state.willingFosterAnimal:null,
      "hours_in_a_day_would_the_foster_be_left_alone": this.state.hours,
      "terms_accepted": this.state.fosterTermsConditon,
     ...(!this.state.livingAlone&&this.state.livingAlone!==null&&{"users_other_members_attributes":this.state.newPersonEntry}),
      ...(this.state.fosteranimalshome&&{"users_other_pets_attributes":this.state.newPetEntry}),
      "living_alone": this.state.livingAlone,
   
    
  }
  return body
  }
 handlePostAdoption=async()=>{
  this.setState({isButtonDisabled:true})

  const header = {
    "token": await getStorageData('auth_token'),
    'Content-Type': configJSON.exampleApiContentType
  };

   const body=this.state.pathName=='/adoption-form'?this.returnBody():this.returnBody2()


  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.postAdoptionApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.request_role_type
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
  
 }
 handlePostGenericFoster=async()=>{
  this.setState({isButtonDisabled:true})
  const header = {
  
    'Content-Type': configJSON.exampleApiContentType
  };

   const body=this.returnBody3()


  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.postGenericFosterApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.generic_foster
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
  
 }
 handleTemplateSubmit=async()=>{
  
  this.setState({isButtonDisabled:true})
  const header = {
    "token": await getStorageData('auth_token'),
    'Content-Type': configJSON.exampleApiContentType
  };
  const body=this.state.pathName=='/adoption-template'?this.returnTemplateBody():this.returnTemplateBody2()
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.postTemplateApiId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    this.state.idUpdatedTemp?.length>0?configJSON.request_templates(this.state.idUpdatedTemp):configJSON.request_templates_post
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
   this.state.idUpdatedTemp?.length>0?'PUT':configJSON.exampleAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
 }
 hanldeNavigation=(path:any)=>{
  const msgs = new Message(getName(MessageEnum.NavigationMessage));
  msgs.addData(getName(MessageEnum.NavigationTargetMessage),path);
  msgs.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
  this.send(msgs)
 }
 hanldeNavigationForCancel=()=>{
  
  if(this.state.pathName=='/generic-foster-form'){
    this.hanldeNavigation('LandingPageWeb')
  }else if(this.state.pathName.includes('template')){
    this.hanldeNavigation('MySubmittedRequests')
   
  }else{
    removeStorageData('petPostId');
    this.props.navigation.navigate('ProductDescriptionPublic',{isSnackBarOpen:false,msg:'',id:this.state.petId})
  }
 }
  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  getAllReceivedRequest = () => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllReceivedRequestCallId = getAllRequestMsg.messageId;

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReceivedRequestApiEndpoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getReceivedRequestApiMethod
    );
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  updateRequestReview = (requestReviewId: string, is_accepted: boolean) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    if (!is_accepted && this.state.rejectText.length < 1) {
      this.setState({ rejectTextError: configJSON.rejectTextFieldIsRequired });
      return;
    }

    const httpBody = {
      is_accepted,
      ...(!is_accepted && {
        rejection_reason: this.state.rejectText,
      }),
    };

    const updateRequestReviewMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateRequestReviewCallId = updateRequestReviewMsg.messageId;

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateRequestApiEndpointStart +
        requestReviewId +
        configJSON.updateRequestApiEndpointEnd
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestApiMethod
    );

    updateRequestReviewMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(updateRequestReviewMsg.id, updateRequestReviewMsg);
  };

  deleteRequest = (deleteRequestId: string) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const deleteRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRequestCallId = deleteRequestMsg.messageId;

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteRequestApiEndpoint + deleteRequestId
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteRequestApiMethod
    );

    runEngine.sendMessage(deleteRequestMsg.id, deleteRequestMsg);
  };

  onChangeRejectText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rejectText: event.target.value });
  };

  onChangeTextRejectText = (rejectText: string) => {
    this.setState({ rejectText });
  };

  setSelectedId = (selectedId: string | null) => {
    this.setState({ selectedId, rejectText: "", rejectTextError: null });
  };

  navigateHandler = () => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), "SentRequest");

    navigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    
    navigationMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(navigationMsg);
  };

  setViewRequest = (viewRequest: IRequest) => {
    this.setState({viewRequest})
  }

  closeViewModal = () => {
    this.setState({viewRequest: null})
  }

  onChangeFilterKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ filterKey: event.target.value });
  };

  onChangeTextFilterKey = (filterKey: string) => {
    this.setState({ filterKey });
  };
 
  handleColorPhoneInput=()=>{
    return this.state.phoneNumError ? "#F87171" : "#CBD5E1"
  }
  convertDateFormat=()=>{
    return   this.state.dateofbirthForRef && moment(this.state.dateofbirthForRef).format("DD/MM/YYYY")
     }
  handleOpenDatePicker() {
      
      this.setState({isDatePickerOpen:!this.state.isDatePickerOpen})
      
    }
  handleChangesForDate = (selectedDate: any) => {
    this.setState({ dateofbirthForRef: moment(selectedDate).format('MM/DD/YYYY') });

    this.setState({ dateofbirth:selectedDate,isDatePickerOpen: false, dateofbirthErr: false });
  };

  handleColorStyleForSelect = (field: keyof MyComponentState) => {
    const fieldValue = this.state[field];
    return fieldValue === "Select" ? "#94A3B8" : "rgba(15, 23, 42, 1)";
  };
  handleHowLongLived= (item: any) => {
    this.setState({ howLongLived: item.id,howLongLivedErr:false, howLongLivedName:item.name})
  }
  handlDoYou= (item: any) => {
    this.setState({ doYou: item.id ,doYouErr:false,doYouName:item.name})
  }
  handlApartment= (item: any) => {
    this.setState({ apartMent: item.id ,apartMentErr:false,apartMentName:item.name})
  }
  handlExpPetOwner= (item: any) => {
    this.setState({ expPetOwner: item.id ,expPetOwnerErr:false,expPetOwnerName:item.name2})
  }
  handleallowOutSide=(item: any)=>{
    this.setState({ allowOutSide: item.id ,allowOutSideErr:false,allowOutSideName:item.name})
  }
  handleRelocationCost=(item: any)=>{
    this.setState({ reLocationCost: item.id,reLocationCostErr:false ,reLocationCostName:item.name})
  }
  handlDescribeAccommodation= (item: any) => {
    this.setState({ descAccommodation: item.id,descAccommodationErr:false,descAccommodationName:item.name })
  }
  handleHouseholdActivityLevel= (item: any) => {
    this.setState({ householdActivityLevel: item.id,householdActivityLevelErr:false ,householdActivityLevelName:item.name})
  }
  handleAccessToPrivateGarden=(state:boolean) => {
    this.setState({ accessToPrivateGarden: state})
}
handlelivingAlone=(state:boolean) => {
  this.setState({ livingAlone: state,livingAloneErr:false })
  const { newPersonEntry } = this.state;
  const errorsToUpdate = newPersonEntry.map((entry: any) => ({
    name_Err: false,
    relationship_Err: false,
    age_Err: false
 }));

  this.setState({
    errorsArr: errorsToUpdate,
  });
}
hanldeSterilized=(item:any)=>{
  this.setState({ selectedSterilized: item.id })
}
handleReasonForAdopt=(itemName: any) => {
  this.setState((prevState:any) => {
      const updatedReson = prevState?.reasonForAdopt?.includes(itemName.name)
          ? prevState?.reasonForAdopt.filter((selected: any) => selected !== itemName.name)
          : [...prevState.reasonForAdopt, itemName.name];

      return {
          reasonForAdopt: updatedReson,
          reasonForAdoptErr: updatedReson.length === 0,
      };
  });
 
 
}

validateFields = (field: string, value: any, formattedValue: any) => {

  if (field === 'name' && value.trim() === '') {
    return true;
  }
  if (field === 'relationship' && value.trim() === '') {
    return true;
  }
  if (field === 'age' && value.trim() === '') {
    return true;
  }
  return false;
};
validationPetFields = (field: string, value: any, formattedValue: any) => {
  if (field === 'age' && value.trim() === '') {
    return true;
  }
  
 
  
  return false;
};
handleNewEntryChange = (index: number, field: any, value: any) => {
  this.setState((prevState) => {
    const updatedEntrie = prevState.newPersonEntry.map((entry: any, i: number) =>
      i === index ? { ...entry, [field]: value } : entry
    );

    const updatedError = prevState.errorsArr.map((error: any, i: number) =>
      i === index
        ? {
          ...error,
          [`${field}_Err`]: this.validateFields(field, value, updatedEntrie[index].formattedValue),
        }
        : error
    );

    return {
      newPersonEntry: updatedEntrie,
      errorsArr: updatedError,
      newEntry:
        index === prevState.newPersonEntry.length
          ? { ...prevState.newEntry, [field]: value }
          : prevState.newEntry,
    };
  });
};
handleNewPetChange = (index: number, field: any, value: any,manualy?:any) => {

 
 this.setState({currentIndex:index})

  field=='category_id'&&this.hanldeFetchBreed(value)
  if(field=='category_id'&&manualy){
    this.call_function1_change(index, field, value)
   
  }else{
    this.call_function2_change(index, field, value)
  }
 
};
call_function1_change=(index: number, field: any, value: any,)=>{
  this.setState((prevState) => {
    const updatedEntrie = prevState.newPetEntry.map((entry: any, i: number) =>
      i === index ? { ...entry, [field]: value,['sub_category_id']:''} : entry
    );

    const updatedError = prevState.errorsPetArr.map((error: any, i: number) =>
      i === index
        ? {
          ...error,
          [`${field}_Err`]: this.validationPetFields(field, value, updatedEntrie[index].formattedValue),
        }
        : error
    );

    return {
      newPetEntry: updatedEntrie,
      errorsPetArr: updatedError,
      newPet:
        index === prevState.newPetEntry.length
          ? { ...prevState.newPet, [field]: value }
          : prevState.newPet,
    };
  });
}
call_function2_change=(index: number, field: any, value: any,)=>{
  this.setState((prevState) => {
    const updatedEntrie = prevState.newPetEntry.map((entry: any, i: number) =>
      i === index ? { ...entry, [field]: value} : entry
    );

    const updatedError = prevState.errorsPetArr.map((error: any, i: number) =>
      i === index
        ? {
          ...error,
          [`${field}_Err`]: this.validationPetFields(field, value, updatedEntrie[index].formattedValue),
        }
        : error
    );

    return {
      newPetEntry: updatedEntrie,
      errorsPetArr: updatedError,
      newPet:
        index === prevState.newPetEntry.length
          ? { ...prevState.newPet, [field]: value }
          : prevState.newPet,
    };
  });
}
handleRemovePerson = (index:any) => {
  if(this.state.livingAlone){
    return
  }else{

  
  this. setState((prevState) => {
     const updatedEntrie = [...prevState.newPersonEntry];
     const updatedError = [...prevState.errorsArr];
    let removedPersonArr = [...this.state.removedPersonArr]
    let removedPers = updatedEntrie.splice(index, 1);
     updatedError.splice(index, 1);
    if (removedPers[0]?.id) {
      let obj = { id: removedPers[0]?.id, _destroy: true }
      removedPersonArr.push(obj)
      this.setState({ removedPersonArr: removedPersonArr })
    }
     return { ...prevState, newPersonEntry: updatedEntrie, errorsArr: updatedError };
   });
  }
 };
 handleRemovePet = (index:any) => {
    
  this. setState((prevState) => {
     const updatedEntriePet = [...prevState.newPetEntry];
     const updatedErrorPet = [...prevState.errorsPetArr];

     const newBreedList=[...this.state.breedList]
     newBreedList.splice(index,1)
     let removedPetsArr=[...this.state.removedPetsArr]
    let removedPet= updatedEntriePet.splice(index, 1);
     updatedErrorPet.splice(index, 1);
     if (removedPet[0]?.id) {
      let obj = { id: removedPet[0]?.id, _destroy: true }
      removedPetsArr.push(obj)
      this.setState({ removedPetsArr: removedPetsArr })
    }
     return { ...prevState, newPetEntry: updatedEntriePet, errorsPetArr: updatedErrorPet ,breedList:newBreedList};
   });
  
 };
 handleChangePhoneNumber = (e:any) => {
  if (e && isValidPhoneNumber(e.toString())) {
    this.setState({ phone_Number: e, phoneNumError: false });
    return true;
  } else {
    this.setState({ phone_Number: e, phoneNumError: true });
    return false;
  }
}
handleChangeWhatsAppNumber = (e:any) => {
  if (e && isValidPhoneNumber(e.toString())) {
    this.setState({ whatsAppNumber: e, whatsAppNumberErr: false });
    return true;
  } else {
    this.setState({ whatsAppNumber: e, whatsAppNumberErr: true });
    return false;
  }
}
handleNewAddAnotherPerson = () => {
  if(this.state.livingAlone){
    return
  }else{

  
  const newErrors = {
    name_Err: false,
    relationship_Err: false,
    age_Err: false,
  };
  
  this.setState((prevState) => ({
    newPersonEntry: [
      ...prevState.newPersonEntry,
      { ...prevState.newEntry },
    ],
    newEntry: {
      name: "",
      relationship: "",
      age: "",

    },
    errorsArr: [...prevState.errorsArr, newErrors],
    
  }));
}
};
handleNewAddAnotherPet = () => {
 

  
  const newErrors = {
    age_Err: false,
    category_id_Err: false,
    sub_category_id_Err: false,
    any_health_condition_Err: false,
    sterilized_Err: false 
  };
  
  this.setState((prevState) => ({
    newPetEntry: [
      ...prevState.newPetEntry,
      { ...prevState.newPet },
    ],
    newPet: {
      age: '',
    category_id: '',
    sub_category_id: '',
    any_health_condition: '',
    sterilized: ''
    },
    errorsPetArr: [...prevState.errorsPetArr, newErrors],
    
  }));

};
handleOtherErroMessage=(state:any)=>{
  return state&& "Please enter a value"
    }
    handlefosterWilling=(state:boolean) => {
      this.setState({ willingFosterAnimal: state})
    }
    handleGenderFoster= (item: any) => {
      this.setState({ foseterGenericGender: item.id,foseterGenericGenderErr:false ,foseterGenericGenderName:item.name})
    }
    handleAgeFoster= (item: any) => {
      this.setState({ foseterGenericAge: item.id,foseterGenericAgeErr:false ,foseterGenericAgeName:item.name})
    }
    handleHeadingaccommodation=()=>{
      return this.state.pathName=="/adoption-form"?"Please describe your accommodation set up ":"Please describe your accommodation"
    }
  
     handlePathNameHeading=(path:string)=>{
    if(path=="/adoption-form"){
    this.setState({heading:"Adoption Request Form"})
    }
    else if(path=="/foster-form"){
      this.setState({heading:"Foster Request Form"})
    
    }
    else if(path=="/generic-foster-form"){
    this.setState({heading:"Foster Request Form"})
    
    }
    else if(path=="/foster-template"){
      this.setState({heading:"My Foster Form"})
      
      }
      else if(path=="/adoption-template"){
        this.setState({heading:"My Adoption Form"})
        
        }

    }
    
     handleHowLongFoster= (item: any) => {
        this.setState({ fosterenLive: item.id, fosterForName:item.name,howLongLivedFosterErr:false})
      }
    handlfosterapplicable=(state:boolean) => {
      this.setState({ fosterapplicable: state })
    }
    handlefosterappointments=(state:boolean) => {
      this.setState({ fosterappointments: state })
    }
    handlefosteranimalshome=(state:boolean) => {
      this.setState({ fosteranimalshome: state,fosteranimalshomeErr:false})
    }
    handleFosterChildren=(state:boolean) => {
      this.setState({ fosterchildrenattimes: state })
    }
    handlefosterTermsConditon=() => {
      this.setState({ fosterTermsConditon: !this.state.fosterTermsConditon,fosterTermsConditonErr:false })
    }
    handlebalconyGardenClose=(state:boolean) => {
      this.setState({ fosterbalconygarden: state, fosterbalconygardenErr:false})
    }
    handlefosterenclosed=(state:boolean) => {
      this.setState({ fosterenclosed: state, fosterenclosedErr:false})
    }
    getRequestDetails=async()=>{
      const header = {
        "token": await getStorageData('auth_token')
      };
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getRequestDetailsApiId = requestMessage.messageId;
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getRequestDetailsEndPoints(this.state.postId)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
    handleSetRequestDetails=(data:any)=>{
      const newData=data?.data
      this.setState({RequestDetails:newData})
    }
    dateConverterFormate = (dateString:string,onlyYears:boolean) => {
      const inputDate = new Date(dateString);

      const currentDate = new Date();
      const timeDiffer =Number(currentDate) - Number(inputDate);
      const daysDiffer = Math.floor(timeDiffer / (1000 * 60 * 60 * 24));
      const monthsDifference = Math.floor(daysDiffer / 30);
      const yearsDifference = Math.floor(monthsDifference / 12);
    
      let result;
    
      if (daysDiffer < 30) {
        result = `${daysDiffer} day${daysDiffer > 1 ? 's' : ''}`;
      } else if (monthsDifference < 13) {
        result = `${monthsDifference} month${monthsDifference > 1 ? 's' : ''}`;
      } else {
        result = `${yearsDifference} Year${yearsDifference > 1 ? 's' : ''}`;
    
        if (monthsDifference % 12 !== 0&&!onlyYears) {
          result += ` ${monthsDifference % 12} Month${monthsDifference % 12 > 1 ? 's' : ''}`;
        }
      }
    
      return result;
    };

    handleMenuPost = (index: number,name:any) => {
      const { postTypeOption } = this.state;
      
      this.setState({ currentTablePage: 1 });
       if (index === 0) {
        this.setState({ postTypeOption: [0] });
        this.getMySubmittedRequest()
        
      } else {
        const updatedOptions = postTypeOption.includes(index)
          ? postTypeOption.filter((item: any) => item !== index)
          : [...postTypeOption, index];
    
        if (updatedOptions.length === this.state.PostArr.length-1 && !updatedOptions.includes(0)) {
          this.setState({ postTypeOption: [0] });
          this.getMySubmittedRequest()
        } 
        else if(updatedOptions.length==0){
          this.setState({ postTypeOption: [0] },()=>this.getMySubmittedRequest());
          
        }
          else {
          this.setState({ postTypeOption: updatedOptions.filter((option:any) => option !== 0) });
          this.getMySubmittedRequest()
        }
      }
    };
    handleMenuType = (index: number,name:any) => {
      const { TypeOption } = this.state;
      this.setState({ currentTablePage: 1 });
      if (index === 0) {
        this.setState({ TypeOption: [0] });
        this.setState({ typeString: "" });
        this.getMySubmittedRequest()
      } else {
        const updatedOptions = TypeOption.includes(index)
          ? TypeOption.filter((item: any) => item !== index)
          : [...TypeOption, index];
    
        if (updatedOptions.length === this.state.typeArr.length-1 && !updatedOptions.includes(0)) {
          this.setState({ TypeOption: [0] });
          this.setState({ typeString: "" });
          this.getMySubmittedRequest()
        } 
        else if (updatedOptions.length === 0) {
          this.setState({ typeString: "" });
          this.setState({ TypeOption: [0] });
          this.getMySubmittedRequest()
        }
        else {
          this.setState({ TypeOption: updatedOptions.filter((option:any) => option !== 0) });
          
          this.setState({typeString:name})
         this.getMySubmittedRequest()
        }
      }
    };

    handleMenustatus= (index: number,name:any) => {
      const { statusOption } = this.state;
      this.setState({ currentTablePage: 1 });
      if (index === 0) {
        this.setState({ statusOption: [0] });
        this.getMySubmittedRequest()
        this.setState({statusOptionStringArr:[]})

      } else {
        const updatedOptions = statusOption.includes(index)
          ? statusOption.filter((item: any) => item !== index)
          : [...statusOption, index];
    
        if (updatedOptions.length === this.state.statusArr.length-1 && !updatedOptions.includes(0)) {
          this.setState({ statusOption: [0] });
        this.getMySubmittedRequest()
        this.setState({statusOptionStringArr:[]})

        } 
        else if (updatedOptions.length === 0) {
          this.setState({ statusOption: [0] });
          this.setState({statusOptionStringArr:[]})
          this.getMySubmittedRequest()

        }
        else {
          this.setState({ statusOption: updatedOptions.filter((option:any) => option !== 0) });
          if (this.state.statusOptionStringArr.includes(name)) {
            const filteredArray = this.state.statusOptionStringArr.filter((n:any) => n !== name);
            this.setState({ statusOptionStringArr: filteredArray });
          } else {
            const newArray = [...this.state.statusOptionStringArr, name];
            this.setState({ statusOptionStringArr: newArray });
          }
        this.getMySubmittedRequest()
          
        }
      }
    };
    handleListKeyDownMenu(event: any, stateName: any) {
      if (event.key === 'Tab') {
        event.preventDefault();
        this.setState({ [stateName]: false }as Pick<any, keyof any>);
      }
     }
  returnCondition = () => {
    return this.state.statusOptionStringArr !== '' && this.state.statusOptionStringArr.length !== 0
  }
  returnCondition2 = () => {
    return this.state.typeString !== ''
  }
  returnCondition3 = () => {
    return this.state.postTypeOption[0] != 0 && this.state.postTypeOption.length !== 0
  }
  returnCondition4 = () => {
    return   this.state.searchKeyword!==''&&this.state.searchKeyword!==null   

  }
   getMySubmittedRequest=async()=>{
    this.setState({isLoading:true})
    const headers = { 
      "token": await getStorageData('auth_token'),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMySubmittedRequestApiId = requestMessage.messageId;
    let endPoint=''
    if(this.returnCondition())
    {
      endPoint=endPoint?endPoint+"&status="+JSON.stringify(this.state.statusOptionStringArr):"&status="+JSON.stringify(this.state.statusOptionStringArr)
    }
    if(this.returnCondition2()){
      endPoint=endPoint?endPoint+"&type="+this.state.typeString:"&type="+this.state.typeString
    }
    if(this.returnCondition3()){
      endPoint=endPoint?endPoint+"&post_id="+JSON.stringify(this.state.postTypeOption):"&post_id="+JSON.stringify(this.state.postTypeOption)
    }
    if(this.returnCondition4()){
      endPoint=endPoint?endPoint+"&keyword="+this.state.searchKeyword:"&keyword="+this.state.searchKeyword
      
    }
    if(this.state.pathName=='/my-received-requests'){
      endPoint=endPoint?endPoint+"&request_name="+"Received":"&request_name="+"Received"
    }
    let  url=`${configJSON.my_Submitted_Requests_end_points}?page=${this.state.currentTablePage}&per_page=10&sort_by=${this.state.sortValue}`+endPoint
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
   }
   getPostDropdownList=async()=>{
    
    const headers = { 
      "token": await getStorageData('auth_token'),
    };
   const endPoint=this.state.pathName=='/my-submitted-requests'?'':'?request_name=Received'
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostDropdownListApiId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.post_type_dropDownList_end_points(endPoint)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
   }
   handleSetTableData=(data:any)=>{
    this.setState({isLoading:false})
     let newData=data?.data
     let newMeta=data?.meta
     if(newData?.length>0){
       this.setState({tableData:newData,tableLastPage:newMeta.last_page,tableTotalRequest:newMeta.total_requests,showEmtyImage:false,showEmtyImageForNoSubmittedReq:false})
       window.scrollTo(0, 0)
      }else{
     let isTrue= ((!this.state.TypeOption.includes(0)) ||
  (!this.state.postTypeOption.includes(0)) ||
  ( !this.state.statusOption.includes(0)) ||
  this.state.searchKeyword!==""&&this.state.searchKeyword!==null
  )

  if(isTrue){

    this.setState({tableData:[],tableLastPage:'',tableTotalRequest:'',showEmtyImageForNoSubmittedReq:false,showEmtyImage:true})
    window.scrollTo(0, 0)
  }else{
    this.setState({tableData:[],tableLastPage:'',tableTotalRequest:'',showEmtyImageForNoSubmittedReq:true,showEmtyImage:false})
    window.scrollTo(0, 0)
  }
     }
   }
   handleSetDropdownList=(data:any)=>{
    let newData=data?.posts
    if(newData){
      let dta=[...this.state.PostArr,...newData]
      this.setState({PostArr:dta})
    }
   }
   handleChangePageForTable=(e:any,page:any)=>{
    this.setState({currentTablePage:page})
    this.getMySubmittedRequest()
    }
    handleClearSeachInput=(a:any,b:any)=>{
     
      if(a.type=='click'){
       this.setState({searchKeyword:''},()=>this.getMySubmittedRequest())
       
      }
   }
formateDate=(realDate:any)=>{
  const date = new Date(realDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate
}
clearOptionFilter = (optionName: string, indexToRemove: number) => {
  const updatedOptions = this.state[optionName as keyof MyComponentState].filter((index: number) => index !== indexToRemove);

  if (updatedOptions.length === 0) {
    updatedOptions.push(0);
  }

  this.setState({ [optionName]: updatedOptions } as Pick<MyComponentState, keyof MyComponentState>);
  this.getMySubmittedRequest()
};
clearOptionFilterType = (indexToRemove: number,name:any) => {
  const updatedOptions = this.state.TypeOption.filter((index: number) => index !== indexToRemove);

  if (updatedOptions.length === 0) {
    updatedOptions.push(0);
    this.setState({typeString:""})
  }

  this.setState({TypeOption: updatedOptions });
  this.getMySubmittedRequest()
};
clearOptionFilterStatus= (indexToRemove: number,name:any) => {

  const updatedOptions = this.state.statusOption.filter((item:any,index: number) => item != indexToRemove);
  if (updatedOptions.length === 0) {
    updatedOptions.push(0);
    this.setState({statusOptionStringArr:[]})
  }

  this.setState({statusOption: updatedOptions });
 
  const updatedstatusOptionStringArr= this.state.statusOptionStringArr?.filter((item: any,index:any) => item != name);
  this.setState({statusOptionStringArr:updatedstatusOptionStringArr})
  this.getMySubmittedRequest()
};

handleConditionOfClear=()=>{
  return ((this.state.TypeOption.length > 0 && !this.state.TypeOption.includes(0)) ||
  (this.state.statusOption.length > 0 && !this.state.statusOption.includes(0))||
  (this.state.postTypeOption.length > 0 && !this.state.postTypeOption.includes(0)) 
  )

 
}
 clearAllOptions = () => {
  this.setState({
    TypeOption: [0],
    postTypeOption: [0],
    statusOption: [0],
    typeString:"",
    statusOptionStringArr:"",
  });
  this.getMySubmittedRequest()
};
    handlnav=(path:any,id:any)=>{
      
      let newPath=this.state.pathName=='/my-submitted-requests'?'my-submitted-requests':'my-received-requests'
      this.props.navigation.navigate(path,{id:`${newPath}/${id}`,postDetails:false,hisPath:this.state.pathName})
    }
    handleNavToDesc=(id:any)=>{
        let path=this.state.pathName=='/my-submitted-requests'?'ProductDescriptionPublic':'ProductDescription'
      this.props.navigation.navigate(path,{id:id,postDetails:false,hisPath:this.state.pathName})
    }

    handleNavigateTemplate=(type:any,request_type:any,path:any)=>{
    
    const msgsIs = new Message(getName(MessageEnum.NavigationMessage));
    msgsIs.addData(getName(MessageEnum.NavigationTargetMessage),path);
    msgsIs.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData),{type:type,request_type:request_type});
    msgsIs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgsIs)
    }
    handleMyTemplateFunction=async(request_type_template:any)=>{
      const headers = {
        "token": await getStorageData('auth_token')
      };
     
      const requestMess = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getTemplateApiId = requestMess.messageId;
    
      requestMess.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.templateEndPoints(request_type_template)
      );
      requestMess.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMess.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(requestMess.id, requestMess);
      return true;
      
    }
    setTemplateData=(data:any)=>{
      const newData=data?.data?.attributes
    if(newData){

      this.setState({
        idUpdatedTemp:data?.data?.id,
        address1: newData?.address_line_1,
        address2: newData?.address_line_2,
        city: newData?.city,
        county: newData?.county,
        postalCode: newData?.postal_code,
        country: newData?.country?.id,


        accessToPrivateGarden: newData?.have_private_garden,
        pleaseExplain: newData?.other_please_explain_activity_level,
        livingAlone: newData?.living_alone,
        newPersonEntry: this.handleNewPersonFieldTemplate(newData?.living_alone, newData?.users_other_members.data),
        reasonForAdopt: newData?.reason_for_wanting_to_adopt_a_new_pet || [],
        howToContactArr:newData?.how_would_you_like_to_be_contacted||[],
        reasonValue: newData?.other_please_explain_reason_for_wanting_to_adopt_a_new_pet,
        pleaseExplain2: newData?.other_please_explain_experience_as_a_pet_owner,
        newPetEntry: this.handleNewPetEnteryForTemplate(newData?.experience_as_a_pet_owner, newData?.users_other_pets?.data),
        hours: newData?.hours_in_a_day_would_the_foster_be_left_alone,
        reLocationCost: newData?.have_you_considered_the_relocation_cost_for_your_new_pet? 1 :  2 ,
        fosterbalconygarden: newData?.is_the_balcony_garden_enclosed_or_cat_proofed,
        fosterenclosed: newData?.not_then_extra_care_when_open_close_door,
        fosterchildrenattimes: newData?.if_children_pet_will_need_space_away_from_the_children_at_times,
        fosteranimalshome: newData?.other_animal_at_home,
        fosterappointments: newData?.vehicle_to_bring_your_foster_cat_to_events_and_appointments,
        fosterapplicable: newData?.are_you_willing_and_able_to_medicate_your_foster_cat_if_applica,
        fosterTermsConditon: newData?.terms_accepted,

      })
      this.handleSetAutoFillOptions(configJSON.howLongLived, newData?.how_long_you_lived_your_address, this.handleHowLongLived)
      this.handleSetAutoFillOptions(configJSON.doYou, newData?.do_you, this.handlDoYou)
      this.handleSetAutoFillOptions(configJSON.pleaseDescribeAccommodation, newData?.accommodation, this.handlDescribeAccommodation)
      this.handleSetAutoFillOptions(configJSON.apartment_Type, newData?.apartment_type, this.handlApartment)
      this.handleSetAutoFillOptions(configJSON.householdActivityLevel, newData?.house_hold_activaty_level, this.handleHouseholdActivityLevel)
      this.handleSetAutoFillOptions(configJSON.expPetOwner, newData?.experience_as_a_pet_owner, this.handlExpPetOwner)
      this.handleSetAutoFillOptions(configJSON.allowOutSide, newData?.pet_allowed_outside, this.handleallowOutSide)
      this.handleSetAutoFillOptions(configJSON.How_long_can_you_foster_for, newData?.how_long_can_you_foster_for, this.handleHowLongFoster)
    }

    }
   handleSetAutoFillOptions=(configData:any,newData:any,callFun:any)=>{
    const matchedIds = configData
    .filter((obj:any) => obj?.name.toLowerCase().includes(newData?.toLowerCase()))
    matchedIds[0]&&callFun(matchedIds[0])
   }
   handleNewPersonFieldTemplate=(living_alone:any,users_other_members:any)=>{
    if(users_other_members?.length==0||!users_other_members?.length){
      return   [{
        name: "",
        relationship: "",
        age: "",
      },]
    }else{
      let arr:any=[]
      users_other_members?.map((item:any)=>{
        let obj={
          name: item.attributes.name,
          relationship: item?.attributes?.relationship,
          age: item.attributes.age,
          id:item?.id
        }
        arr.push(obj)
    })
    return arr
   }
  }
   handleNewPetEnteryForTemplate=(experience_as_a_pet_owner:any,users_other_pets:any)=>{
     if(users_other_pets?.length==0||!users_other_pets?.length){
  return  [{
        age: '',
        category_id: '',
        sub_category_id: '',
        any_health_condition: '',
        sterilized: '',
       
      },]

     }else{
     let arr:any=[]
     this.globalCategoryData=users_other_pets
       arr=users_other_pets?.map((item:any,index:any)=>{
         
       return {
          age: item.attributes.age,
          category_id: item?.attributes?.category?.id,
          sub_category_id:item?.attributes?.sub_category?.id,
          ...(this.handleAny_health_condition()&&{any_health_condition:item.attributes.any_health_condition}),
          sterilized: item.attributes.sterilized,
          id:item?.id
        }
      }) 
      this.handleNewPetChange (0, 'category_id',  users_other_pets[0]?.attributes?.category?.id)
      return arr
     }
    }
    handleResponseForTemplate=(res:any)=>{
      if(res?.data?.attributes?.errors?.postal_code.length>0){
        this.setState({postalCode_Err:true,postalCode_Err_value:res?.data?.attributes?.errors?.postal_code[0],isButtonDisabled:false})
        return
        }
      this.setState({isButtonDisabled:false})
      const msgsIs = new Message(getName(MessageEnum.NavigationMessage));
      msgsIs.addData(getName(MessageEnum.NavigationTargetMessage),'MySubmittedRequests');
      msgsIs.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {isShowTemplateMess:true,message:"SUCCESS! We've received your template."});
      msgsIs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msgsIs)
    }
    handleStatusOfRequest=async()=>{
      const headers = {
        "token": await getStorageData('auth_token')
      };
     
      const requestMess = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.markStatusApi = requestMess.messageId;
    
      requestMess.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.mark_as_EndPoint(this.state.postId))

        requestMess.addData(  getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      requestMess.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      runEngine.sendMessage(requestMess.id, requestMess);
      return true;
      
    }
  handleAfterTrialPeriod=async()=>{
    if(this.state.continueAdoption==2&&(this.state.reason_Cancelling_Adoption.trim()==''||this.state.reason_Cancelling_Adoption==null)){
      this.setState({reason_Cancelling_Adoption_Err:true}) 
      return
    }
    if(this.state.continueAdoption==''||this.state.continueAdoption==null){
      this.setState({option_Err:true}) 
    }
    else{
      const headers = {
        "token": await getStorageData('auth_token'),
        "Content-Type": configJSON.validationApiContentType,
      };
      let user_role=this.state.historyPath=='/my-received-requests'||this.state.showReceiveReq
      let ans=this.state.continueAdoption==1
      const body={
        ...(!user_role&&{"requester_answer": ans}),
        ...(user_role&&{"owner_answer": ans,}),
        ...(!user_role&&!ans&&{"adoption_cancellation_reason_requester": this.state.reason_Cancelling_Adoption}),
         ...(user_role&&!ans&&{ "adoption_cancellation_reason_owner": this.state.reason_Cancelling_Adoption})
    }
      const requestMess = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.afterTrialPeriodApi = requestMess.messageId;
    
      requestMess.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.afterTrialPeriod_EndPoint(this.state.postId)
      );
      requestMess.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMess.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      requestMess.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(requestMess.id, requestMess);
      return true;
      
    }
  }
    handleMarkStatusMessage=(data:any)=>{
          
      if(data?.message){
        this.setState({isShowMarkMess:true,markMess:data?.message,isModalOpen:false},()=>this.getRequestDetails())

      }
      else{
        this.setState({isShowMarkMess:true,markMess:data?.errors[0]?.account,isModalOpen:false},()=>this.getRequestDetails())
      }
     
    }
    handleMarkRejectedMessage=(data:any)=>{
          
      if(data?.message){
        this.setState({isShowMarkMess:true,markMess:data?.message,isRejectModalOpen:false,rejectRequestValue:''},()=>this.getRequestDetails())

      }
      else{
        this.setState({isShowMarkMess:true,markMess:data?.errors[0]?.account,isRejectModalOpen:false,rejectRequestValue:''},()=>this.getRequestDetails())
      }
     
    }
    handleRejectRequest=async()=>{
    if(this.state.rejectRequestValue==''||this.state.rejectRequestValue==null){
      this.setState({rejectRequestValue_Err:true}) 
    }else{
      const headers = {
        "token": await getStorageData('auth_token')
      };
     
      const requestMess = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.markRejectStatusApi = requestMess.messageId;
    
      requestMess.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.mark_as_Reject_EndPoint(this.state.postId,this.state.rejectRequestValue)
      );
      requestMess.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers))
      requestMess.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      runEngine.sendMessage(requestMess.id, requestMess);
      return true;
      
    }
    }
   
  call_getMyAdoptedPetsPost = () => {
    if (this.state.pathName == '/my-adopted-pets'){
      this.getMyAdoptedPetsPost()
    this.hanldeFetchBreedForAdoptedPets()
    this.getAnimalTypedForMyAdoptedPetsFunc()
  }
}
  getAnimalTypedForMyAdoptedPetsFunc=async()=>{
    let header = {
      "token": await getStorageData('auth_token'),
    }
    const adoptedMessage=new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAnimalTypedForMyAdoptedPets = adoptedMessage.messageId
    adoptedMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.adopted_posts_animal_type)
    adoptedMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header)
    adoptedMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType)
    runEngine.sendMessage(adoptedMessage.id, adoptedMessage)
    return true
  }
  
  setAnimalTypedForMyAdoptedPets=(res:any)=>{
    let newData=[...this.state.animalTypeListForAdoptedPets,...res?.data]
      this.setState({animalTypeListForAdoptedPets:newData})
  }
  getMyAdoptedPetsPost = async () => {
    let header = {
      "token": await getStorageData('auth_token'),
      "Content-Type": configJSON.validationApiContentType,
    }
    let endPoint = ''
    if (this.state.postTypeOption[0] != 0 && this.state.postTypeOption.length !== 0) {

      endPoint = endPoint ? endPoint + "&animal_type=" + JSON.stringify(this.state.postTypeOption) : "&animal_type=" + JSON.stringify(this.state.postTypeOption)
    }
    if (this.state.TypeOption[0] != 0 && this.state.TypeOption.length !== 0) {

      endPoint = endPoint ? endPoint + "&breed=" + JSON.stringify(this.state.TypeOption) : "&breed=" + JSON.stringify(this.state.TypeOption)
    }

    let url = `${configJSON.adopted_postsEndPoints}?per_page=10&page=${this.state.currentTablePage}&sort_by=${this.state.sortValue}` + endPoint

    const requestmess = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getMyAdoptedPetsPostApiId = requestmess.messageId
    requestmess.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url)
    requestmess.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header)
    requestmess.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType)
    runEngine.sendMessage(requestmess.id, requestmess)
    return true
  }
 

  setMyadoptedPetsData = (res: any) => {
    let newData = res?.data
    let newMeta = res?.meta
    if (newData.length > 0) {
      this.setState({ showEmpty_ForNoAdoptedPets:false,myAdoptedData: newData, tableLastPage: newMeta.last_page, tableTotalRequest: newMeta.total_posts, showEmtyImageForNoAdoptedPets: false })
    } else {

      if(this.state.postTypeOption[0]!==0||this.state.TypeOption[0]!==0){
      this.setState({showEmpty_ForNoAdoptedPets:true, myAdoptedData: [], tableLastPage: '', tableTotalRequest: '', showEmtyImageForNoAdoptedPets: false })
     return
      }
      this.setState({showEmpty_ForNoAdoptedPets:false, myAdoptedData: [], tableLastPage: '', tableTotalRequest: '', showEmtyImageForNoAdoptedPets: true })

      
    }
    window.scrollTo(0, 0)
  }
  handleChangePageForAdoptedPetsTable = (e: any, page: any) => {
    this.setState({ currentTablePage: page })
    this.getMyAdoptedPetsPost()
  }
  handleMenuCategory = (index: number, name: any) => {
    const { postTypeOption } = this.state;
    if (index === 0) {
      this.setState({ postTypeOption: [0],breedListForMyAdoptedPets:this.state.all_subCategory }, 
        () => this.hanldeFetchBreedForAdoptedPets()
        );
      this.setState({ typeString: "" });
      this.getMyAdoptedPetsPost()
    } else {
      
      const updatedOptions = postTypeOption.includes(index)
        ? postTypeOption.filter((item: any) => item !== index)
        : [...postTypeOption, index];
      if (updatedOptions.length === this.state.animalTypeListForAdoptedPets.length - 1 && !updatedOptions.includes(0)) {
        this.setState({ postTypeOption: [0] ,breedListForMyAdoptedPets:this.state.all_subCategory},
          () => this.hanldeFetchBreedForAdoptedPets()
          );
          this.setState({ typeString: "" });
          this.getMyAdoptedPetsPost()
        }
        else if (updatedOptions.length === 0) {
        this.setState({ typeString: "" });
        this.setState({ postTypeOption: [0] }, () => this.hanldeFetchBreedForAdoptedPets());
        this.getMyAdoptedPetsPost()
      }
      else {
        this.setState({ postTypeOption: updatedOptions.filter((option: any) => option !== 0) }, () => this.hanldeFetchBreedForAdoptedPets());
        this.setState({ typeString: name })
        this.getMyAdoptedPetsPost()

      }
    }
  };
  handleMenuSubCategory = (index: number, name: any) => {
    const { TypeOption } = this.state;
    if (index === 0) {
      this.setState({ TypeOption: [0] });
      this.setState({ typeString: "" });
      this.getMyAdoptedPetsPost()
    } else {
      const updatedOptions = TypeOption.includes(index)
        ? TypeOption.filter((item: any) => item !== index)
        : [...TypeOption, index];

      if (updatedOptions.length === this.state.breedListForMyAdoptedPets.length - 1 && !updatedOptions.includes(0)) {
        this.setState({ TypeOption: [0] });
        this.setState({ typeString: "" });
        this.getMyAdoptedPetsPost()
      }
      else if (updatedOptions.length === 0) {
        this.setState({ typeString: "" });
        this.setState({ TypeOption: [0] });
        this.getMyAdoptedPetsPost()
      }
      else {
        this.setState({ TypeOption: updatedOptions.filter((option: any) => option !== 0) });
        this.setState({ typeString: name })
        this.getMyAdoptedPetsPost()

      }
    }
  };
  clearOptionForAdoptedPetsFilter = (optionName: string, indexToRemove: number) => {

    const updatedOptions = this.state[optionName as keyof MyComponentState].filter((index: number) => index !== indexToRemove);

    if (updatedOptions.length === 0) {
      updatedOptions.push(0);
    }

    this.setState({ [optionName]: updatedOptions } as Pick<MyComponentState, keyof MyComponentState>, () => this.hanldeFetchBreedForAdoptedPets()

    );
    this.getMyAdoptedPetsPost()
  };
  clearAllOptionsForAdoptedPets = () => {
    this.setState({

      postTypeOption: [0],
      TypeOption: [0],
      typeString: "",

    }, () => this.hanldeFetchBreedForAdoptedPets());
    this.getMyAdoptedPetsPost()

  };
  hanldeFetchBreedForAdoptedPets = async () => {
    let header = {
      "token": await getStorageData('auth_token'),
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBreedApiIdForAdoptedPets = requestMessage.messageId

    if (this.state.postTypeOption[0] != 0 && this.state.postTypeOption.length !== 0) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_posts/adopted_posts/breed?animal_type_id=${JSON.stringify(this.state.postTypeOption)}`
      );
    } else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_posts/adopted_posts/breed`
      );
    }
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  setBreedListMyadoptedPetsData = (res: any) => {
    let newData = res?.data
    const compareNames = (a: {name: string}, b: {name: string}) => {
      if (a.name < b.name) {
          return -1;
      }
      if (a.name > b.name) {
          return 1;
      }
      return 0;
  };
        let sortData=newData.sort(compareNames)
    let dta= [{ id: 0, name: 'All' }, ...sortData]

      this.setState({ breedListForMyAdoptedPets: dta })
    
  }
   
  afterTrialPeriodResPonse=(res:any)=>{
    if(res?.errors){
      const objectIs = res?.errors[0]
      let messg;
      if (typeof objectIs == 'object') {
        for (const property in objectIs) {
          messg = objectIs[property]
        }
      } else {
        messg = res?.errors
      }
        
      this.setState({isTrialPeriodModalOpen:false,SuccessMess:messg,showSuccessMessPop:true})
      this.props?.navigation?.history?.replace()
    }else{
      this.setState({isTrialPeriodModalOpen:false,SuccessMess:this.state.historyPath=='/my-received-requests'?'Thank you for confirming.':'Thank you for your response!',showSuccessMessPop:true})
      this.props?.navigation?.history?.replace()
    }
  }
  isAnsReceiveModalFunction=()=>{
    this.setState({isAnsReceiveModal:false})
    this.props?.navigation?.history?.replace()
   this.handlnav('RequestDetails',this.state.postId)
  }
handleHisPath=()=>{
  let hisPath=this.props?.navigation?.history?.location?.pathname
  if(hisPath.includes('my-submitted-requests')){
    this.setState({historyPath:'/my-submitted-requests'})
  }else if(hisPath.includes('my-received-requests')){
    this.setState({historyPath:'/my-received-requests'})
  }
}
handleShowFields=(state:string|boolean)=>{
  if( state!==null&&state!==''&&state!==undefined) {
    return true
  }else{
    return false
  }
}
handleTextStatus=(status:string)=>{
  if (status == 'Archived') {
    return 'Reserved'
}else{
  return status
}
}

handleHowToContact=(itemName: HowToContactInterFace) => {
  this.setState((prevState) => {
      const updatedReson = prevState?.howToContactArr?.includes(itemName.name)
          ? prevState?.howToContactArr.filter((selected: string) => selected !== itemName.name)
          : [...prevState.howToContactArr, itemName.name];

      return {
          howToContactArr: updatedReson,
          howToContactErr: updatedReson.length === 0,
      };
  });
 
 
}
handleHowToContactErr=()=>{
  if(this.state.howToContactArr.length==0){
    return true
  }else{
    return false
  }
}
handleErrCheck=(state:string|null|undefined)=>{
if(state?.trim()===""||state==null||state==undefined){
  return true
}else{
  return false
}
}
handleCheckForhowToContactArr=(howToContactArr:string[],option:string)=>{
  let hisPath=this.props?.navigation?.history?.location?.pathname
  if(hisPath?.includes('my-submitted-requests')){
    return true
  }else if(hisPath?.includes('my-received-requests')){
   return howToContactArr?.length>0&&howToContactArr?.includes(option)
    

  }
}
handleAny_health_condition=()=>{
  if(this.state.pathName!=='/adoption-form'&&this.state.pathName!=='/adoption-template'){
      return true
  }else{
    return false
  }
}
handlePaddingBott=(continueAdoption:number)=>{
  if(continueAdoption == 2){
return '24px'
  }else{
return  ''
  }
 
}
handleBorderBott=(continueAdoption:number)=>{
  if(continueAdoption == 2){
    return '1px solid #E2E8F0' 
  }else{
  return  ""
  }
}
handleCloseFunc=()=>{
  if(this.state.isAnsReceiveModal){
    this.setState({ showSuccessMessPop: false, SuccessMess: '' },()=>this.isAnsReceiveModalFunction())  
  }else{
    this.setState({ showSuccessMessPop: false, SuccessMess: '' })
  }
 
}
handleReturnTex1=()=>{
  return this.state.user_role == 'rescuer'&& this.state.historyPath=='/my-received-requests' ?'Yes, confirmed':'Yes, offically adopted!'
}
handleReturnTex2=()=>{
  return this.state.user_role == 'rescuer'&& this.state.historyPath=='/my-received-requests'?'No':'No, not adopted'
}
handleCheckOwner=()=>{
  return this.state.user_role == 'rescuer' &&this.state.historyPath=='/my-received-requests' 
}
handleCheckCommomRadioButton=(state:boolean|null)=>{
 if(state==null){
return true
 }
 else{
  return false
 }
}
handleCheckCommomRadioButton2=(state:boolean|null)=>{
  if(state==null&&(this.state.pathName == "/foster-template" ||this.state.pathName == "/foster-form" || this.state.pathName == "/generic-foster-form")){
 return true
  }
  else{  return false }
  }
handleCheckForErr=(state:boolean|null,state2:boolean|null)=>{
  if(state==null&&state2!==null&&!state2){
    return true
     }
     else{
      return false
     }
}
returnHourseErr=()=>{
  if(this.state.hours>24){
    return 'Maximum value is 24'
  }else{
    return this.state.hours_Err && "Please enter a value" 
  }
 
}
handleCheckForNull=(value:string|boolean|null)=>{
if(value===null){
  return false
}else{
  return true
}
}
handleConditionForPayload=()=>{
 if(this.state.fosterbalconygarden!==null&&!this.state.fosterbalconygarden) {
  return this.state.fosterenclosed
 }
 else {
return null
 }
  
  
}
  // Customizable Area End
}
