import {Typography,styled} from '@material-ui/core';
import React from "react";
 interface CardHeadingProps {
    descLength: number;
  }
export const  CardHeading = styled(Typography)(({ descLength }:CardHeadingProps) => ({
  fontSize:'60px',
      fontWeight:700,
      fontFamily:"Lato",
      lineHeight:"120.37px",
      letterSpacing:"0.72px",
      color:"#0A2239",
      wordBreak:'break-all',
      textAlign:'center',
  maxWidth: '100%',
      "@media(max-width:1438px)":{
        fontSize:'60px',
      },
      "@media(max-width:1191px)":{
        fontSize:'60px',
      },
    "@media(max-width:1000px)":{
      fontSize:"60px",
      lineHeight:'unset'
    },
    "@media(max-width:800px)":{
      fontSize:"30px"
    },
  }))