export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const PetImage=require("../assets/PetMainImage.svg")
export const Logo=require("../assets/LogoImage.svg")
export const Frame=require("../assets/Frame.png")
export const image_pet_adopter =require("../assets/image_pet adopter (1).jpg")
export const image_pet_adopter1 =require("../assets/image_pet adopter.png")
export const Frame1 =require("../assets/Group.png")
export const logonav=require("../assets/site_logo.png")
export const insta=require("../assets/group_instagram.svg")
export const facebook=require("../assets/group_facebook.svg")
export const linkdin=require("../assets/group_linkedin.svg")
export const tiktok=require("../assets/group_tiktok.svg")
export const snapchat=require("../assets/group_snapchat.svg")
export const checkBoxTrue=require("../assets/checkbox_CheckBoxTrue.svg")
export const checkBoxFalse=require("../assets/checkbox_checkFalse.svg")
export const notification=require("../assets/notification.svg")
export const dropdown=require("../assets/dropdown.svg")
export const slack=require("../assets/slack.svg")
export const google=require("../assets/google.svg")
export const RightIcon = require("../assets/rightIcon.svg");
export const infoIcon = require("../assets/iconInfo.svg");
export const calender = require("../assets/calender.svg");
export const email = require("../assets/email.svg");

export const cat = require("../assets/Cat.svg");
export const rabbit = require("../assets/Rabbits.svg");
export const birds = require("../assets/Bird.svg");
export const Reptiles = require("../assets/turtle_svg.svg");
export const LittleCritters = require("../assets/Critter.svg");
export const dog = require("../assets/Dog.svg");
export const banner =require("../assets/landingPageBanner.svg");
export const rotate =require("../assets/rotate.svg");
export const catFoot =require("../assets/catSteps.svg");
export const dogKissing =require("../assets/dogKissing.svg");

export const catFilled = require("../assets/CatFilled.svg");
export const rabbitFilled = require("../assets/RabbitsFilled.svg");
export const birdsFilled = require("../assets/BirdFilled.svg");
export const dogFilled = require("../assets/DogFilled.svg");
export const ReptilesFilled = require("../assets/turtleFilled.svg");
export const LittleCrittersFilled = require("../assets/CritterFilled.svg");

export const Discover1 = require("../assets/Discover1.svg");

export const arrowRightLight = require("../assets/button_next_light.svg");
export const arrowLeft = require("../assets/button_prev.svg");
export const arrowRight = require("../assets/button_next.svg");
export const arrowLeftLight = require("../assets/button_prev_light.svg");

export const arrow = require("../assets/arrow.svg");
export const coverBanner = require("../assets/banner.jpg");
export const crossIconForClose=require("../assets/crossIcon.png")
















