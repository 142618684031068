import React from "react";
import {
  // Customizable Area Starst
  Typography,
  Box,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// import "./ProductDescription.css";
import "../../../productdescription/src/ProductDescription.css";
import {Loader2} from "../../../../components/src/Loader.web";

import moment from "moment";


import { HomeGrid } from "../../../email-account-login/src/CataloguePage.web";
import { leftArrowIcon2 } from "../../../communityforum/src/assets";
import { Allstyle } from "../../../favourites/src/FavouritesWeb.web";

import BlogPostManagementController ,{ Props }  from "./BlogPostManagementContoller.web";

import  { TypographyStyle,TypographyStyle2 } from "../../../comments/src/CreateComment.web";
export const blogDetailsStyle = {
  titleStyle: {
    fontWeight: 600,
    color: "#1E293B",
    lineHeight: "140%",
    letterSpacing:"-0.15px",
    fontFamily: "Inter",
    fontSize: "30px",
    marginBottom:"16px"
  },
  subtitleStyle: {
    fontWeight: 400,
    color: "#0F172A",
    fontFamily: "Lato",
    fontSize: "16px",
  },
  dateStyle: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    fontFamily: "Inter",

  },
  descriptionStyle: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "29px",
    color: "#334155",
    fontFamily: "Inter",
    marginBottom:"120px"
  },
 

}

// Customizable Area End

export default class BlogPostDetails extends BlogPostManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        
        <Loader2 loading={this.state.isLoading } />

        <HomeGrid style={{ minHeight: 'calc(100vh - 247px)',display:"block" }}>
        <Box style={{ ...Allstyle.headingBoxStyle,alignItems:"center",justifyContent:"space-between" }}>
          <Box style={{display:"flex",flexWrap:"wrap",alignItems:"center"}}>

        <TypographyStyle2 style={{ ...Allstyle.hedingStyle, cursor: "pointer" ,}}
        data-test-id="navigate"
         onClick={() => {
          this.props.navigation.navigate("LandingPageWeb")
        }}>
          Home
        </TypographyStyle2>
      
<img src={leftArrowIcon2}/>
        <TypographyStyle
          style={{
            ...Allstyle.hedingStyle,
            cursor:"pointer"
          }}
        data-test-id="PetootiCommunity"

          onClick={() => {
            this.props.navigation.navigate("BlogPostManagementMain")
          }}
        >
         Blogs
        </TypographyStyle>
        <img src={leftArrowIcon2}/>
        <TypographyStyle
          style={{
            ...Allstyle.hedingStyle,
            cursor:"pointer"
          }} 
          data-test-id="selectedSection"
          onClick={() => {
            this.props.navigation.navigate("BlogPostManagementMain",{selectionId:this.state.selectedSection})
          }}>
        {this.state.selectedSectionTitle} {"Blogs"}
        </TypographyStyle>
        <img src={leftArrowIcon2}/>
 
        <TypographyStyle
          style={{
            ...Allstyle.hedingStyle,
            color:"#0F172A",
           
          }}
        >
       Blog Post
        </TypographyStyle>
          </Box>
      </Box>
      <Box style={{marginTop:"48px",maxWidth:"772px",marginInline:"auto",justifyContent:"center",flexDirection:"column",display:"flex"}}>
<Typography style={blogDetailsStyle.titleStyle}>
{this.state.detailsData?.attributes?.title}
</Typography>
<Box style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"48px"}}>
  <Box style={{display:"flex",gap:"12px",flexDirection:"row",alignItems:"center"}}>

<Typography  style={{...blogDetailsStyle.subtitleStyle,color:"#334155"}}>

Category 

</Typography>
<svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5" fill="none">
  <circle cx="2" cy="2.5" r="2" fill="#64748B"/>
</svg>
    <Typography  style={blogDetailsStyle.subtitleStyle}>
    {this.state.detailsData?.attributes?.blog_post_category?.name}

    </Typography>
  </Box>

    <Typography style={blogDetailsStyle.dateStyle}>
    Created on: {moment(this.state.detailsData?.attributes?.created_at).format("MMM DD, YYYY")}
        </Typography>
</Box>
<img src={this.state.detailsData?.attributes?.image} style={{maxHeight:"304px",objectFit:"cover",width:"100%",marginBottom:"32px"}}/>

<div dangerouslySetInnerHTML={{__html:this.state.detailsData?.attributes?.content}}  
 className="content-wrapper"
style={blogDetailsStyle.descriptionStyle}/>

      </Box>
         
        </HomeGrid>
     
    

      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start  





// Customizable Area End
