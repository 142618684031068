Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.endPointApiGetUserProfileAdopter = "bx_block_profile_bio/profile_bios/";
exports.contentTypeApiGetUserProfileAdopter = "application/json";
exports.methodTypeApiGetUserProfileAdopter = "GET";

exports.endPointApiAdopterUserEmirates = "account_block/dropdowns/emirates";
exports.contentTypeApiAdopterUserEmirates = "application/json";
exports.methodTypeApiAdopterUserEmirates = "GET";

exports.endPointApiAdopterUserAreas = "account_block/dropdowns/areas";
exports.contentTypeApiAdopterUserAreas = "application/json";
exports.methodTypeApiAdopterUserAreas = "GET";

exports.endPointApiAdopterUserNationality = "account_block/dropdowns/nationalities";
exports.contentTypeApiAdopterUserNationality = "application/json";
exports.methodTypeApiAdopterUserNationality = "GET";

exports.endPointApiAdopterUserCountry = "account_block/dropdowns/countries";
exports.contentTypeApiAdopterUserCountry = "application/json";
exports.methodTypeApiAdopterUserCountry = "GET";


exports.endPointApiAdopterUserProfileUpdate = "bx_block_profile_bio/profile_bios";
exports.contentTypeApiAdopterUserProfile = "application/json";
exports.methodTypeApiAdopterUserProfile = "PUT";

exports.endPointApiAdopterUserProfileImgUpdate = "bx_block_profile_bio/profile_bios/add_profile_image";
exports.methodTypeApiAdopterUserProfileImg = "PUT";

exports.endPointApiAdopterUserProfileImgDelete = "bx_block_profile_bio/profile_bios/remove_profile_image";
exports.methodTypeApiAdopterUserProfileImgDelete = "Delete";

exports.endPointApiAdopterUserPasswordUpdate = "bx_block_profile/passwords";
exports.contentTypeApiAdopterUserPasswordUpdate = "application/json";
exports.methodTypeApiAdopterUserPasswordUpdate = "PUT";

exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports. special=/^[a-zA-Z\d!@#$%^&*()\-_=+?<>;:.,{}[\]|/]*$/

// Customizable Area End

