Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";
exports.emirate = [
  'Abu Dhabi',
  'Dubai',
  'Sharjah',
  'Ajman',
  'Umm Al-Quwain',
  'Fujairah',
  'Ras Al Khaimah'
];
exports.area = [
  'Downtown',
  'Suburb',
  'Rural',
  'City Center',
  'Residential',
  'Commercial District',
  'Industrial Zone',
  'Waterfront',
  'Historic District',
  'Business Park',
  'Shopping Mall',
  'Countryside',
  'Tech Hub',
  'Tourist District',
  'Green Belt',
  'Financial District',
  'Art District',
  'Educational Campus',
  'Sports Complex',
  'Waterfront'
];

exports.gender=["Female","Male"]
exports .nationalities = [
  'American',
  'Canadian',
  'British',
  'Australian',
  'French',
  'German',
  'Japanese',
  'Chinese',
  'Indian',
  'Brazilian',
  'Mexican',
  'Russian',
  'Italian',
  'Spanish',
  'South African',
  'Nigerian',
  'Egyptian',
  'Korean',
  'Turkish',
  'Swedish'
];
exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.signUpApiEndpoint="/account_block/accounts"
exports.optVerifyEndpoint="/bx_block_forgot_password/otp_confirmations"
exports.resendotpverifyEndPoint="/bx_block_forgot_password/otps"
exports.signUpAdopterEndpoint="account_block/accounts/account_details"
exports. special=/^[a-zA-Z\d!@#$%^&*()\-_=+?<>;:.,{}[\]|/]*$/
exports.propfileEndPoints=(profileId)=>`bx_block_profile_bio/profile_bios/${profileId}`;
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.animal_GetTypeEndPoint='bx_block_categories/categories';
exports.getBriefAnimalType_EndPoint='bx_block_content_management/animal_type_sections'
exports.show_hide_sponsor_section_EndPoint='bx_block_content_management/sponsor_sections/show_hide_sponsor_section'
// Customizable Area End
