import React from "react";

// Customizable Area Start
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { PrivacyPolicy } from "../../../components/src/WhoAreWe";

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { MainGrid ,SecondGrid,MainBox} from "./UserRole.web";
import { PetImage, checkBoxFalse, checkBoxTrue } from "./assets";

// Customizable Area End

class EmailAccountVerification extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleStyle=()=>{
    if(this.state.userRoleImage!==""){
      return {
        minHeight: 'calc(100vh - 247px)',
        maxHeight: 'calc(100vh - 247px)',
        width:"100%",
        borderRadius:"0px 0px 0px 45px",
        objectFit: 'cover' as 'cover',
        objectPosition: 'top' as 'top'
      }
    }
    else{
      return { width:"335px" }
    }
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const importantField=()=>{
      return <span  style={{fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#FF5E5B"}}>
  *
      </span>
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>

        
        <MainGrid container>
          <SecondGrid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <MainBox
            >
              <Typography style={AllStyle.heading}>
                Sign up to PETOOTI
              </Typography>
              <SecondBox style={AllStyle.secondBox}>
                <Typography style={AllStyle.smallHeading}>
                  Welcome, we'll send a registration code to your email.
                </Typography>
                <Typography style={AllStyle.textStyle}>
                  Email {importantField()}
                </Typography>
                <InputField placeholder="Your Email" variant="outlined" data-test-id="emailtest" value={this.state.email} onChange={(e:any) => {
                  this.handleEmail(e.target.value)
                }} error={this.state.emailerror}
                  helperText={
                    this.state.emailerror &&
                    (this.state.emailErrorMessage)
                  } />
                <Typography style={AllStyle.textStyle}>
                  First Name  {importantField()}
                </Typography>
                <InputField placeholder="Your First Name" value={this.state.firstName} variant="outlined" data-test-id="1stame" onChange={(e:any) => {
                  this.handleValidationFirstLast("firstName",e.target.value,20)
                }} error={this.state.firstNameError}
                  helperText={
                    this.state.firstNameError &&
                    this.state.firstNameErrorMessage
                  } />

                <Typography style={AllStyle.textStyle}>
                  Last Name  {importantField()}
                </Typography>
                <InputField placeholder="Your Last Name" onChange={(e:any) => {
                                   this.handleValidationFirstLast("lastName",e.target.value,20)

                }} variant="outlined" error={this.state.lastNameError}
                  value={this.state.lastName}
                  helperText={
                    this.state.lastNameError &&
                    this.state.lastNameErrorMessage
                  } data-test-id="lstname" />
                <Box style={{ display: "flex", alignItems: "flex-start",marginBottom:this.state.checkederror?"0px":"30px", }}>
                  <img data-test-id="hanldeCheckBox"  src={this.state.checked ? checkBoxTrue : checkBoxFalse} style={{ marginRight: "8px", cursor: "pointer", width: "20px", height: "20px" }} onClick={() => {
                    this.hanldeCheckBox()
                  }} />

                  <Typography data-test-id="Conditions" style={{ color: "#0F172A", fontWeight: 400, fontSize: "16px", fontFamily: 'Lato', cursor: "pointer", lineHeight: "24px" }} >
                  <span  data-test-id="Conditions"  onClick={() => {
                    this.hanldeCheckBox()
                  }}>
                    I agree with{" "}
                    </span>  
                    <span style={AllStyle.boldStyle} data-test-id="terms" onClick={()=>{
                      this.setState({privacyPolicyPopUp:true})
                    }}>Privacy Policy </span>
                    <span data-test-id="Conditions"   onClick={() => {
                    this.hanldeCheckBox()
                  }}>

                    and{" "}
                    </span>
                    <span style={AllStyle.boldStyle}  data-test-id="privacy"onClick={()=>{
                      this.setState({termsAndConditonPopUp:true})
                    }}>
                      Terms & Conditions.
                    </span>
                  </Typography>
                </Box>

                {this.state.checkederror && <Typography style={{
                  color: "#DC2626",
                  fontSize: "12px",
                  fontFamily: "Lato",
                  fontWeight: 400,
                  lineHeight: "18px",
                  marginTop: "3px",
                 marginBottom:"30px"
                }}>
                  Please accept the privacy policy and terms & conditions
                </Typography>}
                <BUttonStyle variant="contained"
                  data-test-id="button"

                  style={AllStyle.btnStyle} onClick={() => {
                    this.handleValidation()
                  }}>
                  Verify Email Address
                </BUttonStyle>
                <Typography data-test-id="button1" style={{
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:"19.2px",
                  color: "#0A2239"
                }}>
                  Already have an account?{" "}
                  <span style={{ ...AllStyle.boldStyle, cursor: "pointer" }} onClick={() => { this.handleNavigateLogin() }}>
                    Log in
                  </span>
                </Typography>
              </SecondBox>
            </MainBox>
          </SecondGrid>
      
        </MainGrid>
        {
                    this.state.termsAndConditonPopUp&&<PrivacyPolicy firstHeading="TERMS & CONDITIONS!" secondHeading=""privacyAndTerms={this.state.termsAndConditonPopUp} handleClose={this.closeTermsCondition} handleCloseButton={this.closeTermsCondition}/>
                }
                  {
                    this.state.privacyPolicyPopUp&&<PrivacyPolicy firstHeading="PRIVACY POLICY!" secondHeading=""privacyAndTerms={this.state.privacyPolicyPopUp} handleClose={this.closePrivacyPolicy} handleCloseButton={this.closePrivacyPolicy}/>
                }
      </Box>
      // Customizable Area End
    );
  }
}
export default EmailAccountVerification;

// Customizable Area Start

const BUttonStyle = styled(Button)({
  minWidth: "360px",

  "@media (max-width:380px)": {
    minWidth: "200px",

  },

})
const SecondBox = styled(Box)({
  maxWidth: "360px",
  "@media (max-width:390px)": {
    paddingInline: "10px"
  },
  "@media (max-width:320px)": {
    justifyContent: "center",
  },
});

export const InputField = styled(TextField)(({disabled,textstyle,textColor}:any)=>({
  marginBottom: "16px",

  '& input::placeholder': {
    color:"#94A3B8",
    fontFamily: "Lato",
    fontSize: "16px",
    opacity:1,
    fontWeight: 400,
    lineHeight: "19px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#CBD5E1",
    },
  },
  "& .MuiInputBase-root":{
    color:textColor ?? "#334155"
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px 8px",
  },
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    textTransform:'uppercase',
    color:textstyle===true?"#94A3B8":"#334155"
  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: disabled? "#F87171":""
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px"
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px"
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':{
    border:"1px solid #CBD5E1",
  }
}));
const AllStyle = {

  textStyle: {
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "4px",
    color: "#0A2239",
    fontFamily: "Lato",
    lineHeight: "22px"
  },

  heading: {
    color: "#0A2239",
    lineHeight: "32px",
    textAlign: "center" as "center",
    fontWeight: 700,
    fontSize: "24px",
    letterSpacing: "-0.12px",
    fontFamily: "Lato"
  },
  smallHeading: {
    color: "#0A2239",
    marginBottom: "30px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Lato"
  },
  boldStyle: {
    color: "#03B5AA",
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 700,
    lineHeight:"24px"
  },
  btnStyle: {
    borderRadius: "8px",
    height: "56px",
    background: "#03B5AA",
    textTransform: "inherit" as "inherit",
    color: "#FFFFFF",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight:"24px"
,    marginBottom: "30px",
  },

  secondBox: {
    flexDirection: "column" as "column",
    display: "flex",
    flexWrap: "Wrap" as "wrap",
    marginBlock: "30px",
  },
};

// Customizable Area End
