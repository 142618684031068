import React from "react";
import {
  Container,
  // Customizable Area Start
  Typography,
  Grid,
  Box,
  Divider,
  TextField,
  Avatar,
  IconButton,
  Button,
  TextareaAutosize,
  MenuItem,
  Select,
  FormControl,
  Dialog
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  Close as CloseIcon, KeyboardArrowDown,
} from "@material-ui/icons";
import { createTheme, styled, ThemeProvider, withStyles, } from "@material-ui/core/styles";
import CommonSnackbar from '../../../components/src/CommonSnackbar.web'
import moment from "moment";
import { DiologComponent } from "../../email-account-registration/src/EmailAccountForm.web";

import CommentController, { Props } from "./CommentsController";
import { HomeGrid } from "../../email-account-login/src/CataloguePage.web";

import { addImg, editImg, deleteImg,flag, disabledAdd } from "./assets";
import { webstyle, DiologStyle } from "../../productdescription/src/ProductDescription.web";
import { MainBox } from "../../communityforum/src/CreateNewTopic.web";
export const theme: any = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: 750,
      fontFamily: "Roboto-Medium",
    },
    h3: {
      fontWeight: 350,
      fontFamily: "Roboto-Medium",
    },
    h6: {
      fontWeight: 500,
      fontFamily: "Roboto-Medium",
    },
    subtitle1: {
      margin: "20px 0px",
      fontFamily: "Roboto-Medium",
    },
  },
});

export const commentStyle = {
  subheadingStyle: {
    color: "#334155",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
  },
  errorNameStyle: {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px",
  },


  ReportStyle: {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#334155",
  },
  ReportFlagIcon: {
    cursor: 'pointer'
  },
  ReportAlreadyStyle: {
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#DC2626",
  }
}

export const TypographyStyle = styled(Typography)({
  paddingInline: "6px",
  '@media ( max-width:421px )': {
    paddingInline: "unset"
  },
})

export const TypographyStyle2 = styled(Typography)({
  paddingRight: "6px",
  '@media ( max-width:421px )': {
    paddingRight: "unset"
  },
})

export const ButtonReport = styled(Button)({
  marginLeft: "0px",
  fontFamily: "Inter",
  width: "151px",
  height: "56px",
  background: '#03B5AA',
  border: '1px solid #03B5AA',
  borderRadius: 8,
  padding: "10px 16px 10px 12px",
  lineHeight: '24px',
  textTransform: 'inherit',
  fontWeight: 700,
  fontSize: 16,
  color: '#fff',
  '@media ( max-width:421px )': {
    width: "140px"
  },
})


// Customizable Area End

export default class CreateComment extends CommentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  starIcon = () => {
    return <span style={{
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#FF5E5B"
    }}>
        {" "}    *
    </span>
}

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainBox  style={{ display:"block" }}>
    
        <hr style={{ width: '100%', border: 'none', height: '1px', backgroundColor: '#CBD5E1' }} />
            <MainGrid >
              <Box >
                <FlexBox>
                  <Comment >Comments </Comment>
                  <SmallComment >({this.state.totalCount})</SmallComment>
                  
                </FlexBox>
                {this.state.edit ? 
                <>
                    <FlexBox>
                    <TextAvatar
                      src={this.state.profileData?.profile_image}
                      alt={this.state?.profileData?.first_name}
                      style={{ border: `2px solid ${this.state.profileData.profile_image ? "#f0e5ff" : "#CDF0EE"}` }}
                    > <span style={{ fontSize: "24px", fontFamily: "Inter", fontWeight: 600 }}> {this.state?.profileData?.first_name?.charAt(0)}</span></TextAvatar>
                    <StyledTextWithIcon id="update-comment-input" value={this.state.updateComment} fullWidth placeholder="Edit comment..." variant="outlined"
                      onChange={(e: any) => this.handleUpdateComment(e)}
                      onKeyPress={(e: any) => {
                        if (this.state.updateComment) {
                          this.handleUpdateComment(e)
                        }
                      }} 
                      data-test-id={"input-update-comment"}

                    />
                    <Typography onClick={this.handleResetAllEvent} style={{fontFamily: 'Inter', color: '#DC2626', textTransform: 'capitalize', fontSize: '14px', fontWeight: 500, margin: '12px 15px', cursor: 'pointer' }}>Cancel</Typography>
                    <img src={!this.state.updateComment ? disabledAdd :addImg}  style={{ cursor: !this.state.updateComment ? "none" : 'pointer', position: 'absolute', right: 93, top: 10, width: '24px', height: '24px' }} onClick={() =>{ 
                      if (this.state.updateComment) {
                        this.updateCommentApiCall()
                      }
                    }
                    }
                      data-test-id={"update-post-api"}
                    />
                  </FlexBox>
                  </>
                  :
                  <FlexBox>
                    <TextAvatar
                      src={this.state.profileData?.profile_image}
                      alt={this.state?.profileData?.first_name}
                      style={{ border: `2px solid ${this.state.profileData.profile_image ? "#f0e5ff" : "#CDF0EE"}` }}
                    > <span style={{ fontSize: "24px", fontFamily: "Inter", fontWeight: 600 }}> {this.state?.profileData?.first_name?.charAt(0)}</span></TextAvatar>
                    <StyledTextWithIcon value={this.state.comment} fullWidth placeholder="Add comment..." variant="outlined"
                      onChange={(e: any) => this.handleInputChange(e)}
                      onKeyPress={(e: any) => {
                        if (this.state.comment) {
                          this.handleInputChange(e)
                        }
                      }} 
                      data-test-id={"input-user-comment"}
                    />
                    <img src={!this.state.comment ? disabledAdd :addImg}  style={{ color: "#0F172A", cursor:!this.state.comment ? "none" : 'pointer', position: 'absolute', right: 15, top: 10, width: '24px', height: '24px' }} onClick={() => {
                      if (this.state.comment) {
                        
                        this.postCommentApiCall()
                      }
                      }} data-test-id={"post-comment"} />
                  </FlexBox>
                }
                <DividerBox />
              </Box>
              {this.state.commentsArray.length ? (
                this.state.commentsArray?.map((elm: any, index: any) => {
                  return (
                    <>
                      <Box key={index}>
                        <CommentBox>
                          <CommentTypography style={{ color: elm.attributes.id === this.state.updateId ? "#94A3B8" : "#334155", wordBreak: "break-all" }}>{elm.attributes.comment}</CommentTypography>
                          <Box>
                            {elm?.attributes?.created_by_me && !elm.attributes.is_reported_by_me &&        
                                (<StyledBox>
                                <IconButtonStyle aria-label="edit" onClick={() => this.handleEdit(elm)}  data-test-id={"edit-comment"}>
                                  <img src={editImg} style={{ cursor: 'pointer', width: '24px', height: '24px' }} />
                                </IconButtonStyle>
                                <IconButton aria-label="delete" onClick={() => this.handleDeletePopUpOpen(elm.id)} data-test-id={"delete-comment"}>
                                  <img src={deleteImg} style={{ cursor: 'pointer', width: '24px', height: '24px' }}  />
                                </IconButton>
                              </StyledBox>)      
                            }
                            {!elm?.attributes?.created_by_me && !elm.attributes.is_reported_by_me && (
                            < Box  
                            data-test-id={"report-comment"}
                            onClick={()=>{this.handleOpenReportPopup(elm)}}
                             style={{display:"flex",flexDirection:"row",gap:"10px"}}
                             >
                             <img src={flag} style={{cursor:"pointer"}}/>
                          <Typography 
                            
                             style={{...commentStyle.ReportStyle,cursor:"pointer"}}>
                            Report 
                              </Typography>
                            </Box>)}
                            {elm.attributes.is_reported_by_me && <Typography style={{ color: '#DC2626', fontSize: '16px', fontWeight: 500, lineHeight: '24px', fontFamily: 'Lato' }} >Comment is reported</Typography>}
                          </Box>
                        </CommentBox>
                        <StyledCommentBox>
                          <TextAvatar
                            src={elm.attributes.account.image_url}
                            alt={elm.attributes.account?.first_name}
                            style={{ height: '24px', width: '24px', border: `2px solid ${elm.attributes.account.image_url? "#f0e5ff" : "#CDF0EE"}` }}
                          > <span style={{ fontSize: "10px", fontFamily: "Inter", fontWeight: 600 }}> {elm.attributes.account?.first_name?.charAt(0)}</span></TextAvatar>
                          <Typography2 style={{ color: "#334155" }}>{`${elm?.attributes?.created_by_me ? "Me" : elm.attributes.account.first_name} ${" "}`}</Typography2>
                          <Typography>
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5" fill="none">
                              <circle cx="2" cy="2.5" r="2" fill="#64748B" />
                            </svg></Typography>
                          <Typography2 style={{ color: "#475569" }}>{moment(elm.attributes.updated_at).format("DD MMM, YYYY @ HH:mm")}</Typography2>
                        </StyledCommentBox>
                      </Box>
                      {index !== this.state.commentsArray.length - 1 && <DividerBox style={{ backgroundColor: '#E2E8F0' }} />}
                    </>
                  )
                })
              ) : (
                <Typography style={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  color: "#334155",
                  marginBottom: '20px'
                }}>No comments are added here yet! Be the first to add a comment.</Typography>
              )}
              {this.state.totalCount > this.state.perPage && (
                <TypographyLoadMore onClick={() => this.handleLoadMore()}>Load more...</TypographyLoadMore>
              )}
              <CommonSnackbar  handleCloseFunc={()=>this.setState({showSnackbar:false})} open={this.state.showSnackbar} message={this.state.snackbarMessage} />
            </MainGrid>
        </MainBox>
              <DiologStyle2
              
                onClose={this.handleDeletePopUp} aria-labelledby="customized-dialog-title" data-test-id="closeId" open={this.state.openDeletePopup}

              >
                <Box style={{
                  display: "flex",
                  padding: "16px 16px 12px 40px ",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}>
                  <IconButton aria-label="close"
                    style={{ padding: "4px", color: "#0A2239" }}
                    data-test-id="closeId2"
                    onClick={this.handleDeletePopUp}
                  >
                    <CloseIcon />

                  </IconButton>
                </Box>
                <BoxStyleReport2 >

                  <TypographyReportHeading >
                  DELETE COMMENT?
                  </TypographyReportHeading>
                  <TypographyReportSubHeading >
                  Confirming your choice indicates a conscious decision to remove your contribution from this conversation.
                  </TypographyReportSubHeading>
                </BoxStyleReport2>


                <hr style={{
                  width: '100%',
                  height: '1px', backgroundColor: '#e2e8f0',
                  border: 'none', margin: "0px",
                }} />

                <BoxStyle2
                >
                  <ButtonComponentComment
                  style={{
                    color: "#03B5AA",
  borderRadius: "8px",
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  textTransform: "inherit",
                  }}
              
                    data-test-id="closeId3" onClick={this.handleDeletePopUp}
                  >
                    {"Cancel"}
                  </ButtonComponentComment>
                  <ButtonComponentCancelComment
                  style={{
                    color: "White",
                    borderRadius: "8px",
                    fontFamily: "Inter",
                    fontWeight: 700,
                    lineHeight: "24px",
                    textTransform: "inherit",
                    fontSize: "16px",
                  }}
                 
                    onClick={() => { this.handleDeleteCommentPopup() }} data-test-id="agree"
                  >
                    {"Delete"}
                  </ButtonComponentCancelComment>
                </BoxStyle2>

              </DiologStyle2>
        <DiologStyle2 onClose={this.handleReportPopup} data-test-id="closeId" aria-labelledby="customized-dialog-title"
          open={this.state.openReportPopup}>
          <BoxStyleReport >
            <IconButton
              style={{ padding: "4px", color: "#0A2239" }}
              data-test-id="closeId2"
              onClick={this.handleReportPopup}
              aria-label="close"

            >
              <CloseIcon />

            </IconButton>
          </BoxStyleReport>
          <BoxStyleReport2 style={{
            borderBottom: "1px solid #E2E8F0",
            display: "flex",
            alignItems: "baseline",
            flexDirection: "column"
          }}>

            <TypographyReportHeading >  REPORT COMMENT?  </TypographyReportHeading>


            <TypographyReportSubHeading >If this comment contains inappropriate content, please select "Report" and we'll review it faster than a greyhound at full speed!
            </TypographyReportSubHeading>
          </BoxStyleReport2>

          <BoxStyleReport4
          >

            <Typography style={{ ...commentStyle.subheadingStyle, marginBottom: "4px" }}>Reason for reporting this comment{this.starIcon()}
            </Typography>

            <SelectField >
              <Select
                variant="outlined"
                error={this.state.reportReasonError}
                id="Category"
                value={this.state.selectedReportReason}
                onChange={(event: any) => this.handleReportSelection(event)}
                data-test-id="report-option"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  MenuListProps: {
                    style: {
                      flexDirection: "column",
                      display: "flex",
                      maxHeight: 250,
                      padding: "10px",
                      gap: "8px"
                    },
                  },

                }}
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  color: this.handleStylePlaceholder(this.state.selectedReportReason),
                  fontFamily: "Lato",

                }}
                fullWidth
                IconComponent={KeyboardArrowDown}
              >
                <MenuItem
                  value="Select reason"
                  style={{
                    display: "none",
                    fontWeight: 400,
                    fontFamily: "Lato",
                    justifyContent: "flex-start",
                    color: "#94A3B8",
                    fontSize: "16px",
                  }}
                >
                  Select reason
                </MenuItem>
                {this.state.reportReasonArray.map((item: any) => {
                  return <MenuItem
                    value={item?.name}
                    style={{
                      fontSize: "16px",
                      display: "flex",
                      fontFamily: "Lato",
                      justifyContent: "flex-start",
                      fontWeight: 400,
                    }}
                  >
                    {item.name}
                  </MenuItem>
                })}
              </Select>
              <Typography style={commentStyle.errorNameStyle}>
                {this.state.reportReasonError && "Please select an option"}
              </Typography>
            </SelectField>
            <Typography
              style={{ ...commentStyle.subheadingStyle, marginBottom: "4px" }}
            >
              Explain in brief
              {this.state.selectedReportReason === "Other" && this.starIcon()}
            </Typography>
            <BoxStyledCompo >

              <AutoCompletStyle
                minRows={8}
                data-test-id="other-report-Error"
                value={this.state.reportDescription}
                placeholder="Write something..."
                style={{
                  overflow: "auto",
                  border: this.state.reportDescriptionError ? "1px solid #F87171" : "1px solid #CBD5E1",
                  color: !this.state.reportDescription ? "#94A3B8" : "#1E293B",
                  
                }}

                onChange={(e: any) => {
                  this.handleReportDescription(e)
                }
                }
              />
              {this.state.reportDescriptionError && <Typography style={{
                color: "#DC2626",
                lineHeight: "18px",
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Lato",
              }}>
                Please enter a value
              </Typography>}
            </BoxStyledCompo>

          </BoxStyleReport4>
          <hr style={{
            width: '100%',
            backgroundColor: '#e2e8f0',
            border: 'none', margin: "0px",
            height: '1px',
          }} />



          <BoxStyle2
          >

            <ButtonComponent2
              style={{
                color: "#03B5AA",
                borderRadius: "8px",
                fontFamily: "Inter",
                fontWeight: 700,
                lineHeight: "24px",
                textTransform: "inherit",

                fontSize: "16px",
              }}
              data-test-id="closeId3"
              onClick={this.handleReportPopup}
            >
              {"Cancel"}
            </ButtonComponent2>
            <ButtonStyleComp
              style={{
                marginLeft: "0px", fontFamily: "Inter",
                textTransform: 'inherit',
                background: '#03B5AA',
                border: '1px solid #03B5AA',
                borderRadius: 8,
                padding: "10px 16px 10px 12px",
                lineHeight: '24px',
                fontWeight: 700,
                fontSize: 16,
                color: '#fff',
              }}
              data-test-id="agree-Report" onClick={() => { this.handleValidationPopup() }} >
              {"Report"}
            </ButtonStyleComp>
          </BoxStyle2>

        </DiologStyle2>
        <DiologComponent success={this.state.showModalOpen} handleClose={this.handleCloseShowModal} firstHeading="COMMENT POSTED!" secondHeading="Your comment has been successfully added to the blog. Thanks for joining the conversation! Your insights make our community even more pawsome!" ButtonName={"OKAY"}  />
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start 

const ButtonComponent = styled(Button)({
  width: "128px", color: "#334155",
  borderRadius: "8px",
  fontFamily: "Lato",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "inherit",
  border: "1px solid #CBD5E1",
  fontSize: "16px",
  height: "44px",
  '@media (max-width: 418px)': {
    minWidth: "151px"
  },
})

const BoxCommentStyled=styled(Container)({
  width: '772px',
  '@media (max-width: 850px)': {
    width: 'unset',
  },
  '@media (max-width: 840px)': {
      paddingLeft: 'unset',
      paddingRight: 'unset'
  },
})

const StyledBox = styled(Box) ({
  '@media (max-width: 790px)': {
    display: 'flex'
  },
})
const IconButtonStyle=styled(IconButton)({
  '@media (max-width: 840px)': {
padding:"0px "
  },
})

const MainGrid = styled(Typography)({
  marginBottom: '120px',
  "@media (max-width:722px)": {
    marginTop: "20px",
  },
});

const Typography2 = styled(Grid)({
  fontFamily: 'Inter, sans-serif',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  color: '#64748B'
});

const TypographyLoadMore = styled(Typography)({
  textAlign: "center",
  marginBottom: "60px",
  marginTop: '60px',
  color: "#074F57",
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  cursor: 'pointer'
})

const FlexBox = styled(Box)({
  display: 'flex',
  position: 'relative',
});
export const DividerBox = withStyles({
  root: {
    height: '1px',
    top: '2000px',
    left: '334px',
    transform: 'rotate(-0deg)',
    margin: '4% 0%',
    backgroundColor: '#CBD5E1',
  },
})((props: any) => <Divider {...props} />);
const Comment = styled(Typography)({
  fontFamily: 'Inter',
  letterSpacing: '-0.5%',
  textAlign: 'left',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
  margin: '2% 0.5% 2% 0%',
  color: '#334155'

})
const SmallComment = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '32px',
  letterSpacing: '-0.005em',
  textAlign: 'left',
  margin: '2% 0%',
  color: "#334155"
})


const TextAvatar = styled(Avatar)({
  width: '40px',
  height: '40px',
  borderRadius: '100px',
  backgroundColor: '#FFFFFF',
  color: "#03B5AA",
  border: '2px solid',
  marginRight: 10,
})
const StyledTextWithIcon = withStyles({
  root: {
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      fontSize: "12px",
      fontFamily: "Lato",
      fontWeight: 400,
      lineHeight: "18px",
    }
    , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F87171"
    },
    "& .MuiInputBase-root": {
      color: "#334155"
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#CBD5E1",
      },
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        borderColor: "#CBD5E1",
      },
    },

    "& .MuiOutlinedInput-input": {
      padding: "12px 16px",
      paddingRight: "45px",
      overflowX: "auto"
    },

    "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px"
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: "1px solid #CBD5E1",
    },
    '@media (max-width:850px )': {
      minWidth: "unset",
      maxWidth: "unset",
    },
    overflow: 'hidden',
    "& .MuiInputBase-input::placeholder": {
      color: "#94A3B8",
      opacity:2,
    },
    "& .MuiInputBase-input": {
      fontWeight: 400,
    }
  }
})((props: any) => (
  <TextField
    {...props}

  />
));

const CommentBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap:"8px",
  flexWrap:"wrap",
  '@media (max-width:840px )': {
    gap:"12px",
    alignItems: 'unset',
    flexDirection:"column"

  },


})
const StyledCommentBox = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: "24px",
  alignItems: "center",
  marginBottom: '25px',
  gap: '8px'

})
const CommentTypography = styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '29px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: "#334155",
  maxWidth:"600px",
  '@media (max-width:840px )': {
    maxWidth: 'unset',

  },

})





const DiologStyle2 = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    borderRadius: "8px 8px 32px 8px",
    minWidth: "600px",
    maxWidth: "600px",
  },
  "@media (max-width:722px)": {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "8px 8px 32px 8px",
      minWidth: "unset",
    },
  },
  "@media (max-width:500px)": {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "8px 8px 8px 8px",
      minWidth: "327px",
    },
  },
  "@media (max-width:400px)": {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "8px 8px 8px 8px",
      minWidth: "unset",
    },
  },
})
 const ButtonComponentCancelComment = styled(Button)({
  width: "151px", color: "#334155",
  borderRadius: "8px",
  fontFamily: "Lato",
  background: '#03B5AA',
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "inherit",
  border: "1px solid #CBD5E1",
  fontSize: "16px",
  height: "56px",
  "&:hover": {
    background: '#03B5AA',
  
     },
  '@media (max-width: 500px)': {
    width:"100%",
    border:"unset !important",
    height:"44px",
    minWidth: "151px"
  },
})

 const ButtonComponentComment = styled(Button)({
  width: "140px",  color: "#03B5AA",
  borderRadius: "8px",
  fontFamily: "Lato",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "inherit",
  border: "1px solid #03B5AA",
  fontSize: "16px",
  height: "56px",
  '@media (max-width: 500px)': {
    width:"100%",
    height:"44px",
    border:"unset !important",
    minWidth: "151px"
  },
})
const BoxStyleReport=styled(Box)({
  display: "flex",
  padding: "16px 16px 12px 40px ",
  alignItems: "center",
 justifyContent: "flex-end",
 "@media (max-width:500px)": {
  padding:"16px"
},
})


const BoxStyleReport2 = styled(Box)({

  marginInline: "40px",

  "@media (max-width:500px)": {
    marginInline: "16px",

  },
})

const TypographyReportHeading=styled(Typography)({
  fontSize: '24px',
  lineHeight: '32px',
  fontFamily: 'Inter',
  letterSpacing: '-0.12px',
  fontWeight: 700,
  "@media (max-width:500px)": {
    color: "#0F172A",
    fontFamily: 'Lato',
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
})

const TypographyReportSubHeading=styled(Typography)({
  fontFamily: 'Inter',
  fontSize: '18px',
  lineHeight: '28.8px',
  marginBottom:"24px",
  marginTop: "16px",
  fontWeight: 400,
  "@media ( max-width:500px )": {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '22px',
    marginBottom:"16px",
    marginTop: "12px",
    fontWeight: 400,
  },
})

const SelectField = styled(FormControl)({
  marginBottom: "24px",
  width: "100%",


  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#CBD5E1",
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px 8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderRadius: "8px"
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#CBD5E1",
    },
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px"
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    lineHeight: "18px",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
  },


  "& .MuiInputBase-input": {
    lineHeight: "19.2px",

  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },
});
export const BoxStyleReport4=styled(Box)({
  marginInline: "40px", marginTop: "24px", 
  flexDirection:"column",
 "@media (max-width:500px)": {
  marginInline: "16px",
  marginTop:"16px"
},
})
const BoxStyledCompo=styled(Box)({
  marginBottom: "24px",
  '@media (max-width:550px )': {
  marginBottom: "16px",
  }
  
})
const AutoCompletStyle = styled(TextareaAutosize)({
  color: "var(--Neutrals-Cool-gray-900, #0F172A)",
  minHeigh: "110px",
  maxHeight: "130px",
  padding: "8px",
  fontSize: "16px",
  width: "100%",
  minWidth: "100%",
  maxWidth: "100%",
  fontFamily: "Lato",
  borderRadius: "8px",
  fontWeight: 400,
  "&:focus-visible": {
    outline: "unset",
  },
  "&::placeholder": {
    color: "#94A3B8",
    fontWeight: 400,
    fontFamily: "Inter",
    fontSize: "16px",
    opacity:1,
  },
  lineHeight: "150%",
  
  
});
const BoxStyle2=styled(Box)({
  padding: "24px", gap: "16px", display: "flex",  justifyContent: "flex-end",
  '@media (max-width: 500px)': {
   justifyContent:"center",
   alignItems:"center",
   flexDirection:"column-reverse",
   padding:"16px"
  },
})
const ButtonComponent2 = styled(Button)({
  width: "128px",
  border: "1px solid #03B5AA !important",
  height: "56px",
  '@media ( max-width: 500px)': {
    width: "unset",
    border: "unset !important",
    height: "44px"
  },
})

const ButtonStyleComp = styled(Button)({
  width: "151px", height: "56px",
  minWidth: 137,
  '& .MuiButton-root:hover': {
    background: "#03B5AA",
  },
  "@media (max-width:500px)": {
    minWidth: "0px",
    width: "100%",
    height: "44px"
  },
  
})

// Customizable Area End
