import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    TextField,
    MenuItem,
    Popper,
    ClickAwayListener,
    Paper,
    MenuList,
    Grow,
    Backdrop,
    Snackbar,
} from "@material-ui/core";

import { styled } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { no_application,no_requestMeet,CrossMark,UpArrow, DownArrow,checkbox_Checked, checkbox_UnChecked} from './assets'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import RequestManagementController, {
    Props,
    configJSON,
} from "./RequestManagementController";

export default class MySubmittedRequests extends RequestManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    hanldeSvg = () => {
        return <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
                fill="#64748B"
            />
        </svg>

    }
    StyleBackground = (value: any) => {
        return value ? "#03B5AA" : "#FFF";
    }
    StyleColor = (value: any) => {
        return value ? "#FFF" : "#334155"
    }
    handleClickPostType = () => {
        this.setState({ postTypeMenu: !this.state.postTypeMenu, TypeMenu:false,statusMenu:false});
    }
    handleClickType = () => {
        this.setState({ TypeMenu: !this.state.TypeMenu ,postTypeMenu:false,statusMenu:false});
    }
    handleSvgArrow = (value: any) => {
        return value ? UpArrow : DownArrow;
    }
    handleForClickStatus = () => {
        this.setState({ postTypeMenu:false,TypeMenu:false,
            statusMenu: !this.state.statusMenu 
            });
    }
    handleClosePost = (e:any) => {
        
     if(e.target.id!='button'&&e.target.classList[0]!='MuiButton-label'){

         this.setState({ postTypeMenu: false });
     }
    };
    handleCloseStatus = (e:any) => {
       
        if(e.target.id!='statusbutton'&&e.target.classList[0]!='MuiButton-label'){
            this.setState({ statusMenu: false });
        }
    };
    handleCloseForType = (e:any) => {
        if(e.target.id!='typebutton'&&e.target.classList[0]!='MuiButton-label'){
            this.setState({ TypeMenu: false });
        }
       
    };
    renderSelectFilter = () => {
        return <FilterBox>
            <div style={{ position: 'relative' }} >
                <ButtonCompSelect variant="contained"
                    ref={this.state.postTypeMenuRef}
                    aria-haspopup="true"
                    aria-controls={Boolean(this.state.postTypeMenu) ? 'menu-list-growanimaltypeMenu' : undefined}
                    style={{ ...Allstyle.optionButtonStyle, background: this.StyleBackground(this.state.postTypeMenu), color: this.StyleColor(this.state.postTypeMenu) }}
                    onClick={this.handleClickPostType}
                    data-test-id='postTypeMenu'
                    id="button"
                >
                    <span  id="button" style={{ marginRight: "6px" }}>Post</span>
                    {
                        <img id="button" src={this.handleSvgArrow(this.state.postTypeMenu)} />
                    }
                </ButtonCompSelect>
                {this.handleMenuOptionDropDown({
                    menuItem: this.state.postTypeMenu,
                    closeFn: this.handleClosePost,
                    options: this.state.PostArr,
                    selectedMenu: this.handleMenuPost,
                    selectedMenuItem: this.state.postTypeOption,
                    ref: this.state.postTypeMenuRef,
                    handleListKeyDownmenu: this.handleListKeyDownMenu,
                    stateName: "postTypeMenu",
                })}
            </div>

            <div style={{ position: 'relative' }}>
                <ButtonCompSelect variant="contained"
                    ref={this.state.TypeMenuRef}
                    aria-haspopup="true"
                    aria-controls={Boolean(this.state.TypeMenu) ? 'menu-list-growanimaltypeMenu' : undefined}
                    style={{ ...Allstyle.optionButtonStyle, background: this.StyleBackground(this.state.TypeMenu), color: this.StyleColor(this.state.TypeMenu) }}
                    onClick={this.handleClickType}
                    data-test-id='TypeMenu'
                    id="typebutton"
                >
                    <span  style={{ marginRight: "6px" }}   id="typebutton">Type</span>
                    {
                        <img  src={this.handleSvgArrow(this.state.TypeMenu)}    id="typebutton"/>
                    }
                </ButtonCompSelect>
                {this.handleMenuOptionDropDown({
                    menuItem: this.state.TypeMenu,
                    closeFn: this.handleCloseForType,
                    options: this.state.typeArr,
                    selectedMenu: this.handleMenuType,
                    selectedMenuItem: this.state.TypeOption,
                    ref: this.state.TypeMenuRef,
                    handleListKeyDownmenu: this.handleListKeyDownMenu,
                    stateName: "TypeMenu",
                })}
            </div>

            <div style={{ position: 'relative' }}>
                <ButtonCompSelect variant="contained"
                    ref={this.state.statusMenuRef}
                    aria-haspopup="true"
                    aria-controls={Boolean(this.state.statusMenu) ? 'menu-list-growanimaltypeMenu' : undefined}
                    style={{ ...Allstyle.optionButtonStyle, background: this.StyleBackground(this.state.statusMenu), color: this.StyleColor(this.state.statusMenu) }}
                    onClick={this.handleForClickStatus}
                    data-test-id='statusbutton'
                    id="statusbutton"
                >
                    <span   id="statusbutton"
                     style={{ marginRight: "6px" }}>Status</span>
                    {
                        <img   id="statusbutton"
                        src={this.handleSvgArrow(this.state.statusMenu)} />
                    }
                </ButtonCompSelect>
                {this.handleMenuOptionDropDown({
                    menuItem: this.state.statusMenu,
                    closeFn: this.handleCloseStatus,
                    options: this.state.statusArr,
                    selectedMenu: this.handleMenustatus,
                    selectedMenuItem: this.state.statusOption,
                    ref: this.state.statusMenuRef,
                    handleListKeyDownmenu: this.handleListKeyDownMenu,
                    stateName: "statusMenu",
                })}
            </div>
        </FilterBox>
    }
    handleMenuOptionDropDown = (menuConfig: any) => {
        const { menuItem, closeFn, options, selectedMenu, selectedMenuItem, ref, handleListKeyDownmenu, stateName } = menuConfig;
        return (
            <CustomPopper  data-test-id='CustomPopper' open={menuItem} anchorEl={ref.current} placement={'bottom-start'} role={undefined} disablePortal style={{ marginTop: "20px", zIndex: 1111, position: "absolute" }}>
                <GridOption  style={{ minWidth: "240px", maxHeight: "321px", overflowY: "auto", borderRadius: "8px", background: "#FFF" }}>
                    <ClickAwayListener onClickAway={closeFn} >
                        <CustomMenuList  data-test-id='CustomMenuList' autoFocusItem={menuItem} id={`menu-list-grow${stateName}`} onKeyDown={(event: any) => handleListKeyDownmenu(event, stateName)}>
                            {options.map((item: any, index: number) => {
                                return (
                                    <MenuItem
                                        onClick={() => {
                                            selectedMenu(item?.id, item.name);
                                        }}
                                        style={{
                                            ...Allstyle.menuListItemStyle,
                                        }}
                                        key={index}
                                        data-test-id='MenuItem'
                                    >
                                        <span
                                            style={{
                                                marginRight: "8px",
                                                width: "24px",
                                                height: "24px",
                                            }}
                                        >
                                            {selectedMenuItem.includes(item?.id) ? (
                                                <img src={checkbox_Checked} />
                                            ) : (
                                                <img src={checkbox_UnChecked} />
                                            )}
                                        </span>
                                        {this.handleTextStatus(item.name)}
                                    </MenuItem>
                                );
                            })}
                        </CustomMenuList>
                    </ClickAwayListener>
                </GridOption>
            </CustomPopper>
        );
    }


    handleMenuIndex = (index: number, value: any) => {
        this.setState({ menu: index, sortValue: value });
        this.getMySubmittedRequest()
    };

    chipBackGroundColor = (status: any) => {
        if (status == 'Pending') {
            return 'rgba(254, 243, 199, 1)'

        } else if (status == 'Rejected') {
            return 'rgba(254, 226, 226, 1)'
        }
        else if (status == 'Archived') {
            return '#93ccea'
        }
        else {
            return 'rgba(209, 250, 229, 1)'

        }
    }
    chipTextColor = (status: any) => {
        if (status == 'Pending') {
            return 'rgba(217, 119, 6, 1)'

        } else if (status == 'Rejected') {
            return 'rgba(220, 38, 38, 1)'
        }
        else if (status == 'Archived') {
            return '#2e6ea7'
        }
        else {
            return 'rgba(5, 150, 105, 1)'

        }
    }
    setAnchor = (event: any) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    setAnchorClose = () => {
        this.setState({ anchorEl: null })
    }
    renderEmptyPage = (image: any, text: any, isExtra: any,extraText:any,showButton:any) => {
        return <EmptyParentBox>
            <Box style={Allstyle.imageTextBoxEmpty}>
                <EmptyImg src={image} alt="" />
                <EmptyTypography>{text}</EmptyTypography>
                {isExtra && <EmptyFeelFreeText>{extraText}</EmptyFeelFreeText>}
                {isExtra &&showButton&& <Button data-test-id='SearchAdvanceNav' style={Allstyle.explore_pets_button} onClick={()=>this.hanldeNavigation("SearchAdvance")}> <Typography style={Allstyle.explore_pets_button_text}>Explore Pets</Typography> </Button>}
            </Box>

        </EmptyParentBox>
    }
    showChipBox = () => {
        return ((!this.state.TypeOption.includes(0)) ||
            (!this.state.postTypeOption.includes(0)) ||
            (!this.state.statusOption.includes(0))
        )
    }
    popoverFun = (isPopover: any) => {

        return isPopover ? 'simple-popover' : undefined;
    }
    renderButtonComp = () => {
        return (
            this.state.pathName!=='/my-received-requests'&&this.state.tableData.length == 0 && this.state.showEmtyImageForNoSubmittedReq && <ButtonComParentBox>
                <ButtonCom variant="outlined"
                    onClick={()=>this.handleNavigateTemplate('request_management_template_data','Adoption','MyAdoptionTemplate')}
                    data-test-id='NavigateMyAdoptionTemplate1'
                >
                    {configJSON.my_Adoption_Template}
                </ButtonCom>
                <ButtonCom variant="outlined"
                     onClick={()=>this.handleNavigateTemplate('request_management_template_data','Foster','MyFosterTemplate')}
                     data-test-id='NavigateMyFosterTemplate2'
                >
                    {configJSON.my_Foster_Template}
                </ButtonCom>
            </ButtonComParentBox>

        )
    }
    returnPageHeading = () => {
        return (
            !this.state.showEmtyImageForNoSubmittedReq && <PageHeadingButtonBox >
                <Typography style={Allstyle.Textstyle}>
                    { this.state.pathName=='/my-received-requests'?configJSON.my_Received_Requests:configJSON.pageHeading}
                </Typography>
                { this.state.pathName!=='/my-received-requests'&&<ButtonComParentBox>
                    <ButtonCom variant="outlined"
                    data-test-id='NavigateMyAdoptionTemplate'
                    onClick={()=>this.handleNavigateTemplate('request_management_template_data','Adoption','MyAdoptionTemplate')}
                    >
                        {configJSON.my_Adoption_Template}
                    </ButtonCom>
                    <ButtonCom variant="outlined"
                     data-test-id='NavigateMyFosterTemplate'
                     onClick={()=>this.handleNavigateTemplate('request_management_template_data','Foster','MyFosterTemplate')}
                    >
                        {configJSON.my_Foster_Template}
                    </ButtonCom>
                </ButtonComParentBox>}
            </PageHeadingButtonBox>
        )
    }
   
    returnFilterAndSearchBox = (id: any, isPopover: any) => {
        return (
            !this.state.showEmtyImageForNoSubmittedReq && 
            <SearchAndFilterParentBox>
                <AutocompleteComponentSearch
                    options={[]}
                    freeSolo
                    id="free-solo"
                    data-test-id='searchInputOuter'
                    onInputChange={this.handleClearSeachInput}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            placeholder="Search by keywords"
                            value={this.state.searchKeyword}
                            data-test-id='searchInput'
                            onChange={(e: any) => {
                                this.setState({ searchKeyword: e.target.value ,currentTablePage:1}, () => this.getMySubmittedRequest())
                                if (e.target.value === '') {
                                    this.getMySubmittedRequest()
                                }
                            }}
                            InputProps={{
                                ...params.InputProps, type: 'search', startAdornment: (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        fill="none"
                                        height="24"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M15.1865 14.4713H15.9765L20.2165 18.7313C20.6265 19.1413 20.6265 19.8113 20.2165 20.2213C19.8065 20.6313 19.1365 20.6313 18.7265 20.2213L14.4765 15.9713V15.1813L14.2065 14.9013C12.8065 16.1013 10.8965 16.7213 8.86646 16.3813C6.08646 15.9113 3.86646 13.5913 3.52646 10.7913C3.00646 6.56133 6.56646 3.00133 10.7965 3.52133C13.5965 3.86133 15.9165 6.08133 16.3865 8.86133C16.7265 10.8913 16.1065 12.8013 14.9065 14.2013L15.1865 14.4713ZM5.47646 9.97133C5.47646 12.4613 7.48646 14.4713 9.97646 14.4713C12.4665 14.4713 14.4765 12.4613 14.4765 9.97133C14.4765 7.48133 12.4665 5.47133 9.97646 5.47133C7.48646 5.47133 5.47646 7.48133 5.47646 9.97133Z"
                                            clip-rule="evenodd"
                                            fill="#94A3B8"
                                        />
                                    </svg>
                                ),
                            }}
                        />
                    )}
                />
                <FilterParentBox>
                    {this.renderSelectFilter()}
                    <SortParentBox
                        aria-haspopup="true"
                        onClick={this.setAnchor}
                        aria-controls={Boolean(this.state.sortMenuItem) ? 'menu-list-grow' : undefined}
                        aria-describedby={id}
                        data-test-id='SortParentBox'
                    >
                        <span
                            style={{
                                ...Allstyle.sortByText,
                                marginRight: "4px",
                                fontWeight: 400,
                            }}
                        >
                            Sort by:
                        </span>
                        <Typography
                            style={{
                                ...Allstyle.submissionDateText,
                                fontWeight: 700,
                                display: "flex",
                                cursor: "pointer",
                            }}
                        >
                            {configJSON.sortMenuItemList[this.state.menu].name}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                                    fill="#334155"
                                />
                            </svg>
                        </Typography>
                    </SortParentBox>
                    <Popper data-test-id='sortPopper' open={isPopover} id={id} anchorEl={this.state.anchorEl} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper style={{ width: "240px", borderRadius: "8px", background: "#FFF" }}>
                                    <ClickAwayListener onClickAway={this.setAnchorClose}>
                                        <CustomSortMenu data-test-id='CustomSortMenu' autoFocusItem={Boolean(this.state.sortMenuItem)} id="menu-list-grow" onKeyDown={(event: any) => this.handleListKeyDownMenu(event, 'sortMenuItem')}>
                                            {configJSON.sortMenuItemList.map((item: any, index: number) => {
                                                return (
                                                    <MenuItem
                                                        data-test-id='sortMenuItemListItem'
                                                        onClick={() => this.handleMenuIndex(index, item.value)}
                                                        style={{
                                                            ...Allstyle.menuItemSortStyle,
                                                            background:
                                                                this.state.menu == index
                                                                    ? "linear-gradient(0deg, rgba(205, 240, 238, 0.50) 0%, rgba(205, 240, 238, 0.50) 100%), #FFF"
                                                                    : "",
                                                        }}
                                                        key={index}
                                                    >
                                                        {this.state.menu == index ? (
                                                            <span
                                                                style={{
                                                                    marginRight: "8px",
                                                                }}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M8.79457 15.8754L5.32457 12.4054C5.13774 12.2181 4.88409 12.1129 4.61957 12.1129C4.35505 12.1129 4.1014 12.2181 3.91457 12.4054C3.52457 12.7954 3.52457 13.4254 3.91457 13.8154L8.09457 17.9954C8.48457 18.3854 9.11457 18.3854 9.50457 17.9954L20.0846 7.41538C20.4746 7.02538 20.4746 6.39538 20.0846 6.00538C19.8977 5.81812 19.6441 5.71289 19.3796 5.71289C19.1151 5.71289 18.8614 5.81812 18.6746 6.00538L8.79457 15.8754Z"
                                                                        fill="#0F172A"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    marginRight: "8px",
                                                                    width: "24px",
                                                                    height: "24px",
                                                                }}
                                                            ></span>
                                                        )}
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </CustomSortMenu>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </FilterParentBox>
            </SearchAndFilterParentBox>
        )
    }
    returnChipsBox = () => {
        return (this.showChipBox() && !this.state.showEmtyImage && <PostBoxMain style={{ marginTop: "24px", marginBottom: "48px", display: "flex", justifyContent: "space-between" }}>
            <PostGridBox style={{ display: "flex", gap: "16px", flexWrap: 'wrap', alignItems: "center" }}>
                {this.state.postTypeOption && this.state.postTypeOption.length > 0 && !this.state.postTypeOption.includes(0) &&
                    this.state.postTypeOption.map((index: any) => {
                        const search = this.state.PostArr.find((ele: any) => ele.id === parseInt(index))
                        return <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                            <img src={CrossMark} style={{ cursor: "pointer" }} data-test-id="test1" onClick={() => { this.clearOptionFilter('postTypeOption', index) }} />
                            <Typography style={Allstyle.optionStyleForclear}>
                                {search?.name}
                            </Typography>
                        </Box>
                    })
                }
                {this.state.TypeOption && this.state.TypeOption.length > 0 && !this.state.TypeOption.includes(0) &&
                    this.state.TypeOption.map((item: any, index: any) => {
                        return <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                            <img src={CrossMark} style={{ cursor: "pointer" }} data-test-id="test2" onClick={() => { this.clearOptionFilterType(this.state.TypeOption[0], this.state.typeString) }} />
                            <Typography style={Allstyle.optionStyleForclear}>
                                {this.state.typeString}
                            </Typography>
                        </Box>
                    })
                }
                {this.state.statusOption && this.state.statusOption.length > 0 && !this.state.statusOption.includes(0) &&
                    this.state.statusOption.map((item: any, index: any) => {
                        const search = this.state.statusArr.find((ele: any) => ele.id === parseInt(item))
                        return <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                            <img src={CrossMark} style={{ cursor: "pointer" }} data-test-id="test3" onClick={() => { this.clearOptionFilterStatus(item, search?.name) }} />
                            <Typography style={Allstyle.optionStyleForclear}>
                                {this.handleTextStatus(search?.name)}
                            </Typography>
                        </Box>
                    })
                }
                {this.handleConditionOfClear() &&
                    <Typography style={Allstyle.clearStyleFilter} data-test-id="test5" onClick={() => { this.clearAllOptions() }}>
                        Clear all
                    </Typography>
                }
            </PostGridBox>
        </PostBoxMain>)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const isPopover = Boolean(this.state.anchorEl);
        const id = this.popoverFun(isPopover)
        
        return (

            <>
                <Backdrop
                    style={{ zIndex: 700 }}
                    open={this.state.isShowTemplateMess}
                    data-test-id='backdrop2'
                ><SnackBarSty
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.isShowTemplateMess}
                    style={{ zIndex: 1000 }}
                    autoHideDuration={3000}
                    data-test-id='snackBarForRequestSuccess'
                    onClose={()=>this.setState({isShowTemplateMess:false,templateMess:''})}
                >
                        <Typography style={Allstyle.popupstyle as React.CSSProperties}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
                            </svg>
                            {this.state.templateMess}
                        </Typography>
                    </SnackBarSty>
                </Backdrop>
                    <Loader loading={this.state.isLoading } />
                <Box style={Allstyle.OuterContainer}>
                    <MainTopArrowHeadingForBigScreenBox  >
                        <MainTopArrowHeading>
                            <Typography
                                style={{ ...Allstyle.hedingsStyle, cursor: "pointer" }}
                                data-test-id="home"
                                onClick={() => this.hanldeNavigation('LandingPageWeb')}
                            >{configJSON.home}
                            </Typography>
                            {this.hanldeSvg()}
                            <Typography
                                style={{
                                    ...Allstyle.hedingsStyle,
                                    ...Allstyle.currentPageStyle
                                }}
                            >{ this.state.pathName=='/my-received-requests'?configJSON.my_Received_Requests:configJSON.my_Submitted_Requests }
                            </Typography>
                        </MainTopArrowHeading>
                        {this.renderButtonComp()}
                    </MainTopArrowHeadingForBigScreenBox>
                    <MainTopArrowHeadingForSmallSreenParentBox  >
                        <MainTopArrowHeadingForSmallSreen>
                            <Typography
                            onClick={() => this.hanldeNavigation('LandingPageWeb')}
                                style={{ ...Allstyle.hedingsStyle, cursor: "pointer" }}
                                data-test-id="home2"
                            >{configJSON.home} </Typography>
                            {this.hanldeSvg()}
                            <Typography
                                style={{
                                    ...Allstyle.hedingsStyle,
                                    ...Allstyle.currentPageStyle
                                }}
                            >{this.state.pathName=='/my-received-requests'?configJSON.my_Received_Requests:configJSON.my_Submitted_Requests} </Typography>
                        </MainTopArrowHeadingForSmallSreen>
                        {this.renderButtonComp()}
                    </MainTopArrowHeadingForSmallSreenParentBox>
                    {this.returnPageHeading()}
                    {this.returnFilterAndSearchBox(id, isPopover)}
                    {this.returnChipsBox()}
                    {this.state.pathName=='/my-submitted-requests' &&this.state.tableData.length == 0 && this.state.showEmtyImage && this.renderEmptyPage(this.state.emptyPageSearchImgSub??no_requestMeet, 'No requests meet your search and filter criteria', false,'',false)}
                    {this.state.pathName=='/my-received-requests'&& this.state.tableData.length == 0 && this.state.showEmtyImage && this.renderEmptyPage(this.state.emptyPageSearchImgReq??no_requestMeet, 'No requests meet your search and filter criteria', false,'',false)}
                    {this.state.pathName=='/my-submitted-requests'&&this.state.tableData.length == 0 && this.state.showEmtyImageForNoSubmittedReq && this.renderEmptyPage(this.state.emptyNoSearchImgSub??no_application, 'No Adoption or Foster Applications Yet!', true, 'Feel free to click on the button below to explore adorable pets and apply for adoption or fostering.',true)}
                   
                    {this.state.pathName=='/my-received-requests'&&this.state.tableData.length == 0 && this.state.showEmtyImageForNoSubmittedReq && this.renderEmptyPage( this.state.emptyNoSearchImgReq??no_application, `You haven't received any requests yet!`, true,`We'll notify you as soon as there's a fostering or adoption request for you to consider.`,false)}
                    {this.state.tableData.length > 0 &&
                        <TableContainer data-test-id='TableContainer'>
                            <Table style={Allstyle.table} aria-label="simple table">
                                <TableHead >
                                    <TableRow>
                                        <TableHeadTableCell>Request number</TableHeadTableCell>
                                        <TableHeadTableCell >Post</TableHeadTableCell>
                                        <TableHeadTableCell >Request type</TableHeadTableCell>
                                        <TableHeadTableCell >Submission date</TableHeadTableCell>
                                        <TableHeadTableCell >Status</TableHeadTableCell>
                                        <TableHeadTableCell >Action</TableHeadTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.tableData.map((items: any, index: any) => (
                                                            

                                        <TableRow key={index}> 
                                            <TableCell style={Allstyle.tableFirstColumn} component="th" scope="row">
                                                REQ{items.id}
                                            </TableCell>
                                            <TableCell  ><Typography style={{ ...Allstyle.tableSecondAndSixthColumn,cursor: 'pointer',width:'182px' }} data-test-id='ProductDescriptionPublicLink' onClick={()=>this.handleNavToDesc(items.attributes.post.id)} >{items.attributes.post.name}</Typography></TableCell>
                                            <TableCell style={Allstyle.tableThirdAndFourthColumn} >{items.attributes.request_type}</TableCell>
                                            <TableCell style={Allstyle.tableThirdAndFourthColumn} >{this.formateDate(items.attributes.submission_date)}</TableCell>
                                            <TableCell ><ChipBox style={{ backgroundColor: this.chipBackGroundColor(items.attributes.status) }}><Typography style={{ ...Allstyle.chipText, color: this.chipTextColor(items.attributes.status) }}>{this.handleTextStatus(items.attributes.status)}</Typography> </ChipBox></TableCell>
                                            <TableCell > <Typography style={{ ...Allstyle.tableSecondAndSixthColumn,cursor: 'pointer'  }} data-test-id='RequestDetailsLink' onClick={()=>this.handlnav("RequestDetails",items.id)}>View details</Typography> </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {this.state.tableData.length > 0 && !this.state.showEmtyImage && <ParentBoxPaginationCompstyled >
                        <PaginationComp data-test-id='paginationComp' page={this.state.currentTablePage} count={this.state.tableLastPage}
                            onChange={this.handleChangePageForTable}

                        />
                        <PaginationCompSmallScreen data-test-id='paginationComp' page={this.state.currentTablePage} count={this.state.tableLastPage}
                            onChange={this.handleChangePageForTable}
                            size="small"
                            siblingCount={0}
                        />
                        <CaptionText>
                            {((this.state.currentTablePage - 1) * this.state.per_page) + 1} - {Math.min(this.state.currentTablePage * this.state.per_page, this.state.tableTotalRequest)} of {this.state.tableTotalRequest} results
                        </CaptionText>
                        
                    </ParentBoxPaginationCompstyled>}
                </Box>
                
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start


const MainTopArrowHeading = styled(Box)({
    display: "flex",
    alignItems: "center",
    '@media (max-width:1012px)': {
        display: 'none'
    }
});
const MainTopArrowHeadingForBigScreenBox = styled(Box)({
    display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: "41px 0px 0px 0px",
    '@media (max-width:1012px)': {
        display: 'none'
    }
});
const MainTopArrowHeadingForSmallSreenParentBox = styled(Box)({

    display: 'none',
    '@media (max-width:1012px)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: "41px 0px 0px 0px",
    },
    '@media (max-width:790px)': {
        flexDirection: "column" as 'column',
        alignItems: 'flex-start',
        gap: '16px'

    }

});
const MainTopArrowHeadingForSmallSreen = styled(Box)({

    display: 'none',
    '@media (max-width:1011px )': {
        display: 'block',
    },
    '@media (max-width:1012px )': {
        display: 'flex',
        alignItems: "center",
    },
});
const TableHeadTableCell = styled(TableCell)({
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    letterSpacing: '0px',
    color: 'rgba(100, 116, 139, 1)',
    width: "100px"
})

const ChipBox = styled(Box)({
    width: '90px',
    height: '22px',
    padding: '2px, 8px, 2px, 8px',
    borderRadius: '40px',
    display: 'flex' as 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',

})
const ButtonComParentBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    "@media (max-width:479px)": {
        flexDirection: 'column',
        alignItems: 'flex-start',
    }
})
const ButtonCom = styled(Button)({
    borderRadius: "8px",
    color: "var(--Primary-color-1, #03B5AA)",
    fontFamily: "Lato",
    fontWeight: 700,
    lineHeight: "24px",
    fontSize: "16px",
    height: "44px",
    textTransform: "inherit",
    '&.MuiButton-outlined': {
        border: "1px solid #03B5AA"
    }
})
const SearchAndFilterParentBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: "1px solid rgba(203, 213, 225, 1)",
    paddingBottom: '16px',
    paddingTop: '16px',
    marginBottom: '16px',
    "@media (max-width:1050px)": {
        flexDirection: 'column',
        gap: '32px',
        alignItems: 'unset',
    }

})
const FilterParentBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    "@media (max-width:798px)": {
        flexDirection: 'column',
        alignItems: 'unset',
        gap: '32px',
    }

})
const AutocompleteComponentSearch = styled(Autocomplete)({
    marginRight: "16px",
    width: "374px",
    "& .MuiFormControl-marginNormal": {
        marginBottom: "unset",
        marginTop: "unset",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: "0px 0px 0px 16px"
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: "0px 16px 0px 9px"
    },
    "& .MuiOutlinedInput-root": {
        height: "44px",

        "&:hover fieldset": {
            borderColor: "#CBD5E1",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#CBD5E1",
        },
    },
    "& .MuiInputBase-root": {
        color: "#334155"
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderRadius: "8px",
        borderWidth: "1px",
    },
    "@media (max-width:1050px)": {
        width: '100%',
        marginRight: 'unset',
    }
})

const PaginationComp = styled(Pagination)({
    width: 'max-content',
    justifyContent: 'center',
    '& .MuiPaginationItem-page.Mui-selected': {
        color: "#03B5AA",
        fontWeight: 700,
        background: "unset",
    },
    '& .MuiPaginationItem-root': {
        color: "#334155", fontSize: "16px",
        fontFamily: "Lato", fontWeight: 400
    },
    '@media(max-width: 600px)': {
        display: 'none',
    },
})
const PaginationCompSmallScreen = styled(Pagination)({
    justifyContent: 'center',
    display: 'none',
    '& .MuiPaginationItem-page.Mui-selected': {
        fontWeight: 700,
        color: "#03B5AA",
        background: "unset",
    },
    '& .MuiPaginationItem-root': {
        fontFamily: "Lato", fontWeight: 400,
        color: "#334155", fontSize: "16px",
    },

    '@media(max-width: 600px)': {
        display: 'block',
        width: 'unset',
    },
})
const ParentBoxPaginationCompstyled = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'max-content',
    paddingTop: '16px',
    paddingBottom: '16px',
    '@media(max-width: 1300px)': {
        justifyContent: 'space-between'
    },
    '@media(max-width: 772px)': {
        flexDirection: 'column',

    }
})
const FilterBox = styled(Box)({
    display: "flex",
    gap: '16px',
    alingItems: 'center',
    '@media (max-width: 798px)': {
        display: "flex",
        gap: '16px',
        flexDirection: 'row',
        alingItems: 'unset',
    },
    '@media (max-width: 430px)': {
        flexDirection: 'column',
    }
})
const SortParentBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
    justifyContent: "center", cursor: "pointer",
    width: '263px',
    '@media (max-width: 798px)': {
        marginLeft: 'unset',
    },
    '@media (max-width: 314px)': {
        flexDirection: 'column',
        width: 'unset',
    }
})
const CaptionText = styled(Typography)({
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgba(100, 116, 139, 1)',
    lineHeight: '18px',
    letterSpacing: '0em',
    textAlign: 'right',
    position: 'absolute',
    right: '8.1%',
    '@media (max-width:772px)': {
        position: 'unset',
        right: 'unset',
    }

})
const CustomPopper = styled(Popper)({
    "& .MuiPaper-rounded": {
        borderRadius: '8px',
        border: '1px solid rgba(245, 245, 245, 1)',
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
    },
})
const ButtonCompSelect = styled(Button)({
    '&.MuiButton-contained': {
        boxShadow: "unset",
        minWidth: "109px",
    }
})
const GridOption = styled(Paper)({
    "&::-webkit-scrollbar": { width: "2px" },
    "&::-webkit-scrollbar-track": {
        borderRadius: "6px",
        "-webkit-box-shadow": "inset 0 0 5px #E2E8F0",
    },
    "&::-webkit-scrollbar-thumb": {
        borderRadius: "6px",
        backgroundColor: "#94A3B8",
    },
})
const CustomMenuList = styled(MenuList)({
    minWidth: "200px",
    paddingTop: "unset",
    paddingBottom: "unset",
    borderRadius: "8px",
})
const CustomSortMenu = styled(MenuList)({
    paddingTop: "unset",
    minWidth: "200px",
    borderRadius: "8px",
    paddingBottom: "unset"
})
const PageHeadingButtonBox = styled(Box)({
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginBottom: '32px',
    marginTop: '32px',
    "@media (max-width:1050px)": {
        flexDirection: 'column',
        gap: '16px',
    }
})
const PostBoxMain = styled(Box)({
    '@media (max-width:600px)': {
        flexWrap: "wrap"
    },
})

const PostGridBox = styled(Box)({
    maxWidth: "900px",
    '@media (max-width:560px)': { maxWidth: "350px" },
    '@media (max-width:400px)': { maxWidth: "250px" },
})
const EmptyParentBox = styled(Box)({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

})
const EmptyImg = styled('img')({
    width: '257.28px',
    height: '248.55px',
    "@media(max-width:320px)":
    {
        width: "100%"
    }
})
const EmptyTypography = styled(Typography)({
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    letterSpacing: ".005em",
    color: "rgba(0, 0, 0, 1)",
    "@media(max-width:514px)": {
        textAlign: 'center',
    }

})
const EmptyFeelFreeText = styled(Typography)({
    fontFamily: 'Lato' as "Lato",
    fontSize: '16px',
    fontHeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    color: 'rgba(71, 85, 105, 1)',
    textAlign: 'center',
    width: '418px',
    "@media(max-width:514px)": {
        width: 'unset',
    }
})
const SnackBarSty= styled(Snackbar)({
    "&.MuiSnackbar-anchorOriginTopCenter":{
      top:"113px",
    }
    })
const Allstyle = {

    OuterContainer: {
        margin: 'auto',
        width: '84%',
        marginBottom: '70px',
    },

    currentPageStyle: {
        paddingInline: "6px",
        color: 'var(--Neutrals-Cool-gray-900, #0F172A)',

    },
    Textstyle: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "30px",
        fontWeight: 700,
        lineHeight: "40px",
        letterSpacing: "-0.15px",

    },
    nameStyling: {
        color: "#334155",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    hedingsStyle: {
        color: "#475569",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    table: {
        minWidth: 650,
    },
    tableFirstColumn: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0px',
        color: 'rgba(0, 0, 0, 1)',
        fontFamily: 'Inter' as 'Inter',
    },
    tableSecondAndSixthColumn: {
        fontFamily: 'Lato' as 'Lato',
        fontWeight: 700,
        lineHeight: '24px',
        fontSize: '16px',
        color: 'rgba(3, 181, 170, 1)',
        letterSpacing: '0em',
        whiteSpace: 'nowrap' as 'nowrap',
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    tableThirdAndFourthColumn: {
        lineHeight: '22px',
        fontFamily: 'Lato' as 'Lato',
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '0em',
        color: 'rgba(0, 0, 0, 1)'
    },
    chipText: {
        fontFamily: 'Lato' as 'Lato',
        fontWeight: 700,
        fontSize: '12px',
        letterSpacing: '0em',
        lineHeight: '18px',
    },

    sortByText: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: 'rgba(51, 65, 85, 1)',
    },
    submissionDateText: {
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: 700,
        letterSpacing: '0px',
        lineHeight: '22px',
        color: 'rgba(51, 65, 85, 1)',
        marginLeft: '4px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',

    },
    SortKeyboardArrowDownStyle: {
        marginLeft: '4px',
    },
    sortMenuText: {
        fontFamily: 'Lato',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '19px',
        letterSpacing: '0em',
    },
    outerBoxSortMenuText: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },
    optionButtonStyle: {
        border: "1px solid #CBD5E1",
        padding: "4px 8px 4px 16px",
        textTransform: "inherit" as "inherit",
        height: "44px",
        borderRadius: "8px",
        background: "#FFF",
        color: "#334155",
        fontSize: "16px",
        fontFamily: "Lato" as "Lato",
        fontWeight: 400,
    },
    menuListItemStyle: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        padding: "12px 16px"
    },

    menuItemSortStyle: {
        fontFamily: "Lato",
        color: "#0F172A",
        fontWeight: 400,
        fontSize: "16px",
        padding: "12px 16px"
    },
    optionStyleForclear: {
        color: "#FF5E5B",
        lineHeight: "18px",
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 400,
    },
    clearStyleFilter: {
        fontFamily: "Lato" as "Lato",
        color: "#64748B",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        cursor: "pointer",
    },
    imageTextBoxEmpty: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '16px',
        marginTop:'105px',
        marginBottom:'105px'
    },
    explore_pets_button: {
        padding: '10 16 10 16',
        borderRadius: 8,
        background: 'rgba(3, 181, 170, 1)',
        textTransform: 'none' as 'none'
    },
    explore_pets_button_text: {
        fontFamily: 'Lato' as "Lato",
        fontSize: '16px',
        
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0em',
        color: 'rgba(255, 255, 255, 1)'
    },
    popupstyle: {
        color: "#0F172A",
        display: "flex",
        gap:"8px",
        backgroundColor: "#ffffff",
        alignItems: "center",
        borderRadius: "8px",

        fontSize: "16px",
        fontWeight: 400,
        boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
        fontFamily: "Inter",
        lineHeight: "24px",
        padding:"9px 16px 9px 8px ",
        textAlign:"center",
      },
}
// Customizable Area End
