import { Route } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";
import LandingPageWeb from "../../../blocks/email-account-registration/src/LandingPage.web"
import EmailAccountLoginBlock from "../../../blocks/email-account-login/src/EmailAccountLoginBlock";
import UserRole from "../../../blocks/email-account-registration/src/UserRole.web";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    if (!routeMap[to].modal) {
      params ? history.push(url, params) :
        history.push(url);
    } else {
      const slash = /\/$/.test(match.url) ? "" : "/";
      url = match.url + slash + url;
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack, history },
    closeModal
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};
const rescuerUserUrl = ['/', '/blogs', "/aboutus","/petootiprocess",
'/ourstory', '/ourwork', '/contactus', '/blogs-details','/faq','/previewProductDescription',
  '/favorites', '/notifications', '/notifications-details', '/myprofile-rescuer',
  '/petpost-description', '/petpost-description-owner', '/my-received-requests', '/my-adopted-pets',
  '/my-posts', '/search', '/community', '/community-topics', '/community-topics-details',
  '/aboutus', '/my-submitted-requests', '/request-details',
  '/generic-foster-form',
  '/community-topics-new', '/community-topics-edit', '/petootiprocess', '/createnewpost', '/editpost','/adoption-form','/foster-form','/adoption-template','/foster-template']
const adopterUserUrl = ['/', '/blogs', '/ourstory',"/aboutus","/petootiprocess",
 '/ourwork', '/contactus', '/blogs-details','/adoption-form','/foster-form','/adoption-template','/foster-template',
  '/petpost-description',
  '/aboutus', '/my-submitted-requests', '/request-details',
  '/generic-foster-form','/adoption-template','/foster-template'
 ,
  '/faq', '/favorites', '/notifications', '/notifications-details', '/myprofile-adopter', '/search',
  '/community', '/community-topics', '/community-topics-details', '/community-topics-new', '/community-topics-edit', '/petootiprocess']

const publicRoute = ['/', '/signup-role', '/blogs','/blogs-details', '/signup-email', '/signup-otp', '/signup-form', '/login', '/password-recovery', '/password-recovery-otp',
  '/password-recovery-newpassword', '/ourstory', "/aboutus","/petootiprocess",
  '/ourwork', '/contactus', '/search', '/LandingPageWeb', "/generic-foster-form", "/faq"
]
const AuthRoute = ({ Component, props, routeMap }) => {
  const isLoggedIn = Boolean(localStorage.getItem('auth_user_role'));
  const user_type = localStorage.getItem('auth_user_role');
  const isSelectedUser = Boolean(localStorage.getItem('user_type'));
  const currentPath = (props?.location?.pathname ?? '').toLowerCase();
  if (isLoggedIn) {
    if (user_type == "rescuer") {
      const publicPath = window.location.pathname;
      const isPublicRoute = rescuerUserUrl.find(route => route.toLowerCase() === currentPath);
      if (isPublicRoute || publicPath.split('/')[1] == "blogs-details"
        || publicPath.split('/')[1] == "notifications-details"
        || publicPath.split('/')[1] == "petpost-description"
        || publicPath.split('/')[1] == "petpost-description-owner"
        || publicPath.split('/')[1] == "community-topics-details"
        || publicPath.split('/')[1] == "request-details"
        || publicPath.split('/')[1] == "community-topics-edit"
        || publicPath.split('/')[1] == "editpost"

        ) {
          return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;

      }
      else{

        window.location.href="/"
      }
  
    } else if (user_type == "adopter") {
      const publicPath = window.location.pathname;
      const isPublicRoute = adopterUserUrl.find(route => route.toLowerCase() === currentPath);
      if (isPublicRoute || publicPath.split('/')[1] == "blogs-details"
      || publicPath.split('/')[1] == "notifications-details"
      || publicPath.split('/')[1] == "petpost-description"
      || publicPath.split('/')[1] == "community-topics-details"
      || publicPath.split('/')[1] == "request-details"
      || publicPath.split('/')[1] == "community-topics-edit"

      ) {
        return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;

        
    }
    else{

      window.location.href="/"
    }


    }
    else {
      return <Wrapper element={<LandingPageWeb />} {...props} routeMap={routeMap} />;

    }

  } else {
    const publicPath = window.location.pathname;
    const isPublicRoute = publicRoute.find(route => route.toLowerCase() === currentPath);
    const isProtectedRoute = !isPublicRoute;

    if (currentPath == "/emailaccountsignup") {
      if (isSelectedUser) {
        return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
      }

      else {
        return <Wrapper element={<UserRole />} {...props} routeMap={routeMap} />;

      }
    }
    else if (publicPath.split('/')[1] == "petpost-description") {
      return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
    }
    else if (publicPath.split('/')[1] == "blogs-details") {
      return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
    }
    else if (isProtectedRoute) {
      return <Wrapper element={<EmailAccountLoginBlock />} {...props} routeMap={routeMap} />;
    } else {
      return <Wrapper element={<Component />} {...props} routeMap={routeMap} />;
    }
  }
};

const WebRoutesGenerator = ({ routeMap }) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => <AuthRoute Component={Component} props={props} routeMap={routeMap} />}
        />
      );
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
