Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TextDetector";
exports.labelBodyText = "TextDetector Body";

exports.btnExampleTitle = "CLICK ME";

exports.requestApiContentType = "application/json";
exports.getReceivedRequestApiEndpoint = "request_management/requests/received";
exports.getReceivedRequestApiMethod = "GET";

exports.getSendRequestApiEndpoint = "request_management/requests/sent";
exports.getSendRequestApiMethod = "GET";

exports.getAllReceivedRequestApiEndpoint = "request_management/requests";
exports.getAllReceivedRequestApiMethod = "GET";

exports.createSendRequestApiEndpoint = "request_management/requests";
exports.createSendRequestApiMethod = "POST";
exports.userProfileEndPoints='/bx_block_profile_bio/profile_bios';
exports.templateEndPoints=(endp)=>`bx_block_request_management/request_management_templates?request_type=${endp}`
exports.countryListEndPoints=`account_block/dropdowns/countries`
exports.Animal_TypeEndPoint='bx_block_categories/categories';
exports.breedEndPoint=(id)=>`bx_block_categories/sub_categories?category_id=${(id)}`;
exports.adopted_posts_animal_type='bx_block_posts/adopted_posts/animal_type'
exports.modalTrialPeriodTitleAdopter='Adoption trial period is ending, Are you going to continue with the adoption or not?'
exports.modalTrialPeriodTitleRescuer=' Adoption Confirmation'
exports.afterTrialPeriod_EndPoint=(id)=>`bx_block_posts/post_requests/${id}/after_trial_period_action`
exports.adopted_postsEndPoints=`bx_block_posts/adopted_posts`
exports.emptyGroupIdAlert = "Please select group id";
exports.emptyRejectReasonAlert = "Please enter reason";
exports.acceptedMsgText = "Request has been accepted.";
exports.rejectedMsgText = "Request has been rejected.";
exports.deletedMsgText = "Request has been deleted.";
exports.requestSentSuccessMsg = "Request has been sent."
exports.requestUpdateSuccessMsg = "Request has been updated."

exports.updateRequestApiEndpointStart = "request_management/requests/";
exports.updateRequestApiEndpointEnd = "/review";
exports.updateRequestApiMethod= "PUT";

exports.deleteRequestApiEndpoint = "request_management/requests/";
exports.deleteRequestApiMethod= "DELETE";

exports.getGroupsApiEndpoint = "account_groups/groups";
exports.getGroupsApiMethod= "GET";

exports.getSentRequestApiEndpoint = "request_management/requests/sent";
exports.getSentRequestApiMethod= "GET";

exports.updateRequestTextApiEndpoint = "request_management/requests/";
exports.updateRequestTextApiMethod= "PUT";

exports.request_role_type=`bx_block_posts/post_requests`;
exports.request_templates=(id)=>`bx_block_request_management/request_management_templates/${id}`;
exports.request_templates_post=`bx_block_request_management/request_management_templates`;


exports.generic_foster=`/bx_block_request_management/generic_foster`
exports.titleText = "Request Management";
exports.sendRequestBtnLabel = "Send Request";
exports.receiveRequestText = "Received Requests";
exports.nameLabelText = "Name";
exports.requestLabelText = "Request Text";
exports.rejectReasonLabelText = "Reject Reason";
exports.statusLabelText = "Status";
exports.actionLabelText = "Action";
exports.acceptBtnLabel = "Accept";
exports.rejectBtnLabel = "Reject";
exports.deleteBtnLabel = "Delete";
exports.sendRequestsText = "Sent Requests";
exports.updateBtnLabel = "Update";
exports.viewBtnLabel = "View";
exports.requestIdLabel = "Request ID";
exports.sendRequestDialogTitle = "Send Request";
exports.updateRequestDialogTitle = "Update Request";
exports.rejectRequestDialogTitle = "Request reject";
exports.cancelBtnLabel = "Cancel";
exports.sendBtnLabel = "Send";
exports.rejectReasonInputLabel = "Please enter reason";
exports.requestTextInputLabel = "Please enter request text";
exports.selectGroupPlaceholder = "Please select group";
exports.rejectTextFieldIsRequired = "Please enter reject reason";
exports.loginAlertMessage = "Please login first.";
exports.filterInputLabel = "Enter request id to filter"
exports.Sterilized='Sterilized'
exports.SterilizedArr=[
  { id: 1, name: "Yes" },
  { id: 2, name: "No" },
  { id: 3, name: "Not yet" },
];


exports.howLongLived = [
  { id: 1, name: "Less than 1 year" },
  { id: 2, name: "1 to 4 years" },
  { id: 3, name: "5+ years" },
];
exports.doYou = [
  { id: 1, name: "Rent" },
  { id: 2, name: "Own" },
  { id: 3, name: "Live with Parents" },
  { id: 4, name: "Live in a shared accommodation" },
];
exports.pleaseDescribeAccommodation = [
  { id: 1, name: "Villa" },
  { id: 2, name: "Apartment" },
  { id: 3, name: "Room Rental" },
  { id: 4, name: "Bedspace" },
];
exports.resonForAdopt = [
  { id: 1, name: "House pet" },
  { id: 2, name: "Mouse control" },
  { id: 3, name: "Companion" },
  { id: 4, name: "Companion for pet" },
  { id: 5, name: "For the kids" },
  { id: 6, name: "Gift" },
  { id: 7, name: "Other" },
];
exports.howToContactConfigArr = [
  { id: 1, name: "Phone call" },
  { id: 2, name: "Email" },
  { id: 3, name: "WhatsApp" },
  ];
exports.expPetOwner = [
  { id: 1, name: "First time owner" ,name2:'First time owner'},
  { id: 2, name: "Previously owned" ,name2:'Previously owned'},
  { id: 3, name: "Currently own",name2:'Currently own' },
  { id: 4, name: "Other", name2:"Other" },
];
exports.allowOutSide = [
  { id: 1, name: "Indoors only" },
  { id: 2, name: "Indoor-outdoor" },
  { id: 3, name: "Indoors with supervised time outside" },
  { id: 4, name: "Indoors with catio access" },
];

exports.relocationCost = [
  { id: 1, name: "Yes, I did my research" },
  { id: 2, name: "No, I need advice on cost" },
];
exports.householdActivityLevel = [
  { id: 1, name: "Calm and quiet" },
  { id: 2, name: "Somewhere in between" },
  { id: 3, name: "Very busy" },
  { id: 4, name: "Other" },

];
exports.selectCountry='Select country'
exports.selectAnimalType='Select animal type'
exports.breedSelect='Select breed'


exports.pleaseExplain='Please explain'
exports.any_health_condition='Any health condition'
exports.home='Home'
exports.postDetails='Post Details'
exports.adoptionRequestForm='Adoption Request Form'
exports. adoption_Request_Form='Adoption Request Form'
exports.firstName='First Name'
exports.lastName='Last Name'
exports.email='Email'
exports.phoneNumber='Phone Number'
exports.whatsAppNumber='WhatsApp Number'
exports.age='Age'
exports.addressLine1='Address line 1'
exports.addressLine2='Address line 2'
exports.city='City/Area'
exports.postalCode='Postal Code'
exports.country='Country'
exports.county='State/Emirate'
exports.how_Long_Have_You_Lived_At_Your_Address='How long have you lived at your address?'
exports.do_You='Do you?'
exports.please_Describe_Your_Accommodation_SetUp='Please describe your accommodation set up'
exports.does_Your_Home_Have_Access_to_A_Private_Garden='Does your home have access to a private garden?'
exports.yes='Yes'
exports.yes_Text='Yes'
exports.no_Text='No'
exports.if_not_do_you_agree="If not, do you agree to NOT let the fostered animal out onto the balcony/garden and you agree to take extra care when opening and closing the door? This includes informing the house help to take extra precaution?"
exports.what_Is_Your_Household_Activity_Level='What is your household activity level?'
exports.list_All_People_Who_Will_be_Living_With_The_New_Pet='List all people, who will be living with the new pet'
exports.i_am_Living_Alone='I am living alone'
exports.i_Live_With_Others='I live with others'
exports.person='Person'
exports.pet='Pet'
exports.Animal_Type='Animal Type'
exports.breed='Breed'
exports.name='Name'
exports.relation='Relation'
exports.Age_In_Years='Age (in years)'
exports.add_Another='Add another'
exports.howToContactHeading='How would you like to be contacted?'
exports.what_Is_Your_Rreason_For_Wanting_To_Adopt_New_pet='What is your reason for wanting to adopt new pet?'
exports.what_Is_Your_Experience_As_A_Pet_Owner='What is your experience as a pet owner?'
exports.How_Many_Total_Hours_Will_Your_Pet_Be_Left_Alone_During_The_Day='How many total hours will your pet be left alone during the day?'
exports.hours_Per_Day='hours/day'
exports.will_Your_Pet_Be_Allowed_Outside='Will your pet be allowed outside?'
exports.have_You_Considered_The_Relocation_Cost_For_Your_New_Pet='Have you considered the relocation cost for your new pet?'
exports.submit_Request='Submit Request'
exports.submit_Changes='Submit Changes'
exports.cancel='Cancel'
exports.How_long_can_you_foster_for = [
  { id: 1, name: "0 to 3 months" },
  { id: 2, name: "3 to 6 months" },
  { id: 3, name: "Until a forever home is found" },

];
exports.apartment_Type_heading='Apartment type'
 exports.apartment_Type= [
  { id: 1, name: "1 Bedroom" },
  { id: 2, name: "2 Bedrooms" },
  { id: 3, name: "3 and more bedrooms" },
  { id: 4, name: "Studio" },

  

];
exports.age_handle= [
  { id: 1, name: "0 to 1 year" },
  { id: 2, name: "1 to 3 years" },
  { id: 3, name: "5 to 7 years" },
  { id: 4, name: "7 years +" },
  

];
exports.gender_handle= [
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "No Preference" },
  

];
exports.list_All_Pets_Currently_Living_With_You='Please list all the pets currently living with you'
exports.Request_Details='Request Details'
exports.Requester_Information='Requester Information'
exports.getRequestDetailsEndPoints=(postId)=>`bx_block_posts/post_requests/${postId}`
exports.list_All_Pets_Currently_Living_With_You = 'Please list all the pets currently living with you'
exports.my_Submitted_Requests = 'My Submitted Requests'
exports.my_Received_Requests = 'My Received Requests'
exports.mark_as_EndPoint=(id)=>`/bx_block_posts/post_requests/${id}/mark_as_approved`
exports.mark_as_Reject_EndPoint=(id,reason)=>`/bx_block_posts/post_requests/${id}/mark_as_rejected?rejected_reason=${reason}`

exports.pageHeading = 'My Submitted Requests'
exports.my_Adoption_Template = 'My Adoption Template'
exports.my_Foster_Template = 'My Foster Template';

 exports.sortMenuItemList = [
  {
    id: 0,
    name: "Submission date - newest",
    value:"newest"
  },
  {
    id: 1,
    name: "Submission date - oldest",
    value:"oldest"
  },
]
exports.my_Submitted_Requests_end_points='bx_block_posts/post_requests'
exports.post_type_dropDownList_end_points=(endPoint)=>`bx_block_posts/post_requests/post_list_for_dropdown${endPoint}`
exports.confirmApproveAdotionRequest_Title='Approve Adoption Request?'
exports.confirmApproveAdotionRequest_Detail=`The status of the request will be changed to 'Reserved', and the pet post will no longer be visible to public users until the end of the adoption trial period.`

exports.confirmApproveFosterRequest_Title='Approve Foster Form?'
exports.confirmApproveFosterRequest_Detail='The status of the request will be changed to "Fostered" and the post will remain available for adoption.'




// Customizable Area End