import React from "react";

// Customizable Area Start
import HeaderController, { Props } from "./HeaderController.web";
import { Box, Grid, Typography, styled } from "@material-ui/core";
import { logonav, insta, facebook,  slack, google, email } from "../assets";
import { PrivacyPolicy } from "../../../../components/src/WhoAreWe";
import {  NavLink } from "react-router-dom";
// Customizable Area End

class Footer extends HeaderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
     handleClick = (newTab:any,link:string) => {
        if(newTab){
              window.open(link, '_blank'); 
        }
        else{
            window.location.href=link
        }
      };
    handleIconStyle = (item: any) => {
        const title = item?.attributes?.title;
        const link = item?.attributes?.link;
        const newTab=item?.attributes?.want_to_open_in_new_tab
          switch (title.toLowerCase()) {
            case 'instagram':
              return <IconStyle title="Instagram" id="instagram"src={insta} style={{ cursor: "pointer" }} onClick={()=>this.handleClick(newTab,link)}/>;
            case 'facebook':
              return <IconStyle title="Facebook" id="facebook" src={facebook} style={{ cursor: "pointer" }}  onClick={()=>this.handleClick(newTab,link)}/>;
            case 'email':
              return <IconStyle title="Email" id="email"src={email} style={{ cursor: "pointer" }}  onClick={()=>this.handleClick(newTab,`mailto:`+link)} />;
          }
       
      };
      
    // Customizable Area End
    render() {
        // Customizable Area Start
        const chegrid=this.state.footerData.length>0?4:6
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box >
                <MainGrid container style={AllStyle.navbar}>
                    <FirstGrid item xs={12} sm={6} md={6} lg={3}>
                        <FooterImg src={logonav} />
                    </FirstGrid>
                    <SecondGrid 
                    justifyContentItem={this.state.footerData.length>0?true:false}
                    item xs={12} sm={6} md={6} lg={6} style={{ display: "flex",alignItems: "center" }}>
                        <FooterItem >

                            <Typography style={{ ...AllStyle.headingstyl, }}    data-test-id="termsConditonTestId"  onClick={
                                this.handleTermsAndConditon
                            }>Terms and Conditions</Typography>
                            <Typography style={{ ...AllStyle.headingstyl }}  data-test-id="privacyPolicyTestId" onClick={
                                this.handlePrivacyPolicy
                            }>Privacy Policy</Typography>
                             <NavLink to="/faq"
                             onClick={()=>{
                        this.handleUserProfile()

                             }}
                              style={{ ...AllStyle.headingstyl }}
                               data-test-id="faqTestId" >FAQ</NavLink >
                        </FooterItem>
                    </SecondGrid>
                    <ThirdGrid item xs={12} sm={12} md={12} lg={3} style={{ display: "flex" }}>
{
    this.state.footerData.map((item:any,index:number)=>{
    return this.handleIconStyle(item);
    })
}
                    </ThirdGrid>
                </MainGrid>
                {
                    this.state.termsAndConditon&&<PrivacyPolicy firstHeading="TERMS & CONDITIONS!" secondHeading=""privacyAndTerms={this.state.termsAndConditon} handleClose={this.handleTermsAndConditonClose} handleCloseButton={this.handleTermsAndConditonClose}/>
                }
                  {
                    this.state.privacyAndPolicy&&<PrivacyPolicy firstHeading="PRIVACY POLICY!" secondHeading=""privacyAndTerms={this.state.privacyAndPolicy} handleClose={this.handleprivacyAndPolicyClose} handleCloseButton={this.handleprivacyAndPolicyClose}/>
                }
            </Box>
            // Customizable Area End
        )

    }
}
export default Footer


// Customizable Area Start

const IconStyle = styled('img')({
    width: "60px",
    height: "60px",
    '@media (max-width:1208px )': {
        width: "53px",
        height: "53px",
    },

})
const GridStyle=styled(Grid)({
    justifyContent:"flex-end",
    '@media (max-width:960px )': {
        justifyContent:"flex-start"
    },
})
const IconStyle2 = styled(Box)({
    minWidth: "60px",
    height: "60px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    borderRadius:"50%",
    backgroundColor:"#058c89",
    '@media (max-width:1208px )': {
        minWidth: "53px",
        height: "53px",
    },

})


const MainGrid = styled(Grid)({
    padding: "51px 135px 51px 135px",
    background: "#03B5AA",

    alignItems:"center",
    '@media (max-width:1398px) and (min-width:959px)': {
        padding: "33px 40px 33px 40px"
    },
    '@media (max-width:959px)': {
        padding: "33px 20px 33px 20px"
    },
   

})
const MainGrid2= styled(Grid)({
    maxHeight:"72px",
    alignItems:"center",
    justifyContent:"center",
    padding: "26.22px 135px 26.78px 135px",
    background: "#03B5AA",

    '@media (max-width:1398px) and (min-width:960px)': {
        padding: "26.22px 40px  26.78px 40px;"
    },
    '@media (max-width:960px) and (min-width:800px)': {
        gap:"12px",
        maxHeight:"unset",
        padding: "26.22px 40px  26.78px 40px"
    },
    '@media (max-width:800px )': {
        maxHeight:"unset",
        gap:"12px",
        padding: "26.22px 20px  26.78px 20px"
    },
  

})
const BoxStyle = styled(Box)({
    '@media (max-width:500px )': {
        flexWrap:"wrap",
        gap:"12px"
    },

})
const FooterImg =styled('img')({
    height:'44px',
    '@media (max-width: 1279px)':{
    height:'35px'
    }
})
const FirstGrid = styled(Grid)({
    justifyContent: "flex-start",
    display: "flex",

    '@media (max-width:600px )': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "20px"
    },

})
const SecondGrid = styled(Grid)(({ justifyContentItem }: any) => ({
    justifyContent: justifyContentItem? 'center' : 'end',
    '@media (max-width: 1279px) and (min-width: 600px)': {
      justifyContent: 'end',
    },
    '@media (max-width: 600px) and (min-width: 350px)': {
      justifyContent: 'center',
    },
  }));
  const FooterItem = styled(Box)({
    textAlign: "center",display:"flex",flexDirection:'row',gap:'48px',
    '@media (max-width:1400px)': {
        gap:"20px"

    },
    '@media (max-width:1280px)': {
        flexDirection:'column',
        gap:"8px"
    }
  })

const ThirdGrid = styled(Grid)({
    justifyContent: "end",
    gap: "15px",

    '@media (max-width:1280px) and  (min-width:600px) ': {
        paddingRight: "0px",
        gap: "20px",
        marginTop: "30px",
        justifyContent: "center"
    },
    '@media  (max-width:600px) and (min-width:350px)': {
        paddingRight: "0px",
        gap: "10px",
        marginTop: "30px",
        justifyContent: "center"
    },
    '@media  (max-width:350px)': {
        paddingRight: "0px",
        gap: "2px",
        marginTop: "30px",
        justifyContent: "center"
    }

})
const AllStyle = {
    navbar: {
        background: "#03B5AA",

    },
    rightIcons: {
        padding: "15px",
    },
    headingstyl: {
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: 500,
        fontFamily: "Inter",
        lineHeight: "24px",
        textDecoration:"none",
        color: "#FFFFFF",
    },
    logoStyle:{
        color:"#FFF",
fontFamily: "Lato",
fontSize: "18px",
fontWeight: 700,
lineHeight: "26px",
    },
    sponser:{
        color:"#FFF",
fontFamily: "Lato",
fontSize: "16px",
fontWeight: 400,
    }

}

// Customizable Area End