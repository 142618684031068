export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const Dog = require("../assets/dog.jpeg");
export const LeftArrow = require("../assets/leftArrow.svg");
export const leftArrowres = require("../assets/leftArrowres.svg");
export const DownArrow = require("../assets/downArrow.svg");
export const LightArrow = require("../assets/lightArrowLeft.svg");