import React from "react";
import {
  Button,
  // Customizable Area Start
  Typography,
  Box,
  TextField,
  styled,
  TextareaAutosize,
  MenuItem,
  Select,
  FormControl,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";

import { leftArrowIcon2} from "./assets";
import { HomeGrid2,Allstyle } from "../../favourites/src/FavouritesWeb.web";
import CommunityForumController,{Props} from "./CommunityForumController";
import { KeyboardArrowDown } from "@material-ui/icons";
import { ButtonComponent } from "../../productdescription/src/ProductDescription.web";
import { TypographyStyle2 ,TypographyStyle} from "../../comments/src/CreateComment.web";

// Customizable Area End

export default class CreateNewTopic extends CommunityForumController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start

    super.componentDidMount();
   

    // Customizable Area End
  }

 starIcon = () => {
    return <span style={{
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        color: "#FF5E5B"
    }}>
        {" "}    *
    </span>
}
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box>
              
        <Loader loading={this.state.isLoading } />

                <HomeGrid2 style={{ display:"block" }}>

      <Box style={{ ...Allstyle.headingBoxStyle,alignItems:"center" }}>
        <TypographyStyle2
        style={{ ...Allstyle.hedingStyle,
            cursor: "pointer" ,
            
             }}
         onClick={() => {
          this.props.navigation.navigate("LandingPageWeb")
        }}
        data-test-id="navigate"
        >
          Home
        </TypographyStyle2>
      
<img src={leftArrowIcon2}/>
        <TypographyStyle
          data-test-id="PetootiCommunity"
          onClick={() => {
            this.props.navigation.navigate("PetootiCommunity")
          }}
          style={{
            ...Allstyle.hedingStyle,
           
            cursor:"pointer"
          }}
          
        >
        Community Chat Room
        </TypographyStyle>
        <img src={leftArrowIcon2}/>
        <TypographyStyle
           onClick={() => {
            this.props.navigation.navigate("PetootiCommunityFormTopic")
          }}
          style={{
            ...Allstyle.hedingStyle,
            cursor:"pointer",
          }}
       
        >
        Topics
        </TypographyStyle>
      { this.state.pathName=="community-topics-edit" ? <>
        <img src={leftArrowIcon2}/>
        <TypographyStyle
          style={{
            ...Allstyle.hedingStyle,
            cursor:"pointer",
            
          }}
          onClick={() => {
            this.props.navigation.navigate("CreateComment",{id:this.state.topicId})
          }}
        >
      Topic Details
        </TypographyStyle>
        <img src={leftArrowIcon2}/>
        <TypographyStyle2
          style={{
            ...Allstyle.hedingStyle,
            color:"#0F172A",
            
          }}
        >
       Edit Topic
        </TypographyStyle2>
      </>
      :
      <>
        <img src={leftArrowIcon2}/>
        <TypographyStyle2
          style={{
            ...Allstyle.hedingStyle,
            color:"#0F172A",
          }}
        >
         Create New Topic
        </TypographyStyle2>
      </>
     }
      </Box>
      <MainBox >

      <Box
        style={{
          ...Allstyle.headingBoxStyle,
          justifyContent: "space-between",
          marginTop: "48px",
          marginBottom:"48px"
        }}
      >
      {  this.state.pathName=="community-topics-edit" ?
        <Typography style={{ ...Allstyle.mainHedingStyle  ,}}>
       Edit Topic
        </Typography>
      :
      <Typography style={{ ...Allstyle.mainHedingStyle  ,}}>
        Create New Topic
        </Typography>}
     
                </Box>
                <SubMainBox>
                    <HeadingBox >
                <Typography style={webStyle.subheadingStyle}>
                Title{this.starIcon()}
                        </Typography>
                    </HeadingBox>
                    <SubMainBox2 >


                        <TextFieldName
                        data-test-id="title"
                        fullWidth
                        value={this.state.titleTopic}
                          placeholder="Enter title"
                          variant="outlined"
                          error={this.state.titleTopicError}
                          helperText={this.handleErroMessage(this.state.titleTopicError)}
                          onChange={(e:any)=>{
                            this.handleTitleValidation(e)
                          }}
                        />
                    </SubMainBox2>

                </SubMainBox>
                <SubMainBox style={{alignItems:"unset"}}>
                    <HeadingBox >
                <Typography style={webStyle.subheadingStyle}>
                Content{this.starIcon()}
                        </Typography>
                    </HeadingBox>
                    <SubMainBox2 >


                        <AutoCompletStyle
                         minRows={8}
                         style={{
                             overflow:"auto",
                             border:this.handleBorderContent(),
                         }}
                        data-test-id="conversation"

                         id="conversation"
                         value={this.state.contentTopic}
                         placeholder="Write something..."
                         onChange={(e: any) => {
                            this.handleContentValidation(e)
                         }
                         }
                        />
                          {this.state.contentTopicError && <Typography style={{
                            color: "#DC2626",
                            fontSize: "12px",
                            fontFamily: "Lato",
                            fontWeight: 400,
                            lineHeight: "18px"
                        }}>
                            Please enter a value
                        </Typography>}
                    </SubMainBox2>

                </SubMainBox>
                <SubMainBox>
                    <HeadingBox >
                <Typography style={webStyle.subheadingStyle}>
                Category {this.starIcon()}
                        </Typography>
                    </HeadingBox>
                    <SubMainBox2 >


                    <SelectField   style={{marginBottom:'unset'}}  >
                        <Select
                            error={this.state.categoryIdTopicError}
                            variant="outlined"
                            value={this.state.categoryIdTopic}
                            data-test-id="Category"
                            onChange={(e: any) => {
                                this.setState({ categoryIdTopic: e.target.value, categoryIdTopicError: false, })
                            }}
                            MenuProps={{
                                getContentAnchorEl: null,
                                MenuListProps: {
                                    style: {
                                        maxHeight: 250,
                                        padding: "10px",
                                        display:"flex",
                                flexDirection:"column",
                                gap:"8px"
                                    },
                                },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                            style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                fontFamily: "Lato",
                                
                                color: this.handleStylePlaceholder(this.state.categoryIdTopic)
                            }}
                            fullWidth
                            IconComponent={KeyboardArrowDown}
                        >
                            <MenuItem
                                value="Select category"
                                style={{
                                    display: "none",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    fontFamily: "Lato",
                                    justifyContent:"flex-start",
                                    color: "#94A3B8",
                                }}

                            >
                                Select category
                            </MenuItem>
                            {this.state.getCatergoryData.slice(1).map((item: any) => {
                                return <MenuItem
                                    value={item?.name}
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        display: "flex",
                                        fontFamily: "Lato",
                                    justifyContent:"flex-start",
                                    }}
                                >
                                    {item.name}
                                </MenuItem>
                            })}
                        </Select>
                      <Typography style={webStyle.errorNameStyle}>
                         {this.handleErroMessageCategory()}
                        </Typography> 
                    </SelectField>
                    </SubMainBox2>

                </SubMainBox>
                <SubMainBox style={{alignItems:"unset",marginBottom:"40px"}}>
                    <HeadingBox >
                <Typography style={{...webStyle.subheadingStyle}}>
                Animal type {this.starIcon()}
                        </Typography>
                    </HeadingBox>
                    <SubMainBox2 >


                    <SelectField   style={{marginBottom:'unset'}}>
                        <Select
                            error={this.state.animalIdTopicError}
                            variant="outlined"
                          
                            value={this.state.animalIdTopic}
                            data-test-id="animal"
                            onChange={(e: any) => {
                                this.setState({ animalTopicIdOther:"",animalTopicIdOtherError:false,animalIdTopic: e.target.value, animalIdTopicError: false })
                            }}
                            MenuProps={{
                                getContentAnchorEl: null,
                                MenuListProps: {
                                    style: {
                                        maxHeight: 250,
                                        padding: "10px",
                                        display:"flex",
                                flexDirection:"column",
                                gap:"8px"
                                    },
                                },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                            style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                fontFamily: "Lato",
                                color: this.handleStylePlaceholder(this.state.animalIdTopic)
                            }}
                            fullWidth
                            IconComponent={KeyboardArrowDown}
                        >
                            <MenuItem
                                value="Select animal type"
                                style={{
                                    display: "none",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    fontFamily: "Lato",
                                    color: "#94A3B8",
                                    justifyContent:"flex-start",
                                }}

                            >
                                Select animal type
                            </MenuItem>
                            {this.state.getAnimalData.slice(1).map((item: any) => {
                                return <MenuItem
                                    value={item.name}
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        fontFamily: "Lato",
                                        justifyContent:"flex-start",
                                        display:"flex"

                                    }}

                                >
                                    {item.name}
                                </MenuItem>
                            })}
                        </Select>
                        <Typography style={webStyle.errorNameStyle}>
                            
                        {this.handleErroMessageAnimal()}
                        </Typography>
                    </SelectField>
                   {this.state.animalIdTopic=="Other" &&<>

                    <Typography style={{...webStyle.subheadingStyle,marginTop:"32px",marginBottom:"4px"}}>
                        Please specify {this.starIcon()}
                    </Typography>
                    <TextFieldName
                        fullWidth
                        data-test-id="otherAnimal"
                        value={this.state.animalTopicIdOther}
                        placeholder="Write something"
                          variant="outlined"
                          error={this.state.animalTopicIdOtherError}
                          helperText={this.handleErroMessage(this.state.animalTopicIdOtherError)}
                          onChange={(e:any)=>{
                            this.handleOtherValidation(e)
                          }}
                        />
                   </>
                   }
                    </SubMainBox2>

                </SubMainBox>
      </MainBox>
      <hr style={{ width: '100%', border:'none',height:'1px',backgroundColor:'#e2e8f0' }} />
      <ButtonMainBox>
                    <Box style={{width:"199px"}}>
                <Typography style={webStyle.subheadingStyle}>
            
                        </Typography>
                    </Box>
                    <ButtonBox >
                    <Button style={{...webStyle.BtnGreen,}}
                    data-test-id="submit"
                    onClick={this.handleValidationCreateNewTopic}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M8.79506 15.8734L5.32506 12.4034C5.13823 12.2162 4.88458 12.1109 4.62006 12.1109C4.35554 12.1109 4.10189 12.2162 3.91506 12.4034C3.52506 12.7934 3.52506 13.4234 3.91506 13.8134L8.09506 17.9934C8.48506 18.3834 9.11506 18.3834 9.50506 17.9934L20.0851 7.41342C20.4751 7.02342 20.4751 6.39342 20.0851 6.00342C19.8982 5.81617 19.6446 5.71094 19.3801 5.71094C19.1155 5.71094 18.8619 5.81617 18.6751 6.00342L8.79506 15.8734Z" fill="white"/>
</svg>  
            <span style={{marginLeft:"8px"}}>
                {this.state.pathName=="community-topics-edit" ?"Save Changes":"Create topic"}
                </span>  
            </Button>
<ButtonComponent style={{width:"88px",height:"44px"}} 
                    data-test-id="cancel"

 onClick={() => {
    if(this.state.pathName=="community-topics-edit"){
      

        this.props.navigation.navigate("CreateComment",{id:this.state.topicId})
    }
    else{
      

        this.props.navigation.navigate("PetootiCommunityFormTopic")
    }
          }}>{"Cancel"}</ButtonComponent>

                    </ButtonBox>

                </ButtonMainBox>
             
       </HomeGrid2>
              
</Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SelectField = styled(FormControl)({
  width:"275px",
    marginBottom: "16px",
    "& .MuiOutlinedInput-root": {
     
      "&.Mui-focused fieldset": {
        borderWidth: "1px",
        borderColor: "#CBD5E1",
      },
      "&:hover fieldset": {
        borderColor: "#CBD5E1",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderRadius: "8px",
      borderColor: "#CBD5E1",
    },
    "& .MuiInputBase-input": {
      lineHeight: "19.2px",
  
    }
    ,
    "& .MuiOutlinedInput-input": {
      padding: "12px 8px",
    },
     "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F87171"
    },
    "& .MuiFormHelperText-contained": {
      marginRight: "0px",
      marginLeft: "0px",
    },
    
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      fontWeight: 400,
      fontSize: "12px",
      fontFamily: "Lato",
      lineHeight: "18px"
    },
    '@media (max-width:810px )': {
      width:"450px"
     },
    '@media (max-width:530px )': {
        width:"400px"
       },
       '@media (max-width:450px )': {
        width: "350px",
       },
       '@media (max-width:400px )': {
        width: "300px",
       },
  });

  const HeadingBox=styled(Box)({
    width:"199px",
    height:"43px",
    alignItems:"center",
    display:"flex",
    '@media (max-width:752px )': {
    height:"unset",
     
  },
  })
const TextFieldName = styled(TextField)({
    minWidth: "573",
    '& input::placeholder': {
      color: '#94A3B8',
      opacity:1
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: "#DC2626",
        fontSize: "12px",
        fontFamily: "Lato",
        fontWeight: 400,
        lineHeight: "18px"
    }
    , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171"
    },


    "& .MuiInputBase-root": {
        color: "#0F172A !important",
        fontWeight:"400 !important",
        fontFamily:"Inter !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#CBD5E1",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#CBD5E1",
        },
    },

    "& .MuiOutlinedInput-input": {
        padding: "12px 8px",
    },

    "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        marginRight: "0px"
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        border: "1px solid #CBD5E1",
    },
    '& ::placeholder':{
      color:"#94A3B8"
    },
  
    '@media (max-width:810px )': {
        minWidth: "450px",
        maxWidth: "450px",
    },
    '@media (max-width:530px )': {
        minWidth: "400px",
        maxWidth: "400px",
       },
       '@media (max-width:450px )': {
        maxWidth: "350px",
        minWidth: "350px",
       },
       '@media (max-width:400px )': {
        maxWidth: "300px",
        minWidth: "300px",
       },
});
const AutoCompletStyle = styled(TextareaAutosize)({
    minWidth: "573px",
    minHeigh: "110px",
    maxWidth:"573px",
    padding: "8px",
    maxHeight: "142px",
    color: "var(--Neutrals-Cool-gray-900, #0F172A)",
    fontFamily: "Lato",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%",
    "&:focus-visible": {
       outline:"unset",
    },

    "&::placeholder": {
        fontWeight:400,
        fontSize:"16px",
        opacity:1
,
        fontFamily:"Inter",
       color:"#94A3B8",
    },
    '@media (max-width:810px )': {
        minWidth: "450px",
    },
    '@media (max-width:530px )': {
        minWidth: "400px",
       },
       '@media (max-width:450px )': {
        minWidth: "350px",
       },
       '@media (max-width:400px )': {
       
        minWidth: "300px",
       },
});
export const MainBox=styled(Box)({
maxWidth:"772px",
flexDirection:"column",
display:"flex",
flexWrap:"wrap",
marginInline:"auto",
'@media (max-width:800px )': {
    maxWidth: "unset",
    flexWrap:"wrap",
    marginInline:"30px"

},
'@media (max-width:500px )': {
    maxWidth: "unset",
    flexWrap:"wrap",
    marginInline:"0px"

},
})
const SubMainBox=styled(Box)({
    minWidth:"772px",
    justifyContent:"space-between",
    display:"flex",
    flexDirection:"row"
    ,maxWidth:"772px",
    flexWrap:"wrap",
    marginBottom:"32px",
    '@media (max-width:800px )': {
        maxWidth: "unset",
        flexWrap:"wrap",
        minWidth:"unset",
        gap:"4px",  
    },

})
const ButtonMainBox=styled(Box)({
    minWidth:"772px",
    justifyContent:"space-between",
    display:"flex",
    marginInline:"auto",
    marginTop:"40px",
    marginBottom:"135px",
    flexDirection:"row"
    ,maxWidth:"772px",
    flexWrap:"wrap",
    alignItems:"center",
    '@media (max-width:800px )': {
        maxWidth: "unset",
        flexWrap:"wrap",
        justifyContent:"center",
        minWidth:"unset",
        gap:"4px",  
    },

})
const SubMainBox2=styled(Box)({
    minWidth:"573px",
    '@media (max-width:800px )': {
        minWidth:"300px",
    },
})
const ButtonBox=styled(Box)({
    minWidth:"573px",
    '@media (max-width:800px )': {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        minWidth:"100%",
        textAlign:"center"
    },
})
export const webStyle = {
    errorNameStyle:{
        color: "#DC2626",
        fontSize: "12px",
        fontFamily: "Lato",
        fontWeight: 400,
        lineHeight: "18px",
    },
    BtnGreen: {
        background: '#03B5AA',
        border: '1px solid #03B5AA',
        borderRadius: 8,
        lineHeight: '24px',
        marginLeft:"0px", 
        marginRight:"16px", 
        fontFamily: "Lato",
        textTransform: 'inherit',
        fontWeight: 700,
        fontSize: 16,
        color: '#fff',
        height:"44px",
        width: 156,
    
      } as React.CSSProperties,
    subheadingStyle: {
        color: "#334155",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
};
// Customizable Area End
