Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.commentsContentType = "application/json";
exports.commentsApiMethodType = "GET";
exports.commentPOSTAPiMethod = "POST";
exports.commentDeleteAPiMethod = "DELETE";
exports.commentPutAPiMethod =  "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Comments";
exports.labelBodyText = "Comments Body";
exports.hintCountryCode = "Select Country";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.commentEndPoint = "/comments/comments"
exports.likeCommentEndPoint = "/comments/like_comment"
exports.propfileEndPoints ='bx_block_profile_bio/profile_bios';
exports.getCommentsApi= "bx_block_comments/comments";
exports.reportCommentApi = 'bx_block_communityforum/reports'
exports.getReportReasonsApi = 'bx_block_communityforum/reports/report_reasons'
// Customizable Area End
