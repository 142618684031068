import React from "react";

// Customizable Area Start
import {  Box, Typography } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { slider1 } from "./assets";
import Carousel from "react-material-ui-carousel";
const baseUrl = require("../../../framework/src/config.js")
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { VideoPlayIcon } from "../../productdescription/src/assets";
import {  DiologCompo } from "../../productdescription/src/CreateRescuerPostpage.web";
import { closeIconSvg } from "../../email-account-login/src/assets";
// Customizable Area End

import CarouselDisplayController, {
  Props,
  configJSON,
} from "./CarouselDisplayController";

export default class CarouselDisplay extends CarouselDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleCarouselChange = this.handleCarouselChange.bind(this); 
    // Customizable Area End
  }

  // Customizable Area Start
  handleCarouselChange(index: number) {
    this.setState({ index });
  }
  renderThumbnails() {
    const { images} = this.props;
    const data=images?.images? [...images?.images,...images?.videos]:[]
    return (
      <div style={{...webStyle.indicatorIconWrap,overflowY:"auto",paddingRight:"10px"}}>
        {data.length>0&& data.map((item: any, key: any) => {
            const imageUrl = item?.url ? `${baseUrl.baseURL}${item.url}` : item
            let check=item?.url?.split('.') ||[];
            let extension=check.length >0 && check[check?.length-1]
            let type=extension.split('?')[1]
            const thumbnail_url=`${baseUrl.baseURL}${item.thumbnail_url}`

          return (
            <div
              key={key}
              id="smallPieceOfPhot"
              onClick={() => this.setState({ index: key })}
              style={{...webStyle.indicatorIcon,width:this.state.index==key?"60px":"64px",minHeight:this.state.index==key?"60px":"64px",border:this.state.index==key?"2px solid #03B5AA":"unset"}}
            >
              {
                (type=="type=video")?
<>
<img src={thumbnail_url} 
  style={{width:"64px",objectFit:"cover",borderRadius: "8px" }}

  />

               <img
               data-test-id="handleurl"
               id="removevideo1"
               onClick={()=>{
                this.handleUrl(baseUrl?.baseURL,item?.url)

               }}
               src={VideoPlayIcon}
               style={{ position: "absolute",width:"24px",height:"24px",objectFit:"cover",  top: "50%",transform: "translate(-50%, -50%)", left: "50%", cursor: "pointer" }}
           />
</>
                            :
  <img src={imageUrl} 
  style={{width:"64px",objectFit:"cover", cursor: "pointer" }}

  />
  
              }
            </div>
           
          );
        })}
      </div>
    );
  }

  // Customizable Area End

  render() {
    console.log(slider1);
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>

          <MainDiv22>
            <Carousel
              animation="slide"
              swipe={true}
              index={this.state.index}
              onChange={this.handleCarouselChange} 
              indicators={false}
              autoPlay={false}
              navButtonsAlwaysVisible={true}

              NextIcon={<ArrowForwardIosOutlinedIcon style={webStyle.nextbtn2}/>}
              PrevIcon={<ArrowBackIosOutlinedIcon  style={webStyle.nextbtn} />}
              navButtonsProps={{
                style: {
                  backgroundColor: 'transparent',
                  borderRadius: 0,
                  color:"#0F172A",
                }
              }}
            >
              {this.props.images?.images && [...this.props.images?.images,...this.props.images?.videos].map((item: any, index: any) => {
                  const imageUrl = item?.url ? `${baseUrl.baseURL}${item.url}` :item
                  const thumbnail_url=`${baseUrl.baseURL}${item.thumbnail_url}`

            let check=item?.url?.split('.') ||[];
            let extension=check.length >0 && check[check?.length-1]
            let type=extension.split('?')[1]

            
                return (
                  <MainDiv key={index} >
                    {
                       (type=="type=video")?
                       <>
                       <Images src={thumbnail_url}/>
                                      <img
                                      data-test-id="removevideo1"
                                      onClick={()=>{
                                        this.setState({url:`${baseUrl?.baseURL}${item?.url}`,openVideo:true})
                                      }} 
                                      src={VideoPlayIcon}
                                      style={{ position: "absolute",transform: "translate(-50%, -50%)",left:"50%",width:"72px",height:"72px", top: "50%", cursor: "pointer" }}
                                  />
                       </>:
                    <Images src={imageUrl} />
                    }
                  </MainDiv>
                );
              })}
            </Carousel>
            
            {this.renderThumbnails()}
          </MainDiv22>
          <DiologCompo id="closeid" onClose={this.closeVideoCaraouselDisplay} style={{overflowY:"auto"}}aria-labelledby="customized-dialog-title" open={this.state.openVideo}>
<Box style={{width:"100%",height:"100%",position:"relative"}}>
<Typography style={{...webStyle.closeIconStyle,position:"absolute",top:24,zIndex:111}} onClick={this.closeVideoCaraouselDisplay}>
    <img src={closeIconSvg}/>
    Close
</Typography>
      <video
       controlsList="nodownload"
      controls 
        style={{ width: "100%", height: "100%", objectFit: "contain" ,background:"#494545"}}
      >
        <source
        src={this.state.url
        }
        />
      </video>
</Box>
</DiologCompo>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const MainDiv=styled("div")({
  flex: 1,
  overflow: "hidden" as "hidden",
  borderRadius: 8,
  position:"relative" as "relative",
maxHeight: "460px",
minHeight:"460px",
height: "100%",
'@media (max-width:1200px) ': {
  minHeight:"unset"

},
})
const Video=styled("video")({
  display:"block",
    maxWidth:"548px",
    borderRadius:"8px",
    width:"100%",

    height: "460px",
    objectFit: "cover" as "cover",
'@media (max-width:1200px) ': {
  height: "unset",
  width:"100%"


},
})
const DivCompo=styled('div')({
  display:"none",
  '@media (max-width:500px )': {
   display:"flex",

},
})
const Images=styled("img")({
  display:"block",

    width:"100%",
    borderRadius:"8px",
    height: "460px",
    objectFit: "cover" as "cover",
    objectPosition: "center",
'@media (max-width:1200px) ': {
  height: "unset",
  width:"100%",


},
})
const MainDiv22=styled('div')({
  position:"relative",
  paddingLeft: 84,
  maxWidth:"548px",
  minWidth:'548px',
  '@media (max-width:1200px) ': {
    position:"relative",
    paddingLeft: 84,
    maxWidth:"548px",
  minWidth:'unset',

  },
})
const webStyle = {
  nextbtn2:{
    fontSize:"2rem",
    // marginTop:'45px',
    // position:'absolute' as 'absolute',
    // right:49,
    
  },
  nextbtn:{
    fontSize:"2rem",
  },
  closeIconStyle:{
    padding:"10px 16px",
    background:"#000",
    display:"flex",
    justifyContent:"center",
    gap:"8px",
    color:"#FFF",
    right:24,
    cursor:"pointer",
    borderRadius: "8px",
    opacity: "0.75",
    width:"76px"
        },
  carouselMainWrapper:{
    position:"relative",
    paddingLeft: 84,
    maxWidth:"548px",
  }as const,

  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  carouselImage: {
    display:"block",
    maxWidth:"485px",
    borderRadius:"8px",
    height: "460px",
    objectFit: "cover" as "cover",
    objectPosition: "center",
    
  },
  carousel: {
    flex: 1,
    overflow: "hidden" as "hidden",
    borderRadius: 8,
    position:"relative" as "relative",
  maxHeight: "460px",
  // minHeight:"460px",
  height: "100%",
  },
  indicatorIconWrap: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "flex-start",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 9,
    bottom: 0,


  }as const,
  indicatorIcon: {
    width: 64,
    cursor:"pointer",
    height: 64,
    overflow: "hidden" as "hidden",
    position:"relative" as "relative",
    display: "flex",
    borderRadius: 8,
    marginBottom: 15,

  },
};
// Customizable Area End
