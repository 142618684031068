import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  IconButton,
  Snackbar,

} from "@material-ui/core";
import { createTheme, styled, } from "@material-ui/core/styles";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { MainGrid, MainBox, SecondGrid } from "../../email-account-registration/src/UserRole.web";
import {  checkBoxFalse, checkBoxTrue } from "../../email-account-registration/src/assets";
import { InputField } from "../../email-account-registration/src/EmailAccountEmailVerification.web";


const SecondBox = styled(Box)({
  maxWidth: "360px",

  marginTop: "40px",
  "@media (max-width:390px)": {
    paddingInline: "10px"
  },
  "@media (max-width:320px)": {
    justifyContent: "center",
  },

});
const BoxStyle=styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems:"center",
   marginBottom: "48px",
   flexWrap:"wrap",
   "@media (max-width:380px)": {
   flexDirection:"column",
   alignItems:"unset",
   gap:"10px"
  },
})

const AllStyle = {

  textStyle: {
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "4px",
    color: "#0A2239",
    fontFamily: "Lato",
    lineHeight: "22px",
  },


  boldStyle: {
    color: "00bdab",
    fontSize: "16px",
    fontFamily: "Lato",
    fontWeight: 700,
  },
  btnStyle: {
    borderRadius: "8px",
    height: "56px",
    lineHeight: "24px",
    background: "#00bdab",
    textTransform: "inherit" as "inherit",
    color: "white",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "30px",
  },
  popup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    color: "#0F172A",
    borderRadius: "4px",
    width: "362px",
    height: "42px",
    fontWeight: 400,
    textAlign: "center",
    fontFamily: "Lato",
    fontSize: "16px",
    lineHeight: "24px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  heading: {
    color: "#0A2239",
    textAlign: "center" as "center",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    marginBottom: "8px",
    fontFamily: "Lato",
    letterSpacing: "-0.12px"
  },

  secondBox: {
    flexDirection: "column" as "column",
    display: "flex",
    flexWrap: "Wrap" as "wrap",
  },
};


const ButtonStyle = styled(Button)({
  minWidth: "360px",
  "@media (max-width:380px)": {
    minWidth: "200px",
  },
})

import { DiologComponent } from "../../email-account-registration/src/EmailAccountForm.web";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  handlepassword = () => {
    return this.state.enablePasswordField ? "password" : "text"
  }
  handleImageStyle=()=>{
   
    if(this.state.imageUrl!==""){
      return {
        minHeight: 'calc(100vh - 247px)',
        maxHeight: 'calc(100vh - 247px)',
        width:"100%",
        borderRadius:"0px 0px 0px 45px",
        objectFit: 'cover' as 'cover',
        objectPosition: 'top' as 'top'
      }
    }
    else{
      return { width:"335px" }
    }
  }
  
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box>
<Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.isSnackbarOpen}
          autoHideDuration={5000}
          onClose={this.closeSnackbar}
        >
          <div style={AllStyle.popup as React.CSSProperties}>
            {this.state.messageSnackbar}
          </div>
        </Snackbar>
        
        <MainGrid container>
          <SecondGrid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <MainBox
            >
              <Typography style={AllStyle.heading}>
                Login to PETOOTI
              </Typography>
              <SecondBox style={AllStyle.secondBox}>

                <Typography style={AllStyle.textStyle}>
                  Email
                </Typography>
                <InputField placeholder={this.state.placeHolderEmail}
                  error={this.state.emailError}

                  variant="outlined" data-test-id="emailtest" value={this.state.email} onChange={(e: any) => {
                    this.handleEmail(e.target.value)
                  }}

                  helperText={
                    this.state.emailError &&
                    this.state.emailErrorMessage
                  }
                />
                <Typography style={AllStyle.textStyle}>
                  Password
                </Typography>
                <InputField
                  error={this.state.passwordError}
                  helperText={this.state.passwordError && this.state.passwordErrorMessage}
                  style={{ marginBottom: "12px" }}
                  placeholder={this.state.placeHolderPassword}
                  data-test-id="txtInputPassword"
                  type={this.handleVisiblPassword()}
                  fullWidth={true}
                  value={this.state.password}
                  variant="outlined"
                  onChange={(e: any) => {
                    this.handlePassword(e.target.value)
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => { this.setState({ enablePasswordField: !this.state.enablePasswordField }) }}
                        edge="end"
                      >
                        {this.state.enablePasswordField ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />

                <BoxStyle >
                  <Typography data-test-id="check" style={{ color: "#0F172A",  display: "flex", alignItems: "center", fontWeight: 400, fontSize: "16px", fontFamily: 'Lato', cursor: "pointer", lineHeight: "24px" }} onClick={() => {
                    this.setState({ rememberMe: !this.state.rememberMe })

                  }}>
                    <img src={this.state.rememberMe ? checkBoxTrue : checkBoxFalse} style={{ marginRight: "3px", width: "20px", height: "20px" }} />
                    {this.state.labelRememberMe}
                  </Typography>

                  <Typography style={{ ...AllStyle.textStyle, color: "#03B5AA", lineHeight: "22px",cursor: "pointer",marginBottom:"0px" }} onClick={()=>{this.handleForgotPassword()}}>
                    {this.state.forgotPassword}
                  </Typography>
                </BoxStyle>

                <ButtonStyle variant="contained"
                  data-test-id="button"

                  style={AllStyle.btnStyle} onClick={() => {
                    this.handleValidtion()
                  }}>
                  {this.labelTitle}
                </ButtonStyle>
                <Typography style={{
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "16px",
                  color:"#0A2239",
                  lineHeight:"19.2px"
                }}>
                  {this.haveAccount}
                  {" "}
                  <span style={{ ...AllStyle.boldStyle, cursor: "pointer",
    lineHeight: "24px",

                 }} data-test-id="naviagte" onClick={() => { this.handleNavigation() }}>
                    {this.labelTitleSignUp}
                  </span>
                </Typography>
              </SecondBox>
            </MainBox>
          </SecondGrid>
         
        </MainGrid>
        
        <DiologComponent success={this.state.openPopup} handleClose={this.handleClose} firstHeading={"HOLD TIGHT!"} secondHeading="Your account is currently under review. We'll get back to you faster than a cat chasing a laser pointer!" ButtonName="Okay"navigate={this.props.navigation.navigate}/>
      </Box >
      // Customizable Area End
    );
  }
}
