import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import storage from "framework/src/StorageProvider";
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  // Customizable Area Start
  favlistData:any[]
  isLoading:boolean
  favImage:string;
  remoItem:boolean;
  removemsg:string;
  user_id:number|null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FavouritesWebController extends BlockComponent<Props, S, SS> {
// Customizable Area Start
favAllListId:string="";
removeItemId:string="";
// Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      favlistData:[],
      favImage:"",
      remoItem:false,
      isLoading:true,
      removemsg:"",
      user_id:null
    };
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.error) {
      switch (apiRequestCallId) {
        case this.removeItemId: 
        if(responseJson.message==="Post is removed from your favourites successfully"){

          setTimeout(() => {
            this.setState((state, props) => ({
              removemsg:responseJson.message,
              remoItem:true
            }), () => {
              setTimeout(() => {
                this.setState(() => ({
              removemsg:"",
                  remoItem:false
                }))
              }, 2000);
            })
          }, 100);
          this.getAllFavData()

        }
          break;
          case this.favAllListId: 
         this.setState({favlistData:responseJson?.data,isLoading:false})
          
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const image=await storage.get("favourites_screen_with_no_items_image")
    const user_id=await storage.get("auth_user_id")
    this.setState({user_id:user_id})
if(image){
  this.setState({favImage:image})
}
    this.getAllFavData()
  }
  handleClose=()=>{
    this.setState({remoItem:false})
  }
  handleAgeFormat = (years:number,months:number) => {
   
  
    if (years === 0 && months === 0) {
      return `Less than a month`;
    }
  
    let result = '';
  
    if (years > 0) {
      result += `${years} ${years === 1 ? 'Year' : 'Years'}`;
    }
  
    if (months > 0) {
      if (result.length > 0) {
        result += ' ';
      }
  
      result += `${months} ${months === 1 ? 'Month' : 'Months'}`;
    }
  
    return result;
  };
  handleNavigation=(id:number,itemId:number)=>{
    console.log(this.state.user_id,id,itemId,"xajfiewfhidsxhwrhdfhr");
    
    if(id==this.state.user_id){
      this.props.navigation.navigate("ProductDescription",{id:itemId,postDetails:false})
    }
    else{
      this.props.navigation.navigate("ProductDescriptionPublic",{id:itemId,postDetails:false})
    }
  }
  getAllFavData = async () => {
    const token = await storage.get("auth_token") as string;
    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favAllListId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_favourites/favourites`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteItem = async (id: any) => {
    const token = await storage.get("auth_token") as string;
    const header = {
      token: token
    };
  const formdata=new FormData();
  formdata.append("favouriteable_id",id)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeItemId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_favourites/favourites`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
