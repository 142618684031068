Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"
exports.fostered=[
  {id:0,name:"Show fostered pets only",value:"Show fostered pets only"}
]
exports. animaltype = [
  { id: 0, name: "All" },
  { id: 1, name: "Dog" },
  { id: 2, name: "Cat" },
  { id: 3, name: "Rabbit" },
  { id: 4, name: "Birds" },
  { id: 5, name: "Reptiles" },
  { id: 6, name: "Little Critters" },
];
exports. breed = [
  { id: 0, name: "All" },
  { id: 1, name: "Dog" },
  { id: 2, name: "Cat" },
  { id: 3, name: "Rabbit" },
  { id: 4, name: "Birds" },
  { id: 5, name: "Reptiles" },
  { id: 6, name: "Little Critters" },
];
exports.gender=[
  {id:0,name:"All"},
  {id:1,name:"Female"},
  {id:2,name:"Male"},
]

exports.menuItem = [
  {
    id: 0,
    name: "Date added - Newest on top",
    value:"newest"
  },
  {
    id: 1,
    name: "Date added - Oldest on top",
    value:"oldest"
  },
  {
    id: 2,
    name: "Age - Oldest on top",
    value:"oldest_age"
  },
  {
    id: 3,
    name: "Age - Youngest on top",
    value:"youngest_age"
  },
 
];
exports.age=[
  {id:0,name:"All"},
  {id:1,name:"0 - 6 Months",value:"0_6_months"},
  {id:2,name:"6 - 12 Months",value:"6_12_months"},
  {id:3,name:"1 - 3 Years",value:"1_3_years"},
  {id:4,name:"3 - 5 Years",value:"3_5_years"},
  {id:5,name:"5 - 8 Years",value:"5_8_years"},
  {id:6,name:"10 - 14 Years",value:"10_14_years"},
  {id:7,name:"15+ Years",value:"15_years"},
]
exports.level=[
  {id:0,name:"All"},
  {id:1,name:"Low"},
  {id:2,name:"Moderate"},
  {id:3,name:"High"},
]
exports.location=[
  {id:0,name:"All"},
  {id:1,name:"Abu Dhabi"},
  {id:2,name:"Dubai"},
  {id:3,name:"Sharjah"},
  {id:4,name:"Ajman"},
  {id:5,name:"Ras Al Khaimah"},
  {id:6,name:"Fujairah"},
  {id:7,name:"Umm Al Quwain"},
]
exports.goodHome=[
  {id:0,name:"All"},
  {id:1,name:"Children"},
  {id:2,name:"Dogs"},
  {id:3,name:"Cats"},
  {id:4,name:"Others"},
]
exports.temperament=[
  {id:0,name:"All"},
  { id: 1, name: 'Independent' },
  { id: 2, name: 'Needy' },
  { id: 3, name: 'Gentle' },
  { id: 4, name: 'Feisty' },
  { id: 5, name: 'Shy' },
  { id: 6, name: 'Confident' },
  { id: 7, name: 'Anxious' },
  { id: 8, name: 'Easy going' },
  { id: 9, name: 'Skittish' },
  { id: 10, name: 'Relaxed' },
  { id: 11, name: 'Affectionate' },
  { id: 12, name: 'Reserved' },
  { id: 13, name: 'Curious' },
  { id: 14, name: 'Aloof' },
  { id: 15, name: 'Intelligent' },
  { id: 16, name: 'Not too bright' },
  { id: 17, name: 'Playful' },
  { id: 18, name: 'Lazy' },
  { id: 19, name: 'Fearful' },
  { id: 20, name: 'Fearless' },
  { id: 21, name: 'Submissive' },
  { id: 22, name: 'Dominant' },
  { id: 23, name: 'Friendly' },
  { id: 24, name: 'Antisocial' },
  { id: 25, name: 'Talkative' },
  { id: 26, name: 'Quiet' }
]

// Customizable Area End