// Customizable Area Start
import React from "react";
import {
  Avatar, Box, Button, Card, CardContent, Grid,Backdrop,
  Dialog, Paper, TextField, Typography,styled, FormControl, IconButton, Select, MenuItem,Tooltip, Snackbar, Container, Breadcrumbs, Link
} from "@material-ui/core";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {profileBackground, checkBoxFalse, checkBoxTrue, RightImg , infoIcon,LightArrow} from './assets';
import CloseIcon from "@material-ui/icons/Close";
const configJSON = require("./config");
import PhoneInput from 'react-phone-number-input';
import { KeyboardArrowDown, Visibility, VisibilityOff,DateRange } from "@material-ui/icons";
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Loader from "../../../components/src/Loader.web";
import { DiologComponent } from "../../email-account-registration/src/EmailAccountForm.web";
const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: "#FFF",
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    padding: "8px 16px",
    lineHeight: "22px",
    borderRadius: "8px",
    border: "0.5px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
    boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)",

  },
})(Tooltip);
export const DialogStyled=styled(Dialog)({
  '& .MuiDialog-paperWidthSm':{
    overflowX:'hidden'
  }
})
// Customizable Area End

import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";

export default class UserProfileBasicAdopter extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderChangePassword = () => {
      return (
        <>
          <UserProfileStyle>
            <DialogStyled
              keepMounted
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
              className="modal"
              style={{ display: 'flex', justifyContent: 'center', borderRadius:"8px", alignItems:"center" }}
            >
              <ModalInnerBox >
                <Card style={{borderRadius:"8px",position:'relative'}}>
                  <CardContentBox >
                    <CloseIcon
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "20px",
                        cursor: "pointer",
                      }}
                      onClick={this.handleClose}
                    />
                    <Box>
                      <Typography
                        style={{
                          fontSize: "24px",
                          fontWeight: 700,
                          fontFamily: "Inter",
                          color:"#0f172a",
                        }}
                      >
                        TIME FOR A PASSWORD MAKEOVER!
                      </Typography>
                      <Typography style={{ fontSize: "18px", fontFamily: "Inter",color:"#334155" }}>
                      It's time to give your password a little refresh. Update it now to keep things secure. Strong passwords are always in fashion!
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        marginTop: "10px",
                        gap: "4px",
                      }}
                    >
                      <Typography style={{ color: '#334155',fontFamily: 'Lato',fontSize: '14px',fontStyle: 'normal',fontWeight: 700,lineHeight: '22px',}}>
                        Current Password
                      </Typography>
                      <InputField
                        placeholder="Enter current password"
                        fullWidth
                        type={this.state.enablePasswordField ? "password" : "text"}
                        variant="outlined"
                        error={this.state.oldPassError}
                        data-test-id="old-pass"
                        value={this.state.oldPassword}
                        helperText={this.state.oldPassError && this.state.oldPassErrMsg}
                    onChange={this.handleOldPassword}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.setPasswordField}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <Box style={{  gap: "4px" }}>
                      <Typography style={{ color: '#334155',fontFamily: 'Lato',fontSize: '14px',fontStyle: 'normal',fontWeight: 700,lineHeight: '22px',}}>
                        New Password
                      </Typography>
                      <InputField
                        placeholder="Enter new password"
                        fullWidth
                        variant="outlined"
                        error={this.state.newPassError}
                        data-test-id="new-pass"
                        value={this.state.newPass}
                        helperText={this.state.newPassError && this.state.newPassErrorMsg}
                        onChange={this.handleNewPasswordChange}
                        type={!this.state.handleVisiblCPassword ? "password" : "text"}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.setConfirmPasswordField}
                              edge="end"
                            >
                              {!this.state.handleVisiblCPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <Box className="textInput" style={{ gap: "4px"}}>
                      <Typography style={{ color: '#334155',fontFamily: 'Lato',fontSize: '14px',fontStyle: 'normal',fontWeight: 700,lineHeight: '22px',}}>
                        Confirm New Password
                      </Typography>
                      <InputField
                        placeholder="Enter new password"
                        fullWidth
                        data-test-id="confirm-pass"
                        error={this.state.confirmPassError}
                        variant="outlined"
                        type={!this.state.handleVisiblNPassword ? "password" : "text"}
                        value={this.state.confirmPass}
                        helperText={this.state.confirmPassError && this.state.confirmPassErrorMessage}
                        onChange={this.handleConfirmPasswordChange}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.setConfirmNewPasswordField}
                              edge="end"
                            >
                              {!this.state.handleVisiblNPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                        style={{borderRadius:"8px"}}
                      />
                    </Box>
                    <BoxButton
                     
                    >
                      <Custombutton  data-test-id="change-pass" onClick={this.handleAdopterProfilePasswordUpdate}>Change Password</Custombutton>
                      <CustombuttonCancel
                        
                        onClick={this.handleClose}
                      >
                        Cancel
                      </CustombuttonCancel>
                    </BoxButton>
                  </CardContentBox>
                </Card>
              </ModalInnerBox>
            </DialogStyled>
          </UserProfileStyle>
        </>
      )
    }
    handleStyle = () => {
      return this.state.phoneError ? "4px" : "16px"
    }
    hanldePlaceholder = () => {
      return !this.state.phoneNumber  && "00000 00000"
    }
    hanldeDropDown = (emirateData: any) => {
      return emirateData?.map((option: any) => (
        <MenuItem
          value={option.name} key={option.id}
        >{`${option.name}`}</MenuItem>
      ))
    }
    
    hanldeDropDownNational = (nationality: any) => {
      return nationality?.map((option: any) => 
      (
        <MenuItem
        value={option.name}
        key={option.id}
        >
          {`${option.name}`}
          </MenuItem>
      ))
    }
    hanldeDropDownCountry = (countryData: any) => {
      return countryData?.map((option: any) => 
      (
        <MenuItem
        value={option.name}
        key={option.id}
        >{`${option.name}`}
        </MenuItem>
      ))
    }
  
    handleError = (error: boolean) =>
     {
      return error && (
        <Typography
          style={AllStyle.errorTextStyle}
        >
          Please select an option
        </Typography>)
    }
    closeSnackbar = ()=>{
      this.setState({
        isSnackbarOpen:false
      })
    }
    navigateToHome = ()=>{this.props.navigation.navigate("LandingPageWeb")}
    showEmirateSelect = ()=>{
      if(this.state.residence === "United Arab Emirates" || this.state.residence === "United Arab Emairates"){
        return (
          <>
            <>
                      <Typography className="label">Emirate  {this.renderStarMark()}</Typography>
                      <SelectFieldColor fullWidth  >
                        <Select
                          error={this.state.emirateError}
                          variant="outlined"
                          value={this.state.emirateName}
                          onChange={this.handleAdopterEmirate}
                          data-test-id="Emirate"
                          MenuProps={{
                            getContentAnchorEl: null,
                            MenuListProps: {
                              style: {
                                maxHeight: 398,
                                padding: "10px",
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            fontFamily: "Lato",
                            color: this.state.emirateName === "Select" ? "#94A3B8" : "#0F172A"

                          }}
                          fullWidth
                          IconComponent={KeyboardArrowDown}
                        >
                          <MenuItemStyle
                          value="Select"
                            >
                           Select
                          </MenuItemStyle>
                          {this.hanldeDropDown(this.state.emirateData)}
                        </Select>
                        {this.handleError(this.state.emirateError)}
                      </SelectFieldColor>

                    </>
                    <>
                      <Typography className="label">Area  {this.renderStarMark()}</Typography>
                      <InputField variant="outlined" error={this.state.areaError} placeholder="Area" fullWidth value={this.state.areaName}
                        helperText={this.state.areaError && this.areaFeildErrorMessage()} data-test-id="areaName" onChange={this.hanldeDropDownAreaSelect} />
                    </>
          </>
        )
      }else {
        return (
          <>
                      <Typography className="label">City  {this.renderStarMark()}</Typography>
                      <InputField variant="outlined" data-test-id="cityName" placeholder="City" fullWidth value={this.state.cityName} onChange={this.handleCityChange} error={this.state.cityError} helperText={this.state.cityError && this.cityFeildErrorMessage()}  />
                    </>
        )
      }
    }

    renderImageBtns = ()=>{
      return (
        <ImageBtnsBox >
                          <Grid item container direction="column">
                           <label htmlFor="adopter_image_Upload">
                           <Button className="changeImgBtn" component="span" style={{fontFamily:"Lato", fontWeight:700,fontSize:"16px"}}>
                             {this.state.selectedProfileImg || this.state.userProfileData?.profile_image ? "Change" : "Upload"}  Image
                            </Button>
                            {this.state.imageError ? <Typography style={{...AllStyle.errorTextStyle, marginLeft:"8px"}}>Max size should not exceed more than 5 mb</Typography> : null}
                            {this.state.fileFormateErr ? <Typography style={{...AllStyle.errorTextStyle, marginLeft:"8px"}}>Invalid file format. Accepted formats: PNG, JPG and JPEG</Typography> : null}
                          
                           </label>
                           <input
                        id="adopter_image_Upload"
                        accept=".png, .jpg, .jpeg"
                        data-test-id="adopter_image_Upload"
                        type="file"
                        onChange={this.handleAdopterImageUpload}
                        style={{ display: "none" }}
                    />
                            { this.state.selectedProfileImg || this.state.userProfileData?.profile_image ? (<Button  style={{fontFamily:"Lato",width:"fit-content", fontWeight:700,fontSize:"16px", color:"#ff5e5b", textTransform:"unset", textDecoration:"underline"}} onClick={this.handleAdopterProfileDelete} data-test-id="adopter-image-remove-btn" >Remove Image</Button>) : null}
                          </Grid>
                        </ImageBtnsBox>
      )
    }
    renderStarMark = ()=>{
      return (<span style={{ color: "#FF5E5B" , fontWeight:400, fontFamily:"Lato",fontSize:"14px",lineHeight:"22px" }}>*</span>)
    }
    // Customizable Area End
    
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <Loader loading={this.state.isLoading } />

                <Backdrop
                style={{ zIndex: 700 }}
                data-test-id='backdrop2'
                open={this.state.isSnackbarOpen}
            ><SnackBarStyle
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                style={{ zIndex: 1000 }}
                autoHideDuration={5000}
                onClose={this.closeSnackbar}
                open={this.state.isSnackbarOpen}
                data-test-id='snackbar2'
            >
                <Typography style={AllStyle.popup as React.CSSProperties}>
                    <svg xmlns="http://www.w3.org/2000/svg"  height="26" width="26" viewBox="0 0 26 26" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
                    </svg>
                    {this.state.snackbarMessage}
                </Typography>
            </SnackBarStyle>
            </Backdrop>
        <UserProfileStyle>
          
          <ThemeProvider theme={theme}>
        

          <Container style={{ marginTop: "50px", marginBottom: "50px", maxWidth:"1100px" }} >
            <Grid style={{marginTop:'1rem'}}>
          <StyledBreadcrums separator={<img src={LightArrow} />}  aria-label="breadcrumb">
        <Link color="inherit" style={{ ...AllStyle.hedingStyle, paddingInline: "6px", cursor: "pointer" }}  onClick={this.navigateToHome} >
          Home
        </Link>
        <Typography style={{
    ...AllStyle.hedingStyle,
    color: "#0F172A",
    paddingInline: "6px",
  }}  >My Profile</Typography>
      </StyledBreadcrums>
            <Paper style={{borderRadius:"10px"}}>
                <Box>
                  <Box
                    style={{ backgroundImage: `url(${profileBackground})`, height: "145px",borderTopLeftRadius: '8px',borderTopRightRadius:'8px' }}></Box>
                  <GridParent
                    item
                    container
                    justifyContent="space-between"
                    alignItems="flex-end"
                    style={{ padding: "0px 50px 50px 50px" }}
                  >
                    <Box>
                      <GridChiled item container>
                        <Avatar
                          src={this.state.selectedProfileImg || this.state.userProfileData?.profile_image}
                          alt={this.state?.userProfileData?.first_name}
                          style={{marginBottom:'16px',border:`5px solid ${this.state.selectedProfileImg || this.state.userProfileData.profile_image ? "#f0e5ff" :"#cdf0ee"}`}}
                          className="large" > <span style={{fontSize:"56px",fontFamily:"Inter",fontWeight:600}}> {this.state?.userProfileData?.first_name?.charAt(0)}</span></Avatar>
                          {this.renderImageBtns()}
                       
                      </GridChiled>
                    </Box>
                    <Box>
                      <Grid item container style={{height:'44px'}}>
                        <Button
                          variant="outlined"
                          startIcon={<LockOutlinedIcon />}
                          className="changePwdBtn"
                          onClick={this.handleOpen}
                        >
                          Change Password
                        </Button>
                      </Grid>
                    </Box>
                  </GridParent>
                </Box>
                {this.renderChangePassword()}
                <Gridcontainer container  >
                  <PersonalGridChiled item lg={4}>
                    <Typography className="personal">Personal information</Typography>
                  </PersonalGridChiled>
                  <Grid item lg={6} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                    
                      <Typography className="label">First Name</Typography>

                      <InputField variant="outlined" disabled fullWidth value={this.state.firstName}  />
                    
                      <Typography className="label">Last Name</Typography>
                      <InputField variant="outlined" disabled fullWidth value={this.state.lastName}  />
                    
                    
                      <Typography className="label">Email</Typography>
                      <InputField variant="outlined" disabled fullWidth value={this.state.email}  />
                   {!this.state.isLoading && <>
                      <Typography className="label">Phone Number  <span style={{ color: "#FF5E5B", fontFamily:"Lato", fontWeight:400 }}>*</span></Typography>
                      
                      <div style={{ position: 'relative', marginBottom: this.handleStyle() }}>
                        <label style={{
                          position: 'absolute', top: 16, left: 100, fontFamily: "Lato",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "24px", color: '#94A3B8'
                        }} htmlFor="">{this.hanldePlaceholder()}</label>

                        <PhoneStyle
                          data-test-id="txtInputPhonenumber"
                          borderColor={this.handleColorPhone()}
                          className="custominput"
                          value={this.state.phoneNumber}
                          onChange={(e:any)=>{this.handlePhoneNumbberCallBack(e)}}
                          defaultCountry="AE"
                          countries={[]}
                          international
                        />
                        {this.state.phoneError && <Typography
            style={{ ...AllStyle.errorTextStyle, marginBottom: "0.8rem" }}
          >
            Please enter a valid phone number
          </Typography>}
                      </div>
                      
                      </>}
                      <>
                      <Typography className="label">Country of Residence  {this.renderStarMark()}</Typography>
                      <SelectFieldColor fullWidth >
                        <Select
                          fullWidth
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            fontFamily: "Lato",
                            color: this.state.residence === "Select" ? "#94A3B8" : "#0F172A"
                          }}
                          error={this.state.residenceError}
                          variant="outlined"
                          onChange={this.handleSeperateResidence}
                          value={this.state.residence}
                          data-test-id="Residence"
                          MenuProps={{
                            MenuListProps: {
                              style: {
                                padding: "10px",
                                maxHeight: 398,
                              },
                            },
                            anchorOrigin: {
                              horizontal: "left",
                              vertical: "bottom",
                            },
                            getContentAnchorEl: null,

                          }}
                          IconComponent={KeyboardArrowDown}
                        >
                          <MenuItemStyle
                            value={this.state.residence}
                          >
                            {this.state.residence}
                          </MenuItemStyle>
                          {this.hanldeDropDownCountry(this.state.countryData)}
                        </Select>
                        {this.handleError(this.state.residenceError)}
                      </SelectFieldColor>
                    </>
                      {this.showEmirateSelect()}
                      <Typography className="label">Gender</Typography>
                      <SelectField fullWidth >
                        
                        <Select
                          variant="outlined"
                          value={this.state.gender}    
                          disabled                     
                          MenuProps={{
                            MenuListProps: {
                              style: {
                                maxHeight: 398,
                                padding: "10px",
                              },
                            },
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                          fullWidth
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            fontFamily: "Lato",
                            color: this.handleColorStyle("gender")
                          }}
                          error={this.state.genderError}
                          IconComponent={KeyboardArrowDown}
                        >
                          <MenuItem
                            value={this.state.gender}
                            style={{
                              display: "none",
                              fontSize: "16px",
                              fontWeight: 400,
                              fontFamily: "Lato",
                              color: "#94A3B8",
                            }}
                          >
                              {this.state.gender}
                          </MenuItem>
                        </Select>
                        {this.handleError(this.state.genderError)}
                      </SelectField>
                      
                      <Typography className="label">Date of Birth</Typography>
                      <InputField  variant="outlined" fullWidth  disabled InputProps={{endAdornment :<DateRange />}}value={this.state.dOB}/>

                  
                    <Box className="textInput">
                      <Typography className="label">
                        Nationality
                        </Typography>
                      <SelectFieldColor fullWidth >
                        <Select
                          onChange={this.handleNationality}
                          data-test-id="Nationality"
                          variant="outlined"
                          placeholder="Select"
                          value={this.state.nationality}
                          MenuProps={{
                            getContentAnchorEl: null,
                            MenuListProps: {
                              style: {
                                maxHeight: 398,
                                padding: "10px",
                              },
                            },
                            anchorOrigin: {
                              horizontal: "left",
                              vertical: "bottom",
                            },
                          } }
                          fullWidth
    style={{
                            fontFamily: "Lato",
                            fontSize: "16px",
                            fontWeight: 400,
                            color: this.state.nationality === "Select" ? "#94A3B8" : "#0F172A"
                          }}
                          IconComponent={KeyboardArrowDown}
                        >
                          <MenuItem
                           
                            style={{
                              display: "none",
                              color: "#94A3B8",
                              fontFamily: "Lato",
                              fontSize: "16px",
                              fontWeight: 400,      
                                                  }}
                            value="Select"
                          > Select
                          </MenuItem>{this.hanldeDropDownNational(this.state.nationalityData)}</Select>
                      </SelectFieldColor>
                    </Box>
                    
                  </Grid>
                </Gridcontainer>
                <hr style={{ width: '90%', border:'none',height:'1px',backgroundColor:'#e2e8f0' }} />
                <GridBottom container  >
                  <Grid item lg={4} xs={12}  className="GridBottomChiled">
                    <Typography className="personal" >
                      <PetPreferencesSpan >
                      <span> <span>Pet Preferences  </span>   {this.renderStarMark()}  </span> </PetPreferencesSpan>
                      <LightTooltip
                      data-test-id="petrefrenceid"
                      open={this.state.tooltipOpenchannelPetsReferenceTooltip}
                      onClose={()=>this.handleTooltipCloseChanelsDataPetsReference()}
                          title="Please check which animals you would love to adopt or foster"
                          placement="right" style={{display:'inline'}} >
                          <IconButtonStyleShow data-test-id='infoComp'  onClick={()=>this.handlePetsReference()} onMouseLeave={()=>this.handlePetsReferenceMouseOver()}
                            onMouseEnter={()=>this.handlePetsReferenceMouseHover()} >
                            <img src={infoIcon} />
                          </IconButtonStyleShow>
                        </LightTooltip>
                        {this.state.petsError && (
                        <PetPreferencesErrText
                          style={{...AllStyle.errorTextStyle,marginTop:'2px'}}
                          className="petPreferencesErrText"
                        >
                          Please select at least one option
                        </PetPreferencesErrText>
                      )}
                    </Typography>
                    
                  </Grid>
                  <Grid item lg={3} xs={6} >

                    <Box className="checkBoxFirstColumn" >

                      {["Cats", "Birds", "Rabbits"].map((item: string, id: number) => {
                        return <Typography key={id}
                        style={AllStyle.petRefrence}
                           onClick={() => {
                            this.handlePetSelection(item)
                          }}>
                          <img src={this.state.pets.includes(item) ? checkBoxTrue : checkBoxFalse} className="checkBoxStyle" />
                          {item}
                        </Typography>
                      })}

                    </Box>
                  </Grid>
                  <Grid item lg={3} xs={6} >
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      {["Dogs", "Reptiles", "Little Critters"].map((item: string, id: number) => {
                        return <Typography
                          key={id}
                          style={AllStyle.petRefrence}
                           onClick={() => {
                            this.handlePetSelection(item)
                          }}>
                          <img src={this.state.pets.includes(item) ? checkBoxTrue : checkBoxFalse} className="checkBoxStyle" />
                          {item}
                        </Typography>
                      })}

                    </Box>                    
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >

                    </Box>
                  </Grid>
                </GridBottom>
                <hr style={{ width: '90%', border:'none',height:'1px',backgroundColor:'#e2e8f0' }} />
                <GridBox container >
                  <Grid item lg={4}>

                  </Grid>
                  <Grid item lg={6}>
                  <Box style={{ display: 'flex' }}>
                  <button className="changeBtn" data-test-id="profile-update-btn" onClick={this.handleValidationForAdopter}
                  ><img src={RightImg} />Save Changes</button>
                  <button className="cancelBtn" style={{cursor:"pointer"}} data-test-id="cancel-changes" onClick={()=>{this.getUserProfileAdopterDetails(true)}}>Cancel</button>
                </Box>
                  </Grid>
                </GridBox>
                
              </Paper>
            </Grid>
          </Container>
          </ThemeProvider>
          
        </UserProfileStyle>
        <DiologComponent  success={this.state.isModalOpen} handleClose={this.handleCloseSnackBar} firstHeading="PASSWORD UPGRADE IS COMPLETE!" secondHeading="Your password has been successfully changed! Now you're all set to continue saving the day for your rescues." ButtonName={"OKAY"}  />
      </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const UserProfileStyle = styled(FormControl)({
background:"#cdf0ee33",
  "& .large": {
    width: "156px",
    height: "156px",
    marginTop: "-50px",
    backgroundColor:"#ffffff",
    color:"#03B5AA",
    fontSize:"56px",
    fontFamily:"Inter",
    fontWeight:"600"
  },
  "& .changeImgBtn": {
    color: "#03B5AA",
    textTransform: "capitalize",
    fontFamily:"Lato",
    fontSize:"16px",
    fontWeight:700,
    lineHeight:"1.5rem"
  },
  "& .changePwdBtn": {
    fontSize:"16px",
    fontWeight:700,
    border: "2px solid #03B5AA",
    color: "#03B5AA",
    borderRadius:"8px",
    textTransform:"unset",
    fontFamily:"Lato"
  },
  "& .modal": {
    display: "flex",
    justifyContent: "center",
  },
  "& .label": {
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
  },
  "& .disabledlabel":{
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight:"22px",
    textShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"
  },
  "& .personal": {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: '24px'
  },
  "& .textInput": {
    marginBottom: '36px',
    height: '44px'
  },
  "& .cancelBtn": {
    marginRight: '16px',
    background: '#FFF',
    fontSize: '16px',
    color: '#03B5AA',
    fontFamily: "Lato",
    fontWeight: 600,
    border: '1px solid #03B5AA',
    width: '80px',
    height: '44px',
    borderRadius: '8px',
    padding: '16px 32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '40px'
  },
  "& .changeBtn": {
    width: '162px',
    fontFamily: "Lato",
    marginRight: '16px',
    fontWeight: 600,
    background: '#03B5AA',
    fontSize: '16px',
    borderRadius: '8px',
    color: '#FFF',
    border: 'none',
    height: '44px',
    display: 'flex',
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '40px',
    cursor:"pointer"
  },
  "& .textStyle": {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    marginBottom: "4px",
    color: "#0A2239",
    paddingLeft: '16px'
  },
  "& .errorTextStyle": {
    color: "#64748B",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px",
  },
  "& .petReference": {
    color: "#0A2239",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: 'Lato',
    cursor: "pointer",
    lineHeight: "19.2px",
  },
  "& .checkBoxStyle": {
    marginRight: "8px",
    width: "20px",
    height: "20px"
  },

});
export const PhoneStyle = styled(PhoneInput)(({ borderColor }: any) => (
  {
  zIndex: 1,
  position: "relative",
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${borderColor || "#F87171"}`,
  borderRadius: "8px",
  height: 40,
  padding:"7px 10px",
  color:'#0F172A',
  
  '& input': {
    border: 'none'
  },
  '& input:focus': {
    border: 'none',
    outline: 'none'
  },
  '& input:focus-visible': {
    border: 'none',
    outline: 'none'
  },
  '& .PhoneInputInput': {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 400,
    background: "transparent",
    fontFamily: "Lato",
    lineHeight: "24px"
  }
}
)
);

const IconButtonStyleShow = styled(IconButton)({
  "&.MuiIconButton-root":{
  padding:'0px 12px 0.3px 12px'
  },
  "&.MuiIconButton-root:hover": {
    background: "unset"
  },
  '@media (max-width:600px )': {
    display: "block"
  },
})
export const SelectField = styled(FormControl)({
  marginBottom: "16px",

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
  "&.Mui-focused fieldset": {
    borderWidth: "1px",
    borderColor: "#CBD5E1"
  },
  "& .MuiOutlinedInput-root": {
    borderRadius:'8px',
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#CBD5E1",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#CBD5E1",
    borderRadius: "8px",
    outline:"none"
  },
  "& .MuiOutlinedInput-input": {
    padding: "18.4px 8px",
  },
  "& .MuiInputBase-input": {
   
    lineHeight: "19.2px",

  },
  "& .MuiSelect-icon.Mui-disabled":{
      color:'#64748B'
  },
   "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px"
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontWeight: 400,
    lineHeight: "18px",
    fontSize: "12px",
    fontFamily: "Lato",
  },}
});
export const SelectFieldColor= styled(FormControl)({
  marginBottom: "16px",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {borderColor: "#CBD5E1",},
      
  "&.Mui-focused fieldset": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius:'8px',
    "&:hover fieldset": {  borderColor: "#CBD5E1",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderRadius: "8px",
    borderWidth: "1px",
    outline:"none"
  },
  "& .MuiOutlinedInput-input": { padding: "18.4px 8px",
  },
  "& .MuiInputBase-input": {    lineHeight: "19.2px",
  },
  "& .MuiSelect-icon.Mui-disabled":{color:'#0F172A' 
  },
   "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {  borderColor: "#F87171"
  },
  "& .MuiFormHelperText-contained": {
    marginRight: "0px",
    marginLeft: "0px",
  },
  "& .MuiSelect-icon":{
    color:'#0F172A' 
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontWeight: 400,
    color: "#DC2626",
    fontFamily: "Lato",
    lineHeight: "18px",
    fontSize: "12px",
  },}
});
export const MenuItemStyle = styled(MenuItem)({
  display: "none",
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 400,
  color: "#94A3B8 !important",
});
const GridBox=styled(Grid)({
  display:'flex',
  justifyContent:"space-around",
  '@media (max-width:1279px )': {
    justifyContent:"center",
    alignItems:'center'
  },
})
const ImageBtnsBox=styled(Box)({
  marginTop:'1rem',
   "@media (max-width:821px) ":{
    marginBottom:'16px',
    marginTop:'unset',
   },
})
const AllStyle = {
  petRefrence:{
    color: "#0A2239",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: 'Lato',
    cursor: "pointer",
    lineHeight: "19.2px",
    width:'max-content'
  },
  errorTextStyle: {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px",
  },
  hedingStyle: {
    color: "#475569",
    textDecoration:"none",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  popup: {
    color: "#0F172A",
    backgroundColor: "#ffffff",
  
    fontFamily: "Inter",
    padding:"9px 16px 9px 8px ",
    textAlign:"center",
    fontWeight: 400,
    fontSize: "16px",
    borderRadius: "8px",
    lineHeight: "24px",
    display: "flex",
    gap:"8px",
    alignItems: "center",
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
  },
};
const SnackBarStyle = styled(Snackbar)({
  "&.MuiSnackbar-anchorOriginTopCenter":{
    top:"113px",
  }
  })
const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiOutlinedInput: {
      // Name of the rule
      input: {
        // Some CSS
      padding:'0.8rem'   
    },
    focused:{
      input:{
        outline:"none"
      }
    }
    },
  },
});
export const InputField = styled(TextField)((disabled?:any,textstyle?:any)=>({
  marginBottom: "16px",
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    }
  },
  "& .MuiInputBase-root":{
    color:"#0F172A",
    fontWeight:"400 !important",
  },
  '& ::placeholder':{
    color:"#94A3B8",
    fontFamily: "Lato",
fontSize: "16px",
fontWeight: 400,
lineHeight: "24px",
opacity:1
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#CBD5E1",
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "18.4px 8px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  
  "& .MuiInputBase-input[type='date']": {
    textTransform:'uppercase',
    color:"#0f172a",
    lineHeight: "19.2px"
  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: disabled? "#F87171":""
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px"
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px"
  },
  
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':{
    border:"1px solid #CBD5E1",
  },
  "& .MuiInputBase-root.Mui-disabled":{
    color:"#64748B",

  }
}));
const GridParent =styled(Grid)({

  '@media(max-width: 821px)': {
     flexWrap:'unset',
     flexDirection:'column',
     alignItems:'flex-start',
 }
})
const GridChiled=styled(Grid)({
  '@media(max-width: 821px)': {
     flexWrap:'unset',
     alignItems:'flex-start',
     flexDirection:'column',
 }
})
export const StyledBreadcrums = styled(Breadcrumbs)({
  width:"fit-content",

  '@media(min-width: 1390px)': {
    position:"relative",top:"-35px" ,left:'-155'
  },
  '@media(max-width: 1390px)': {
    position:"relative",bottom:"35px" ,left:'0'
  },
  
})
export const HomeGrid = styled(Grid)({
  marginTop: "48px",
  maxWidth: "1168px",
  marginInline: "auto",
  '@media(max-width: 1209px)': {
    maxWidth: "unset",
    marginInline: "20px",
  },
  '@media(max-width: 400px)': {
    maxWidth: "unset",
    marginInline: "10px",
  },

})
const ModalInnerBox=styled(Box)({
 width: "600px",
  "@media (max-width:640px)":{
    width: "100%"
  }
})
const CardContentBox=styled(CardContent)({
  padding: "48px 48px 0 48px" ,
   "@media (max-width:353px)":{
     padding: "48px 20px 0 20px" ,

    }

})
const BoxButton=styled(Box)({
 
   display: 'flex', 
   flexDirection: 'row',
   marginTop: "18px",
   '@media (max-width: 500px)': {
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
   
   },

})
const Gridcontainer=styled(Grid)({
 display: 'flex', justifyContent: "space-around", padding: '10px' ,
 "@media (max-width:1279px)":{
  padding: '10px 64px 10px 64px' 
 },
 "@media (max-width:500px)":{ padding: '10px 20px 10px 20px' ,
},

})
const GridBottom=styled(Grid)({
  display: 'flex', justifyContent: "space-around" ,padding: '10px',alignItems:"baseline",
  "& .GridBottomChiled":{
    height:'56px',
  },
  "@media (max-width:1279px)":{
   padding: '10px 64px 10px 64px'
  },
  "@media (max-width:450px)":{ 
    flexDirection:'column',
    "& .GridBottomChiled":{
      paddingBottom:'8px'
    },
},
  "@media (max-width:500px)":{ padding: '10px 20px 10px 20px' ,
},
"& .checkBoxFirstColumn":{
  display: "flex", flexDirection: "column", paddingLeft: '44px' ,
  "@media (max-width:450px)":{ paddingLeft:'unset'
},
}
 })
 const PetPreferencesSpan=styled('span')({
 paddingLeft:'14px',

  "@media (max-width:1279px)":{
    paddingLeft:'unset',
   
  }
 })
 const PetPreferencesErrText=styled(Typography)({
  paddingLeft:'14px',
  "@media (max-width:1279px)":{
    paddingLeft:'unset',
    
  }
 })
 const PersonalGridChiled=styled(Grid)({
  '@media (max-width:1279px)': 
  {marginBottom:'8px'
  },
 })
 const Custombutton=styled('button')({
    width: '174px',
    fontFamily: "Lato",
    marginRight: '16px',
    fontWeight: 600,
    background: '#03B5AA',
    fontSize: '16px',
    borderRadius: '8px',
    color: '#FFF',
    border: 'none',
    height: '56px',
    display: 'flex',
    padding: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '40px',
    cursor:"pointer",
    '@media (max-width:500px)': {
        marginBottom:'10px',
        marginRight: 'unset',
        width: '100%',
    }

 })
 const CustombuttonCancel=styled('button')({
    marginRight: '16px',
    background: '#FFF',
    fontSize: '16px',
    color: '#03B5AA',
    fontFamily: "Lato",
    fontWeight: 600,
    border: '1px solid #03B5AA',
    width: '112px',
    height: '56px',
    borderRadius: '8px',
    padding: '16px 32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '40px',
    cursor:"pointer",
    '@media (max-width:500px)': {
      marginBottom:'unset',
      width: '100%',
      marginRight: 'unset',
  }
 })
// Customizable Area End
