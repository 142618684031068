export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const like = require('../assets/like.png')
export const comments = require('../assets/comments.png')
export const settings = require('../assets/Settings.png')
export const searchImg = require('../assets/searchImg.png')
export const profile = require('../assets/profile.jpeg')
export const Profile1 = require('../assets/Profile1.jpeg')
export const Profile2 = require('../assets/Profile2.jpeg')
export const threeDots = require('../assets/threeDots.png')
export const share = require('../assets/share.png')
export const Prodcut = require('../assets/Product1.jpeg');
export const browser = require("../assets/browser.png");
export const redHeart = require("../assets/redHeart.png");
export const imageGiveaway = require("../assets/imageGiveaway.png");
export const backArrow = require("../assets/backArrow.png");
export const check = require("../assets/check.png");
export const back = require("../assets/backArrow.png");
export const leftArrowIcon = require("../assets/leftArrowIcon.svg");

export const dog = require("../assets/Dog.png");
export const cat = require("../assets/Cat.svg");
export const rabbit = require("../assets/Rabbits.svg");
export const birds = require("../assets/Bird.svg");
export const Reptiles = require("../assets/turtle.svg");
export const LittleCritters = require("../assets/Critter.svg");


export const dogFilled = require("../assets/DogFilled.png");
export const catFilled = require("../assets/CatFilled.png");
export const rabbitFilled = require("../assets/RabbitFilled.png");
export const birdsFilled = require("../assets/BirdFilled.png");
export const ReptilesFilled = require("../assets/tortoiseFIlled.png");
export const LittleCrittersFilled= require("../assets/LittleCritterFilled.png")

export const leftArrowIcon2 = require("../assets/leftArrowIcon2.svg");
export const MsgIcon = require("../assets/MsgIcon.svg");
export const wallpaper = require("../assets/wallpaper.png");
export const leftArrowIconWhite = require("../assets/leftArrowIconWhite.svg");

