import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";


import storage from "../../../framework/src/StorageProvider";


// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  report_reasonError: boolean;
  otherreportError: boolean
  reportDescription: string;
  reportDescriptionError: boolean;
  reportReasonError: boolean;
  selectedReportId: any;
  deleteTopiPopUp: boolean
  openReportPopup: boolean;
  selectedReportReason: string;
  commentReportableId: any
  profileData: any;
  focusUpdateInput: boolean
  totalCount: number,


  commentData: any;
  perPage: number
  openDeletePopup: boolean
  commentDeleteId: any
  snackbarMessage: string
  newToken: any;
  showSnackbar: boolean
  topicId: number | null;
  isLoading:boolean
  token: string;
  edit: boolean;
  updateId: number | null
  updateComment: string;
  topicDetail: any;
  user_id: number | null;
  errorMsg: string;
  loading: boolean;
  comment: string;
  replyCommentId: any;
  reporte_pop_up: boolean;
  report_brief: string;
  report_reason: string;
  report_reason_data: {
    name: string,
    id: number
  }[],

  // Customizable Area End
}
interface SS {
  id: any;
}

export default class TopicDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  putCommnetApiCall: string = "";
  commentApiCallId: string = "";
  postReasonReportId: string = "";
  getTopicDetailsId: string = "";
  deleteCommentApiCall: string = "";
  getReasonDetailsId: string = "";
  apiCommentItemCallId: string = "";
  deleteTopicId: string = ""
  reportCommentApiCall: string = "";
  commentApiCallWebID: string = "";
  getReportReasonsApiId: string = "";
  createCommentId: string = "";
  likeCommentId: string = "";
  postCommnetApiCall: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      topicId: null,
      topicDetail: {},
      otherreportError: false,
      profileData: {},
      openReportPopup: false,
      selectedReportReason: 'Select reason',
      reportDescription: "",
      selectedReportId: null,
      commentReportableId: null,
      user_id: null,
      reporte_pop_up: false,
      report_brief: "",
      report_reason: "Select reason",
      report_reason_data: [],
      focusUpdateInput: false,
      updateComment: "",
      openDeletePopup: false,
      commentDeleteId: null,
      snackbarMessage: "",
      showSnackbar: false,
      perPage: 10,
      commentData: [],
      isLoading:true,
      updateId: null,
      newToken: "",
      report_reasonError: false,
      loading: false,
      comment: "",
      replyCommentId: null,
      edit: false,
      deleteTopiPopUp: false,
      errorMsg: "",
      reportDescriptionError: false,
      reportReasonError: false,
      totalCount: 0,

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
 
    const infomsg = this.props.navigation?.history?.location?.state?.infomsg;
    const createTopic = await storage.get("createTopic");
    if(createTopic=="true"){
      setTimeout(() => {
        this.setState((state, props) => ({
          snackbarMessage: infomsg,
          showSnackbar: true
        }), () => {
          setTimeout(() => {
            this.setState(() => ({
  
              snackbarMessage: "",
              showSnackbar: false
            }))
            storage.set("createTopic",false)
          }, 5000);
        })
      }, 100);
    }
    const userId=await storage.get("auth_user_id")
    const id=window.location.pathname.split("/")
    const topic_id=id[id.length - 1] 
    const token= await storage.get("auth_token");
    this.setState({topicId:parseInt(topic_id),user_id:userId,token:token})
    this.getTopicDetailsData(parseInt(topic_id))
    this.getReasonData()
    // Customizable Area End
  }

 

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.getReasonDetailsId:
          this.setState({ report_reason_data: responseJson?.data })
          break;
          case this.deleteTopicId:
            this.props.navigation.navigate("PetootiCommunityFormTopic", { createTopic: true, infomsg: "TOPIC VANISHED! As swiftly as a bird taking flight, your blog topic has been successfully deleted." })
            window.scrollTo(0, 0)
  
            break;
   case this.getTopicDetailsId:

    if(responseJson?.data){this.setState({ topicDetail: responseJson?.data,isLoading:false })}
    if(responseJson?.errors && responseJson?.errors[0]=="Record not found"){
      this.props.navigation.navigate("PetootiCommunityFormTopic")
    }
  
          break;
     
        case this.postReasonReportId:
          this.setState({ reporte_pop_up: false })
          setTimeout(() => {
            this.setState((state, props) => ({
              snackbarMessage: "TOPIC REPORTED! Thanks! Your vigilance helps keep tails wagging and purrs coming!",
              showSnackbar: true
            }), () => {
              setTimeout(() => {
                this.setState(() => ({

                  snackbarMessage: "",
                  showSnackbar: false
                }))
              }, 5000);
            })
          }, 100);
          this.getTopicDetailsData(this.state.topicId)
          break;
       
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleOtherReport = async (e: any) => {
    const inputValue = e.target.value.trim();
    const maxLength = 500;

    this.setState({ report_brief: e.target.value.substring(0, maxLength) })
    if (this.state.report_reason == "Other") {
      if (inputValue.length == 0) {
        this.setState({ otherreportError: true, })
      }
      else {
        this.setState({ otherreportError: false })

      }
      const userData = await getStorageData("userDetail")
      this.setState({ profileData: JSON.parse(userData) })
    }
  }
  validationSpace = (maxLength: number, value: any) => {
    const inputValue = (typeof value === 'string') ? value.trim() : '';

    if (inputValue.length <= maxLength && inputValue.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  handleDeleteTopicClose = () => {
    this.setState({
      report_reason:"Select reason",
      report_brief:""
    })
    this.setState({ deleteTopiPopUp: false })
  } 
 
  handleReportTopicClose = () => {
    this.setState({
      report_reason:"Select reason",
      report_brief:""
    })
    this.setState({ reporte_pop_up: false })
  }
  
  handleEditTopic = () => {
    this.props.navigation.navigate("EditTopic", { id: this.state.topicId })
  }


  openDeleteTopic = () => {
    this.setState({ deleteTopiPopUp: true })

  }
  handleErroMessageReportReason = () => {
    return this.state.report_reasonError && "Please select an option"
  }
  handleBorderContent = () => {
    return this.state.otherreportError ? "1px solid #F87171" : "1px solid #CBD5E1"

  }
  handleValidation = () => {
    const errors = {
      report_reasonError: this.state.report_reason == "Select reason" ? true : false,
      otherreportError: (this.validationSpace(500, this.state.report_brief) && this.state.report_reason == "Other") ? true : false
    }
    this.setState(errors);
    const hasErrors = Object.values(errors).some(error => error);
    if (!hasErrors) {
      this.postReportTopic()
    }
  }

  handleClose = () => {
    this.setState({
      report_reason:"Select reason",
      report_reasonError:false,
      otherreportError:false,
      report_brief:""
    })
    this.setState({ reporte_pop_up: false })
  }
  handleStylePlaceholder = (state: any) => {
    return state === "Select reason" ? "#94A3B8" : "#1E293B"
  }
  async getReasonData() {
    const header = {
      "Content-Type": "application/json", token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReasonDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_communityforum/reports/report_reasons`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  async getTopicDetailsData(id: number | null) {
    const header = {
      "Content-Type":  "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopicDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_communityforum/community_forums/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async postReportTopic() {
    const header = {
      token: this.state.token,
    };
    const id = this.state.report_reason_data.find((item: { name: string, id: number }) => {
      return item?.name == this.state.report_reason
    })
    const formdata = new FormData();
    formdata.append("reportable_type", "BxBlockCommunityforum::CommunityForum")
    formdata.append("reportable_id", String(this.state.topicId))
    formdata.append("description", this.state.report_brief)
    formdata.append("report_reason_id", String(id?.id))

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postReasonReportId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_communityforum/reports`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deleteTopicApiCall = async () => {
    const header = {
      "Content-Type": "application/json",  token: this.state.token,
    };

    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteTopicId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `/bx_block_communityforum/community_forums/${this.state.topicId}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }


 


  // Customizable Area End
}
