import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    Typography,
    styled,
} from "@material-ui/core";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { InputField } from "../../email-account-registration/src/EmailAccountEmailVerification.web";
import { MainGrid, SecondGrid ,MainBox} from "../../email-account-registration/src/UserRole.web";
// Customizable Area End

class ForgotPasswordWeb extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
  
    // Customizable Area End
    render() {
        // Customizable Area Start
        const importantField = () => {
            return <span style={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "22px",
                color: "#FF5E5B"
            }}>
                *
            </span>
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>

                
                <MainGrid container>
                    <SecondGrid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <MainBox
                        >
                            <Typography style={AllStyle.heading}>
                                Forgot Password?
                            </Typography>
                            <SecondBox style={AllStyle.secondBox}>
                                <Typography style={AllStyle.smallHeading}>
                                    Welcome, we'll send a registration code to your email.
                                </Typography>
                                <Typography style={AllStyle.textStyle}>
                                    Email {importantField()}
                                </Typography>
                                <InputField placeholder="Your Email" variant="outlined" data-test-id="emailtest"
                                    style={{
                                        marginBottom: "30px",

                                    }}
                                 value={this.state.email} onChange={(e:any) => {
                                  this.handleEmail(e.target.value)
                                }} error={this.state.emailError}
                                  helperText={
                                    this.state.emailError &&
                                    (this.state.emailErrorMessage  )
                                  } 
                                />

                                <BUttonStyle variant="contained"
                                    data-test-id="button"

                                    style={AllStyle.btnStyle} onClick={() => {
                                        this.handleValidation()
                                    }}>
                                    Verify Email Address
                                </BUttonStyle>
                                <Typography data-test-id="button1" style={{
                                    fontFamily: "Lato",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "19.2px",
                                    color: "#0A2239"
                                }}>
                                    Already have an account?{" "}
                                    <span style={{ ...AllStyle.boldStyle, cursor: "pointer" }} onClick={() => {
                                        this.handleNavigateLogin() 
                                    }}>
                                        Log in
                                    </span>
                                </Typography>
                            </SecondBox>
                        </MainBox>
                    </SecondGrid>
                   
                </MainGrid>
                
            </Box>
            // Customizable Area End
        );
    }
}
export default ForgotPasswordWeb;

// Customizable Area Start



const BUttonStyle = styled(Button)({
    minWidth: "360px",

    "@media (max-width:380px)": {
        minWidth: "200px",

    },

})
const SecondBox = styled(Box)({
    maxWidth: "360px",
    "@media (max-width:390px)": {
        paddingInline: "10px"
    },
    "@media (max-width:320px)": {
        justifyContent: "center",
    },
});




const AllStyle = {

    textStyle: {
        fontSize: "14px",
        fontWeight: 700,
        marginBottom: "4px",
        color: "#0A2239",
        fontFamily: "Lato",
        lineHeight: "22px"
    },
    btnStyle: {
        borderRadius: "8px",
        height: "56px",
        fontFamily: "Lato",
        background: "#00bdab",
        color: "#FFFFFF",
        textTransform: "inherit" as "inherit",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px"
        , marginBottom: "30px",
    },
    heading: {
        color: "#0A2239",
        lineHeight: "32px",
        textAlign: "center" as "center",
        fontWeight: 700,
        fontSize: "24px",
        letterSpacing:"-0.12px",
        fontFamily: "Lato"
    },
    boldStyle: {
        color: "00bdab",
        fontSize: "16px",
        fontFamily: "Lato",
        fontWeight: 700,
        lineHeight: "24px"
    },
   

    smallHeading: {
        marginBottom: "30px",
        fontWeight: 500,
        fontSize: "16px",
        color: "#0A2239",
        lineHeight: "24px",
        fontFamily: "Lato"
    },
  
    secondBox: {
        display: "flex",
        flexDirection: "column" as "column",
        marginBlock: "30px",
        flexWrap: "Wrap" as "wrap",
    },
};

// Customizable Area End
