import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    email: string;
    emailError: boolean,
    emailErrorMessage: string;
    enablePasswordField: boolean;
    failOpenPopup: boolean;
    password: string;
    enablePasswordFieldNew: boolean;
    newPassword: string;
    message: string;
    resetImage:string
    passwordError: boolean;
    newPasswordError: boolean;
    passwordErrorMessage: string;
    newPasswordErrorMesssage: string;
    diologOpen: boolean
    forgotImage:string;
    isLoading:boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    forgotPasswordId: string = ""
    newpasswordId: string = ""

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            email: "",
            emailError: false,
            password: "",
            newPassword: "",
            forgotImage:"",
            resetImage:"",
            message: "",
            enablePasswordFieldNew: true,
            passwordError: false,
            failOpenPopup: false,
            newPasswordError: false,
            passwordErrorMessage: "",
            diologOpen: false,
            newPasswordErrorMesssage: "",
            emailErrorMessage: "",
            enablePasswordField: true,
            isLoading:false,
            // Customizable Area End
        };

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start   
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            runEngine.debugLog("TOKEN", token);
        }
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
      
        if (responseJson && !responseJson.error) {
            if (apiRequestCallId === this.forgotPasswordId) {
                this.handleResponseJson(responseJson)
            }
            else if (apiRequestCallId === this.newpasswordId) {
                if (responseJson?.messages === "Your password is changes successfully.") {
                    this.setState({ diologOpen: true })
                }
                else if (responseJson?.errors[0]?.token && responseJson?.errors[0]?.token == "Invalid token") {
                    const email = await storage.get("email")
                  this.props.navigation.navigate("ForgotPassword",{
email:email
                  })
                }
                else if (responseJson?.errors[0]?.profile && responseJson?.errors[0]?.profile == "Password change failed") {
                    this.setState({ failOpenPopup: true,message:responseJson?.errors[0]?.profile })
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
const email=this.props.navigation?.history?.location?.state?.email;

if(email&& email!==""){
    this.setState({email:email,emailError:true,emailErrorMessage:"Session expired, please try resetting password again"})
}

      }
      handleImageStyle=()=>{
   
        if(this.state.resetImage!==""){
          return {
            minHeight: 'calc(100vh - 247px)',
            maxHeight: 'calc(100vh - 247px)',
            width:"100%",
            borderRadius:"0px 0px 0px 45px",
            objectFit: 'cover' as 'cover',
            objectPosition: 'top' as 'top'
          }
        }
        else{
          return { width:"335px" }
        }
      }
    handleClose = () => {
        this.props.navigation. navigate("LandingPageWeb");

        this.setState({ diologOpen: false })
    }
    navigate = (ButtonName: string) => {
        if (ButtonName === "Login") {
            this.props.navigation.navigate("EmailAccountLoginBlock");
        }
    }

 
    handlePassword = (value: string) => {
        const special=configJSON.special
        const error = "Please enter a valid message of minimum 5 characters"
        this.setState({ password: value })
        const isLengthValid = value.length >= 5;
        if ((isLengthValid) && special.test(value)) {

            this.setState({ passwordError: false, passwordErrorMessage: "" })
            if(this.state.newPassword.length>0 &&special.test(this.state.newPassword) && this.state.newPassword!==value){
this.setState({newPasswordError: true, newPasswordErrorMesssage: "Passwords do not match" })
            }
            else if(this.state.newPassword.length>0 &&special.test(this.state.newPassword) && this.state.newPassword==value){
                this.setState({newPasswordError: false, newPasswordErrorMesssage: "" })
                            }

        }
        else if(!isLengthValid||!special.test(value)){
            this.setState({ passwordError: true, passwordErrorMessage: error })

        }

    }


    handleResponseJson=(responseJson:any)=>{
         if (responseJson?.meta && responseJson?.meta?.otp_token) {
                    this.props.navigation.navigate("ForgotPasswordAccountOtpVerify")
                    storage.set("email", this.state.email)
                    storage.set("otp_token", responseJson?.meta?.otp_token)
                    storage.set("forgot_key", true)
                    storage.remove("saveUserInfo")
                }
                else if (responseJson?.errors.length > 0) {
                    this.setState({ emailError: true, emailErrorMessage: responseJson?.errors[0]?.otp })
                }
    
    }
    failOpenFn = () => {
        this.setState({ failOpenPopup: false })
    }

    handleResetPassword = (value: string) => {
        this.setState({ newPassword: value })

        if (this.state.password === value) {
            this.setState({ newPasswordError: false, newPasswordErrorMesssage: "" })
            return true
        }
        else {
            this.setState({ newPasswordError: true, newPasswordErrorMesssage: "Passwords do not match" })
            return false
        }
    }
    handleEmail = (value: string) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if(value===""){
          this.setState({
            emailError: true,
            email: value,
            emailErrorMessage:"Please enter a value"
          });
          return false
        }
        else if (!value.match(emailPattern)) {
          this.setState({
            emailError: true,
            email: value,
            emailErrorMessage:"Please enter a valid email address"
          });
          return false
        } else {
          this.setState({
            emailError: false,
            email: value,
            emailErrorMessage:""
          });
          return true
        }
    }

    handleVisiblPassword = () => {
        return this.state.enablePasswordField ? "password" : "text"
    }
    handleVisiblNewPassword = () => {
        return this.state.enablePasswordFieldNew ? "password" : "text"
    }
    getForgotPassword = async () => {
        const header = {
            "Content-Type": configJSON.forgotPasswordAPiContentType,
        };
        const body = {
            "data": {
                "otp_type": "forgot_password",
                "attributes": {
                    "email": this.state.email

                }
            }
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.forgotPasswordId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_forgot_password/otps`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleValidationPassword = () => {
        const checkEmpty = this.state.password === "";
        const error = "Please enter a valid password"
        if (checkEmpty) {
            this.setState({ newPasswordError: false, passwordError: true, passwordErrorMessage: error })
        }
        else if (this.state.password !== this.state.newPassword) {
            this.setState({ newPasswordError: true, newPasswordErrorMesssage: "Passwords do not match" })
        }
        else if (!this.state.passwordError && !this.state.newPasswordError) {
            this.getNewPassword()
        }

    }

    getNewPassword = async () => {
        this.setState({isLoading:true})
        const header = {
            "Content-Type": configJSON.forgotPasswordAPiContentType,
            "token": await storage.get("token")
        };
        const body = {
            "data": {
                "token": await storage.get("otp_token"),
                "new_password": this.state.password,
                "confirm_password":this.state.newPassword
            }
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.newpasswordId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_forgot_password/passwords`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    handleNavigateLogin = () => {
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    handleValidation = () => {
        const check = this.handleEmail(this.state.email);
        if (check) {
            this.getForgotPassword()
        }
    }
    // Customizable Area End
}
