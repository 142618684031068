export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const slider1 = require("../assets/slider-1.jpg");
export const slider2 = require("../assets/slider-2.jpg");
export const slider3 = require("../assets/slider-3.jpg");
export const dummyProduct = require("../assets/dummy-image.jpg");
export const postOwner = require("../assets/postOwner.jpg")
export const rightDropdown=require("../assets/rightDropdown.svg")
export const radiobutton_=require("../assets/radiobutton_.svg")
export const camera=require("../assets/camera.svg")
export const video=require("../assets/videoIcon.svg")
export const RightIcon=require("../assets/rightIcon.svg")
export const fillcheckBoxRadio=require("../assets/fillcheckBoxRadio.svg")
export const crossIcon=require("../assets/crossIcon.svg")
export const document2=require("../assets/document.svg")
export const crossIconPlan=require("../assets/crossIconPlan.svg")
export const iconInfo=require("../assets/iconInfo.svg")
export const documentIcon=require("../assets/documentIcon.svg")
export const starIcon=require("../assets/starIcon.svg")
export const fillStarIcon=require("../assets/fillStarIcon.svg")
export const calender=require("../assets/calender.svg")
export const VideoPlayIcon=require("../assets/VideoPlayIcon.svg")
export const heartFill=require("../assets/heartFill.svg")
export const heart=require("../assets/heart.svg")
export const closeIconSvg = require("../assets/closeIconSvg.svg");
export const Wallpaper=require("../assets/wallpaper.png")
export const ArrowDown=require("../assets/ArrowDown.svg")




