import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Typography,
    Grid,
    IconButton,
    Button,
    Dialog,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { leftArrowIcon2 } from "../../communityforum/src/assets";
import CommonSnackbar from "../../../components/src/CommonSnackbar.web";
import { Close as CloseIcon } from "@material-ui/icons";
import { right } from "./assets";
import moment from "moment";

// Customizable Area End

import NotificationDetailsController, {
    Props,
} from "./NotificationDetailsController";

class NotificationDetails extends NotificationDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box>
                <MainGrid container>
                    <Container>
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            <NotificationView>
                                <Box style={{ display: "flex", alignItems: 'flex-start', }}>

                                    <TypographyStyle2 style={{ ...Allstyle.hedingStyle, cursor: "pointer", }}
                                        data-test-id="navigate"
                                        onClick={() => {
                                            this.props.navigation.navigate("LandingPageWeb")
                                        }}>
                                        Home
                                    </TypographyStyle2>

                                    <img src={leftArrowIcon2} style={{ margin: '2px' }} />
                                    <TypographyStyle
                                        style={{
                                            ...Allstyle.hedingStyle,
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            this.props.navigation.navigate("NotificationWeb")
                                        }}
                                    >
                                        Notifications
                                    </TypographyStyle>

                                    <img src={leftArrowIcon2} style={{ margin: '2px' }} />
                                    <TypographyStyle
                                        style={{
                                            ...Allstyle.hedingStyle,
                                            color: "#0F172A",
                                            cursor: "pointer"
                                        }}
                                    >
                                        Notification Details
                                    </TypographyStyle>
                                </Box>
                                <ActionContainer>
                                    <TypographyStyle
                                        style={{
                                            ...Allstyle.markAsReadStyle,
                                            cursor: "pointer"
                                        }}
                                        data-test-id="mark-as-unread"
                                        onClick={() => {
                                            if (this.state.showNotificationData.attributes.is_read) {
                                                this.markAsUnReadNotification(this.state.showNotificationData.attributes.id)
                                            }
                                        }}
                                    >
                                        Mark as unread
                                    </TypographyStyle>
                                    <TypographyStyle
                                        style={{
                                            ...Allstyle.deleteStyle,
                                            cursor: "pointer",
                                            marginLeft: '24px'
                                        }}
                                        data-test-id="delete-popup"
                                        onClick={() => this.openDeleteNotification()}
                                    >
                                        Delete
                                    </TypographyStyle>
                                </ActionContainer>
                            </NotificationView>
                            <NotificationContainer >
                                <NotificationHeader>
                                    <Typography style={userStyles.notedText}>{this?.state?.showNotificationData?.attributes?.headings}</Typography>
                                    <Typography style={userStyles.notedLightText}>{moment(this?.state?.showNotificationData?.attributes?.created_at).format("ddd, DD MMM, h:mm a")}</Typography>
                                </NotificationHeader>
                                <Box style={this?.state?.showNotificationData?.attributes?.app_url ? userStyles.notificationCenter : {marginBottom: "134px"}}>
                                    <Typography style={userStyles.notedMainText}>
                                        {this?.state?.showNotificationData?.attributes?.contents}
                                    </Typography>
                                </Box>
                                {this?.state?.showNotificationData?.attributes?.app_url ? <Box data-test-id="url-redirect" onClick={() => {this.handleNavigation()}} style={userStyles.notificationButton}>
                                    <ViewButtion>View details
                                        <img src={right} style={{ width: "6.59px", height: "11.17px", marginLeft: "8px", marginRight: "10px" }} />
                                    </ViewButtion>
                                </Box> : <></>}
                            </NotificationContainer>
                        </Box>
                    </Container>
                </MainGrid>
                <CommonSnackbar data-test-id='commonSnackBar' handleCloseFunc={()=>this.setState({isShowSnackbar:false})} open={this.state.isShowSnackbar} message={this.state.isSnackbarMessage} />
                <DiologStyle
                    aria-labelledby="customized-dialog-title"
                    data-test-id="closeId"
                    open={this.state.deletePopUp}
                    onClose={this.handleDeleteNotificationClose}
                >
                    <Box style={{
                        padding: "16px 16px 12px 40px ",
                        justifyContent: "flex-end",
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <IconButton aria-label="close"
                            onClick={this.handleDeleteNotificationClose}
                            style={{ padding: "4px", color: "#0A2239" }}
                            data-test-id="closeId2"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box style={{ marginInline: "40px" }}>
                        <Typography style={{
                            fontFamily: "Inter",
                            fontSize: "24px",
                            letterSpacing: " -0.12px",
                            color: "#0F172A",
                            marginBottom: "12px",
                            lineHeight: "32px",
                            fontWeight: 700,
                        }}>
                            DELETE NOTIFICATION?
                        </Typography>
                        <Typography style={{
                            fontWeight: 400,
                            color: "#334155",
                            fontSize: "18px",
                            lineHeight: "160%",
                            marginBottom: "24px",
                            fontFamily: "Inter",
                        }}>
                            Proceeding with this action will permanently remove the selected notification.
                        </Typography>
                    </Box>
                    <hr style={{
                        border: 'none', margin: "0px",
                        width: '100%',
                        height: '1px', backgroundColor: '#e2e8f0',
                    }} />

                    <Box style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: "24px",
                        flexWrap: "wrap",
                        gap: "16px",
                    }}>
                        <ButtonComponent
                            data-test-id="closeId3"
                            style={{ width: "140px", height: "56px", border: "1px solid #03B5AA", color: "#03B5AA" }}
                            onClick={this.handleDeleteNotificationClose}
                        >
                            {"Cancel"}
                        </ButtonComponent>
                        <Button
                            style={{
                                fontFamily: "Inter", width: "151px", height: "56px",
                                ...webstyle.BtnGreen, marginLeft: "0px"
                            }}
                            data-test-id="agree"
                            onClick={() => this.deleteNotification(this.state.showNotificationData.attributes.id)}
                        >
                            {"Delete"}
                        </Button>
                    </Box>
                </DiologStyle>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start


export const TypographyStyle = styled(Typography)({
    '@media ( max-width : 550px )': {
        paddingInline: "unset"
    },
    paddingInline: "6px",
})

const MainGrid = styled(Grid)({
    "@media (max-width:722px)": {
        marginTop: "20px",
    },
    minHeight: "calc(100vh - 232px)",
});

export const TypographyStyle2 = styled(Typography)({
    '@media ( max-width : 550px )': {
        paddingRight: "unset"
    },
    paddingRight: "6px",
})

const ButtonComponent = styled(Button)({
    '@media (max-width: 418px)': {
        minWidth: "151px"
    },
})

export const notificationStyles = ({
    container: {
        width: "772px",
        margin: 'auto',
        marginBottom: "134px"
    },
});
export const ViewButtion = styled(Button)({
        borderRadius: "8px",
        border: "1px solid #03B5AA",
        fontSize: "16px",
        color: "#03B5AA",
        display: "flex",
        alignItems: "center" as const,
        justifyContent: "center" as const,
        fontFamily: "Lato",
        fontWeight: 700,
        lineHeight: "24px",
        padding: "10px 16px 10px 24px",
        gap: "8px",
        textTransform: "none"
})

const DiologStyle = styled(Dialog)({
    "& .MuiDialog-paperWidthSm": {
        maxWidth: "600px",
        minWidth: "600px",
        borderRadius: "8px 8px 32px 8px"
    },
    "@media (max-width:722px)": {
        "& .MuiDialog-paperWidthSm": {
            minWidth: "unset",
            borderRadius: "8px 8px 32px 8px"
        },
    },
})

const NotificationContainer = styled(Box)({
    width: "848px",
    margin: 'auto',
    '@media (max-width: 900px)': {
      width: '100%',
    },

  })
  const NotificationHeader = styled(Box)({
    display: "flex",
        alignItems: "baseline" as const,
        justifyContent: "space-between" as const,
        marginBottom: "24px",
        '@media (max-width: 563px)': {
            flexDirection: 'column'
        },
  })

  const NotificationView = styled(Box)({
    display: "flex", 
    justifyContent: 'space-between', 
    margin: '48px 0px 48px 0px',
    
    '@media (max-width: 573px)': {
       flexDirection: 'column'
      },
  })

  const ActionContainer = styled(Box)({
    display: 'flex', 
    alignItems: 'center',
  })

export const Allstyle = {
    hedingStyle: {
        fontSize: "14px",
        color: "#475569",
        fontWeight: 400,
        fontFamily: "Lato",
        lineHeight: "22px",
    },
    markAsReadStyle: {
        fontFamily: "Inter",
        fontFize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#64748B"
    },
    deleteStyle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        color: "#DC2626"
    }
};

export const webstyle = {
    BtnGreen: {
        background: '#03B5AA',
        border: '1px solid #03B5AA',
        borderRadius: 8,
        padding: "10px 16px 10px 12px",
        lineHeight: '24px',
        textTransform: 'capitalize',
        fontWeight: 700,
        fontSize: 16,
        fontFamily: "Lato",
        color: '#fff',
        marginLeft: 15,
        minWidth: 137,

    } as React.CSSProperties,
}
const userStyles = {
    notedText: {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Lato",
        lineHeight: "32px",
        letterSpacing: "-0.005em",
        color: "#1E293B"
    },
    notedLightText: {
        display: "flex",
        alignItems: "flex-end" as const,
        fontSize: "14px",
        fontWeight: 400,
        color: "#64748B",
        fontFamily: "Inter",
        lineHeight: "16.94px",
    },
    notificationCenter: {
        width: "100%",
    },
    notedMainText: {
        fontSize: "18px",
        fontWeight: 400,
        fontFamily: "Inter",
        lineHeight: "32.4px",
        color: "#1E293B"
    },
    notedMainText2: {
        fontSize: "18px",
        fontWeight: 400,
        marginTop: "24px",
        fontFamily: "Inter",
        lineHeight: "32.4px",
        color: '#1E293B'
    },
    notificationButton: {
        display: "flex",
        justifyContent: "center" as const,
        alignItems: "center" as const,
        marginTop: "48px",
        marginBottom: "134px"
    },
}
export default NotificationDetails

// Customizable Area End
