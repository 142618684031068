import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    Grid,
    IconButton,
    Snackbar,
    Typography,
    styled,
    Backdrop,
} from "@material-ui/core";
import ForgotPasswordController, { Props } from "./ForgotPasswordController.web";
import { InputField } from "../../email-account-registration/src/EmailAccountEmailVerification.web";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { DiologComponent } from "../../email-account-registration/src/EmailAccountForm.web";
import { MainGrid, SecondGrid ,MainBox} from "../../email-account-registration/src/UserRole.web";
import {Loader2} from "../../../components/src/Loader.web";

// Customizable Area End

class ResetPasswordWeb extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    handleReturnSnackBar=()=>{
        return<>
        <Backdrop
        open={this.state.diologOpen}
        style={{ zIndex: 700 }}
        data-test-id='backdrop1'
    
      ><SnackBarStyle
        anchorOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        open={this.state.diologOpen}
        onClose={()=>this.setState({diologOpen:false},()=>this.handleNavigateLogin()) }
        data-test-id='snackbar1'
        autoHideDuration={3000}
        style={{ zIndex: 1000 }}
    
      >
          <Typography style={{...AllStyle.popup as React.CSSProperties}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
            </svg>
            PASSWORD RESET SUCCESSFUL! Time to strut your stuff with your new creditials.
          </Typography>
        </SnackBarStyle>
        </Backdrop> </>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const importantField = () => {
            return <span style={{
                lineHeight: "22px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#FF5E5B"
            }}>
                *
            </span>
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                        <Loader2 loading={this.state.isLoading } />

                <Snackbar
                    anchorOrigin={{
                        horizontal: "center",
                        vertical: "top",
                    }}
                    autoHideDuration={5000}
                    onClose={this.failOpenFn}
                    open={this.state.failOpenPopup}
                >
                    <div style={AllStyle.failPopUp as React.CSSProperties}>
                        {this.state.message}
                    </div>
                </Snackbar>
                {this.handleReturnSnackBar()}
                <MainGrid container>
                    <SecondGrid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <MainBox
                        >
                            <Typography style={AllStyle.heading}>
                                Set New Password
                            </Typography>
                            <SecondBox style={AllStyle.secondBox}>
                                <Typography style={AllStyle.smallHeading}>
                                    Please enter the new password for your account
                                </Typography>
                                <Typography style={AllStyle.textStyle}>
                                    New Password {importantField()}
                                </Typography>
                                <InputField
                                    error={this.state.passwordError}
                                    helperText={this.state.passwordError && this.state.passwordErrorMessage}
                                    placeholder={"Password"}
                                    data-test-id="txtInputPassword"
                                    type={this.handleVisiblPassword()}
                                    fullWidth={true}
                                    value={this.state.password}
                                    variant="outlined"
                                    onChange={(e: any) => {
                                        this.handlePassword(e.target.value)
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => { this.setState({ enablePasswordField: !this.state.enablePasswordField }) }}
                                                edge="end"
                                            >
                                                {this.state.enablePasswordField ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        ),
                                    }}
                                />
                                <Typography style={AllStyle.textStyle}>
                                    Confirm New Password {importantField()}
                                </Typography>
                                <InputField
                                   InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => { this.setState({ enablePasswordFieldNew: !this.state.enablePasswordFieldNew }) }}
                                            edge="end"
                                        >
                                            {this.state.enablePasswordFieldNew ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    ),
                                }}
                                    style={{ marginBottom: "30px" }}
                                    onChange={(e: any) => {
                                        this.handleResetPassword(e.target.value)
                                    }}
                                    variant="outlined"
                                    error={this.state.newPasswordError}
                                    helperText={this.state.newPasswordError && this.state.newPasswordErrorMesssage}
                                    placeholder={"Password"}
                                    data-test-id="txtInputPassword2"
                                    type={this.handleVisiblNewPassword()}
                                    fullWidth={true}
                                    value={this.state.newPassword}
                                 
                                />
                                <BUttonStyle variant="contained"
                                    data-test-id="button"

                                    style={AllStyle.btnStyle} onClick={() => {
                                        this.handleValidationPassword()
                                    }}>
                                    Set New Password
                                </BUttonStyle>
                                <Typography style={{
                                    fontSize: "16px",
                                    fontFamily: "Lato",
                                    lineHeight: "19.2px",
                                    fontWeight: 400,
                                    color: "#0A2239"
                                }}>
                                    Already have an account?{" "}
                                    <span style={{ ...AllStyle.boldStyle, cursor: "pointer" }} data-test-id="button1" onClick={() => {
                                        this.handleNavigateLogin()
                                    }}>
                                        Log in
                                    </span>
                                </Typography>
                            </SecondBox>
                        </MainBox>
                    </SecondGrid>
                  
                </MainGrid>
                
            </Box>
            // Customizable Area End
        );
    }
}
export default ResetPasswordWeb;

// Customizable Area Start

const BUttonStyle = styled(Button)({
    minWidth: "360px",

    "@media (max-width:380px)": {
        minWidth: "200px",

    },

})




const SecondBox = styled(Box)({
    maxWidth: "360px",
    "@media (max-width:390px)": {
        paddingInline: "10px"
    },
    "@media (max-width:320px)": {
        justifyContent: "center",
    },
});



const AllStyle = {
    btnStyle: {
        borderRadius: "8px",
        height: "56px",
        background: "#00bdab",
        textTransform: "inherit" as "inherit",
        color: "#FFFFFF",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px"
        , marginBottom: "30px",
    },
    failPopUp: {
        alignItems: "center",
        backgroundColor: "#ffffff",
        color: "#0F172A",
        width: "362px",
        height: "42px",
        borderRadius: "4px",
        display: "flex",
        fontWeight: 400,
        textAlign:"center",
        fontFamily:"Lato",
        fontSize: "16px",
        lineHeight: "24px",
        justifyContent:"center",
        paddingLeft: "10px",
        paddingRight: "10px"
      },
    secondBox: {
        flexDirection: "column" as "column",
        display: "flex",
        flexWrap: "Wrap" as "wrap",
        marginBlock: "30px",
    },
    textStyle: {
        fontSize: "14px",
        fontWeight: 700,
        marginBottom: "4px",
        color: "#0A2239",
        fontFamily: "Lato",
        lineHeight: "22px"
    },

    heading: {
        color: "#0A2239",
        lineHeight: "32px",
        textAlign: "center" as "center",
        fontWeight: 700,
        fontSize: "24px",
        letterSpacing: "-0.12px",
        fontFamily: "Lato"
    },
    smallHeading: {
        color: "#0A2239",
        fontFamily: "Lato",
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "30px",
        fontWeight: 500,
    },
    boldStyle: {
        fontFamily: "Lato",
        color: "00bdab",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px"
    },
    popup: {
        backgroundColor: "#ffffff",
        gap:"8px",
        display: "flex",
       
        textAlign:"center",
        borderRadius: "8px",
        color: "#0F172A",
        padding:"9px 16px 9px 8px ",
        
        fontSize: "16px",
        fontFamily: "Inter",
        fontWeight: 400,
        boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
    
        lineHeight: "24px",
      
      },
    

};

const SnackBarStyle = styled(Snackbar)({
    "&.MuiSnackbar-anchorOriginTopCenter":{
      top:"113px",
    }
    })
// Customizable Area End
