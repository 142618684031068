import React, { Component } from "react";
import { Box, Button, Typography,Grid,styled } from "@material-ui/core";
import { BottonComponentCom } from "../../blocks/productdescription/src/CreateRescuerPostpage.web";
const empty=require("./image_(1).svg")
const emptyFav=require("./emptyFav.svg")
const emptyImgSearchResult=require("./emptyImgSearchResult.svg")
const emptyTopic
=require("./emptyTopic.svg")

interface Props {
  navigation?: any;
  bottom?:string;
  image?:string;
  heading?:string
}
export default class EmptyPost extends Component<Props> {
 
    render() {
      const image=this.props.image!==""?this.props.image:empty

        return (
            <Box>
                  <HomeGrid style={{ ...Allstyle.headingBoxStyle }}>
            <Typography style={{ ...Allstyle.hedingStyle, paddingInline: "6px" ,cursor:"pointer"}} onClick={()=>{
          this.props.navigation("LandingPageWeb")

            }}>
              Home
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
                fill="#64748B"
              />
            </svg>

            <Typography
              style={{
                ...Allstyle.hedingStyle,
                color: "#0F172A",
                paddingInline: "6px",
              }}
            >
              My Posts
            </Typography>
          </HomeGrid>
          <Box style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginInline:'10px'}}>

                <img src={image} alt="Empty" style={{marginBottom:"32px",width:"257px"}}/>
                <Typography style={{
                    color: "#000",
                    fontFamily: "Lato",
                    fontSize: "24px",
                    textAlign:'center',
                    fontWeight: 700,
                    lineHeight: "32px",
                    letterSpacing: "-0.12px",
                    marginBottom:"8px"
                }}>
                    {'You have not created any posts yet!'}
                </Typography>
                <Box style={{ maxWidth: "373px",marginBottom:"20px",display:"flex",alignItems:"center",justifyContent:"center",textAlign:"center" }}>
                    <Typography
                        style={{
                            color: "#475569",
                            fontFamily: "Lato",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "150%",
                        }}>
                       {' Please click on the button below to create a new post and publish it for fellow pet lovers.'}
                    </Typography>
                </Box>
  <BottonComponentCom variant="contained" style={{ ...Allstyle.btnStyle }} onClick={()=>{
                  this.props.navigation("CreateNewPostpage",{postDetails:true})
                }}>
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                  fill="white"
                />
              </svg>
              Add new post
                </BottonComponentCom>
          </Box>
            </Box>
        );
    }
}
export  class EmptyPostCategori extends Component<Props> {
 
    render() {
      console.log(this.props.image,"this.props.image");
      
      const image=this.props.image!=="" && this.props.image!==undefined ?this.props.image:emptyImgSearchResult
        return (
            <Box>
          <Box style={{ marginBottom:this.props.bottom,display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>

                <img src={image} alt="Empty" style={{marginBottom:"32px",width:"288px"}}/>
                <Typography style={{
                    color: "#000",
                    fontFamily: "Lato",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "32px",
                    letterSpacing: "-0.12px",
                    marginBottom:"8px"
                }}>
                    {'No results found!'}
                </Typography>
                <Box style={{ maxWidth: "373px",marginBottom:"20px",display:"flex",alignItems:"center",justifyContent:"center",textAlign:"center" }}>
                    <Typography
                        style={{
                            color: "#475569",
                            fontFamily: "Lato",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "150%",
                        }}>
                     No matching results for your search. Please try again with different keywords.
                    </Typography>
                </Box>
             
          </Box>
            </Box>
        );
    }
}
export class EmptySerach extends  Component<Props> {
    render() {
      const image=this.props?.image!==""?this.props.image:empty;
      
        return (
         
          <Box style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginBlock:"136px"}}>

                <img src={image} style={{marginBottom:"32px",width:"257px"}}/>
                <Typography style={{
                    color: "#000",
                    fontFamily: "Lato",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "32px",
                    letterSpacing: "-0.12px",
                    marginBottom:"8px"
                }}>
                {this.props.heading}
                </Typography>
              
          </Box>
        );
    }
}
export class EmptySearchCommunity extends  Component<Props> {
  render() {
    
      return (
       
        <Box style={{display:'flex', flexDirection:"column", alignItems:"center", justifyContent:"center", minHeight:"600px", marginBottom:"120px"}}>

              <img src={empty} style={{marginBottom:"32px",width:"257px"}}/>
              <Typography style={{
                  color: "#000",
                  fontFamily: "Lato",
                  textAlign:"center",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "32px",
                  letterSpacing: "-0.12px",
              }}>
              {this.props.heading}
              </Typography>
            
        </Box>
      );
  }
}

export class EmptyFav extends Component<Props>{
  render() {
    const image=this.props.image!==""?this.props.image:emptyFav;
    return (
      <Box style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:"59px ",marginBottom:"61px "}}>

            <img src={image} alt="Empty" style={{marginBottom:"23px",width:"257px"}}/>
            <Typography style={{
                   color: "#1E293B",
                   fontFamily: "Inter",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "32px",
                textAlign:"center",
                letterSpacing: "-0.12px",
                marginBottom:"8px"
            }}>
             You have not added anything yet!
            </Typography>
            <Box style={{
                maxWidth:"400px",
                textAlign:"center"

            }}>
            <Typography style={{
                color: "#475569",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "150%",
                marginBottom:"20px",
            }}>
             Feel free to click on the button below to explore adorable pets and add them to your favorites.
            </Typography>
            </Box>
            <BottonComponentCom variant="contained" style={{ ...Allstyle.btnStyle }} onClick={()=>{
                this.props.navigation("SearchAdvance")
            }}>
                
              Explore Pets
                </BottonComponentCom>
          
      </Box>
    );
}
}
export class EmptyTopic extends Component<Props>{
  render() {
    return (
      <Box style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:"59px ",marginBottom:"61px "}}>

            <img src={emptyTopic} alt="Empty" style={{marginBottom:"23px",width:"257px"}}/>
            <Typography style={{
                   color: "#1E293B",
                   fontFamily: "Inter",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "32px",
                textAlign:"center",
                letterSpacing: "-0.12px",
                marginBottom:"8px"
            }}>
           No topics are created yet!
            </Typography>
            <Box style={{
                maxWidth:"574px",
                textAlign:"center"

            }}>
            <Typography style={{
                color: "#475569",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "150%",
                marginBottom:"20px",
            }}>
             Feel free to click on the button below to start sharing your thoughts and experiences with fellow pet enthusiasts!
            </Typography>
            </Box>
            <BottonComponentCom variant="contained" style={{ ...Allstyle.btnStyle,marginBottom:"167px" }} onClick={()=>
              this.props.navigation("CreateNewTopic")
            }>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" fill="white"/>
</svg>
                
             < span style={{marginLeft:"8px"}}>  Create topic</span>
                </BottonComponentCom>
          
      </Box>
    );
}
}
 const HomeGrid = styled(Grid)({
    marginTop: "48px",
    marginBottom:"96px",
    maxWidth: "1168px",
    marginInline: "auto",
    '@media(max-width: 1209px)': {
      maxWidth: "unset",
      marginInline: "20px",
    },
    '@media(max-width: 400px)': {
      maxWidth: "unset",
      marginInline: "10px",
    },
  
  })
const Allstyle = {
    headingBoxStyle: {
      display: "flex" as "flex",
      flexDirection: "row" as "row",
      flexWrap:"wrap" as "wrap"
    },
    hedingStyle: {
      color: "#475569",
      fontFamily: "Lato",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
    },
   
    btnStyle: {
      color: "#FFF",
      marginBottom:"120px",
      fontFamily: "Lato" as "Lato",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
      height: "44px",
      width: "163px",
      borderRadius: "8px",
      padding: "10px 16px",
      background: "#03B5AA",
      textTransform: "inherit" as "inherit",
    },
  };


