import React from "react";
import {
  // Customizable Area Starst
  Typography,
  Box,
  Divider,
  Avatar,
  IconButton,
  Button,
  TextareaAutosize,
  MenuItem,
  Select,
  FormControl,
  Dialog
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import CommonSnackbar from '../../../components/src/CommonSnackbar.web'


import {
  Close as CloseIcon, KeyboardArrowDown,
} from "@material-ui/icons";
import {  styled, withStyles, } from "@material-ui/core/styles";
import moment from "moment";


import { HomeGrid } from "../../email-account-login/src/CataloguePage.web";
import { leftArrowIcon2 } from "../../communityforum/src/assets";
import { Allstyle } from "../../favourites/src/FavouritesWeb.web";
import { MainBox } from "../../communityforum/src/CreateNewTopic.web";
import { DiologStyle, webstyle } from "../../productdescription/src/ProductDescription.web";

import {deleteIcon, editIcon, flag } from "../../comments/src/assets";
import TopicDetailsController ,{ Props }  from "./TopicDetailsController.web";

import Comments, { TypographyStyle,TypographyStyle2 } from "../../comments/src/CreateComment.web";
export const commentStyle = {
  userNameStyle: {
    fontWeight: 400,
    color: "#1E293B",
    lineHeight: "26px",
    fontFamily: "Lato",
    fontSize: "18px",
  },
  ReportStyle: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#334155",
    fontFamily: "Inter",

  },
  ReportAlreadyStyle: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#DC2626",
    fontFamily: "Lato",
  },
  subheadingStyle: {
    color: "#334155",
    fontWeight: 500,
    lineHeight: "22px",
    fontSize: "14px",
    fontFamily: "Inter",
  },
  errorNameStyle: {
    color: "#DC2626",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px",
    fontSize: "12px",
  },
  headingStyle: {
    fontSize: "30px",
    wordBreak: "break-word" as"break-word",

    lineHeight: "140%",
    letterSpacing: "-0.15px",
    color: "#1E293B",
    fontWeight: 600,
    marginBottom: "16px",
    fontFamily: "Inter",
  },
  categoryStyle: {
    fontSize: "16px",
    color: "#64748B",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  descriptionStyle: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "29px",
    marginBottom: "48px",
    color: "#334155",
    wordBreak: "break-word" as"break-word",

    fontFamily: "Inter",
  },
  wrritenStyle: {
    fontSize: "16px",
    color: "#64748B",
    fontFamily: "Inter",
    fontWeight: 500,
    textTransform: "uppercase" as "uppercase",
    marginBottom: "24px",
    letterSpacing: " 0.32px",
    lineHeight: "24px",
  },

}
const BoxStyle=styled(Box)({
  display:"flex",justifyContent:"space-between",marginTop:"16px",marginBottom:"40px",
  '@media (max-width:650px )': {
    gap:"15px",
    flexWrap:"wrap",
  },

})
// Customizable Area End

export default class TopicDetails extends TopicDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  starIcon = () => {
    return <span style={{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#FF5E5B"
    }}>
      {" "}    *
    </span>
}

handleProfile=()=>{
  return   this.state.topicDetail?.attributes?.account?.image_url?  <Avatar  style={{ border:"2px solid #F0E5FF"}}src={this.state.topicDetail?.attributes?.account?.image_url}/>
  :
  <Avatar style={{ background:"white",fontSize:"20px",color:"#03B5AA",border:"2px solid #CDF0EE"}} >
                        {this.state.topicDetail?.attributes?.account.full_name[0].toUpperCase()}
                        </Avatar>
}


                        handleNameTopic=()=>{
                          return this.state.topicDetail?.attributes?.account?.id ==this.state.user_id?"Me": this.state.topicDetail?.attributes?.account?.full_name?.split(" ")[0]
                        }
handlereportDetails=()=>{
  return this.state.topicDetail?.attributes?.is_reported_by_me?
  <Typography 
 
  style={commentStyle.ReportAlreadyStyle}>
Topic is reported
   </Typography>
  :< Box  
  id="report"
  onClick={()=>{
    this.setState({reporte_pop_up:true})
   }}
   style={{display:"flex",flexDirection:"row",gap:"4px"}}
   >
   <img src={flag} style={{cursor:"pointer"}}/>
<Typography 
  
   style={{...commentStyle.ReportStyle,cursor:"pointer"}}>
 Report topic
    </Typography>
  </Box>
}
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        
        <Loader loading={this.state.isLoading } />

        <HomeGrid style={{ minHeight: 'calc(100vh - 247px)',display:"block" }}>
        <Box style={{ ...Allstyle.headingBoxStyle,alignItems:"center",justifyContent:"space-between" }}>
          <Box style={{display:"flex",flexWrap:"wrap",alignItems:"center"}}>

        <TypographyStyle2 style={{ ...Allstyle.hedingStyle, cursor: "pointer" ,}}
        data-test-id="navigate"
         onClick={() => {
          this.props.navigation.navigate("LandingPageWeb")
        }}>
          Home
        </TypographyStyle2>
      
<img src={leftArrowIcon2}/>
        <TypographyStyle
          style={{
            ...Allstyle.hedingStyle,
            cursor:"pointer"
          }}
        data-test-id="PetootiCommunity"

          onClick={() => {
            this.props.navigation.navigate("PetootiCommunity")
          }}
        >
         Community Chat Room
        </TypographyStyle>
        <img src={leftArrowIcon2}/>
        <TypographyStyle
          style={{
            ...Allstyle.hedingStyle,
            cursor:"pointer",
          }}
        data-test-id="PetootiCommunityFormTopic"

          onClick={() => {
            this.props.navigation.navigate("PetootiCommunityFormTopic")
          }}
        >
        Topics
        </TypographyStyle>
        <img src={leftArrowIcon2}/>
        <TypographyStyle
          style={{
            ...Allstyle.hedingStyle,
            color:"#0F172A",
           
          }}
        >
         Topic Details
        </TypographyStyle>
          </Box>
         
     
      </Box>
          <MainBox style={{ marginTop: "55px", position: "relative" }}>
            {this.state.topicDetail?.attributes?.account?.id == this.state.user_id && <ButtonComponentBox>
              <ButtonComponent style={{ 
               }}
                data-test-id="handleEditTopic"

                onClick={this.handleEditTopic}>
                <img src={editIcon} />
                <span style={{ marginLeft: "8px" }} >

                  {"Edit topic"}
                </span>
              </ButtonComponent>
              <ButtonComponent
                data-test-id="openDeleteTopic"

                style={{ width: "148px", color: "#FF5E5B",
                borderRadius: "8px",
                fontFamily: "Lato",
                fontSize: "16px",
                height: "44px",
                fontWeight: 700,
                lineHeight: "24px",
                textTransform: "inherit",
                border: "1px solid #CBD5E1", }} onClick={this.openDeleteTopic}>
                <img src={deleteIcon} />
                <span style={{ marginLeft: "8px" }}>
                  {"Delete topic"}
                </span>
              </ButtonComponent>

            </ButtonComponentBox>}
            <Typography style={commentStyle.headingStyle}>
            {this.state.topicDetail?.attributes?.name}
            </Typography>
            <BoxStyle 
            >
              <Box style={{display:"flex",alignItems:"center",gap:"12px"}}>

              <Typography style={commentStyle.categoryStyle}>
                {this.state.topicDetail?.attributes?.community_category?.name}

              </Typography>
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5" fill="none">
  <circle cx="2" cy="2.5" r="2" fill="#64748B"/>
</svg>
<Typography style={{...commentStyle.categoryStyle,marginRight:"35px"}}>
                {this.state.topicDetail?.attributes?.animal_type?.name || this.state.topicDetail?.attributes?.custom_animal_type}

              </Typography>
            </Box>
              <Typography style={commentStyle.categoryStyle}>
                Created on : {moment(this.state.topicDetail?.attributes?.created_at).format("DD MMM, YYYY")}
              </Typography>
            </BoxStyle>

         <div  style={commentStyle.descriptionStyle} dangerouslySetInnerHTML={{ __html: this.state.topicDetail?.attributes?.content.replace(/\r\n/g, "<br>") }} />
        <Box style={{display:"flex",flexDirection:'column',marginBottom:"56px"}}>

        <Typography style={commentStyle.wrritenStyle}>
        Written by
        </Typography>
        <Box style={{display:"flex",flexWrap:"wrap",flexDirection:"row",alignItems:'center',justifyContent:"space-between"}}>
          <Box style={{display:'flex',flexDirection:"row",alignItems:"center",gap:"12px"}}>
       {  this.handleProfile()}
        <Typography style={commentStyle.userNameStyle}>
          {this.handleNameTopic()}
       </Typography>
          </Box>
          { (
          //@ts-ignore
            this.state.topicDetail?.attributes?.account?.id !== parseInt( this.state.user_id ) )  && <Box style={{display:'flex',flexDirection:"row",alignItems:"center",gap:"4px"}}>
          { this.handlereportDetails()
            }
          </Box>}
        </Box>
        </Box>
        </MainBox>
      <Comments navigation={this.props.navigation} id={""}/>
        </HomeGrid>
        <DiologStyle2 onClose={this.handleReportTopicClose} data-test-id="closeId" aria-labelledby="customized-dialog-title"
          open={this.state.reporte_pop_up}>
          <BoxStyleReport >
            <IconButton  
            style={{ padding:"4px",color: "#0A2239" }}
            data-test-id="closeId2" 
            onClick={this.handleClose} 
            aria-label="close"
             >
              <CloseIcon />

            </IconButton>
          </BoxStyleReport>
          <BoxStyleReport2 style={{ 
            borderBottom: "1px solid #E2E8F0" ,
            display:"flex",
            alignItems:"baseline",
            flexDirection:"column"
            }}>

            <TypographyReportHeading > SPOT SOMETHING FISHY?  </TypographyReportHeading>


            <TypographyReportSubHeading >Do you want to report this blog topic? Let us know by selecting a reason so we can keep our community pawsome and safe.
            </TypographyReportSubHeading>
          </BoxStyleReport2>
          
          <BoxStyleReport4
          >

            <Typography style={{ ...commentStyle.subheadingStyle, marginBottom: "4px" }}>Reason for reporting this topic{this.starIcon()}
            </Typography>

            <SelectField >
              <Select
                variant="outlined"
                error={this.state.report_reasonError}
                id="Category"
                value={this.state.report_reason}
                onChange={(e: any) => {
                  this.setState({ report_reason: e.target.value,
                     report_reasonError: false, })
                }}
                data-test-id="reportoption"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  MenuListProps: {
                    style: {
                      flexDirection: "column",
                      display: "flex",
                      maxHeight: 250,
                      padding: "10px",
                      gap: "8px"
                    },
                  },
                 
                }}
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  color: this.handleStylePlaceholder(this.state.report_reason),
                  fontFamily: "Lato",

                }}
                fullWidth
                IconComponent={KeyboardArrowDown}
              >
                <MenuItem
                  value="Select reason"
                  style={{
                    display: "none",
                    fontWeight: 400,
                    fontFamily: "Lato",
                    justifyContent: "flex-start",
                    color: "#94A3B8",
                    fontSize: "16px",
                  }}
                >
                  Select reason
                </MenuItem>
                {this.state.report_reason_data.map((item: any) => {
                  return <MenuItem
                    value={item?.name}
                    style={{
                      fontSize: "16px",
                      display: "flex",
                      fontFamily: "Lato",
                      justifyContent: "flex-start",
                      fontWeight: 400,
                    }}
                  >
                    {item.name}
                  </MenuItem>
                })}
              </Select>
              <Typography style={commentStyle.errorNameStyle}>
                {this.handleErroMessageReportReason()}
              </Typography>
            </SelectField>
            <Typography 
            style={{ ...commentStyle.subheadingStyle, marginBottom: "4px" }}
            >
              Explain in brief 
              {this.state.report_reason==="Other" && this.starIcon()}
            </Typography>
            <BoxStyledCompo style={{



            }}>

              <AutoCompletStyle
                minRows={8}
                data-test-id="otherreportError"
                value={this.state.report_brief}
                placeholder="Write something..."
                style={{
                  overflow: "auto",
                  border: this.handleBorderContent(),

                }}
              
                onChange={(e: any) => {
                  this.handleOtherReport(e)
                }
                }
              
                
              />
              {this.state.otherreportError && <Typography style={{
                color: "#DC2626",
                lineHeight: "18px",
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: "Lato",
              }}>
                Please enter a value
              </Typography>}
            </BoxStyledCompo>

          </BoxStyleReport4>
          <hr style={{ width: '100%',
           backgroundColor: '#e2e8f0' ,
           border: 'none',margin:"0px",
            height: '1px', 
            }} />



          <BoxStyle2 
       >

            <ButtonComponent2 
            style={{
            color: "#03B5AA",
              borderRadius: "8px",
              fontFamily: "Inter",
              fontWeight: 700,
              lineHeight: "24px",
              textTransform: "inherit",
             
              fontSize: "16px",
            }}
            data-test-id="closeId3" 
            onClick={this.handleClose} 
             >
              {"Cancel"}
            </ButtonComponent2>
            <ButtonStyleComp 
            style={{
              marginLeft: "0px", fontFamily: "Inter", 
  textTransform: 'none',
  background: '#03B5AA',
  border: '1px solid #03B5AA',
  borderRadius: 8,
  padding: "10px 16px 10px 12px",
  lineHeight: '24px',
  fontWeight: 700,
  fontSize: 16,
  color: '#fff',
            }}
            data-test-id="agreeReport" onClick={() => { this.handleValidation() }} >
              {"Report Topic"}
            </ButtonStyleComp>
          </BoxStyle2>

        </DiologStyle2>
        <DiologStyle2 
        eVdata-test-id="closeId" 
        onClose={this.handleDeleteTopicClose} 
        aria-labelledby="customized-dialog-title"
          open={this.state.deleteTopiPopUp}
          >
          <Box style={{ display: "flex", 
          padding: "16px 16px 12px 40px ",
          justifyContent: "flex-end",
           alignItems: "center", 
            }}>
            <IconButton aria-label="close"
             style={{padding:"4px", color: "#0A2239" }}
             data-test-id="closeId2" 
             onClick={this.handleDeleteTopicClose} 
             >
              <CloseIcon />

            </IconButton>
          </Box>
          <BoxStyleReport2 >

            <TypographyReportHeading style={{
              marginBottom: "12px",
            }}>
              DELETE TOPIC?
            </TypographyReportHeading>
            <TypographyReportSubHeading>
            Deleting this topic will remove it from the community and as well as any comments added on the topic by Petooti members.
            </TypographyReportSubHeading>
          </BoxStyleReport2>


          <hr style={{ width: '100%',
            height: '1px', backgroundColor: '#e2e8f0',
           border: 'none',margin:"0px",
             }} />

          <BoxStyle2 >

            <ButtonComponentDelete 
            onClick={this.handleDeleteTopicClose} 
            style={{ 
            color: "var(--Primary-color-1, #03B5AA)",
            borderRadius:"8px",
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "24px",
            textTransform:"inherit",
            border:"1px solid #03B5AA",
           }}
            data-test-id="closeId3" 
            >
              {"Cancel"}
            </ButtonComponentDelete>
            <ButtonComponentCancel 
            onClick={() => { this.deleteTopicApiCall() }} 
            data-test-id="agree" 
            style={{ ...webstyle.BtnGreen, marginLeft: "0px",
             fontFamily: "Inter", }} 
             >
              {"Delete"}
            </ButtonComponentCancel>
          </BoxStyle2>

        </DiologStyle2>
        <CommonSnackbar  data-test-id='commonSnackBar' handleCloseFunc={()=>this.setState({showSnackbar:false})} open={this.state.showSnackbar} message={this.state.snackbarMessage} />

      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start  
const ButtonComponentBox = styled(Box)({
  display: "flex",
  gap: "16px",
  position: "absolute",
  top: "-85px",
  right: 0,
  '@media (max-width:800px )': {
    top: "0px",
    marginBottom:"20px",
    position: "unset"

  },
})

const ButtonStyleComp=styled(Button)({
 width: "151px", height: "56px" ,
  
  minWidth: 137,
  '& .MuiButton-root:hover':{
    background:"#03B5AA",
  },
  "@media (max-width:500px)": {
    minWidth:"295px",
    width:"100%",
    height:"44px"
  },
  "@media (max-width:375px)": {
    minWidth:"280px",
    width:"100%",
    height:"44px"
  },
    
})
const ButtonComponent2 = styled(Button)({
  width: "128px",
  border: "1px solid #03B5AA !important",
  height: "56px",
  '@media ( max-width: 500px)': {
    width: "295px",
    border:"unset !important",
    height:"44px"
  },
})

const BoxStyle2=styled(Box)({
  padding: "24px", gap: "16px", display: "flex",  justifyContent: "flex-end",
  '@media (max-width: 500px)': {
   justifyContent:"center",
   alignItems:"center",
   flexDirection:"column-reverse",
   padding:"16px"
  },
})

export const DiologStyle2 = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "600px",
    minWidth: "600px",
    borderRadius: "8px 8px 32px 8px"
  },
  "@media (max-width:722px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "unset",
      borderRadius: "8px 8px 32px 8px"
    },
  },
  "@media (max-width:500px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "327px",
      borderRadius: "8px 8px 8px 8px"
    },
  },
  "@media (max-width:400px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "unset",
      borderRadius: "8px 8px 8px 8px"
    },
  },
})
const BoxStyleReport=styled(Box)({
  display: "flex",
  padding: "16px 16px 12px 40px ",
  alignItems: "center",
 justifyContent: "flex-end",
 "@media (max-width:500px)": {
  padding:"16px"
},
})
export const BoxStyleReport2=styled(Box)({

  marginInline: "40px",

 "@media (max-width:500px)": {
  marginInline: "16px",

},
})

export const TypographyReportHeading=styled(Typography)({
  fontSize: '24px',
  lineHeight: '32px',
  fontFamily: 'Inter',
  letterSpacing: '-0.12px',
  color: "#0F172A",

  fontWeight: 700,
  "@media (max-width:500px)": {
    color: "#0F172A",
    fontFamily: 'Lato',
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "28px",
  },
})
export const TypographyReportSubHeading=styled(Typography)({
  fontFamily: 'Inter',
              fontSize: '18px',
              lineHeight: '28.8px',
              color: "#334155",

              marginBottom:"24px",
              marginTop: "16px",
              fontWeight: 400,
  "@media ( max-width:500px )": {
    fontFamily: 'Inter',
              fontSize: '14px',
              lineHeight: '22px',
              marginBottom:"16px",
              marginTop: "12px",
              fontWeight: 400,
  },
})
export const BoxStyleReport4=styled(Box)({
  marginTop: "24px", 
  marginInline: "40px", 
  flexDirection:"column",
 "@media (max-width:500px)": {
   marginTop:"16px",
  marginInline: "16px",

},
})
const SelectField = styled(FormControl)({
  width: "100%",
  marginBottom: "24px",

  "& .MuiOutlinedInput-input": {
    padding: "12px 8px",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
  },
 
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
    },
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderRadius: "8px",
    borderColor: "#CBD5E1",
  },

 
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px"
  },
  "& .MuiInputBase-input": {
    lineHeight: "19.2px",

  },
  "& .MuiFormHelperText-root.Mui-error": {
    lineHeight: "18px",
    fontSize: "12px",
    color: "#DC2626",
    fontWeight: 400,
    fontFamily: "Lato",
  }


 
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },
});
const BoxStyledCompo=styled(Box)({
  marginBottom: "24px",
  '@media (max-width:550px )': {
  marginBottom: "16px",
  }
  
})
const AutoCompletStyle = styled(TextareaAutosize)({
  color: "var(--Neutrals-Cool-gray-900, #0F172A)",
  fontFamily: "Lato",
  borderRadius: "8px",
  maxHeight: "130px",
  padding: "8px",
  minHeigh: "110px",
  fontSize: "16px",
  fontWeight: 400,
  width: "100%",
  minWidth: "100%",
  maxWidth: "100%",
  "&::placeholder": {
    fontWeight: 400,
    fontFamily: "Inter",
    opacity:1,
    color: "#94A3B8",
    fontSize: "16px",
  },
  lineHeight: "150%",
  "&:focus-visible": {
    outline: "unset",
  },
  
});
const ButtonComponent = styled(Button)({
  width: "128px", color: "#334155",
  borderRadius: "8px",
  fontFamily: "Lato",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "inherit",
  border: "1px solid #CBD5E1",
  fontSize: "16px",
  height: "44px",
  '@media (max-width: 500px)': {
    minWidth: "151px"
  },
})
export const ButtonComponentCancel = styled(Button)({
  
  width: "151px", color: "#334155",
  borderRadius: "8px",
  fontFamily: "Lato",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "inherit",
  border: "1px solid #CBD5E1",
  fontSize: "16px",
  height: "56px",
  '@media (max-width: 500px)': {
    width:"100%",
    border:"unset !important",
    height:"44px",
    minWidth: "151px"
  },
})

export const ButtonComponentDelete = styled(Button)({
  width: "140px",  color: "#334155",
  borderRadius: "8px",
  fontFamily: "Lato",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "inherit",
  border: "1px solid #CBD5E1",
  fontSize: "16px",
  height: "56px",
  '@media (max-width: 500px)': {
    width:"100%",
    height:"44px",
    border:"unset !important",
    minWidth: "151px"
  },
})

export const DividerBox = withStyles({
  root: {
    height: '1px',
    top: '2000px',
    left: '334px',
    transform: 'rotate(-0deg)',
    margin: '4% 0%',
    color: '#CBD5E1',

  },
})((props: any) => <Divider {...props} />);
const Comment = styled(Typography)({
  fontFamily: 'Inter',
  letterSpacing: '-0.5%',
  textAlign: 'left',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
  margin: '2% 0.5% 2% 0%',
  color: '#334155'

})




// Customizable Area End
