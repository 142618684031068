import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface AllSectionDataInterface{
    id: string;
    type: string;
    attributes: {
        name: string;
        brief: string|null;
        image: string|null;
        banner:string|null;
    }
}
export interface CatgoryDataInterface{
  id: string;
  
      name: string;
    
}
export interface DetailsData{
  id: string;
  type: string;
  attributes: {
    title: string;
    content: string;
    image: string;
    blog_post_category:{
      id: number;
      name:string;
    }
    blog_section:{
      id: number;
      name:string;

    }
    created_at: string;
    updated_at: string;
  }
}

interface S {
  
  // Customizable Area Start
  isLoading:boolean;
  allSectionData:AllSectionDataInterface[];
  selectedSection:string;
  selectedSectionTitle:string;
  selectedSectionImg:string|null;
  sectionBymenu:string;
  allCatgoryData:CatgoryDataInterface[];
  allData:[];
  searchFieldBlog:string;
  postId:number;
  detailsData:DetailsData;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BlogPostManegementController extends BlockComponent<Props, S, SS> {
    getSectionId:string="";
    getDataId:string="";
    getCategoryId:string="";
    getDetailsId:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   searchFieldBlog:"",
 isLoading:false,
 selectedSection:"",
 selectedSectionTitle:"",
 detailsData:{
      "id": "",
      "type": "",
      "attributes": {
          "title": "",
          "content": "",
          "image": "",
          "blog_post_category": {
              "id": 0,
              "name": ""
          },
          "blog_section": {
              "id": 0,
              "name": ""
          },
          "created_at": "",
          "updated_at": ""
      }
},
 selectedSectionImg:"",
 sectionBymenu:"Select category",
 allSectionData:[],
 allCatgoryData:[],
 allData:[],
 postId:0,

 
   // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    getPgetProductApiCallId: any;
    roductApiCallId: any;
    
  async componentDidMount() {
    super.componentDidMount();
    this.getSectionOption();
    
    const id=window.location.pathname.split("/")
    if(id[1]==="blogs-details"){
      const post_id=id[id.length - 1]
  post_id &&
  this.setState({postId:parseInt(post_id)});
  this.getDeatilsData(parseInt(post_id))
    }

  }
    // Customizable Area End

 


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
  
    if (responseJson ) {
   
      switch (apiRequestCallId) {
        case this.getDetailsId:
          if(responseJson?.errors){
            this.props.navigation.navigate("BlogPostManagementMain")
          }
          else{

            this.setState({
              detailsData:responseJson?.data
              ,isLoading:false
            })
          }
          break;
        case this.getSectionId: 
       
      const selectionId = this.props.navigation?.history?.location?.state?.selectionId;
      const checkSelection=selectionId!==undefined ??parseInt(selectionId)
      if(checkSelection){
        const filterObject=responseJson?.data.find((item:any)=>{
          return item?.id==selectionId
        })
        this.setState({
          allSectionData:responseJson?.data,
          selectedSection:filterObject?.id,
          selectedSectionTitle:filterObject?.attributes?.name,
          selectedSectionImg:filterObject?.attributes?.banner
      });
      }
      else{
        this.setState({
            allSectionData:responseJson?.data,
            selectedSection:responseJson?.data[0]?.id,
            selectedSectionTitle:responseJson?.data[0]?.attributes?.name,
            selectedSectionImg:responseJson?.data[0]?.attributes?.banner
        });
      }
      const id=window.location.pathname.split("/")
      if(id[1]!=="BlogPostManagementDetails"){
        this.setState({isLoading:false})
        this.getAllData();
        this.getCategoryOption(parseInt(responseJson?.data[0]?.id));
      }
    
          break;
          case this.getDataId:
            this.setState({allData:responseJson?.data})
            break;
            case this.getCategoryId:
              const data=[
             {   id:"any",
                name:"Any"}
              ]
              this.setState({allCatgoryData:[...data ,...responseJson?.data]})
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any) {
   
    if(prevState.searchFieldBlog !== this.state.searchFieldBlog ||
     prevState.sectionBymenu !== this.state.sectionBymenu ||
      prevState.selectedSection !== this.state.selectedSection
      ){
        if( prevState.selectedSection !== this.state.selectedSection ){
          this.getCategoryOption(parseInt(this.state.selectedSection))
          this.getAllData();
        }
        else{
          this.getAllData();
        }
   }
 }

 handleEndPointSearchAdvance=()=>{
   const  handleKeyWord= this.state.searchFieldBlog!=="" &&this.state.searchFieldBlog!=null?`&keyword=${this.state.searchFieldBlog}`:""
   const  handleSection= this.state.sectionBymenu!=="Select category" &&this.state.sectionBymenu!=="any" ?`&category_ids=${JSON.stringify([this.state.sectionBymenu])}`:""
return `/bx_block_blogpostsmanagement2/blog_posts?blog_section_id=${this.state.selectedSection}${handleKeyWord}${handleSection}`;
     
}
getDeatilsData = async (id:number) => {
  const header = {
    "Content-Type": "application/json",
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getDetailsId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_blogpostsmanagement2/blog_posts/${id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};
 getSectionOption = async () => {
  this.setState({isLoading:true})
   const header = {
     "Content-Type": "application/json",
   };

   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getSectionId = requestMessage.messageId

   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     `/bx_block_blogpostsmanagement2/blog_posts/sections`
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
   );

   runEngine.sendMessage(requestMessage.id, requestMessage);
   return true;
 };
 getCategoryOption = async (id:number) => {
  const header = {
    "Content-Type": "application/json",
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getCategoryId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_blogpostsmanagement2/blog_posts/categories?blog_section_id=${id}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};
 getAllData = async () => {
  const header = {
    "Content-Type": "application/json",
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getDataId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   this.handleEndPointSearchAdvance()
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

  
  handleStyle=()=>{
    return this.state.sectionBymenu === "Select category" ? "#94A3B8" : "#334155"
   }
  // Customizable Area End
}
