import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    data: any[];
    selectedData: any;
    token: any;
    isSnackbarMessage: string;
    isShowSnackbar: boolean;
    notificationId: number;
    deletePopUp: boolean
    showNotificationData: any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class NotificationsDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    notificationDeleteApiCallWebID: string = "";
    showNotificationApiCallWebId: string = "";
    notificationUnReadApiCallWebID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            data: [],
            selectedData: null,
            token: "",
            isSnackbarMessage: "",
            isShowSnackbar: false,
            notificationId: 0,
            deletePopUp: false,
            showNotificationData: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        this.getToken();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
                this.getToken();
            });
        }
        // Customizable Area Start
        this.showNotifificationDetails()
        // Customizable Area End
    }

    

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            runEngine.debugLog("TOKEN", token);
            this.setState({ token: token });
        }  else if (this.showNotificationApiCallWebId === message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponse&&apiResponse.data) {
                this.setState({ showNotificationData: apiResponse.data })
            }
            else{
                this.props.navigation.navigate("NotificationWeb")
            }
        }
        else if (this.notificationDeleteApiCallWebID === message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponses = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponses) {
                this.setState({
                    deletePopUp: false
                })
                setTimeout(() => {
                    this.setState(() => ({
                        isSnackbarMessage: "NOTIFICATION VANISHED! Your notification has been successfully deleted.",
                        isShowSnackbar: true,
                    }), () => {
                        setTimeout(() => {
                            this.setState(() => ({
                                isSnackbarMessage: "",
                                isShowSnackbar: false,
                            }))
                            window.location.reload();
                            this.props.navigation.navigate('NotificationWeb')
                        }, 1500);
                    })
                }, 100);
            }
        }
        else if (this.notificationUnReadApiCallWebID === message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponses = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiResponses) {
                setTimeout(() => {
                    this.setState(() => ({
                        isSnackbarMessage: "",
                        isShowSnackbar: false,
                    }), () => {
                        setTimeout(() => {
                            this.setState(() => ({
                                isSnackbarMessage: "",
                                isShowSnackbar: false,
                            }))
                            window.location.reload();
                            
                        }, 1500);
                    })
                }, 100);
            }
        }
        // Customizable Area End
    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };
    // Customizable Area Start
   

    showNotifificationDetails = () => {
        const postpath=window.location.pathname.split("/")
    const post_id=postpath[postpath.length - 1]
        
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("auth_token"),
        };
        const message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.showNotificationApiCallWebId = message.messageId;

        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.blockNotification}/${parseInt(post_id)}`
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getDataMethod
        );
        runEngine.sendMessage(message.id, message);
        return true;
    }
    markAsUnReadNotification = (id: number) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("auth_token"),
        };
        const message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.notificationUnReadApiCallWebID = message.messageId;

        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.blockNotification}/${id}/unread`
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.markAsReadMethod
        );
        runEngine.sendMessage(message.id, message);
        return true;
    }

    deleteNotification = (id: number) => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("auth_token"),
        };
        const message = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.notificationDeleteApiCallWebID = message.messageId;

        message.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.blockNotification}/${id}`
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        runEngine.sendMessage(message.id, message);
        return true;
    }

    openDeleteNotification = () => {
        this.setState({ deletePopUp: true })
    }
    handleDeleteNotificationClose = () => {
        this.setState({ deletePopUp: false })   
    }

    handleNavigation = () => {
        const appUrl = this?.state?.showNotificationData?.attributes?.app_url;
        if(appUrl.includes('https')){
            window.location.href=appUrl
        }
        else{

            let baseUR2L:string = baseURL.replace("ruby", "react");
             window.location.href = `${baseUR2L}${appUrl}`;
        }
    }

    // Customizable Area End
}
