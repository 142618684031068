// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Share from "../../blocks/share/src/Share";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPasswordWeb from "../../blocks/forgot-password/src/ResetPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import CataloguePage from "../../blocks/email-account-login/src/CataloguePage.web";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import FavouritesWeb from "../../blocks/favourites/src/FavouritesWeb.web";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import TopicDeatils from "../../blocks/communityforum/src/TopicDeatils.web";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Notifications from "../../blocks/notifications/src/Notifications";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import BlogPostManagementMain from "../../blocks/email-account-login/src/BlogPostManagement/BlogPostManagementMain.web";
import BlogPostManagementDetails from "../../blocks/email-account-login/src/BlogPostManagement/BlogPostManagementDetails.web";
import Categories from "../../blocks/categoriessubcategories/src/Categories.web";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Guestlogin2 from "../../blocks/guestlogin2/src/Guestlogin2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import RequestDetails from "../../blocks/requestmanagement/src/RequestDetails.web";
import CommunityForum from "../../blocks/communityforum/src/CommunityForum.web";
import CommunityFormTopic from "../../blocks/communityforum/src/CommunityFormTopic.web";
import CreateNewTopic from "../../blocks/communityforum/src/CreateNewTopic.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import UserRole from "../../blocks/email-account-registration/src/UserRole.web"
import EmailAccountVerification from "../../blocks/email-account-registration/src/EmailAccountEmailVerification.web"
import EmailAccountOtpVerify from "../../blocks/email-account-registration/src/EmailAccountOtpVerify.web"
import EmailAccountForm from "../../blocks/email-account-registration/src/EmailAccountForm.web"
import ProductDescription from "../../blocks/productdescription/src/ProductDescription.web"
import LandingPageWeb from "../../blocks/email-account-registration/src/LandingPage.web"
import UserProfileBasicAdopter from "../../blocks/user-profile-basic/src/UserProfileBasicAdopter.web"
import CreateRescuerPostpage from "../../blocks/productdescription/src/CreateRescuerPostpage.web"
import UserProfileBasicRescuer from "../../blocks/user-profile-basic/src/UserProfileBasicRescuer.web"
import Interactivefaq from "../../blocks/interactivefaqs/src/InteractiveFaq.web"
import MySubmittedRequests from "../../blocks/requestmanagement/src/MySubmittedRequests.web"
import MyAdoptedPets from "../../blocks/requestmanagement/src/MyAdoptedPets.web"
import NotificationDetails from "../../blocks/notifications/src/NotificationDetails.web"
import NotificaationWeb from "../../blocks/notifications/src/Notifications.web"

import WhoAreWe ,{Checklist_for_Adopters, WhatWeDo,Petooti_Process} from "../../components/src/WhoAreWe";
import ContactusWeb from "../../blocks/contactus/src/ContactusWeb.web"
import Header from "../../blocks/email-account-registration/src/Components/Header.web";
import Footer from "../../blocks/email-account-registration/src/Components/Footer.web";
import { handleRouteChange } from "../../blocks/utilities/src/handle-route-change";


const routeMap = {
  BlogPostManagementMain:{
    component:BlogPostManagementMain,
    path:"/blogs"
  },
  BlogPostManagementDetails:{
    component:BlogPostManagementDetails,
    path:"/blogs-details/:id"
  },
  PetootiCommunityFormTopic:{
    component:CommunityFormTopic,
    path:"/community-topics"
  },
  CreateNewTopic:{
    component:CreateNewTopic,
    path:"/community-topics-new"
  },
  EditTopic:{
    component:CreateNewTopic,
    path:"/community-topics-edit/:id"
  },
  WhoAreWe:{
    component:WhoAreWe,
    path:"/ourstory"
      },
      WhoAreWe:{
        component:WhoAreWe,
        path:"/ourstory"
          },
          Checklist_for_Adopters:{
            component:Checklist_for_Adopters,
            path:"/aboutus"
              },
              Petooti_Process:{
                component:Petooti_Process,
                path:"/petootiprocess"
                  },
      NotificationWeb:{
        component:NotificaationWeb,
        path:"/notifications"
          },
          NotificationDetails: {
          component: NotificationDetails,
          path: "/notifications-details/:id"
        },
      PetootiCommunity:{
        component:CommunityForum,
        path:"/community"
          },
      WhatWeDo:{
        component:WhatWeDo,
        path:"/ourwork"
          },
          ContactUs:{
            component:ContactusWeb,
            path:"/contactus"
              },
              FavouritesPost:{
                component:LandingPageWeb,
                path:"/favouritesPost"
                  },
  UserRole:{
    component:UserRole,
    path:"/signup-role"
  },
  CreateNewPostpage:{
    component:CreateRescuerPostpage,
    path:"/createnewpost"
  },
  editNewPostpage:{
    component:CreateRescuerPostpage,
    path:"/editpost/:id"
  },
  EmailAccountVerification:{
component:EmailAccountVerification,
path:"/signup-email"
  },
  LandingPageWeb:{
    component:LandingPageWeb,
    path:"/",
    exact: true
      },
  EmailAccountForm:{
    component:EmailAccountForm,
    path:"/signup-form"
      },
  EmailAccountOtpVerify:{
    component:EmailAccountOtpVerify,
    path:"/signup-otp"
      },
      ForgotPasswordAccountOtpVerify:{
        component:EmailAccountOtpVerify,
        path:"/password-recovery-otp"
          },
      ResetPasswordWeb:{
        component:ResetPasswordWeb,
        path:"/password-recovery-newpassword"
      },
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Share:{
 component:Share,
path:"/Share"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
 component:ForgotPasswordWeb,
path:"/password-recovery"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ProductDescriptionPublic:{
  component:ProductDescription,
 path:"/petpost-description/:id"},
ProductDescription:{
  component:ProductDescription,
 path:"/petpost-description-owner/:id"},
 PreviewProductDescription:{
  component:ProductDescription,
 path:"/previewProductDescription"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},

CataloguePage:{
 component:CataloguePage,
path:"/my-posts"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},

FavouritesWeb:{
 component:FavouritesWeb,
path:"/favorites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:TopicDeatils,
path:"/community-topics-details/:id"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},


EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
SearchAdvance:{
 component:Categories,
path:"/search"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Guestlogin2:{
 component:Guestlogin2,
path:"/Guestlogin2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/login"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
RequestManagement:{
 component:RequestManagement,
path:"/adoption-form"},
RequestManagementFoster:{
  component:RequestManagement,
 path:"/foster-form"},
 RequestManagementGenericFoster:{
  component:RequestManagement,
 path:"/generic-foster-form"},
 RequestDetails:{
  component:RequestDetails,
 path:"/request-details/:id"
},

MyAdoptedPets:{
  component:MyAdoptedPets,
 path:"/my-adopted-pets"},
 MyReceivedRequests:{
  component:MySubmittedRequests,
 path:"/my-received-requests"},
 MySubmittedRequests:{
  component:MySubmittedRequests,
 path:"/my-submitted-requests"},
 MyAdoptionTemplate:{
  component:RequestManagement,
 path:"/adoption-template"},
 MyFosterTemplate:{
  component:RequestManagement,
 path:"/foster-template"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
UserProfileBasicAdopter:{
  component:UserProfileBasicAdopter,
 path:"/myprofile-adopter"},
 UserProfileBasicRescuer:{
  component:UserProfileBasicRescuer,
  path:"/myprofile-rescuer"
 },
 Interactivefaq:{
  component: Interactivefaq,
  path:"/faq"
},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  scrollTop=()=>{
    window.scrollTo(0, 0);
  }

  async componentDidMount() {
    handleRouteChange();
  }

  async componentDidUpdate() {
    handleRouteChange();
  }

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: 'auto', minHeight: '100vh',overflowY:"auto" }}>
        <Header/>

        {window.scrollTo(0,0)}

       { WebRoutesGenerator({ routeMap })}
        <ModalContainer />
        <Footer/>
      </View>
    );
  }
}

export default App;