export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const avtarImg = require("../assets/avtar.png");
export const ProfileBg = require("../assets/ProfileBg.png");
export const checkBoxTrue=require("../assets/checkbox_CheckBoxTrue.svg")
export const checkBoxFalse=require("../assets/checkbox_checkFalse.svg")
export const RightImg=require("../assets/RightImg.png")
export const profileBackground=require("../assets/profilebackground.svg")
export const infoIcon = require("../assets/iconInfo.svg");
export const LightArrow = require("../assets/lightArrowLeft.svg");
export const AddCircle = require("../assets/addCircle.svg");


