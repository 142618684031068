Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Your Email";
exports.placeHolderPassword = "Your Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";


exports. coatLength = [
  { id: 0, name: "Short" },
  { id: 1, name: "Medium" },
  { id: 2, name: "Long" },
  { id: 3, name: "Hairless" },
  
];
exports.gender2=[
  {id:0,name:"Male"},
  {id:1,name:"Female"},
]

exports.menuItem = [
  {
    id: 0,
    name: "Created date - newest",
    value:"newest"
  },
  {
    id: 1,
    name: "Created date - oldest",
    value:"oldest"
  },
  {
    id: 2,
    name: "Age - Low to High",
    value:"age_low_to_high"
  },
  {
    id: 3,
    name: "Age - High to Low",
    value:"age_high_to_low"
  },
  {
    id: 4,
    name: "Alphabetically - A to Z ",
    value:"alphabetically_a_to_z"
  },
  {
    id: 5,
    name: "Alphabetically - Z to A ",
    value:"alphabetically_z_to_a"
  },
];


exports.gender=[
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Male",
  },
  {
    id: 2,
    name: "Female",
  },
]
exports.postStatus=[
  {
    id: 0,
    name: "All",
  },

  {
    id: 1,
    name: "Archived",
  },
  {id:2,
  name:"Disabled"},

  {id:3,name:"Pending"},
  {id:4,name:"Published"},
  {id:5,name:"Rejected"},

]

exports.loginEndpoint="/bx_block_login/logins"
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.labelTitleSignUp = "Sign up";
exports.forgotPassword="Forgot password?"
exports.haveAccount="Don't have an account?"
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

// Customizable Area End