import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");

export interface YourComponentState {
  // Define your state properties here
  animaltypeOption: number[];
  genderOption: number[];
  animalOptionData:any[],
  breedOptionData:any[],
  breedOption: number[];
  ageOption: number[];
  activitylevelOption: number[];
  temperamentOption: number[];
  goodinhomewithOption: number[];
  currentlocationOption:number[];
  sortItemOption:number;
  fosteredonlyOption:boolean

}


import storage from "../../../framework/src/StorageProvider";
import React from "react";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  animaltypeSelect: boolean,
  animaltypeOption: any,
  breedSelect: boolean,
  breedAllData:any
  emptyImage:string;
  breedOption: any,
  open: boolean
  genderSelect: boolean,
  genderOption: any,
  ageSelect: boolean,
  ageOption: any,
  goodinhomewithSelect: boolean,
  fosteredonlySelect: boolean,
  goodinhomewithOption: any,
  fosteredonlyOption: boolean,
  temperamentSelect: boolean,
  temperamentOption: any,
  activitylevelSelect: boolean,
  activitylevelOption: any,
  currentlocationSelect: boolean,
  animalOptionData:any[];
  breedOptionData:any[]
  currentlocationOption: any,
  currentPage: number,
  sortItem:boolean,
sortItemRef:any,
isLoading:boolean
sortItemOption:number,
searchKeyword:string,
showResultText:boolean,
showText:string,
petsData:any,
page:any,
totalPage:any,
totalPost:any,
showEmptyPage:boolean,
category_ids:any,
sub_category_ids:any,
gender:string,
age_range:any,
good_in_house_with:any,
temperament:any,
activity_levels:any,
locations:any,
sort_by:string,
messageFav:string,
isSnackOpen:boolean,
auth_user_id:any
user_id:number|null
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  animalOptionId:string="";
  breedOptionId:string=""
  breedOptionAllId:string=""
  options: any
  searchApiId:string=''
  favoriteApiId:string=''


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      auth_user_id:'',
      animaltypeSelect: false,
      animaltypeOption: [0],
      breedOption: [0],
      genderOption: [0],
      ageOption: [0],
      sortItemOption:0,
      emptyImage:"",
      breedAllData:[],
      goodinhomewithOption: [0],
      temperamentOption: [0],
      activitylevelOption: [0],
      currentlocationOption: [0],
      fosteredonlyOption:false,
      currentPage: 1,
      breedSelect: false,
      genderSelect: false,
      ageSelect: false,
      goodinhomewithSelect: false,
      temperamentSelect: false,
      activitylevelSelect: false,
      open: false,
      sortItem:false,
      user_id:null,
      animalOptionData:[],
      breedOptionData:[],
      searchKeyword:'',
      showResultText:false,
      showText:'',
      messageFav:'',
      isSnackOpen:false,
      petsData:[],
      page:1,
      totalPage:1,
      category_ids:[],
      sub_category_ids:[],
      gender:'',
      age_range:"",
      good_in_house_with:[],
      temperament:[],
      activity_levels:[],
      locations:[],
      sort_by:"",
      totalPost:0,
      showEmptyPage:false,
      fosteredonlySelect:false,
sortItemRef:React.createRef(),
isLoading:false,
      currentlocationSelect: false,
    };
    this.options = [
      "Animal type",
      "Breed",
      "Gender",
      "Age",
      "Good in home with",
      "Temperament",
      "Activity level",
      "Current location",
      "Fostered only"
    ];

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  // Customizable Area Start

  async componentDidMount() {
    this.setState({isLoading:true})
    let auth_user_id= await getStorageData('auth_user_id')
    this.setState({auth_user_id:auth_user_id})
const image=await storage.get("search_results_screen_with_no_results_based_on_search_image");
const user_id=await storage.get("auth_user_id")
this.setState({user_id:user_id})
if(image){

  this.setState({emptyImage:image})
}
    this.getAnimalOptionData()
    this.getBreedOptionData()
    this.getBreedAllData()
    this.handleSearch()
  }
  handleFilterOpen = () => {
    storage.set("scroll1", "true")
    this.setState({ open: true });
    this.applyStylesFromLocalStorage()
  };
  handleListKeyDownmenu(event: any, stateName: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ [stateName]: false }as Pick<any, keyof any>);
    }
   }
   handleClick = () => {
    this.setState({ sortItem: !this.state.sortItem });
  };
  applyStylesFromLocalStorage = async () => {

    let hasScrollClass = await storage.get('scroll1');

    const body = document.body;

    if (hasScrollClass === "true") {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = '';
    }
  }

  getAnimalOptionData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.animalOptionId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_categories/categories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getBreedOptionData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  const url=  !this.state.animaltypeOption.includes(0) &&this.state.animaltypeOption.length!==0 ?`bx_block_categories/sub_categories?category_id=${JSON.stringify(this.state.animaltypeOption)}`:`bx_block_categories/sub_categories`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.breedOptionId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getBreedAllData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.breedOptionAllId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `bx_block_categories/sub_categories`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleCloseMenu=()=>{
    this.setState({sortItem:false})
  }
  handleMenu=(index:number)=>{
this.setState({sortItemOption:index})
  }
  handleDrawerClose = () => {
    storage.set("scroll1", "false")
    this.setState({ open: false });
    this.applyStylesFromLocalStorage()

  };

  handleItemClick = (item: string) => {
    const name = item.replace(/\s+/g, "").toLowerCase();
    this.setState((prevState: any) => ({
      ...prevState,
      [name + "Select"]: !prevState[name + "Select"],
    }));
  };
  clearAllOptions = () => {
    this.setState({
      animaltypeOption: [0],
      breedOption: [0],
      genderOption: [0],
      ageOption: [0],
      goodinhomewithOption: [0],
      temperamentOption: [0],
      currentlocationOption: [0],
      activitylevelOption: [0],
      fosteredonlyOption:false,
      page:1
    });
  };
  handleConditionOfClear = () => {
    const hasNonZeroOption = (option: any[]) => option.length > 0 && !option.includes(0);
  
    return (
      [
        this.state.animaltypeOption,
        this.state.breedOption,
        this.state.genderOption,
        this.state.ageOption,
        this.state.goodinhomewithOption,
        this.state.temperamentOption,
        this.state.currentlocationOption,
        this.state.activitylevelOption,
      ].some(hasNonZeroOption) || this.state.fosteredonlyOption
    );
  };
  

  clearOption = (optionName: string, indexToRemove: number) => {
    const updatedOptions = this.state[optionName as keyof YourComponentState].filter((index: number) => index !== indexToRemove);
  
    const finalOptions = updatedOptions.length > 0 ? updatedOptions : [0];
  
    this.setState({ [optionName]: finalOptions } as Pick<YourComponentState, keyof YourComponentState>);
  };
  
  handleToggleOption = (index: {
    id:number,
    name:string
  }, stateName: keyof YourComponentState,length:number,optionsKey:string,options:number[]): void => {
   
   
    let stateUpdate: any = {};
  if(optionsKey==="Fostered only"){
this.setState({fosteredonlyOption:!this.state.fosteredonlyOption})
  }
  else{

    if (index ?.id=== 0) {
      stateUpdate[stateName] = [0];
    } 
    
    else {
      const updatedOptions = options.includes(index?.id)
        ? options.filter((item: number) => item !== index?.id)
        : [...options, index?.id];
  
      if (updatedOptions.length === length-1 && !updatedOptions.includes(0)) {
        stateUpdate[stateName] = [0];
      } 
      else if (updatedOptions.length === 0) {
        stateUpdate[stateName]= [0] 
      }
      else {
        stateUpdate[stateName] = updatedOptions.filter((option: number) => option !== 0);
      }
    }
  
    this.setState(stateUpdate);
  }
  };
  
  removeStorage = () =>{
    removeStorageData('animal');
  }
 
  componentWillUnmount(): Promise<void> {
    this.removeStorage();
    return Promise.resolve();
  }
  
  
  componentDidUpdate(prevProps: any, prevState: YourComponentState) {
   
      if(prevState.animaltypeOption !== this.state.animaltypeOption ||
       prevState.breedOption !== this.state.breedOption ||
       prevState.genderOption !== this.state.genderOption||
       prevState. ageOption!== this.state.ageOption||
       prevState.goodinhomewithOption !== this.state.goodinhomewithOption||
       prevState.temperamentOption !== this.state.temperamentOption||
       prevState.activitylevelOption !== this.state.activitylevelOption||
         prevState.currentlocationOption !== this.state.currentlocationOption||
         prevState.sortItemOption !== this.state.sortItemOption||
         prevState.fosteredonlyOption !== this.state.fosteredonlyOption
         ){
   if (prevState.animaltypeOption !== this.state.animaltypeOption) {
    this.getBreedOptionData()
    }
  this.handleSearch()
     }
   
    
  }
  hanldeSnackBar = (data: any) => {
    if (!data.errors) {
      this.setState({ messageFav: data?.message, isSnackOpen: true })
    }

  }
  handleFreeSolo=(a:any,b:any)=>{
     
     if(a.type=='click'){
      this.setState({showResultText:false,showText:'',searchKeyword:''},()=>this.handleSearch())
      
     }
  }
  handleForSearchEnterKey=(event:any)=>{
    this.setState({page:1})
    if(event.key==='Enter'&&this.state.searchKeyword!==""){
      this.handleSearch()
    }
  }
  handlePostEmpty2=()=>{
   
    const valid= (
      ( Array.isArray(this.state.animaltypeOption) &&
       this.state.animaltypeOption.includes(0)
     ) &&
     (
       Array.isArray(this.state.breedOption) &&
       this.state.breedOption.includes(0)
     ) &&
     (
       Array.isArray(this.state.genderOption) &&
       this.state.genderOption.includes(0)
     ) &&
     (
       Array.isArray(this.state.currentlocationOption) &&
       this.state.currentlocationOption.includes(0)
     ) &&
     (
      Array.isArray(this.state.activitylevelOption) &&
      this.state.activitylevelOption.includes(0)
    )&&
    (
      Array.isArray(this.state.temperamentOption) &&
      this.state.temperamentOption.includes(0)
    )&&
    (
      Array.isArray(this.state.goodinhomewithOption) &&
      this.state.goodinhomewithOption.includes(0)
    )&&
    (
      Array.isArray(this.state.ageOption) &&
      this.state.ageOption.includes(0)
    )
    )
         return valid
     
       
  }
  handlePostEmpty=()=>{
   
    const valid= (
      ( Array.isArray(this.state.animaltypeOption) &&
       !this.state.animaltypeOption.includes(0)
     ) ||
     (
       Array.isArray(this.state.breedOption) &&
       !this.state.breedOption.includes(0)
     ) ||
     (
       Array.isArray(this.state.genderOption) &&
       !this.state.genderOption.includes(0)
     ) ||
     this.state.sortItemOption === 0 ||
     (
       Array.isArray(this.state.currentlocationOption) &&
       !this.state.currentlocationOption.includes(0)
     ) ||
     (
      Array.isArray(this.state.activitylevelOption) &&
      !this.state.activitylevelOption.includes(0)
    )||
    (
      Array.isArray(this.state.temperamentOption) &&
      !this.state.temperamentOption.includes(0)
    )||
   this.handleEmptyConditon()||
     this.state.searchKeyword !== "" )&&
     this.state.petsData.length === 0;
         return valid
     
       
  }
  handleEmptyConditon=()=>{
    const vaild=(
      (
        Array.isArray(this.state.goodinhomewithOption) &&
        !this.state.goodinhomewithOption.includes(0)
      )||
      (
        Array.isArray(this.state.ageOption) &&
        !this.state.ageOption.includes(0)
      )
    )
    return vaild;
  }
  handleEndPointSearchAdvance=()=>{
    const currentlocationOptionHandle = this.state.currentlocationOption.map((index:any) => configJSON.location[index].name);
    const handleLocation = !this.state.currentlocationOption.includes(0) ? `&locations=${JSON.stringify(currentlocationOptionHandle)}` : "";

    const activitylevelOptionHandle = this.state.activitylevelOption.map((index:any) => configJSON.level[index].name);
    const handleActivityLevel = !this.state.activitylevelOption.includes(0) ? `&activity_levels=${JSON.stringify(activitylevelOptionHandle)}` : "";

    const temperamentOptionHandle = this.state.temperamentOption.map((index:any) => configJSON.temperament[index].name);
    const handletemperamentOption = !this.state.temperamentOption.includes(0) ? `&temperament=${JSON.stringify(temperamentOptionHandle)}` : "";

    const goodinhomewithOptionHandle = this.state.goodinhomewithOption.map((index:any) => configJSON.goodHome[index].name);
    const handlegoodinhomewithOptionHandle = !this.state.goodinhomewithOption.includes(0) ? `&good_in_house_with=${JSON.stringify(goodinhomewithOptionHandle)}` : "";
 
    const ageOptionHandle = this.state.ageOption.map((index:any) => configJSON.age[index].value);
    const handleageOptionHandle = !this.state.ageOption.includes(0) ? `&age_range=${JSON.stringify(ageOptionHandle)}` : "";

    const genderOptions = this.state.genderOption.map((index:any) => configJSON.gender[index].name);
     const  handleGender= !this.state.genderOption.includes(0)?`&gender=${String(genderOptions)}`:""

     const  handleBreed= !this.state.breedOption.includes(0)?`&sub_category_ids=${JSON.stringify(this.state.breedOption)}`:""
     const  handleAnimal= !this.state.animaltypeOption.includes(0)?`&category_ids=${JSON.stringify(this.state.animaltypeOption)}`:""
     const  handleSearch= this.state.searchKeyword!=="" && this.state.searchKeyword !==null && this.state.searchKeyword!==undefined?`&keyword=${this.state.searchKeyword}`:""
  return `bx_block_advanced_search/search/filter?sort_by=${configJSON.menuItem[this.state.sortItemOption].value}${handleSearch}&fostered_only=${this.state.fosteredonlyOption}${handleAnimal}${handleBreed}&${handleGender}${handleageOptionHandle}${handlegoodinhomewithOptionHandle}${handletemperamentOption}${handleActivityLevel}${handleLocation}&per_page=12$&page=${this.state.page}`
       
  }
  handleSearch=async()=>{
   
      let auth_token= await getStorageData('auth_token')
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        'token':auth_token
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.searchApiId = requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.handleEndPointSearchAdvance()
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
   
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleSetSearchData=(data:any,meta:any)=>{
  
   this.setState({petsData:data})
   this.setState({totalPage:meta.last_page})
   this.setState({totalPost:meta.total_posts})
   
   if(data.length<=0&&this.state.searchKeyword!=="" && this.handlePostEmpty2()){
   this.setState({showEmptyPage:true})
   }
   else if(data.length>0&&this.state.searchKeyword ){
    this.setState({showEmptyPage:false})
    this.setState({showResultText:true,showText:this.state.searchKeyword})
    }
   else{
   this.setState({showEmptyPage:false})
   }
  }
  handleChangePage=(e:any,page:any)=>{
  this.setState({page:page})
  this.handleSearch()
  }
   dateConverter = (dateString:any) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
  
    const timeDifference =Number(currentDate) - Number(inputDate);
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(monthsDifference / 12);
  
    let result;
  
    if (daysDifference < 30) {
      result = `${daysDifference} day${daysDifference > 1 ? 's' : ''}`;
    } else if (monthsDifference < 13) {
      result = `${monthsDifference} month${monthsDifference > 1 ? 's' : ''}`;
    } else {
      result = `${yearsDifference} Year${yearsDifference > 1 ? 's' : ''}`;
  
      if (monthsDifference % 12 !== 0) {
        result += ` ${monthsDifference % 12} Month${monthsDifference % 12 > 1 ? 's' : ''}`;
      }
    }
  
    return result;
  };
  handleNavigation=(id:number,itemId:number)=>{
    if(id==this.state.user_id){
      this.props.navigation.navigate("ProductDescription",{id:itemId,postDetails:false})
    }
    else{
      this.props.navigation.navigate("ProductDescriptionPublic",{id:itemId,postDetails:false})

    }
  }
  handleFavorite=async(fav_id:any,isFav:any)=>{
    let authorization_token= await getStorageData('auth_token')
    if(!authorization_token){
      this.props.navigation.navigate("EmailAccountLoginBlock") 
      return
    }
      let method=isFav?'DELETE':'POST'
    const headers = {
      "Content-Type": configJSON.categoryApiContentType,
      'token':authorization_token
    };
     const body={
      'favouriteable_id':fav_id
     }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favoriteApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      
      `bx_block_favourites/favourites`
     
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
  

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.error) {
      const add=[{
        id:0,name:"All"
      }]
      switch (apiRequestCallId) {
        case this.animalOptionId:
          const id=this.props.navigation?.history?.location?.state?.id;
      if(id){
        this.setState({animaltypeOption:[id]})
      }
          this.setState(prevState => ({
            animalOptionData: [...add, ...responseJson?.data],
          }));
          const animalData = await getStorageData('animal');
          if(animalData){
            const findId = this.state.animalOptionData.find((element) => element.name.toLowerCase() === animalData.toLowerCase());
            this.setState({animaltypeOption : [findId.id]})
          }
        
       
          break;
          case this.breedOptionId:
            const compareNames = (firstNameAnimal: {name: string}, lastNameAnimal: {name: string}) => {
              if (firstNameAnimal.name < lastNameAnimal.name) {
                  return -1;
              }
              if (firstNameAnimal.name > lastNameAnimal.name) {
                  return 1;
              }
              return 0;
          };
                let sortData=responseJson?.data.sort(compareNames)
      
            this.setState(prevState => ({
              breedOptionData: [...add, ...sortData],
            }));
          break;
          case this.breedOptionAllId:
            this.setState({
              breedAllData: [ ...responseJson?.data],
            });             
          break;
          case this.searchApiId:
            this.setState({isLoading:false})
            
            this.handleSetSearchData(responseJson?.data,responseJson?.meta)
            break;
            case this.favoriteApiId:
              this.handleSearch()
            break;

      }}

    // Customizable Area End
  }


}
