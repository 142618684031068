import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import storage from '../../../../framework/src/StorageProvider';

// Customizable Area Start
import React from "react"
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
export interface WhoWeAreInterface{
  id:string;
  type:string;
  attributes:{
    title:string;
    description:string;
  }
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
menuItem:any
scroll:any
open:boolean
anchorRefResources:any
anchorRef1:any
userType:string
dashboardItem:any
openDashboard:any
userName:string
footerData:any[]
rescuerArr:any
resorcesItemArr:{
  name:string;
  path:string;
}[]
adopterArr:any
whoWeAreData:WhoWeAreInterface|{}
whatWeDoData:WhoWeAreInterface|{}
peetotiProcess:WhoWeAreInterface|{}
checklistForAdopters:WhoWeAreInterface|{}
privacyAndPolicy:boolean
termsAndConditon:boolean
isOpenSnack:boolean
importUpdatePopUp:boolean
termsacceptCheckBox:boolean
termsacceptCheckBoxError:boolean
imageUrl:string | null;
termsAndConditonPopUp:boolean
privacyPolicyPopUp:boolean;
resourcesItem:any
isLoading:boolean
notificationCountData: any;
customModalOpen:boolean;

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileId:string=''
  footerApiId:string=''
  getWhatWeDoId:string=''
  getTermsAndCondtionId:string=''
  getPrivacyAndPolicyId:string=''
  allImgagesId:string=''
  termsPrivacyAcceptId:string=''
  notificationApiCallWebID: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
       // Customizable Area Start
       ,getName(MessageEnum.SessionResponseMessage)
       // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      menuItem:false,
      customModalOpen:false,
      whoWeAreData:{},
      peetotiProcess:{}
,checklistForAdopters:{}
  ,    whatWeDoData:{},
      resorcesItemArr:[
        {name:"About Us",path:"/aboutus"},
        {name:"Pet Advice",path:"/blogs"},
        {name:"Fostering",path:"/generic-foster-form"},
        {name:"FAQ",path:"/faq"},


      ],
      imageUrl:"",
      termsAndConditon:false,
      privacyAndPolicy:false,
      dashboardItem:null,
      resourcesItem:false,
      openDashboard:false,
      termsAndConditonPopUp:false,
privacyPolicyPopUp:false,
      scroll: storage.get('scroll'),
      open:false,
      userType:"",
      anchorRefResources: React.createRef(),
      anchorRef1: React.createRef(),
      isOpenSnack:false,
      footerData:[],
      userName:'',
       rescuerArr :[
        { title: 'My Profile', path: '/myprofile-rescuer' },
        { title: 'My Pet Posts', path: '/my-posts' },
        { title: 'My Submitted Requests', path: '/my-submitted-requests' },
        { title: 'My Received Requests', path:'/my-received-requests' },
        { title: 'My Adopted Pets', path:'/my-adopted-pets' },
    ],

    importUpdatePopUp:false,
    isLoading:false,
    termsacceptCheckBox:false,
    termsacceptCheckBoxError:false,
    notificationCountData: 0,
    adopterArr:[{ title: 'My Profile', path: '/myprofile-adopter' }, { title: 'My Submitted Requests', path: '/my-submitted-requests' }]
      // Customizable Area End
    };

    // Customizable Area Start
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.error && responseJson) {
      if(apiRequestCallId===this.getUserProfileId){
        setStorageData("userDetail",JSON.stringify(responseJson?.data.attributes))
       
         this.handleResponseUserPorfile(responseJson)
      }
      else if(apiRequestCallId===this.footerApiId){
        if(responseJson?.message!=="No Content is present"){
          const filterData=responseJson?.data.filter((item:any) => !item.attributes?.inactive)
          this.setState({footerData:filterData})
        }
      }
    else  if(apiRequestCallId===this.termsPrivacyAcceptId){
        this.setState({importUpdatePopUp:false,termsacceptCheckBox:false})
      }
      else if(apiRequestCallId===this.allImgagesId){
        storage.remove("signup_screen_image")
        storage.remove("login_screen_image")
        storage.remove("reset_password_screen_image")
        storage.remove("posts_screen_with_no_posts_image")
        storage.remove("posts_screen_with_no_results_based_on_search_image")
        storage.remove("search_results_screen_with_no_results_based_on_search_image")
        storage.remove("favourites_screen_with_no_items_image")
        storage.remove("received_requests_screen_with_no_requests_image")
        storage.remove("received_available_screen_with_no_results_based_on_search_image")
        storage.remove("submitted_requests_screen_with_no_requests_image")
        storage.remove("submitted_available_screen_with_no_results_based_on_search_image")
        storage.remove("notifications_screen_with_no_notifications_image")
        this.handleResponseApiId(responseJson)
       
      }
      else if(this.getWhatWeDoId===apiRequestCallId){
          this.handleResponseWhatWeData(responseJson?.data)

      }
      else if(this.getPrivacyAndPolicyId===apiRequestCallId){
        storage.remove("privacyAndPolicy")
        storage.set("privacyAndPolicy",responseJson?.data?.attributes?.description)

    }
    else if(this.getTermsAndCondtionId===apiRequestCallId){
      storage.remove("termsAndCondition")
      storage.set("termsAndCondition",responseJson?.data?.attributes?.description)

  } else if(this.notificationApiCallWebID === apiRequestCallId) {
    if(responseJson?.errors && responseJson?.errors?.length>0 && responseJson?.errors[0].token=="Token has Expired"){
      storage.remove("auth_token");
  storage.remove("auth_user_id");
  storage.remove("auth_user_role");
  this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    this.setState({notificationCountData: responseJson?.meta?.unread_count})
  }
    }
    

    // Customizable Area End
}

  // Customizable Area Start
handleResponseApiId=(responseJson:any)=>{
  if(responseJson && responseJson.errors === undefined && responseJson.message === undefined && responseJson?.data.length > 0){
    responseJson?.data.map((item:any)=>{
      storage.set(item?.attributes?.image_name,item?.attributes?.image?.url)
    })
  }
}
openTerms=()=>{
  this.setState({termsAndConditonPopUp:true})
}
openPrivacy=()=>{
  this.setState({privacyPolicyPopUp:true})
}
handleResponseUserPorfile=(responseJson:any)=>{
  let res=responseJson?.data?.attributes
  this.getAllImagesData()
  this.getWhatWeDoAre()
if(this.state.userName!==res.first_name || this.state.importUpdatePopUp!==res.terms_accepted||this.state.imageUrl==res.profile_image){
  this.setState({userName:res.first_name,importUpdatePopUp:!res.terms_accepted,imageUrl:res.profile_image});
  if( this.state.importUpdatePopUp!==res.terms_accepted){
    this.getPrivacyAndPolicyData()
 
    this.getTermsAndConditionData()
  }
}

}
handleTermsAndConditonClose2=()=>{
  this.setState({termsAndConditonPopUp:false})
}
handleprivacyAndPolicyClose2=()=>{
  this.setState({privacyPolicyPopUp:false})
}
handleClosePopUpImportant=()=>{
  
    storage.remove("auth_user_role");
    storage.remove("auth_user_id");
    storage.remove("auth_token");
      this.setState({importUpdatePopUp:false})
      this.setState({isOpenSnack:true})
      setTimeout(() => { 
    window.location.href="/login"
     this.setState({isOpenSnack:false})
    }, 3000);
  

}
handleResponseWhatWeData=(responseJson:any[])=>{
  if(responseJson&& responseJson?.length>0){
    const whatWeDo=responseJson.find((item:any)=>{
      return item.attributes.title.toLowerCase()==="our work"
    })||{}
    const whatWeAre=responseJson.find((item:any)=>{
      return item.attributes.title.toLowerCase()==="our story"
    })||{}
    const Petooti_Process=responseJson.find((item:any)=>{
      return item.attributes.title.toLowerCase()==="petooti process"
    })||{}
    const Checklist_for_Adopters=responseJson.find((item:any)=>{
      return item?.attributes?.title?.toLowerCase()==="about us"
    })||{}
    if(Object.keys(whatWeDo).length>0){
      this.setState({whatWeDoData:whatWeDo})
    }
    if(Object.keys(whatWeAre).length>0){
      this.setState({whoWeAreData:whatWeAre})
    }
    if(Object.keys(Petooti_Process).length>0){
      this.setState({peetotiProcess:Petooti_Process})
    }
    if(Object.keys(Checklist_for_Adopters).length>0){
      this.setState({checklistForAdopters:Checklist_for_Adopters})
    }
  }
}
accpetTermsAndPolicy = async () => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    "token":await storage.get("auth_token")
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.termsPrivacyAcceptId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `/bx_block_profile_bio/profile_bios/accept_terms`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PUT"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};
handleTermsAndPolicyApi=()=>{
  if(this.state.termsacceptCheckBox){
    this.accpetTermsAndPolicy()
  }
  else{
    this.setState({termsacceptCheckBoxError:true})
  }
}
handlePrivacyPolicy=()=>{
 this.setState({privacyAndPolicy:true})
}
aceeptTermsAndCondtion=()=>{
  if (!this.state.termsacceptCheckBox === false) {
    this.setState({ termsacceptCheckBoxError: true })
    this.setState({ termsacceptCheckBox: !this.state.termsacceptCheckBox })

  }
  else {
    this.setState({ termsacceptCheckBoxError: false })

    this.setState({ termsacceptCheckBox: !this.state.termsacceptCheckBox })
  }
 }
handleTermsAndConditon=()=>{
  this.setState({termsAndConditon:true})
 }
handleprivacyAndPolicyClose=()=>{
  this.setState({privacyAndPolicy:false})
    }

    handleTermsAndConditonClose=()=>{
      this.setState({termsAndConditon:false})
        }
   handleDrawerOpen = () => {
    this.setState({open:true});
  };
  getWhatWeDoAre = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getWhatWeDoId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_content_management/content_pages`
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getTermsAndConditionData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getTermsAndCondtionId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/content_managements?content_type=terms_and_conditions`
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getPrivacyAndPolicyData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getPrivacyAndPolicyId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_content_management/content_managements?content_type=privacy_policy`
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getFooterData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.footerApiId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/footer_sections`
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getAllImagesData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.allImgagesId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/images`
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
handlePeetotiCommunity=async()=>{
  let auth_user=await getStorageData('auth_token')
  if(this.state.userType==""||!auth_user){
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  else{
    this.props.navigation.navigate("PetootiCommunity")
  }
}
handleNotification=()=>{
  this.props.navigation.navigate("NotificationWeb")
}
handleFavIcon=()=>{
  if(this.state.userType==""){
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  else{
    this.props.navigation.navigate("FavouritesWeb")
  }
}
handleSearchIcon=()=>{
    this.props.navigation.navigate("SearchAdvance")
}
handleWhoAreWe=()=>{
  this.props.navigation.navigate("WhoAreWe",{data:this.state.whoWeAreData})
}

handleFaq = ()=>{
  this.props.navigation.navigate("Interactivefaq")
}

handleWhatWeDo=()=>{
  this.props.navigation.navigate("WhatWeDo",{data:this.state.whatWeDoData})
}

handleContactUs=()=>{
  this.props.navigation.navigate("ContactUs")
}
   handleDrawerClose = () => {
     
     this.setState({open:false});

  };

  logOutFunctionality=()=>{
    storage.remove("auth_token");
    storage.remove("auth_user_id");
    storage.remove("auth_user_role");
    this.setState({menuItem:false}) 
    this.setState({userType:""}) 
    this.setState({open:false});
    this.setState({isOpenSnack:true})
     setTimeout(() => { 
    this.setState({isOpenSnack:false})
    }, 3000);
  }
   handleListKeyDown(event:any) {
    if (event.key === 'Tab') {
      event.preventDefault();
    this.setState({menuItem:false}) 
    }
  }
handleNavigate=()=>{
  this.props.navigation.navigate("CataloguePage")
}
  handleListKeyDown2(event:any) {
    if (event.key === 'Tab') {
      event.preventDefault();
    this.setState({openDashboard:false}) 
    }
  }


  async componentDidMount() {
    if("privacy_policies=true"===window.location.search.slice(1)){
      this.setState({privacyPolicyPopUp:true})
    }
    if("terms_and_conditions=true"==window.location.search.slice(1)){
      this.setState({termsAndConditonPopUp:true})
    }
this.getFooterData()
this.getTermsAndConditionData()
this.getPrivacyAndPolicyData()
this.getAllImagesData()
this.getNotificationList();
this.getWhatWeDoAre()
   let user_role=await storage.get("auth_user_role");
   if(user_role&& user_role.length>0){
   this.setState({userType:user_role})
   }
   this.handleUserProfile()
  }
 
  closeSnackbar=()=>{
    this.setState({isOpenSnack:false})
  }
  handleNav=(path:any)=>{
    if(path!==''){
      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
      
  }
  handleUserProfile=async()=>{
    window.scrollTo(0,0);
    this.getNotificationList()
    const UserprofileId=await getStorageData('auth_user_id')
    if(UserprofileId!==null&&UserprofileId!==undefined){

      const headerToken = {
      "token": await getStorageData('auth_token')
    };
   
    const requestMessageIs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileId = requestMessageIs.messageId;

    requestMessageIs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.propfileEndPoints(UserprofileId)
    );


    requestMessageIs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerToken)
    );

    requestMessageIs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessageIs.id, requestMessageIs);
    return true;
    }
    
  }
  getNotificationList = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token":await storage.get("auth_token")
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.notificationApiCallWebID = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_notifications/notifications`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  // Customizable Area End
}
