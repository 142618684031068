// Customizable Area Start
import React from "react";
import { Avatar, Box, Button,Container, Grid, Tooltip,styled,
    Paper, Typography, Snackbar,Link, MenuItem,Card, CardContent , Dialog, IconButton,Select, Backdrop} from "@material-ui/core";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { profileBackground, checkBoxFalse, checkBoxTrue, RightImg, infoIcon,LightArrow,AddCircle} from './assets';
import { createTheme, ThemeProvider , withStyles} from '@material-ui/core/styles';
import  {UserProfileStyle, PhoneStyle,SelectField, MenuItemStyle, InputField, StyledBreadcrums,SelectFieldColor,DialogStyled}  from "./UserProfileBasicAdopter.web";
import { KeyboardArrowDown, Visibility, VisibilityOff, DateRange} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../../components/src/Loader.web";
import { DiologComponent } from "../../email-account-registration/src/EmailAccountForm.web";

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: "#FFF",
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    padding: "8px 16px",
    lineHeight: "22px",
    borderRadius: "8px",
    border: "0.5px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
    boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)",

  },
})(Tooltip);


// Customizable Area End

import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";

export default class UserProfileBasicRescuer extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    
    renderRescuerChangePassword = () => {
      return (
        <>
          <UserProfileStyle>
            <DialogStyled

              onClose={this.handleClose}
              aria-describedby="keep-mounted-modal-description"
              open={this.state.open}
              style={{ display: 'flex', justifyContent: 'center',borderRadius:"8px", alignItems:"center"  }}
              keepMounted
              aria-labelledby="keep-mounted-modal-title"
              className="modal"
            >
              <ModalBox >
                <Card style={{borderRadius:"8px",position:'relative'}}>
                  <CustomCardContent >
                    <CloseIcon
                      onClick={this.handleClose}
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "20px",
                        right:"20px",
                      }}
                    />
                    <Box>
                      <Typography
                        style={{
                          fontWeight: 700,
                          fontFamily: "Inter",
                          fontSize: "24px",
                          color:"#0f172a",
                        }}
                      >
                        TIME FOR A PASSWORD MAKEOVER!
                      </Typography>
                      <Typography style={{  fontFamily: "Inter",fontSize: "18px" , color:"#334155"}}>
                      It's time to give your password a little refresh. Update it now to keep things secure. Strong passwords are always in fashion!
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        marginTop: "10px",
                        gap: "4px",
                      }}
                    >
                      <Typography style={{  fontWeight: 700, fontSize: "14px", fontFamily: "Lato", color: '#334155', lineHeight:"22px" }}>
                        Current Password
                      </Typography>
                      <InputField
                        type={this.showHidePassword(this.state.enablePasswordField)}
                        placeholder="Enter current password"
                        fullWidth
                        variant="outlined"
                        data-test-id="old-pass"
                        value={this.state.oldPassword}
                        error={this.state.oldPassError}
                        helperText={this.state.oldPassError && this.state.oldPassErrMsg}
                    onChange={this.handleOldPassword}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={this.setPasswordField}
                            >
                              {this.state.enablePasswordField ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <Box style={{ gap: "4px" }}>
                      <Typography style={{ fontSize: "14px", fontWeight: 700,fontFamily: "Lato", color: '#334155', lineHeight:"22px"}}>
                        New Password
                      </Typography>
                      <InputField
                        fullWidth
                        variant="outlined"
                        type={this.showHidePassword(!this.state.handleVisiblCPassword)}
                        data-test-id="new-pass"
                        error={this.state.newPassError}
                        placeholder="Enter new password"
                        value={this.state.newPass}
                        helperText={this.state.newPassError && this.state.newPassErrorMsg}
                        onChange={this.handleNewPasswordChange}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                            onClick={this.setConfirmPasswordField}
                              aria-label="toggle password visibility"
                              edge="end"
                            >
                              {!this.state.handleVisiblCPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <Box style={{ gap: "4px" }}>
                      <Typography style={{ fontSize: "14px", fontWeight: 700, fontFamily: "Lato", color: '#334155', lineHeight:"22px"}}>
                        Confirm New Password
                      </Typography>
                      <InputField
                        type={this.showHidePassword(!this.state.handleVisiblNPassword)}
                        fullWidth
                        variant="outlined"
                        error={this.state.confirmPassError}
                        data-test-id="confirm-pass"
                        value={this.state.confirmPass}
                        placeholder="Enter new password"
                        helperText={this.state.confirmPassError && this.state.confirmPassErrorMessage}
                        onChange={this.handleConfirmPasswordChange}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.setConfirmNewPasswordField}
                              edge="end"
                            >
                              {!this.state.handleVisiblNPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <ButtonBox
                     
                    >
                      <CustombuttonChangePass 
                      data-test-id="change-pass"
                      onClick={this.handleAdopterProfilePasswordUpdate} >Change Password</CustombuttonChangePass>
                      <CustomCancelbutton
                        onClick={this.handleClose}
                       
                      >
                        Cancel
                      </CustomCancelbutton>
                    </ButtonBox>
                  </CustomCardContent>
                </Card>
              </ModalBox>
            </DialogStyled>
          </UserProfileStyle>
        </>
      )
    }
    
    hanldePlaceholder = () => {
      return !this.state.phoneNumber  && " 00000 00000"
    }
    hanldeDropDown = (data: any) => {
      return data?.map((option: any) => (
        <MenuItem
          value={option.name} key={option.id}
        >{`${option.name}`}</MenuItem>
      ))
    }
    handleStyle = () => {
      return this.state.phoneError ? "4px" : "16px"
    }
    showHidePassword = (isValid:boolean)=>{
      return isValid ? "password" : "text"
    }
    
    hanldeDropDownNational = (data: any) => {
      return data?.map((option: any) => 
      (
        <MenuItem
        value={option.name}
        key={option.id}
        >
          {`${option.name}`}
          </MenuItem>
      ))
    }
    isPhoneValid = (isValid :boolean, i:number)=>{
      return  this.state.clinicsErrors[i]?.phoneError ? "#F87171" : "#CBD5E1"
    }
    
  showCheckbox = (isValid:boolean)=>{
    return isValid ? checkBoxTrue : checkBoxFalse
  }
    handleError = (err: boolean) =>
     {
      return err && (
        <Typography
          style={AllStyle.errorTextStyle}
        >
          Please select an option
        </Typography>)
    }
    navigateToHome = ()=>{this.props.navigation.navigate("LandingPageWeb")}
    closeSnackbar = ()=>{
      this.setState({
        isSnackbarOpen:false
      })
    }
    handleClinicStyle = (index:number,id:any)=>{
     return (
      <>
      <Box style={{display:'flex',marginBottom:"1rem", justifyContent:this.state.veterinariClinicsData.length > 1 ? "space-between" : "flex-start"}}>
                      <Typography style={{fontSize:"16px", fontWeight:400,fontFamily:"Lato",color:"#0F172A"}}>Clinic {index +1}</Typography>
      { this.state.veterinariClinicsData.length > 1 && <Typography style={{color: "#ff5e5b", fontSize: "14px",fontFamily: "Lato",fontWeight: 'bold',lineHeight: "18px", cursor:"pointer"}} data-test-id='removeClinic' onClick={()=>{this.removeClinic(index,id)}}>Remove</Typography>}
                      
                      </Box>
      </>
     )
       
    }

    showPetsError = ()=>{
     return this.state.petsError && (
        <ErrPetsTypography
          style={{...AllStyle.errorTextStyle}}
        >
          Please select at least one option
        </ErrPetsTypography>
      )
    }
    showHelperText = ()=>{
      return this.state.has_rescue_group_error&& "Please enter a value"
    }

    renderRescuerImageBtns = ()=>{
      return (
        <ImageBtnBox >
                            <Grid item container direction="column">
                            <label htmlFor="rescuer_image_Upload">
                           <Button className="changeImgBtn" component="span" style={{fontFamily:"Lato", fontWeight:700,fontSize:"16px"}}>
                           {this.state.selectedProfileImg || this.state.userProfileData?.profile_image ? "Change" : "Upload"}  Image
                            </Button>
                            {this.state.imageError ? <Typography style={{...AllStyle.errorTextStyle, marginLeft:"8px"}}>Max size should not exceed more than 5 mb</Typography> : null}
                            {this.state.fileFormateErr ? <Typography style={{...AllStyle.errorTextStyle, marginLeft:"8px"}}>Invalid file format. Accepted formats: PNG, JPG and JPEG</Typography> : null}
                         
                           </label>
                           
                              <input
                        id="rescuer_image_Upload"
                        accept=".png, .jpg, .jpeg"
                        data-test-id="rescuer_image_Upload"
                        type="file"
                        onChange={this.handleAdopterImageUpload}
                        style={{ display: "none" }}
                    />
                              {this.state.selectedProfileImg || this.state.userProfileData?.profile_image ? (<Button style={{fontFamily:"Lato", fontWeight:700,fontSize:"16px", color:"#ff5e5b", textTransform:"unset", textDecoration:"underline", width:"fit-content"}} onClick={this.handleAdopterProfileDelete} data-test-id="rescuer-image-remove-btn" >Remove Image</Button>) : null}
                            </Grid>
                          </ImageBtnBox>
      )
    }
    renderAvatar = ()=>{
      return (
        <Avatar
                            alt={this.state?.userProfileData?.first_name}
                            src={this.state.selectedProfileImg || this.state.userProfileData.profile_image}
                            className="large"
                            style={{marginBottom:'16px',border:`5px solid ${this.state.selectedProfileImg || this.state.userProfileData.profile_image ? "#f0e5ff" :"#cdf0ee"}`, fontFamily:"Lato", fontWeight:700,fontSize:"16px"}}
                          >
                           <span style={{fontSize:"56px",fontFamily:"Inter",fontWeight:600}}>{this.state?.userProfileData?.first_name?.charAt(0)}</span> 
                          </Avatar>
      )
    }
    renderPhoneError = (isValid:boolean,i:number)=>{

      return  this.state.clinicsErrors[i]?.phoneError && (<Typography
        style={AllStyle.errorTextStyle}
      >
        Please enter a valid phone number
      </Typography>)
    }
    renderStarMark = ()=>{
      return (<span style={{ color: "#FF5E5B", fontFamily:"Lato", fontWeight:400,fontSize:"14px",lineHeight:"22px" }}>*</span>)
    }

    renderRescueGrpCheckbox = ()=>{
      return (
        <>
                      <Typography className={this.state.userProfileData.has_rescue_group ? "label" : "disabledlabel"}>Rescue Group Name  {this.state.userProfileData.has_rescue_group &&this.renderStarMark()}</Typography>
                      <InputField variant="outlined" placeholder="Rescue Group Name" fullWidth value={this.state?.rescueGroupData[0]?.attributes?.name} data-test-id='handleRescueGrpNameChange' onChange={(e:any)=>{this.handleRescueGrpNameChange(e)}}
                      disabled={!this.state.userProfileData.has_rescue_group} error={this.state.has_rescue_group_error} helperText={this.showHelperText( )}  />
                    </>
      )
    }
    handleDropdownColor=(condition:any)=>{
    return  condition=== "Select" ? "#94A3B8" : "#0F172A"
    }
    renderClinicData=()=>{
      return <Grid item lg={6} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {this.state.veterinariClinicsData.map((data,index)=>(
         <React.Fragment key={index}>
        {this.handleClinicStyle(index,data?.id)}
        <>
            <Typography className="label">Veterinarian Name  {this.renderStarMark()}</Typography>
            <InputField data-test-id='veterinarianName' variant="outlined"  fullWidth placeholder="Veterinarian Name" value={data.attributes.name} onChange={(e:any)=>{this.handleVeterinaryClinicNameChange(index,'name',e,'nameError',50)}} error={!data.attributes.name.trim() && this.state.clinicsErrors[index]?.nameError} helperText={this.state.clinicsErrors[index]?.nameError && !data.attributes.name.trim() && "Please enter a value"} />
          </>
          <>
            <Typography className="label">Clinic Name  {this.renderStarMark()}</Typography>
            <InputField data-test-id='clinicName' variant="outlined" fullWidth placeholder="Clinic Name" value={data.attributes.clinic_name} error={this.state.clinicsErrors[index]?.clinicNameError && !data.attributes.clinic_name.trim()} onChange={(e:any)=>{this.handleVeterinaryClinicNameChange(index,'clinic_name',e,'clinicNameError',100)}} helperText={this.state.clinicsErrors[index]?.clinicNameError && !data.attributes.clinic_name.trim() && "Please enter a value"} />
          </>
          {!this.state.isLoading &&   <>
            <Typography className="label">Contact Number  {this.renderStarMark()}</Typography>
            <div style={{ position: 'relative', marginBottom: "32px" }}>
              <label style={{
                position: 'absolute', top: 16, left: 100, fontFamily: "Lato",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px", color: '#94A3B8'
              }} htmlFor="">{!data.attributes.contact_number && " 00000 00000"} </label>

              <PhoneStyle
                data-test-id="txtInputPhonenumber"
                borderColor={this.isPhoneValid(!data.attributes.isPhoneValid, index )}
                className="custominput"
                value={data.attributes.contact_number}
                onChange={(e:any)=>{this.handleRescuerClinicsPhoneChange(index,e)}}
                defaultCountry="AE"
                countries={[]}
                international
              />
            {this.renderPhoneError(data.attributes.isPhoneValid,index)}
            </div>
          </>}</React.Fragment>
      ))
      }
      {this.state.veterinariClinicsData.length < 3 && <Typography onClick={this.handleAddClinic} data-test-id="add-clinic" style={{display:'flex', marginTop:'-8px', cursor:'pointer', color:"#03B5AA", fontFamily:"Lato", fontWeight:700}}><img src={AddCircle}  style={{ alignSelf:'center', marginRight:'0.6rem', fontFamily:'Lato'}} /> Add another</Typography>}
      </Grid>
    }
    // Customizable Area End
    
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                  <Loader loading={this.state.isLoading } />
            
        <Backdrop
                data-test-id='backdrop2'
                open={this.state.isSnackbarOpen}
                style={{ zIndex: 700 }}
            ><SnackBarStyle
                anchorOrigin={{
                  horizontal: "center",
                    vertical: "top",
                }}
                data-test-id='snackbar2'
                style={{ zIndex: 1000 }}
                open={this.state.isSnackbarOpen}
                autoHideDuration={5000}
                onClose={this.closeSnackbar}
            >
                <Typography style={AllStyle.popup as React.CSSProperties}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26"  viewBox="0 0 26 26" height="26" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
                    </svg>
                    {this.state.snackbarMessage}
                </Typography>
            </SnackBarStyle>
            </Backdrop>
         
        <UserProfileStyle>
          
          <ThemeProvider theme={theme}>
            <Container style={{marginBottom: "50px" , marginTop: "50px",maxWidth:'1100px' }}>
              <Grid style={{marginTop:'1rem'}}>
          <StyledBreadcrums aria-label="breadcrumb"  separator={<img src={LightArrow} />}  >
        <Link onClick={this.navigateToHome} color="inherit" style={{ ...AllStyle.hedingStyle, paddingInline: "6px", cursor: "pointer" }}  >
          Home
        </Link>
        <Typography color="textPrimary" style={{
    ...AllStyle.hedingStyle,
    color: "#0F172A",
    paddingInline: "6px",
  }} >My Profile</Typography>
      </StyledBreadcrums>
                <Paper style={{borderRadius:"10px"}}>
                  <Box>
                  <Box
                    style={{ backgroundImage: `url(${profileBackground})`, objectFit:'contain', height: "145px",borderTopLeftRadius: '8px',borderTopRightRadius:'8px' }}></Box>
                    <GridParent1
                      item
                      container
                      justifyContent="space-between"
                      style={{ padding: "0px 50px 50px 50px" }}
                      alignItems="flex-end"
                    >
                      <Box>
                        <GridChiled1 item container>
                          {this.renderAvatar()}
                          {this.renderRescuerImageBtns()}
                        </GridChiled1>
                      </Box>
                      <Box>
                        <Grid item container style={{height:'44px'}}>
                          <Button
                            variant="outlined"
                            startIcon={<LockOutlinedIcon />}
                            className="changePwdBtn"
                             onClick={this.handleOpen}
                          >
                            Change Password
                          </Button>
                        </Grid>
                      </Box>
                    </GridParent1>
                  </Box>
                  {this.renderRescuerChangePassword()}
                  <Gridcontainer container  >
                  <StyleHeadingGrid item lg={4}>
                    <Typography className="personal" >Personal information</Typography>
                  </StyleHeadingGrid>
                  <Grid item lg={6} style={{ display: 'flex', flexDirection: 'column',width: '100%' }}>

                    
                      <Typography className="label" >First Name</Typography>

                      <InputField variant="outlined" value={this.state.firstName} disabled fullWidth />
                    
                    <>
                      <Typography className="label">Last Name</Typography>
                      <InputField variant="outlined" value={this.state.lastName} disabled fullWidth />

                    </>
                    <>
                      <Typography className="label">Email</Typography>
                      <InputField variant="outlined" value={this.state.email} disabled fullWidth />
                      

                    </>
                   {!this.state.isLoading && <>
                      <Typography className="label">Phone Number  {this.renderStarMark()}</Typography>
                      <div style={{ marginBottom: this.handleStyle(), position: 'relative' }}>
                        <label style={{
                          position: 'absolute',left: 100, top: 16,  fontFamily: "Lato",
                          fontWeight: 400,
                          fontSize: "16px",
                          lineHeight: "24px", color: '#94A3B8'
                        }} htmlFor=""> {this.hanldePlaceholder()} </label>

                        <PhoneStyle
                          borderColor={this.handleColorPhone()}
                          className="custominput"
                          value={this.state.phoneNumber}
                          data-test-id="txtInputPhonenumber"
                          onChange={this.handlePhoneNumbberCallBack}
                          defaultCountry="AE"
                          countries={['AE']}
                          international
                          countryCallingCodeEditable={false}
                        />
                        {this.state.phoneError && <Typography
            style={{ ...AllStyle.errorTextStyle, marginBottom: "0.8rem" }}
          >
            Please enter a valid phone number
          </Typography>}
                      </div>
                      </>}
                      <>
                      <Typography className="label"> Emirate {this.renderStarMark()} </Typography>
                      <SelectFieldColor fullWidth  >
                        <Select
                          variant="outlined"
                          value={this.state.emirateName}
                          data-test-id="Emirate"
                          error={this.state.emirateError}
                          MenuProps={{
                            getContentAnchorEl: null,
                            MenuListProps: {
                              style: {
                                padding: "10px",
                                maxHeight: 398,
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                          onChange={this.handleAdopterEmirate}
                          style={{
                            fontWeight: 400,
                            fontFamily: "Lato",
                            color: this.handleDropdownColor(this.state.emirateName ) ,
                            fontSize: "16px",
                          }}
                          IconComponent={KeyboardArrowDown}
                          fullWidth
                        >
                          <MenuItemStyle
                          value={this.state.emirateName}
                            >
                           Select
                          </MenuItemStyle>
                          {this.hanldeDropDown(this.state.emirateData)}
                        </Select>
                        {this.handleError(this.state.emirateError)}
                      </SelectFieldColor>

                    </>
                    <>
                      <Typography className="label">Area  {this.renderStarMark()}</Typography>
                      <InputField placeholder="Area" variant="outlined" fullWidth value={this.state.areaName} error={this.state.areaError} helperText={this.state.areaError && this.areaFeildErrorMessage()} data-test-id="areaName"  onChange={this.hanldeDropDownAreaSelect} />
                    </>
                    <>
                      <Typography className="label">Gender</Typography>
                      <SelectField fullWidth >
                        
                        <Select
                          variant="outlined"
                          value={this.state.gender}                          
                          MenuProps={{
                            MenuListProps: {
                              style: {
                                padding: "10px",
                                maxHeight: 398,
                              },
                            },
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          }}
                          disabled
                          fullWidth
                          style={{
                            fontWeight: 400,
                            fontSize: "16px",
                            fontFamily: "Lato",
                            color: this.handleColorStyle("gender")
                          }}
                          error={this.state.genderError}
                          IconComponent={KeyboardArrowDown}
                        >
                          <MenuItem
                            value={this.state.gender}
                            style={{
                              fontWeight: 400,
                              display: "none",
                              color: "#64748B",
                              fontSize: "16px",
                              fontFamily: "Lato",
                            }}
                          >
                              {this.state.gender}
                          </MenuItem>
                        </Select>
                        {this.handleError(this.state.genderError)}
                      </SelectField>

                    </>
                      <>
                      <Typography className="label">Date of Birth</Typography>
                      <InputField  fullWidth variant="outlined" disabled InputProps={{endAdornment :<DateRange />}}   value={this.state.dOB}/>

                    </>
                    <>
                      <Typography className="label">
                        Nationality
                        </Typography>
                      <SelectFieldColor fullWidth >
                        <Select
                          fullWidth
                          onChange={this.handleNationality}
                          variant="outlined"
                          value={this.state.nationality}
                          placeholder="Select"
                          MenuProps={{
                            getContentAnchorEl: null,
                            MenuListProps: {
                              style: {
                                maxHeight: 398,
                                padding: "10px",
                              },
                            },
                            anchorOrigin: {
                              horizontal: "left",
                              vertical: "bottom",
                            },
                          } }
                          data-test-id="Nationality"
                      style={{    
                            fontFamily: "Lato",
                            fontSize: "16px",
                            fontWeight: 400,
                            color: this.handleDropdownColor(this.state.nationality) , 
                          }}
                          IconComponent={KeyboardArrowDown}
                        >
                          <MenuItem
                           
                            style={{
                              fontWeight: 400,      
                              display: "none",
                              color: "#94A3B8",
                              fontFamily: "Lato",
                              fontSize: "16px",
                                                  }}
                            value="Select"
                          > Select
                          </MenuItem>{this.hanldeDropDownNational(this.state.nationalityData)}</Select>
                      </SelectFieldColor>
                    </>
                    
                  </Grid>
                </Gridcontainer>

                <hr style={{ width: '90%', border:'none',height:'1px',backgroundColor:'#E2E8F0' }} />

                <AssociatedRescueGroupGrid container >
                <AssociatedRescueGroupGridChiled item lg={4}>
                    <Typography className="personal">Associated Rescue Group</Typography>
                  </AssociatedRescueGroupGridChiled>
                  <Grid item lg={6} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Typography id="test" data-test-id="rescue-box-check" onClick={this.handleCheckRescueGrp} style={{ color: "#0F172A", marginBottom: "16px", display: "flex", alignItems: "center", fontWeight: 400, fontSize: "16px", fontFamily: 'Lato', cursor: "pointer", lineHeight: "24px" }}>
<img src={this.showCheckbox(!this.state.userProfileData.has_rescue_group)} data-test-id="rescue-box-check"  style={{ marginRight: "3px", width: "20px", height: "20px" }} />
I am not associated with any rescue group.
</Typography>
                   
                      {this.renderRescueGrpCheckbox()}    
                  </Grid>
                </AssociatedRescueGroupGrid>

                <hr style={{ width: '90%', border:'none',height:'1px', backgroundColor:'#E2E8F0' }} />

                <VeterinaryClinicsGrid container >
                <Grid item lg={4} sm={10}>
                    <Typography className="personal">
                      Veterinary Clinics  {this.renderStarMark()}
                      <LightTooltip style={{display:'inline'}}
                      data-test-id='veterinary'
                        open={this.state.tooltipOpenchannelVeterinaryTooltip}
                        onClose={this.handleTooltipCloseChanelsData}
                      
                          title="Please provide the general information for at least one veterinary clinic that you use. Such clinic must agree to have their contact information provided as they maybe contacted to confirm your identity."
                          placement="right" >
                          <IconButtonStyleShow data-test-id='infoComp1'  onClick={this.handleVeterinary} onMouseLeave={()=>this.handleVeterinaryMouseOver()}
                            onMouseEnter={()=>this.handleVeterinaryMouseHover()}
>
                            <img src={infoIcon} />
                          </IconButtonStyleShow>
                        </LightTooltip>
                    </Typography>
                  </Grid>
               {this.renderClinicData()}   
                </VeterinaryClinicsGrid>
                
                <hr style={{ width: '90%', border:'none',height:'1px',backgroundColor:'#E2E8F0' }} />
                <WhichAnimalCategoriesGrid container  >
                  <WhichAnimalCategoriesGridChiled item lg={4}>
                    <Typography className="personal" style={{display:"flex", alignItems:"center"}}>
                      <WhichAnimalCategoriesSpan >
                      <span> <span>Which animal categories do you primarily rescue?</span>   {this.renderStarMark()}  </span> 
                    
                    <LightTooltip
                       open={this.state.tooltipOpenchannelCategoriesTooltip}
                       onClose={this.handleTooltipCloseChanelsDataCategories}
                       data-test-id="animals"
                        title="Please check which animals you currently rescue and would like to list"
                        placement="right" style={{display:'inline',padding:0, marginLeft:"8px"}} >
                        <IconButtonStyleShow data-test-id='infoComp'  onClick={this.handleCategories} onMouseLeave={()=>this.handleCategoriesMouseOver()}
                            onMouseEnter={()=>this.handleCategoriesMouseHover()} >
                          <img src={infoIcon} />
                        </IconButtonStyleShow>
                      </LightTooltip>
                      </WhichAnimalCategoriesSpan>
                    </Typography>
                    {this.showPetsError()}
                  </WhichAnimalCategoriesGridChiled>
                  <Grid item lg={3} sm={3} >

                    <Box className="whichAnimalCheckBox" >

                      {["Cats", "Birds", "Rabbits"].map((item: string, id: number) => {
                        return <Typography key={id}
                        style={AllStyle.petRefrence}
                           onClick={() => {
                            this.handlePetSelection(item)
                          }}>
                          <img src={this.showCheckbox(this.state.pets.includes(item))} className="checkBoxStyle" />
                          {item}
                        </Typography>
                      })}

                    </Box>
                  </Grid>
                  <Grid item lg={3} >
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      {["Dogs", "Reptiles", "Little Critters"].map((item: string, id: number) => {
                        return <Typography
                          key={id}
                          style={AllStyle.petRefrence}
                          data-test-id='handlePetSelection'
                           onClick={() => {
                            this.handlePetSelection(item)
                          }} >
                          <img src={this.showCheckbox(this.state.pets.includes(item))} className="checkBoxStyle" />
                          {item}
                        </Typography>
                      })}

                    </Box>                    
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >

                    </Box>
                  </Grid>
                </WhichAnimalCategoriesGrid>
                <hr style={{ width: '90%', border:'none',height:'1px',backgroundColor:'#E2E8F0' }} />
                <PostPreferencesGrid container  >
                  <PostPreferencesGridChild item lg={4} sm={10}>
                    <Typography className="personal" style={{ marginRight:"1rem"}}>
                      Post Preferences
                    </Typography>
                  </PostPreferencesGridChild>
                  <Grid item lg={6} sm={10} >
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Typography 
                          className="petReference"
                          data-test-id="post-name-preference"
                           onClick={this.handlePostsNamePreference}>
                          <img src={this.showCheckbox(this.state.userProfileData.publish_name_in_post)} className="checkBoxStyle" />
                          Display my name to public people in my posts
                        </Typography>
                        <Typography 
                          className="petReference"
                          data-test-id="post-image-preference"
                           onClick={this.handlePostsImagePreference}>
                          <img src={this.showCheckbox(this.state.userProfileData.publish_image_in_post)} className="checkBoxStyle" />
                          Display my picture to public people in my posts
                        </Typography>
                    </Box>
                  </Grid>
                </PostPreferencesGrid>
                <hr style={{ width: '90%', border:'none',height:'1px',backgroundColor:'#E2E8F0' }} />
                <SociallinksGrid container  >
                <SociallinksGridChiled item lg={4}>
                    <Typography className="personal">Social  links</Typography>
                  </SociallinksGridChiled>
                  <Grid item lg={6} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <>
                        <Typography className="label">Facebook</Typography>
                        <InputField variant="outlined" fullWidth data-test-id="facebook_url" error={this.state.facebookError} helperText={this.state.facebookError && "Please provide valid link"}
                         name={'facebook_url'} placeholder="Facebook Account Link" value={this.state.userProfileData.facebook_url} onChange={(e:any)=>{this.socialMediaChange('facebook_url',e.target.value,"facebookError")}}  />
                      </>
                      <>
                        <Typography className="label">TikTok</Typography>
                        <InputField variant="outlined" fullWidth data-test-id="tiktok_url" error={this.state.tiktokError} helperText={this.state.tiktokError && "Please provide valid link"}
                         name={'tiktok_url'} placeholder="TikTok Account Link" value={this.state.userProfileData.tiktok_url} onChange={(e:any)=>{this.socialMediaChange('tiktok_url',e.target.value,"tiktokError")}} />
                      </>
                      <>
                        <Typography className="label">LinkedIn</Typography>
                        <InputField variant="outlined" fullWidth data-test-id="linkedIn_url" error={this.state.linkedinError} helperText={this.state.linkedinError && "Please provide valid link"}
                         name={'linkedin_url'} placeholder="LinkedIn Account Link" value={this.state.userProfileData.linkedin_url} onChange={(e:any)=>{this.socialMediaChange('linkedin_url',e.target.value,"linkedinError")}}  />
                      </>
                      <>
                        <Typography className="label">Snapchat</Typography>
                        <InputField variant="outlined" fullWidth data-test-id="snap_url" error={this.state.snapchatError} helperText={this.state.snapchatError && "Please provide valid link"}
                        name={'snapchat_url'} placeholder="Snapchat Account Link" value={this.state.userProfileData.snapchat_url} onChange={(e:any)=>{this.socialMediaChange('snapchat_url',e.target.value,"snapchatError")}} />
                      </>
                      <>
                        <Typography className="label">Instagram</Typography>
                        <InputField variant="outlined" fullWidth data-test-id="insta_url" error={this.state.instaError} helperText={this.state.instaError && "Please provide valid link"}
                         name={'instagram_url'} placeholder="Instagram Account Link" value={this.state.userProfileData.instagram_url} onChange={(e:any)=>{this.socialMediaChange('instagram_url',e.target.value,"instaError")}}  />
                      </>
                  </Grid>
                </SociallinksGrid>
                <hr style={{ width: '90%', border:'none',height:'1px',backgroundColor:'#E2E8F0' }} />
                <GridBox container >
                  <Grid item lg={4}>

                  </Grid>
                  <Grid item lg={6}>
                <Box style={{ display: 'flex' }}>
                  <button className="changeBtn" data-test-id="update-rescuer-profile" onClick={this.handleSubmitRescuerProfileUpdate}
                  ><img src={RightImg} />Save Changes</button>
                  <button className="cancelBtn" data-test-id="cancel-changes" style={{cursor:"pointer"}} onClick={()=>{this.getUserProfileAdopterDetails(true)}}>Cancel</button>
                </Box>
                </Grid>
                </GridBox>
                </Paper>
              </Grid>
            </Container>
              </ThemeProvider>
              
              </UserProfileStyle>
              <DiologComponent  success={this.state.isModalOpen} handleClose={this.handleCloseSnackBar} firstHeading="PASSWORD UPGRADE IS COMPLETE!" secondHeading="Your password has been successfully changed! Now you're all set to continue saving the day for your rescues." ButtonName={"OKAY"}  />
   
          </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start


const IconButtonStyleShow = styled(IconButton)({

  "&.MuiIconButton-root:hover": {
    background: "unset"
  },
  '@media (max-width:600px )': {
    display: "block"
  },
})
const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiOutlinedInput: {
      // Name of the rule
      input: {
        // Some CSS
      padding:'0.8rem',
    },
    },
  },
});
const ImageBtnBox=styled(Box)({
  marginTop:'1rem',
  "@media (max-width:452px)":{
    marginBottom:'16px',
  },
  "@media (max-width:821px)":{
    marginTop:'unset',
    marginBottom:'16px',
   }
 })
 const ModalBox=styled(Box)({
 width: "600px",
   "@media (max-width:640px)":{
    width: "100%"
   }
 })
 const CustomCardContent=styled(CardContent)({
   padding: "48px 48px 0 48px" ,
    "@media (max-width:353px)":{
      padding: "48px 20px 0 20px" ,

     }

 })
 const ButtonBox=styled(Box)({
  
    display: 'flex', flexDirection: 'row',marginTop: "18px",
   "@media (max-width:500px)":{
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    }

})
const Gridcontainer=styled(Grid)({
  display: 'flex',
   justifyContent: "space-around",
    padding: '10px',
  "@media (max-width:1279px)":{
   padding: '10px 64px 10px 64px'
  },
  "@media (max-width:500px)":{ padding: '10px 20px 10px 20px' ,
},
 })
 const VeterinaryClinicsGrid=styled(Grid)({display: 'flex', 
  
  justifyContent: "space-around", 
  padding: '10px 10px 40px 10px' ,

  "@media (max-width:1279px)":{
    padding: '10px 64px 40px 64px' ,
    justifyContent:'unset'
  },
  "@media (max-width:500px)":{ padding: '10px 20px 40px 20px' ,
},
 })
 const AssociatedRescueGroupGrid=styled(Grid)({padding: '10px' ,
  
 display: 'flex', justifyContent: "space-around", 

 "@media (max-width:1279px)":{ padding: '10px 64px 10px 64px' ,
 },
 "@media (max-width:500px)":{ padding: '10px 20px 10px 20px' ,
},
})
const WhichAnimalCategoriesGrid=styled(Grid)({display: 'flex', 
justifyContent: "space-around", 
padding: '10px', flexWrap:"wrap" ,
"& .whichAnimalCheckBox":{
   display: "flex", 
   flexDirection: "column", 
   paddingLeft: '44px' ,
   "@media (max-width:925px)":{ paddingLeft: 'unset' ,
},
},
"@media (max-width:1279px)":{ padding: '10px 64px 10px 64px' ,justifyContent:'unset'
},

"@media (max-width:925px)":{ padding: '10px 64px 10px 64px' , flexDirection:'column'
},
"@media (max-width:613px)":{ padding: '10px 113px 10px 64px' ,
},
"@media (max-width:500px)":{ padding: '10px 53px 10px 20px' ,
},
})
const WhichAnimalCategoriesSpan=styled('span')({
  paddingLeft:'12px',
  "@media (max-width:925px)":{ 
    paddingLeft:'0',
},
"@media (max-width:1279px)":{ paddingLeft:'0px',
},
})
const PostPreferencesGrid=styled(Grid)({ display: 'flex', justifyContent: "space-around", padding: '10px' ,

"@media (max-width:1279px)":{ padding: '10px 64px 10px 64px' ,justifyContent:'unset'
},
"@media (max-width:500px)":{ padding: '10px 20px 10px 20px' ,
},
})
const SociallinksGrid=styled(Grid)({ display: 'flex', padding: '10px' , justifyContent: "space-around",

"@media (max-width:1279px)":{ padding: '10px 64px 10px 64px' ,
},
"@media (max-width:500px)":{ padding: '10px 20px 10px 20px' ,
},
})


const AllStyle = {
  petRefrence:{
    color: "#0A2239",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: 'Lato',
    cursor: "pointer",
    lineHeight: "19.2px",
    width:'max-content'
  },
  errorTextStyle: {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px",
  },
  popup: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    padding:"9px 16px 9px 8px ",
    textAlign:"center",
    gap:"8px",
    alignItems: "center",
 
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    color: "#0F172A",
    lineHeight: "24px",
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",

  },
  hedingStyle: {
    color: "#475569",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textDecoration:"none"
  },
};
const SnackBarStyle = styled(Snackbar)({
  "&.MuiSnackbar-anchorOriginTopCenter":{
    top:"113px",
  }
  })
  const GridBox=styled(Grid)({
    display:'flex',
    justifyContent:"space-around",
    '@media (max-width:1279px )': {
      justifyContent:"center",
      alignItems:'center'
    },
  })
 const ErrPetsTypography=styled(Typography)({
  paddingLeft:"12px",
  '@media (max-width:925px)': {
    paddingLeft:'0',
  },
 })
 const StyleHeadingGrid=styled(Grid)({
  
  '@media (max-width:1279px)': {
    marginBottom:'8px'
  },
 })
 const AssociatedRescueGroupGridChiled=styled(Grid)({
  '@media (max-width:1279px)': {marginBottom:'8px'},
    
  
 })
 const PostPreferencesGridChild=styled(Grid)({
  '@media (max-width:1279px)': {marginBottom:'8px'
  },
 })
 const WhichAnimalCategoriesGridChiled=styled(Grid)({
  '@media (max-width:1279px)': 
  {marginBottom:'8px'
  },
 })
 const SociallinksGridChiled=styled(Grid)({
  '@media (max-width:1279px)': 
  {marginBottom:'8px'
  },
 })
 const CustombuttonChangePass=styled('button')({
  width: '174px',
  marginRight: '16px',
  fontWeight: 600,
  fontFamily: "Lato",
  fontSize: '16px',
  borderRadius: '8px',
  background: '#03B5AA',
  color: '#FFF',
  border: 'none',
  height: '56px',
  padding: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '40px',
  cursor:"pointer",
  '@media (max-width:500px)': {
      marginBottom:'10px',
      marginRight: 'unset',
      width: '100%',
  }

})
const CustomCancelbutton=styled('button')({
  background: '#FFF',
  marginRight: '16px',
  fontSize: '16px',
  color: '#03B5AA',
  fontFamily: "Lato",
  fontWeight: 600,
  width: '112px',
  border: '1px solid #03B5AA',
  height: '56px',
  borderRadius: '8px',
  padding: '16px 32px',
  display: 'flex',
  marginBottom: '40px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor:"pointer",
  '@media (max-width:500px)': {
    width: '100%',
    marginBottom:'unset',
    marginRight: 'unset',
}
})
const GridParent1 =styled(Grid)({

  '@media(max-width: 821px)': {flexWrap:'unset',  alignItems:'flex-start', flexDirection:'column',
 }
})
const GridChiled1=styled(Grid)({
  '@media(max-width: 821px)': { flexDirection:'column',
     flexWrap:'unset',
     alignItems:'flex-start', 
 }
})
// Customizable Area End
