import React, { Component } from "react";
import { HomeGrid } from "../../blocks/email-account-login/src/CataloguePage.web";
import HeaderController, { WhoWeAreInterface } from "../../blocks/email-account-registration/src/Components/HeaderController.web";
import {Typography,Button,Dialog,DialogContent,IconButton,styled,Box} from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { Allstyle } from "../../blocks/favourites/src/FavouritesWeb.web";
import storage from '../../framework/src/StorageProvider';
import Loader from "../../components/src/Loader.web";
import { checkBoxTrue ,checkBoxFalse} from "../../blocks/email-account-registration/src/assets";4


interface Props {
 handleClose:any
 privacyAndTerms:boolean;
 firstHeading:string;
 secondHeading:string;
 handleCloseButton:any
}
interface Props2 {
  terms_accept:boolean
  handleClose:any
  terms_acceptCheck:boolean;
  checkederror:boolean;
  handleTermsAndPolicy:any
  handleTermsAndPolicyApi:any
  termsAndConditonPopUp:boolean
    privacyPolicyPopUp:boolean
    opentermsAndConditon:any
    openPrivacyAndPolicy:any
    closeTerms:any
    closePrivacy:any
 }

const ButtonComponent=styled(Button)({
  borderRadius: "8px",
  color: "03B5AA",
  fontFamily: "Inter",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  minWidth:"120px",height:"56px",width:"120px",

  textTransform: "inherit",
  border: "1px solid #03B5AA",
  '@media (max-width: 500px)': {
   minWidth:"295px",
  height: "44px",
  border:"unset"

  },
  '@media (max-width: 400px)': {
    minWidth:"250px",
   height: "44px",
  border:"unset"

 
   },
})
const ButtonComponent2=styled(Button)({
  background: '#03B5AA',
    border: '1px solid #03B5AA',
    borderRadius: 8,
    padding: "10px 16px 10px 12px",
    lineHeight: '24px',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 16,
    color: '#fff',
    minWidth:"120px" ,marginLeft:"0px", fontFamily: "Inter",width:"120px",height:"56px",
    '&.MuiButton-root:hover':{
      background: '#03B5AA',

    },
  '@media (max-width: 500px)': {
    width:"295px",
  height: "44px",

  },
  '@media (max-width: 400px)': {
    width:"250px",
   height: "44px",
 
   },
})
const DiologStyle3 = styled(Dialog)({
  "& .MuiDialog-container":{
    overflow:"auto"
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "900px",
    minWidth: "900px",
    overflow:"auto",
    borderRadius: "8px 8px 32px 8px"
  },
  "@media (max-width:1000px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "unset",
      borderRadius: "8px 8px 32px 8px"
    },
  },
  "@media (max-width:500px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "327px",
      borderRadius: "8px 8px 8px 8px"
    },
  },
  "@media (max-width:400px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "unset",
      borderRadius: "8px 8px 8px 8px"
    },
  },
})
const DivStyle = styled('div')({
  display:"flex",justifyContent:"space-between",alignItems:"center" ,
     borderBottom:"1px solid #E2E8F0"
     ,padding:"24px 40px",
     "@media (max-width:500px)": {
      padding:"16px"
    },
})
const DivStyle2 = styled('div')({
  padding:"0px 20px",marginBottom:"40px" ,
     "@media (max-width:500px)": {
      marginBottom:"unset",
      padding:"0px 9px 0px 16px"
    },
})
const BoxStyle=styled(Box)({
  borderTop:"1px solid #E2E8F0",height:"104px",display:"flex",justifyContent:"end",
  "@media (max-width:500px)": {
   alignItems:"center",

    justifyContent:"center",
    height:"76px"
  },

})
const ButtonStyleClose=styled(Button)({
  color: "#FFFFFF",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    borderRadius: "8px",
    width: "120px",
    margin:"24px",
    height: "56px",
    background: "#03B5AA",
    textTransform: "inherit" as "inherit",
    '&.MuiButton-contained:hover':{
      background: "#03B5AA",

    },

  "@media (max-width:500px)": {
    margin:"16px",
    width:"155px",
    height:"44px"
  },
})
const DiologStyle2 = styled(Dialog)({
  "& .MuiDialog-container":{
    overflow:"auto"
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "600px",
    minWidth: "600px",
    overflow:"auto",
    borderRadius: "8px 8px 32px 8px"
  },
  "@media (max-width:1000px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "unset",
      borderRadius: "8px 8px 32px 8px"
    },
  },
  "@media (max-width:500px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "327px",
      borderRadius: "8px 8px 8px 8px"
    },
  },
  "@media (max-width:400px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "unset",
      borderRadius: "8px 8px 8px 8px"
    },
  },
})
const BoxStyleImportant=styled(Box)({
  display:"flex",justifyContent:"space-between" ,alignItems:"center",padding: "24px 16px 24px 40px ", borderBottom: "1px solid #E2E8F0",
  "@media (max-width:500px)": {
    padding:"16px", 
    
  },

})
const BoxStyleImportant2=styled(Box)({
  paddingInline: "40px",paddingTop:"24px",
  "@media (max-width:500px)": {
    paddingInline:"16px", 
    
  },

})

const TypographyStyle2=styled(Typography)({
  fontSize:"24px",fontFamily:"Lato",color:"#0A2239",fontWeight:700,lineHeight:"32px",letterSpacing:"-0.12px",
  "@media (max-width:500px)": {
    fontSize:"18px",fontFamily:"Lato",color:"#0A2239",fontWeight:700,lineHeight:"32px",letterSpacing:"-0.12px"
  },

})
const BoxStyleImportant3=styled(Box)({
  padding:"24px",gap:"16px",display:"flex",
  "@media (max-width:500px)": {
    padding:"16px",
    flexDirection:"column-reverse",
    justifyContent:"center"
    
  },

})
const BoxStyleImportant4=styled(Box)({
  justifyContent:"flex-end",display:"flex",borderTop:"1px solid #E2E8F0",
  "@media (max-width:500px)": {
    
    justifyContent:"center"
    
  },

})

const MainBox=styled(Box)({
  paddingInline: "40px",
  maxHeight:"336px", 
  marginTop: "40px ",
  display:"flex",
  overflowY:"auto",
  "&::-webkit-scrollbar": {
    width: "2px",

  },
  "&::-webkit-scrollbar-track": {
    backgroundColor:"#94A3B8"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#0A2239",
    borderRadius: "8px"
  },
  "@media (max-width:600px)": {
    padding:"0px 9px 0px 16px", 
    maxHeight:"456px",
    marginTop:"24px"
  },
 
})


export  class ImportantUpadate  extends Component<Props2>{
render(){
  return   <DiologStyle2 onClose={this.props.handleClose} data-test-id="closeId" aria-labelledby="customized-dialog-title"
  open={this.props.terms_accept}>

<BoxStyleImportant >

<TypographyStyle2>
HEADS UP - IMPORTANT UPDATE INCOMING!
</TypographyStyle2>
<IconButton aria-label="close"  data-test-id="closeId2" onClick={this.props.handleClose} style={{ color: "#0A2239" }}>
<CloseIcon />
</IconButton>
</BoxStyleImportant>
<BoxStyleImportant2 style={{ }}>

<Typography style={{ fontSize: "16px",color:"#0F172A", fontWeight: 400, fontFamily: "Inter", marginBottom: "24px",lineHeight:"24px" }}>
{"We've got some news that you won't want to miss! Check out the latest updates to our policies to stay in the loop. This update is as exciting as a new squeaky toy!"}
</Typography>
<Box style={{ display: "flex", alignItems: "flex-start",marginBottom:this.props.checkederror?"0px":"24px", }}> 

<img data-test-id="hanldeCheckBox"  src={this.props.terms_acceptCheck ? checkBoxTrue : checkBoxFalse} style={{ marginRight: "8px", cursor: "pointer", width: "20px", height: "20px" }} onClick={() => {
      this.props.handleTermsAndPolicy()
    }} />
<Typography data-test-id="Conditions" style={{ color: "#0F172A", fontWeight: 400, fontSize: "16px", fontFamily: 'Inter', cursor: "pointer", lineHeight: "24px" }} >
      <span onClick={() => {
      this.props.handleTermsAndPolicy()
    }} data-test-id="Conditions2" >

I have read and agree with revised{" "}
      </span>
      <span style={{color: "#03B5AA",
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight:"24px"}} data-test-id="terms" onClick={()=>{
        this.props.openPrivacyAndPolicy()
      }}>Privacy Policies </span>
      <span  data-test-id="handleTermsAndPolicy"  onClick={() => {
      this.props.handleTermsAndPolicy()
    }}>

      and{" "}
      </span>
      <span style={{color: "#03B5AA",
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight:"24px"}}  data-test-id="privacy"onClick={()=>{
      this.props.opentermsAndConditon()
      }}>
       Terms and Conditions
      </span>
    </Typography>

</Box>
  {this.props.checkederror && <Typography style={{
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px",
    marginTop: "3px",
   marginBottom:"24px"
  }}>
    Please accept the privacy policy and terms & conditions
  </Typography>}
</BoxStyleImportant2>
<BoxStyleImportant4  style={{ }}>
<BoxStyleImportant3 >

<ButtonComponent  onClick={this.props.handleClose}  data-test-id="closeId3"  style={{}}>
{"Cancel"}
</ButtonComponent>
<ButtonComponent2   data-test-id="agree" onClick={this.props.handleTermsAndPolicyApi}>
{"Agree"}
</ButtonComponent2>
</BoxStyleImportant3>
</BoxStyleImportant4>

</DiologStyle2>
}
}


export class PrivacyPolicy extends Component<Props>{
  state = {
    terms_and_conditions_dynamic: '',
    privacy_policy_dynamic: '',
  };
  async componentDidMount() {
    const privacy_policy_dynamic = await storage.get("privacyAndPolicy");
    const terms_and_conditions_dynamic = await storage.get("termsAndCondition");

    this.setState({
      terms_and_conditions_dynamic,
      privacy_policy_dynamic,
    });
  }
  render(){
    const content= this.props.firstHeading!=="PRIVACY POLICY!" ?this.state.terms_and_conditions_dynamic:this.state.privacy_policy_dynamic
  
    return <DiologStyle3
    open={this.props.privacyAndTerms}
    onClose={this.props.handleClose} 
     >
    <DialogContent style={{padding:"0"}}>
    <DivStyle
    >
         <TypographyStyle2 >
        {this.props.firstHeading}
      </TypographyStyle2>
      <IconButton 
      aria-label="close" onClick={this.props.handleClose} style={{ color: "#0A2239",padding:"0 !important" }}
      >
        <CloseIcon />
      </IconButton>

    </DivStyle>
    <DivStyle2 >
     <MainBox >
     <div style={{fontWeight:500,color:"0A2239",fontFamily:"Lato",fontSize:"16px",lineHeight:"24px"}} dangerouslySetInnerHTML={{__html:content}} />
     </MainBox>
    </DivStyle2>
 <BoxStyle >

      <ButtonStyleClose variant="contained"  onClick={this.props.handleCloseButton}>
      OKAY
      </ButtonStyleClose>
 </BoxStyle>
    </DialogContent>

  </DiologStyle3>
  }
}


export default class WhoAreWe extends HeaderController {
 
    render() {
      //@ts-ignore
        const data:WhoWeAreInterface=this.state.whoWeAreData;
        
        return (
            <>
  <Loader loading={Object.keys(this.state.whoWeAreData).length==0?true:false } />
            <HomeGrid style={{marginBottom:"48px",
  minHeight: 'calc(100vh - 247px)', 
          }}>
            <Box style={{display:"flex",alignItems:"center",marginBottom:"24px",gap:"6px"}}>

            <Typography style={{ ...Allstyle.hedingStyle,  cursor: "pointer" }} onClick={() => {
          this.props.navigation.navigate("LandingPageWeb")
        }}>
          Home
        </Typography>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
            fill="#64748B"
          />
        </svg>
        <Typography
          style={{
            ...Allstyle.hedingStyle,
            color: "#0F172A",
            
          }}
        >
       Our story
        </Typography>
            </Box>
            <Typography
          style={{
            color: "#0F172A",
            fontFamily: "Inter",
            fontSize: "30px",
            fontWeight: 700,
            lineHeight: "40px",
            letterSpacing: "-0.15px",
          }}
        >
        {data?.attributes?.title ||  "Our Story"}
        </Typography>
     <div  dangerouslySetInnerHTML={{__html: data?.attributes?.description}} />
            </HomeGrid>
            </>
        );
    }
}
export  class WhatWeDo extends HeaderController {
 
    render() {
      //@ts-ignore

        const data:WhoWeAreInterface=this.state.whatWeDoData;

        return (
            <>
  <Loader loading={Object.keys(this.state.whatWeDoData).length==0?true:false } />

            <HomeGrid style={{marginBottom:"48px",
  minHeight: 'calc(100vh - 247px)', 

          }}>
              <Box style={{display:"flex",alignItems:"center",marginBottom:"24px",gap:"6px"}}>

             <Typography style={{ ...Allstyle.hedingStyle,  cursor: "pointer" }} onClick={() => {
          this.props.navigation.navigate("LandingPageWeb")
        }}>
          Home
        </Typography>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
            fill="#64748B"
          />
        </svg>
        <Typography
          style={{
            ...Allstyle.hedingStyle,
            color: "#0F172A",
           
          }}
        >
         Our work
        </Typography>
              </Box>
              <Typography
          style={{
            color: "#0F172A",
            fontFamily: "Inter",
            fontSize: "30px",
            fontWeight: 700,
            lineHeight: "40px",
            letterSpacing: "-0.15px",
          }}
        >
                  {data?.attributes?.title || "Our Work"}

        </Typography>
              
            <div  dangerouslySetInnerHTML={{__html: data?.attributes?.description}} />
            </HomeGrid>
            </>
        );
    }
}

export  class Checklist_for_Adopters extends HeaderController {
 
  render() {
    //@ts-ignore

      const data:WhoWeAreInterface=this.state.checklistForAdopters;

      return (
          <>
<Loader loading={Object.keys(this.state.checklistForAdopters).length==0?true:false } />

          <HomeGrid style={{marginBottom:"48px",
minHeight: 'calc(100vh - 247px)', 

        }}>
            <Box style={{display:"flex",alignItems:"center",marginBottom:"24px",gap:"6px"}}>

           <Typography style={{ ...Allstyle.hedingStyle,  cursor: "pointer" }} onClick={() => {
        this.props.navigation.navigate("LandingPageWeb")
      }}>
        Home
      </Typography>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
          fill="#64748B"
        />
      </svg>
      <Typography
        style={{
          ...Allstyle.hedingStyle,
          color: "#0F172A",
         
        }}
      >
       About Us
      </Typography>
            </Box>
            <Typography
        style={{
          color: "#0F172A",
          fontFamily: "Inter",
          fontSize: "30px",
          fontWeight: 700,
          lineHeight: "40px",
          letterSpacing: "-0.15px",
        }}
      >
                {data?.attributes?.title || "About Us"}

      </Typography>
            
          <div style={{wordBreak:"break-all"}} dangerouslySetInnerHTML={{__html: data?.attributes?.description}} />
          </HomeGrid>
          </>
      );
  }
}
export  class Petooti_Process extends HeaderController {
 
  render() {
    //@ts-ignore

      const data:WhoWeAreInterface=this.state.peetotiProcess;

      return (
          <>
<Loader loading={Object.keys(this.state.peetotiProcess).length==0?true:false } />

          <HomeGrid style={{marginBottom:"48px",
minHeight: 'calc(100vh - 247px)', 

        }}>
            <Box style={{display:"flex",alignItems:"center",marginBottom:"24px",gap:"6px"}}>

           <Typography style={{ ...Allstyle.hedingStyle,  cursor: "pointer" }} onClick={() => {
        this.props.navigation.navigate("LandingPageWeb")
      }}>
        Home
      </Typography>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
          fill="#64748B"
        />
      </svg>
      <Typography
        style={{
          ...Allstyle.hedingStyle,
          color: "#0F172A",
         
        }}
      >
       Petooti Process
      </Typography>
            </Box>
            <Typography
        style={{
          color: "#0F172A",
          fontFamily: "Inter",
          fontSize: "30px",
          fontWeight: 700,
          lineHeight: "40px",
          letterSpacing: "-0.15px",
        }}
      >
                {data?.attributes?.title || "Petooti Process"}

      </Typography>
            
          <div  dangerouslySetInnerHTML={{__html: data?.attributes?.description}} />
          </HomeGrid>
          </>
      );
  }
}