Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";
exports.sortByMenuItem = [
  {
    id: 0,
    name: "Created date - newest",
    value:"newest"
  },
  {
    id: 1,
    name: "Created date - oldest",
    value:"oldest"
  },

];
exports.btnExampleTitle = "CLICK ME";
exports.helpcentreAPIEndPoint = "/help_centre/question_type";
exports.httpGetType = "GET";

exports.GET = "GET";
exports.POST = "POST";
exports.createPostEndPoint = "/bx_block_communityforum/posts";
exports.getAllTagesApiEndPoint = "/bx_block_hashtags/hashtags";

// Customizable Area End
