import React from "react";
import { Backdrop, Snackbar, Typography, styled } from "@material-ui/core";

interface SnackbarProps {
    message: string;
    open: boolean;
    handleCloseFunc:Function
}


const CustomSnackbar: React.FC<SnackbarProps> = ({handleCloseFunc, message, open }) => {
    return (
        <Backdrop
            style={{ zIndex: 700 }}
            open={open}
            data-test-id='backdrop2'
        ><SnackBarStyle
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            style={{ zIndex: 1000}}
            autoHideDuration={5000}
            data-test-id='snackbar2'
            onClose={()=>handleCloseFunc()}
        >
                <Typography style={AllStyle.popup as React.CSSProperties}>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{minHeight:'26',minWidth:'26'}} width="26" height="26" viewBox="0 0 26 26" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
                    </svg>
                    {message}
                </Typography>
            </SnackBarStyle>
        </Backdrop>
    );
};

const AllStyle = {
    popup: {
        display: "flex",
        gap: "8px",
        alignItems: "center",
        backgroundColor: "#ffffff",
        color: "#0F172A",
        borderRadius: "8px",
      
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
     
        lineHeight: "24px",
        padding:"9px 16px 9px 8px ",
        textAlign:"center",
        boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
    },
}

const SnackBarStyle = styled(Snackbar)({
    "&.MuiSnackbar-anchorOriginTopCenter": {
        top: "128px",
    }
})

export default CustomSnackbar;

