import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import storage from '../../../framework/src/StorageProvider';


import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  userType:string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  emailError:boolean;
  emailErrorMessage:string;
  passwordError:boolean;
  passwordErrorMessage:string;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imageUrl:any
  imgPasswordInVisible: any;
  isSnackbarOpen:boolean
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  rememberMe:boolean;
  labelOr: string;
  messageSnackbar:string;
  openPopup:boolean
  errorMessage:string;
  openSuccessPopUp:boolean
  forgotPassword:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  haveAccount:string=configJSON.haveAccount
  labelTitleSignUp:string=configJSON.labelTitleSignUp
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      imageUrl:"",
      isSnackbarOpen:false,
      password: "",
      openPopup:false,
      enablePasswordField: true,
      rememberMe:false,
      messageSnackbar:"",
      userType:"",
      checkedRememberMe: false,
      forgotPassword:configJSON.forgotPassword,
      emailError:false,
      errorMessage:"Please enter a value",
      emailErrorMessage:"",
      openSuccessPopUp:false,
      passwordError:false,
      passwordErrorMessage:"",
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const type = await storage.get("user_type")
           const credentialsEmail = await storage.get("credentialsEmail")
    const credentialsPassword = await storage.get("credentialsPassword")
    this.setState({ userType: type })
const imageUrl=await storage.get("login_screen_image")
if(imageUrl){

  this.setState({imageUrl:imageUrl})
}
    if(credentialsEmail&&credentialsPassword){
      
      this.setState({ email:credentialsEmail,password:credentialsPassword})
    }
    // Customizable Area End
  }

  // Customizable Area Start
 

  // Web Event Handling
  

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  handleForgotPassword=()=>{
     this.props.navigation.navigate("ForgotPassword")
  }

    handleVisiblPassword = () => {
      return this.state.enablePasswordField ? "password" : "text"
    }

    handlePassword = (value: string) => {
      this.setState({ password: value })
    if(value==""){
      this.setState({password:value,passwordError:true,passwordErrorMessage:this.state.errorMessage})
      return false
    }
    else {
      this.setState({password:value,passwordError:false,passwordErrorMessage:""})
      return true
  
    }
    }
    handleEmail = (value: string) => {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if(value===""){
        this.setState({
          emailError: true,
          email: value,
          emailErrorMessage:this.state.errorMessage
        });
        return false
      }
   else if (!value.match(emailPattern)) {
        this.setState({
          emailError: true,
          email: value,
          emailErrorMessage:"Email does not exist"
        });
        return false
      } else {
        this.setState({
          emailError: false,
          email: value,
          emailErrorMessage:""

        });
        return true;
      }
    }
    closeSnackbar=()=>{
      this.setState({isSnackbarOpen:false})
    }
    handleValidtion=()=>{
    const resultEmail=  this.handleEmail(this.state.email);
    const resultPassword=  this.handlePassword(this.state.password);
      if(resultEmail &&resultPassword){
       this.getLoginApiFunction()
        
      }
    }
    getLoginApiFunction = async () => {
      if(this.state.rememberMe){

        storage.set("credentialsEmail",this.state.email)
        storage.set("credentialsPassword",this.state.password)
      }
    else if(!this.state.rememberMe){
      storage.remove("credentialsEmail")
      storage.remove("credentialsPassword")
    }
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
      const body = {
        "data":{
          "attributes": {
              "email":this.state.email,
              "password":this.state.password
          }
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiEmailLoginCallId = requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.loginEndpoint
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
  handleNavigation=()=>{
    this.props.navigation.navigate("UserRole")
  }

  handleResponse=(responseJson:any)=>{
   
    if(responseJson?.errors?.length>0 && responseJson?.errors[0]?.email==="Your account is not yet activated."){
      this.setState({emailError:true,emailErrorMessage:"Email address does not exist"})
    }
    else if(responseJson?.errors && responseJson?.errors[0]?.email==="Please complete the sign up first"){
      this.setState({emailError:true,emailErrorMessage:"Please complete the sign up first"})

    }
    else if(responseJson?.errors && responseJson?.errors[0]?.email==="Sorry, your account is disabled. Please contact us for more information"){
      this.setState({emailError:true,emailErrorMessage:"Sorry, your account is disabled. Please contact us for more information"})

    }
    else if(responseJson?.errors?.length>0 && responseJson?.errors[0]?.email==="Email does not exist"){
      this.setState({emailError:true,emailErrorMessage:responseJson.errors[0]?.email})

    }
    else if( responseJson?.errors?.length>0 && responseJson?.errors[0]?.password==="Password is not correct"){
      this.setState({passwordError:true,passwordErrorMessage:responseJson?.errors[0]?.password})
    }
    else if(
      responseJson?.errors?.length>0 && responseJson?.errors[0]?.email==="Your account is still under review"
      ){
        this.setState({openPopup:true})
      }
    else if (responseJson?.meta && responseJson?.meta?.token) {
      const token = responseJson?.meta?.token;
      const user_role = responseJson?.meta?.user_role;
            const ID=responseJson?.meta?.id
      storage.remove("email")
      storage.remove("firstName")
      storage.remove("forgot_key")
      storage.remove("otp_token")
      storage.remove("user_type")
      storage.remove("token")
      storage.remove("user_id")
      storage.set("auth_token", token);
      storage.set("auth_user_id", ID);
      storage.set("auth_user_role", user_role);
            if(window.location.pathname==="/login"){
              window.location.href="/"
      }
      else{
        
        const path=window.location.pathname+ window.location.search
        window.location.href=path
      }
  }
  }
  handleClose=()=>{
   this.props.navigation. navigate("LandingPageWeb");

    this.setState({openPopup:false})
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.handleResponse(responseJson)
        
        }
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
