// Customizable Area Start
import React from "react";
import { Box,Container,styled,Grid,
    TextField, Typography, Breadcrumbs, Link, Accordion,AccordionDetails,AccordionSummary} from "@material-ui/core";
import {BannerDog,ExpandIcon, LightArrow,NoResultIcon} from "./assets"
import {  Allstyle } from "../../favourites/src/FavouritesWeb.web";
import { Autocomplete } from "@material-ui/lab";



const baseUrl = require("../../../framework/src/config.js")
// Customizable Area End

import InteractiveFaqController, {
    Props
} from "./InteractiveFaqController";

export default class Interactivefaqs extends InteractiveFaqController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
        // Customizable Area Start

    handleHomeNavigate = ()=>{this.props.navigation.navigate("LandingPageWeb")}
    handleAccordianChange = (name:string)=>{
      let expanded = this.state.expanded === name ? "" : name
      this.setState({
        expanded
      })
    }

        // Customizable Area End


    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            <Box>
            
            <ImaegStyle >
                  <BannerImg src={BannerDog} />
                <HomeGrid2 >

      <Box style={{ ...Allstyle.headingBoxStyle,alignItems:"center" }}>
        <Typography style={{ ...Allstyle.hedingStyle, paddingRight: "6px", cursor: "pointer" ,color:"rgba(71, 85, 105, 1)"}}
        data-test-id="navigate"
         onClick={this.handleHomeNavigate}>
          Home
        </Typography>
      
<img src={LightArrow}/>
        <Typography
          style={{
            ...Allstyle.hedingStyle,
            color:"#0f172a",
            paddingInline: "6px",
          }}
        >
         FAQ
        </Typography>
      </Box>
                </HomeGrid2>
                </ImaegStyle>
                
                <Container style={{ minHeight: '100vh',display:"block" }}>
                <Box
        style={{
          ...Allstyle.headingBoxStyle,
          justifyContent: "center",
          marginTop: "65px",
        }}
      >
        <Typography style={{ ...Allstyle.mainHedingStyle, color:"rgba(30, 41, 59, 1)" }}>
        Frequently Asked Questions
        </Typography>
     
       </Box>
       <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "28px",paddingBottom:"49px", }}>
        <AutocompleteComponent
          freeSolo
          id="free-solo"
          options={[]}
          data-test-id='searchInputOuter'
          renderInput={(params: any) => (
            <TextField
              {...params}
              onChange={this.handlerSearch}
              variant="outlined"
              value={this.state.searchKeyWord}
              placeholder="Search  by keyword"
              margin="normal"
              data-test-id='searchInput'
              InputProps={{
                ...params.InputProps, type: 'search', startAdornment: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.1865 14.4713H15.9765L20.2165 18.7313C20.6265 19.1413 20.6265 19.8113 20.2165 20.2213C19.8065 20.6313 19.1365 20.6313 18.7265 20.2213L14.4765 15.9713V15.1813L14.2065 14.9013C12.8065 16.1013 10.8965 16.7213 8.86646 16.3813C6.08646 15.9113 3.86646 13.5913 3.52646 10.7913C3.00646 6.56133 6.56646 3.00133 10.7965 3.52133C13.5965 3.86133 15.9165 6.08133 16.3865 8.86133C16.7265 10.8913 16.1065 12.8013 14.9065 14.2013L15.1865 14.4713ZM5.47646 9.97133C5.47646 12.4613 7.48646 14.4713 9.97646 14.4713C12.4665 14.4713 14.4765 12.4613 14.4765 9.97133C14.4765 7.48133 12.4665 5.47133 9.97646 5.47133C7.48646 5.47133 5.47646 7.48133 5.47646 9.97133Z"
                      fill="#94A3B8"
                    />
                  </svg>
                ),
              }}
            />
          )}
        />   
      </Box>
      {this.state.faqData.length ?  <Box style={{marginBottom:"120px"}}>
        {this.state.faqData.map((faq:any,i:number)=>{
            return (
              <AccordianStyle key={i} expanded={ this.state.expanded === `panel${i}`}  >
            <AccordionSummary
              expandIcon={<img src={ExpandIcon} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              data-test-id={`accordian`}
              onClick={()=>{this.handleAccordianChange(`panel${i}`)}}
            >
              <Typography style={{fontFamily:"Inter",color:"#1e293b", fontSize:"16px", fontWeight:"700",lineHeight:"21px"}} >
                {faq.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography style={{color:"#334155",fontFamily:"Inter",fontSize:"16px", fontWeight:"400",lineHeight:"30px"}} dangerouslySetInnerHTML={{__html:faq.content}} />
            
            </AccordionDetails>
          </AccordianStyle>
            )
        })}
      
      </Box> : !this.state.isLoading && <Box style={{display:"flex", justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
        <img src={NoResultIcon} alt="no result found" style={{ maxWidth:"500px", marginBottom:"1rem"}} />
        <Typography style={{ ...Allstyle.mainHedingStyle }}>
        No results Found!
        </Typography>
        <Typography style={{fontFamily:"Inter",fontWeight:"400",marginTop:"1rem", maxWidth:"400px", textAlign:"center"}}>No matching results for your search. Please try again with different keywords</Typography>
      </Box> }
      
                </Container>
          

            </Box>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start

  const AccordianStyle = styled(Accordion)({
    "&.MuiAccordion-root":{
      boxShadow:"-1px 3px 10px 0px rgba(0, 0, 0, 0.10)",
      marginBottom:"26px",
      borderColor:"transparent",
      padding:"10px 24px",
      maxWidth:"971px",
      marginLeft:"auto",
      marginRight:"auto",
      "&.MuiAccordian-rounded":{
        borderRadius:"8px",
      },
      "&::before":{
        backgroundColor:"#fff",
        boxShadow:"-1px 3px 10px 0px rgba(0, 0, 0, 0.10)",
      }

    },
    })
    const StyledImgBox = styled(Box)({

      '@media (max-width:756px) ': {
      backgroundSize:"cover"
      }
    })
    const BannerImg=styled('img')({
      width:"100%",objectFit:"cover",
      '@media (max-width:1335px)':{
        height:'100%'
      }
    })
    const ImaegStyle=styled(Box)({
      position:"relative",
      width:"100%",
      minWidth:"360px",
      '@media ( max-width:1440px )': {
        height:"448px",
        maxWidth:"1440px" ,
      },
     
     '@media ( max-width:800px )': {
      maxWidth:"1440px" ,
      height:"253px"
    },
    '@media ( max-width:560px )': {
      maxWidth:"1440px" ,
      height:"200px"
    },
    '@media ( max-width:460px )': {
      maxWidth:"1440px" ,
      height:"190px"
    },'@media ( max-width:400px )': {
      maxWidth:"1440px" ,
      height:"160px"
    },
    })
    const HomeGrid2 = styled(Grid)({
      marginInline:"auto",
  maxWidth: "1168px",
  position:"absolute",
  top:48,
  left:129,
  '@media(max-width: 1440px)': {
   left:"unset",
  minWidth: "unset",

    marginInline: "135px",
  },
  '@media(max-width: 1430px)': {
   left:"unset",

    maxWidth: "unset",
  marginInline: "20px",
  minWidth:"unset"
  },
  '@media(max-width: 400px)': {
   left:"unset",

    maxWidth: "unset",
    marginInline: "10px",
  minWidth:"unset"

  },

    })
    export const AutocompleteComponent = styled(Autocomplete)({
      width: "674px",
      marginRight: "16px",
      "& .MuiFormControl-marginNormal":{
        marginTop:"unset",
        marginBottom:"unset",
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: "0px 0px 0px 16px"
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: "12px 0px 12px 8px"
      },
      "& .MuiOutlinedInput-root": {
        height: "44px",
    
        "&:hover fieldset": {
          borderColor: "#CBD5E1",
        },
        "&.Mui-focused fieldset": {
          borderWidth: "1px",
          borderColor: "#CBD5E1",
        },
      },
      "& .MuiInputBase-root": {
        color: "#0f172a",
        fontWeight:400
      },
      "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderRadius: "8px"
      },
      "& .MuiAutocomplete-endAdornment":{
        display:"none"
      }
    
    })
  // Customizable Area End

