export function handleRouteChange() {
    let newTitle: string | undefined;
    const path = window.location.pathname;

    if (path === '/') {
        newTitle = 'Home';
    } else if (path === '/blogs') {
        newTitle = 'Pet Advice';
    } else if (path.startsWith('/blogs-details')) {
        newTitle = 'Pet Advice Details';
    } else if (path === '/community-topics') {
        newTitle = 'Community Topics';
    } else if (path === '/community-topics-new') {
        newTitle = 'New Community Topic';
    } else if (path.startsWith('/community-topics-edit')) {
        newTitle = 'Edit Community Topic';
    } else if (path === '/ourstory') {
        newTitle = 'Our Story';
    } else if (path === '/aboutus') {
        newTitle = 'About Us';
    } else if (path === '/petootiprocess') {
        newTitle = 'Petooti Process';
    } else if (path === '/notifications') {
        newTitle = 'Notifications';
    } else if (path.startsWith('/notifications-details')) {
        newTitle = 'Notification Details';
    } else if (path === '/community') {
        newTitle = 'Community Forum';
    } else if (path === '/ourwork') {
        newTitle = 'Our Work';
    } else if (path === '/contactus') {
        newTitle = 'Contact Us';
    } else if (path === '/favouritesPost') {
        newTitle = 'Favourites Post';
    } else if (path === '/signup-role') {
        newTitle = 'Sign Up - Choose Role';
    } else if (path === '/createnewpost') {
        newTitle = 'Create New Post';
    } else if (path.startsWith('/editpost')) {
        newTitle = 'Edit Post';
    } else if (path === '/signup-email') {
        newTitle = 'Sign Up - Email';
    } else if (path === '/signup-form') {
        newTitle = 'Sign Up - Form';
    } else if (path === '/signup-otp') {
        newTitle = 'Sign Up - OTP';
    } else if (path === '/password-recovery-otp') {
        newTitle = 'Password Recovery - OTP';
    } else if (path === '/password-recovery-newpassword') {
        newTitle = 'Password Recovery - New Password';
    } else if (path === '/BulkUploading') {
        newTitle = 'Bulk Uploading';
    } else if (path === '/SocialMediaAccountLogin') {
        newTitle = 'Social Media Login';
    } else if (path === '/SocialMediaAccountRegistration') {
        newTitle = 'Social Media Registration';
    } else if (path === '/Share') {
        newTitle = 'Share';
    } else if (path === '/ImportExportData') {
        newTitle = 'Import/Export Data';
    } else if (path === '/password-recovery') {
        newTitle = 'Forgot Password';
    } else if (path === '/ForgotPasswordOTP') {
        newTitle = 'Forgot Password - OTP';
    } else if (path === '/NewPassword') {
        newTitle = 'New Password';
    } else if (path.startsWith('/petpost-description')) {
        newTitle = 'Pet Post Description';
    } else if (path === '/previewProductDescription') {
        newTitle = 'Preview Product Description';
    } else if (path === '/TermsConditions') {
        newTitle = 'Terms and Conditions';
    } else if (path === '/TermsConditionsDetail') {
        newTitle = 'Terms and Conditions Details';
    } else if (path === '/TermsConditionsUsers') {
        newTitle = 'Terms and Conditions for Users';
    } else if (path === '/my-posts') {
        newTitle = 'My Posts';
    } else if (path === '/Emailnotifications2') {
        newTitle = 'Email Notifications';
    } else if (path === '/ReviewApprovalAdmin') {
        newTitle = 'Review Approval - Admin';
    } else if (path === '/ReviewApprovalBasicUser') {
        newTitle = 'Review Approval - User';
    } else if (path === '/CountryCodeSelector') {
        newTitle = 'Select Country Code';
    } else if (path === '/UserProfileBasicBlock') {
        newTitle = 'User Profile';
    } else if (path === '/favorites') {
        newTitle = 'Favorites';
    } else if (path === '/AddFavourites') {
        newTitle = 'Add Favorites';
    } else if (path === '/PhoneNumberInput') {
        newTitle = 'Phone Number Input';
    } else if (path === '/AdditionalDetailForm') {
        newTitle = 'Additional Details';
    } else if (path === '/Settings2') {
        newTitle = 'Settings';
    } else if (path.startsWith('/community-topics-details')) {
        newTitle = 'Community Topic Details';
    } else if (path === '/Pushnotifications') {
        newTitle = 'Push Notifications';
    } else if (path === '/EmailAccountRegistration') {
        newTitle = 'Email Registration';
    } else if (path === '/search') {
        newTitle = 'Search';
    } else if (path === '/Adminconsole2') {
        newTitle = 'Admin Console';
    } else if (path === '/Guestlogin2') {
        newTitle = 'Guest Login';
    } else if (path === '/Analytics') {
        newTitle = 'Analytics';
    } else if (path === '/login') {
        newTitle = 'Login';
    } else if (path === '/NavigationMenu') {
        newTitle = 'Navigation Menu';
    } else if (path === '/LandingPage') {
        newTitle = 'Landing Page';
    } else if (path === '/AdvancedSearch') {
        newTitle = 'Advanced Search';
    } else if (path === '/EducationalUserProfile') {
        newTitle = 'Educational User Profile';
    } else if (path === '/PostCreation') {
        newTitle = 'Create Post';
    } else if (path === '/Posts') {
        newTitle = 'Posts';
    } else if (path.startsWith('/PostDetails')) {
        newTitle = 'Post Details';
    } else if (path === '/CarouselDisplay') {
        newTitle = 'Carousel Display';
    } else if (path === '/adoption-form') {
        newTitle = 'Adoption Form';
    } else if (path === '/foster-form') {
        newTitle = 'Foster Form';
    } else if (path === '/generic-foster-form') {
        newTitle = 'Generic Foster Form';
    } else if (path.startsWith('/request-details')) {
        newTitle = 'Request Details';
    } else if (path === '/my-adopted-pets') {
        newTitle = 'My Adopted Pets';
    } else if (path === '/my-received-requests') {
        newTitle = 'My Received Requests';
    } else if (path === '/my-submitted-requests') {
        newTitle = 'My Submitted Requests';
    } else if (path === '/adoption-template') {
        newTitle = 'Adoption Template';
    } else if (path === '/foster-template') {
        newTitle = 'Foster Template';
    } else if (path === '/myprofile-adopter') {
        newTitle = 'My Profile - Adopter';
    } else if (path === '/myprofile-rescuer') {
        newTitle = 'My Profile - Rescuer';
    } else if (path === '/faq') {
        newTitle = 'FAQ';
    } else if (path === '/OTPInputAuth') {
        newTitle = 'OTP Input Authentication';
    } else if (path === '/InfoPage') {
        newTitle = 'Information Page';
    } else if (path === '*/AlertWeb') {
        newTitle = 'Alert';
    } 

    document.title = newTitle ? `${newTitle} | PETOOTI` : 'PETOOTI';
}
