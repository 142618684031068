import React from "react";

// Customizable Area Start
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { Box, Button, Grid, Typography, styled } from "@material-ui/core";
import {
  PetImage,
  image_pet_adopter,
  Frame,
  image_pet_adopter1,
  Frame1,
} from "./assets";

// Customizable Area End

class UserRole extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
   handleStyle=()=>{
    if(this.state.userRoleImage!==""){
      return {
        minHeight: 'calc(100vh - 247px)',
        maxHeight: 'calc(100vh - 247px)',
        width:"100%",
        borderRadius:"0px 0px 0px 45px",
        objectFit: 'cover' as 'cover',
        objectPosition: 'top' as 'top'
      }
    }
    else{
      return { width:"335px" }
    }
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
   
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
        
        <MainGrid container>
          <SecondGrid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <MainBox
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems:"unset"
              }}
            >
              <Typography style={AllStyle.heading}>Choose Your Role</Typography>
              <Typography style={AllStyle.smallHeading}>
                Please select the appropriate role to continue.
              </Typography>
              <SecondBox style={AllStyle.secondBox}>
                <Box
                data-test-id="usertest"
                  style={{
                    ...AllStyle.BoxStyle,
                    background: this.state.pertRescuer ? "#00bdab" : "none",
                    boxShadow: this.state.pertRescuer
                      ? "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                      : "none",
                    border: this.state.pertRescuer
                      ? "1px solid  #FFFFFF"
                      : "1px solid #E2E8F0",
                  }}
                  onClick={() => {
                    this.setState({
                      petAdopter: false,
                      pertRescuer: true,
                    });
                  }}
                >
                  {" "}
                  <img
                    src={this.state.pertRescuer ? Frame1 : Frame}
                    style={AllStyle.imageStyle}
                  />
                  <Typography
                    style={{
                      ...AllStyle.textStyle,
                      color: this.state.pertRescuer ? "#FFFFFF" : "#03B5AA",
                    }}
                  >
                    Pet
                    <br />
                    Rescuer
                  </Typography>
                </Box>
                <Box
                data-test-id="usertest2"

                  style={{
                    ...AllStyle.BoxStyle,
                    background: this.state.petAdopter ? "#00bdab" : "none",
                    boxShadow: this.state.petAdopter
                      ? "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                      : "none",
                    border: this.state.petAdopter
                      ? "1px solid #FFFFFF"
                      : "1px solid #E2E8F0",
                  }}
                  onClick={() => {
                    this.setState({
                      petAdopter: true,
                      pertRescuer: false,
                    });
                  }}
                >
                  <img
                    src={
                      this.state.petAdopter
                        ? image_pet_adopter1
                        : image_pet_adopter
                    }
                    style={AllStyle.imageStyle}
                  />
                  <Typography
                    style={{
                      ...AllStyle.textStyle,
                      color: this.state.petAdopter ? "#FFFFFF" : "#03B5AA",
                    }}
                  >
                    Pet
                    <br />
                    Adopter
                  </Typography>
                </Box>
              </SecondBox>
              <BUttonStyle variant="contained" style={AllStyle.btnStyle} onClick={this.handleNavigateEmailForm}>
                Continue
              </BUttonStyle>
            </MainBox>
          </SecondGrid>
         
        </MainGrid>
        
      </Box>
      // Customizable Area End
    );
  }
}
export default UserRole;

// Customizable Area Start


export const MainGrid = styled(Grid)({
minHeight: "calc(100vh - 287px)",

    "@media (max-width:1398px)": {
      minHeight:"calc(100vh - 243px)"
    },
      "@media (max-width:722px)": {
     height:'100%',
    marginTop: "20px",
  },
  
});
export const SecondGrid = styled(Grid)({

  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    borderRadius: "10px"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#848FAC80",
    borderRadius: "10px"
  },
  "@media (max-width:959px)": {
    height: 'unset',
    overflowY: 'unset',
  },
  "@media (max-width:450px)": {
    paddingInline: "20px",
  },
});
export const MainBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginBlock:"auto",
  alignItems:"center",
  paddingBlock:"50px",

  "@media (max-width:959px)": {
  paddingTop:"25px",
  paddingBottom:"55px"
  },
  "@media (max-width:320px)": {
    alignItems: "center",
  },
});
const BUttonStyle = styled(Button)({
  minWidth: "360px",
height:"44px",
borderRadius:"8px",
  "@media (max-width:380px)": {
    minWidth: "200px",
  },

})


const SecondBox = styled(Box)({
  maxWidth:"360px",
justifyContent:"center",
alignItems:"center",
"@media (max-width:390px)":{
  paddingInline:"10px"
    },
"@media (max-width:320px)": {
  justifyContent: "center",
},

});

const AllStyle = {
  btnStyle: {
    fontFamily:"Lato",
    background: "#00bdab",
    textTransform: "inherit" as "inherit",
    color: "#FFFFFF",
    lineHeight:"24px",
    fontSize:"16px",
    fontWeight:700
  },
  imageStyle: {
    marginBottom: "10px",
    width: "40px",
    height:"40px",
    marginTop: "24px",
  },
  BoxStyle: {
    width: "147px",
    height: "154px",
    display: "flex",
    cursor: "pointer" as "pointer",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    textAlign: "center" as "center",
    borderRadius: "15px",
  },
  secondBox: {
    display: "flex",
    flexWrap: "Wrap" as "wrap",
    flexDirection: "row " as "row",
    gap: "20px",
    marginBlock: "40px",
  },
  textStyle: {
    fontFamily:"Lato",
lineHeight:"28px",
    fontSize: "20px",
    fontWeight: 400,
    marginBottom:"24px"
  },
  heading: {
    fontWeight: 700,
    fontFamily:"Lato",
    letterSpacing:"-0.12px",
lineHeight:"32px",
    fontSize: "24px",
    color: "#0A2239",
    marginBottom: "8px",
  },
  smallHeading: {
    fontFamily:"Lato",
    fontWeight: 500,
    lineHeight:"22px",
    letterSpacing:"0.7px",
    fontSize: "14px",
    color: "#0A2239",
  },
};

// Customizable Area End
