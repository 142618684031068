import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Tooltip,
  Button,
  Dialog,
  Backdrop,
  Snackbar,
  TextareaAutosize
} from "@material-ui/core";
import MuiDialogContent from '@material-ui/core/DialogContent';
import {styled } from "@material-ui/core/styles";
import {dot_view,checkMark,cross_reject,crossIcon, fillcheckBoxRadioButton,radiobuttons,no_image} from './assets'
const baseUrl = require("../../../framework/src/config.js")
import moment from "moment";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestDetails extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  hanldeSvg = () => {
    return <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
        fill="#64748B"
      />
    </svg>

  }
 returnTitleText=(title:any,text:any)=>{
  return(
   text&& <TitleTextBox><Typography style={Allstyle.titleText}>{title}</Typography><Typography style={Allstyle.valueText}>{text}</Typography></TitleTextBox>
  )
 }
  returnDateText = (status: any) => {

    if (status == 'Pending') {
      return "Submitted on"
    } else if (status == 'Rejected') {
      return "Rejected on"
    }
    else {
      return "Approved on"
    }

  }
  stateReturnStatusColor=(status:any)=>{
    if (status == 'Pending') {
      return "rgba(217, 119, 6, 1)"
    } else if (status == 'Rejected') {
      return "rgba(220, 38, 38, 1)"
    }
    else if(status == 'Archived'){
   return '#2e6ea7'
    }
    else {
      return "rgba(5, 150, 105, 1)"
    }

  }
  returnFun1 = (item: any) => {
    return item?.attributes?.requester_template_info?.data.attributes || ''
  }
  returnFun2 = (item: any) => {
    return item?.attributes?.post?.data?.attributes || ''
  }
  returnFun3 = (dataIs: any) => {
    return dataIs?.full_phone_number?.includes('+') ? dataIs?.full_phone_number : "+" + dataIs?.full_phone_number
  }
  retunFun4 = (dataIs: any) => {
    return dataIs?.whatsapp_number?.includes('+') ? dataIs?.whatsapp_number : "+" + dataIs?.whatsapp_number
  }
  returnRejectText = (item: any) => {
    return (
      item?.attributes?.status == 'Rejected' && <RejectMassegeBox>
        <Typography style={Allstyle.rejectedText}>
          {item?.attributes?.rejected_reason || item?.attributes?.adoption_cancellation_reason_admin || item?.attributes?.adoption_cancellation_reason_owner || item?.attributes?.adoption_cancellation_reason_requester}
        </Typography></RejectMassegeBox>
    )
  }
  returnFields1 = (item: any, dataIs: any) => {
    return (
      <>
        {(item?.attributes?.request_type !== 'Adoption') && <TitleTextBox><Typography style={Allstyle.titleText}>Is the balcony/garden enclosed or cat proofed?</Typography><Typography style={Allstyle.valueText}>{dataIs?.is_the_balcony_garden_enclosed_or_cat_proofed ? 'Yes' : 'No'}</Typography></TitleTextBox>}
        {(item?.attributes?.request_type !== 'Adoption') && this.handleCheckForNull(dataIs?.not_then_extra_care_when_open_close_door )&&<TitleTextBox><Typography style={Allstyle.titleText}>If not, do you agree to NOT let the fostered animal out onto the balcony/garden and you agree to take extra care when opening and closing the door? This includes informing the house help to take extra precaution?</Typography><Typography style={Allstyle.valueText}>{dataIs?.not_then_extra_care_when_open_close_door ? 'Yes, I agree' : 'No'}</Typography></TitleTextBox>}

        {(item?.attributes?.request_type == 'Adoption') && this.handleCheckForNull(dataIs?.have_private_garden)&&<TitleTextBox><Typography style={Allstyle.titleText}>Does your home have access to a private garden?</Typography><Typography style={Allstyle.valueText}>{dataIs?.have_private_garden ? 'Yes' : 'No'}</Typography></TitleTextBox>
        }      </>
    )

  }
  returnFields2 = (dataIs: any) => {
    return (
      <>
        {dataIs?.users_other_members?.data.length > 0 && <TitleTextBox><Typography style={Allstyle.titleText}>List all people, who will be living with the new pet</Typography>
          <Box style={Allstyle.otherBox}>{dataIs.users_other_members.data.map((item: any, index: any) => (
            <Box key={index} style={Allstyle.otherSubBox}>
              <Typography style={Allstyle.valueText}>
                {index + 1}. {item.attributes.name}
              </Typography>
              <Typography style={Allstyle.subtext}>{item.attributes.relationship}, {item.attributes.age} {item.attributes.age == 1 ? 'year' : 'years'}</Typography>
            </Box>
          ))} </Box>
        </TitleTextBox>}

        {dataIs?.reason_for_wanting_to_adopt_a_new_pet?.length > 0 && <TitleTextBox><Typography style={Allstyle.titleText}>What is your reason for wanting to adopt new pet?</Typography>
          <Box > {dataIs?.reason_for_wanting_to_adopt_a_new_pet?.map((item: any, index: any) => (
            <Typography style={Allstyle.valueText}> {item}{index == dataIs?.reason_for_wanting_to_adopt_a_new_pet.length - 1 ? "" : <span>, </span>} </Typography>
          ))}</Box> </TitleTextBox>}
      </>
    )
  }
  returnFields3 = (dataIs: any,item:any) => {
    return (
      <>
       {dataIs?.users_other_pets?.data.length > 0 && <TitleTextBox><Typography style={Allstyle.titleText}>Please list all the pets currently living with you</Typography>
                  <Box style={Allstyle.otherBox}>{dataIs.users_other_pets.data.map((item: any, index: any) => (
                    <Box key={index} style={Allstyle.otherSubBox}>
                      <Typography style={Allstyle.valueText}>
                        {index + 1}. {item.attributes.category.name}, {item.attributes.sub_category.name}
                      </Typography>
                      <Typography style={Allstyle.subtext}>{item.attributes.age} {item.attributes.age == 1 ? 'Year' : 'Years'}{item?.attributes?.sterilized ? `, Sterilized - ${item?.attributes?.sterilized}` : ''} </Typography>
                      {item?.attributes?.any_health_condition?<Typography style={{...Allstyle.subtext,wordBreak:'break-word'}}>Any health condition - {item?.attributes?.any_health_condition}</Typography>:''}
                    </Box>
                  ))} </Box>
                </TitleTextBox>}
                {item?.attributes?.request_type!=='Adoption'&&this.handleCheckForNull(dataIs?.vehicle_to_bring_your_foster_cat_to_events_and_appointments)&&this.returnTitleText('Do you drive or have access to a vehicle to bring your foster pet to events and appointments?', dataIs?.vehicle_to_bring_your_foster_cat_to_events_and_appointments?'Yes':'No')}
                {item?.attributes?.request_type!=='Adoption'&&this.handleCheckForNull(dataIs?.are_you_willing_and_able_to_medicate_your_foster_cat_if_applica)&&this.returnTitleText('Are you willing and able to medicate your foster pet, if applicable?', dataIs?.are_you_willing_and_able_to_medicate_your_foster_cat_if_applica?'Yes':'No')}

      </>
      )
    }
    returnFields4=(item: any, dataIs: any)=>{

     return <>
      {(item?.attributes?.request_type !== 'Adoption') &&this.handleCheckForNull(dataIs?.if_children_pet_will_need_space_away_from_the_children_at_times)&&<TitleTextBox><Typography style={Allstyle.titleText}>
        If you have children, do you understand that the pet will need space away from the children at times?
        </Typography><Typography style={Allstyle.valueText}>{dataIs?.if_children_pet_will_need_space_away_from_the_children_at_times? 'Yes' : 'No'}</Typography></TitleTextBox>}
      </>
    }
    returnFields5=(item: any, dataIs: any)=>{
    
      return <>
       {(item?.attributes?.request_type !== 'Adoption') && <TitleTextBox><Typography style={Allstyle.titleText}>
       Do you have other animals at home?
         </Typography><Typography style={Allstyle.valueText}>{dataIs?.other_animal_at_home? 'Yes' : 'No'}</Typography></TitleTextBox>}
       </>
     }
  returnModal = () => {
    const item=this.state.RequestDetails
    return <>
      <CustomizedDilog 
       PaperProps={{
        style: {
            maxHeight:item?.attributes?.request_type=='Adoption'?'380px':'auto'
        }
    }}
        onClose={() => this.setState({ isModalOpen: false })}
        open={this.state.isModalOpen} data-test-id='CustomizedDilogClose'>
        <CrossImgModal src={crossIcon} alt="" data-test-id='CustomizedDilogClose1' onClick={() => this.setState({ isModalOpen: false })} />
        <DialogContent>
          <ModalTitleTypography >
            {item?.attributes?.request_type=='Adoption'?configJSON.confirmApproveAdotionRequest_Title:configJSON.confirmApproveFosterRequest_Title}
          </ModalTitleTypography>
          <ModalContentTypography >
          {item?.attributes?.request_type=='Adoption'?configJSON.confirmApproveAdotionRequest_Detail:configJSON.confirmApproveFosterRequest_Detail}
          </ModalContentTypography>
        </DialogContent>
        <ButtComPareModalBox>
          <BottonCompone variant="outlined"
            style={{ ...Allstyle.buttonCancelStyling,border: '1px solid #03B5AA',
            color:'rgba(3, 181, 170, 1)' }}
            onClick={() => this.setState({ isModalOpen: false })}
            data-test-id='CustomizedDilogClose2'
          >

            Cancel
          </BottonCompone>
          <BottonCompone variant="contained"
            style={{ ...Allstyle.buttonConfirmStyling ,width:'151px',background:'rgba(3, 181, 170, 1)',
            color:'rgba(255, 255, 255, 1)',}}
            onClick={() => this.handleStatusOfRequest()}
            data-test-id='handleStatusOfRequest'

          >

Approve
          </BottonCompone>
        </ButtComPareModalBox>
      </CustomizedDilog>
    </>
  }
  renderErrPleaseSelectOption = (err: any) => {
    if (err) {
      return <Typography data-test-id='errTextOption' style={Allstyle.errorTextStyle}>
        Please select an option
      </Typography>
    }

  }
  importantField = () => {
    return <span style={{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#FF5E5B"
    }}>
      {" "}    *
    </span>
  }
  renderErrFunction = (err:any) => {
    if (err) {
      return <Typography style={Allstyle.errorTextStyle}>
        Please enter a value
        </Typography>
    }
   
  }
  
  returnRejectRequestModal = (item:{attributes:{request_type: string}}) => {
    return <>
      <CustomizedRejectDilog
        onClose={() => this.setState({ isRejectModalOpen: false })}
        open={this.state.isRejectModalOpen} data-test-id='CustomizedRejectDilogClose'>
        <CrossImgModal src={crossIcon} alt=""data-test-id='CustomizedRejectDilogClose1' onClick={() => this.setState({ isRejectModalOpen: false })} />
        <DialogContent>
              <ModalTitleTypography >
              {item?.attributes?.request_type == 'Adoption'?'Reject Adoption Request?':'Reject Foster Form?'}
          </ModalTitleTypography>
         
          <Box style={{width:'100%'}}>
            <Typography style={Allstyle.reasonForRejectTitle}>
            Reason for rejecting request {this.importantField()}
            </Typography>
            <InputTextField placeholder="write a reason..."
              value={this.state.rejectRequestValue}
              data-test-id='writeReason'
              onChange={(e: any) => {
                this.setState({ 
                    rejectRequestValue: e.target.value 
                }, () => {
                    if (e.target?.value?.length > 0) {
                        this.setState({ rejectRequestValue_Err: false });
                    }
                });
            }}
              minRows={8}
              style={{
                border: this.state.rejectRequestValue_Err ? '1px solid #DC2626' : '1px solid rgba(203, 213, 225, 1)'
              }}
            />

            {this.renderErrFunction(this.state.rejectRequestValue_Err)}
          </Box>
        </DialogContent>
        <ButtComPareModalBox>
          <BottonCompone variant="outlined"
            data-test-id="CancelButton"
            style={{ ...Allstyle.buttonCancelStyling,border: '1px solid #FF5E5B',
            color:'#FF5E5B' }}
            onClick={() => this.setState({ isRejectModalOpen: false })}
          >

            Cancel
          </BottonCompone>
          <BottonCompone variant="contained"
            data-test-id="RejectReqButton"
            style={{ ...Allstyle.buttonConfirmStyling ,background:'#FF5E5B',
            color:'rgba(255, 255, 255, 1)',}}
            onClick={() => this.handleRejectRequest()}
          >

{item?.attributes?.request_type == 'Adoption'?'Reject Request':'Reject Form'}
          </BottonCompone>
        </ButtComPareModalBox>
      </CustomizedRejectDilog>
    </>
  }
 
  returnTrialPeriodModal = (name:string,nameAdopter:string) => {
    return <>
      <CustomizedForContinueTrialDilog
        open={this.state.isTrialPeriodModalOpen}
      >
        <DialogContent>
          <ModalTitleTypographyForContinueTrialDilog >
            {this.state.trial_period&&(this.state.user_role == 'rescuer'&&this.state.historyPath=='/my-received-requests' ?  name+configJSON.modalTrialPeriodTitleRescuer : `Has ${name} officially been adopted?`)}
            {!this.state.trial_period&&(this.state.isOwner? configJSON.modalTrialPeriodTitleRescuer : configJSON.modalTrialPeriodTitleAdopter)}
          
          </ModalTitleTypographyForContinueTrialDilog>
          {this.handleCheckOwner()&& <ModalContentTypography >
          Please confirm if {nameAdopter} has confirmed the adoption of {name}.
          </ModalContentTypography>}
          <Box style={{ ...Allstyle.radioParentContainer, paddingBottom:this.handlePaddingBott(this.state.continueAdoption ) , borderBottom:this.handleBorderBott(this.state.continueAdoption )  }} >
            <Box data-test-id='radioOptionYes' style={Allstyle.radioParentBox} onClick={() => this.setState({ continueAdoption: 1, option_Err: false })}><img data-test-id='radioOptionImageYes' src={this.state.continueAdoption == 1 ? fillcheckBoxRadioButton : radiobuttons} style={{ cursor: 'pointer' }} /><Typography style={Allstyle.radioText}>{this.handleReturnTex1()}</Typography></Box>

            <Box data-test-id='radioOptionNo' style={Allstyle.radioParentBox} onClick={() => this.setState({ continueAdoption: 2, option_Err: false })}> <img data-test-id='radioOptionImageNo' src={this.state.continueAdoption == 2 ? fillcheckBoxRadioButton : radiobuttons} style={{ cursor: 'pointer' }} /><Typography style={Allstyle.radioText}>{this.handleReturnTex2()}</Typography></Box>
            {this.renderErrPleaseSelectOption(this.state.option_Err)}
          </Box>
          {this.state.continueAdoption == 2 && <Box style={{ width: '100%' }}>
            <Typography style={Allstyle.reasonForRejectTitle}>
              Reason for cancelling adoption{this.importantField()}
            </Typography>
            <InputTextField placeholder="write a reason..."
              value={this.state.reason_Cancelling_Adoption}
              maxLength={500}
              data-test-id='writeReasonInput'
              onChange={(e: any) => {
                this.setState({
                  reason_Cancelling_Adoption: e.target.value
                }, () => {
                  if (e.target.value.length > 0) {
                    this.setState({ reason_Cancelling_Adoption_Err: false });
                  }
                });
              }}
              minRows={8}
              style={{
                border: this.state.reason_Cancelling_Adoption_Err ? '1px solid #DC2626' : '1px solid rgba(203, 213, 225, 1)'
              }}
            />

            {this.renderErrFunction(this.state.reason_Cancelling_Adoption_Err)}
          </Box>}
        </DialogContent>
        <ButtComPareModalBoxForContinueTrialDilog>
          <BottonComponeForContinueTrialDilog variant="contained"
            data-test-id="confirmButton"
            style={{
              width: '151px', background: 'rgba(3, 181, 170, 1)',
              color: 'rgba(255, 255, 255, 1)',
            }}
            onClick={() => this.handleAfterTrialPeriod()}
          >

            Confirm
          </BottonComponeForContinueTrialDilog>
        </ButtComPareModalBoxForContinueTrialDilog>
      </CustomizedForContinueTrialDilog>
    </>
  }

  returnDateForm=(item:any,submitDate:any)=>{
    let dateis=item?item:submitDate
    return moment(dateis).format("DD MMM YYYY")
  }
  handleAddress=(address1:string,address2:string)=>{
    return this.state.historyPath=='/my-submitted-requests'&& <>
    {this.returnTitleText('Address line 1', address1)}
    {this.returnTitleText('Address line 2', address2)}
    </>
      }
  handleEmail_phone_WhatsApp_Number=(email:string,phoneNumber:string,whatsapp_Number:string,howToContactArr:string[])=>{
    return <>
      {this.handleCheckForhowToContactArr(howToContactArr,'Email')&&this.returnTitleText('Email', email)}
      {this.handleCheckForhowToContactArr(howToContactArr,'Phone call')&&this.returnTitleText('Phone Number', phoneNumber)}
      {this.handleCheckForhowToContactArr(howToContactArr,'WhatsApp')&&this.returnTitleText('WhatsApp Number', whatsapp_Number)} 

      
      </>
  }
  returnHowToContactFields=(howToContactArr:string[])=>{
    let hisPath=this.props?.navigation?.history?.location?.pathname
    return hisPath?.includes('my-submitted-requests')&&<> {howToContactArr?.length > 0 && <TitleTextBox><Typography style={Allstyle.titleText}>{configJSON.howToContactHeading}</Typography>
      <Box style={{display:'flex'}}> {howToContactArr?.map((item: string, index: number) => (
        <Typography style={Allstyle.valueText}> {item}{index == howToContactArr?.length - 1 ? "" : <span>,&nbsp;</span>}</Typography>
      ))}</Box> </TitleTextBox>}
    </>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const item = this.state.RequestDetails
    const dataIs =this.returnFun1(item) 
    const dataPost =this.returnFun2(item)
    const phoneNumber =this.returnFun3(dataIs) 
    const whatsapp_Number =this.retunFun4(dataIs)  
    const date_of_birth = this.dateConverterFormate(dataIs?.date_of_birth,true)

    return (

      <>
 <Loader loading={this.state.isLoading } />
        {this.returnModal()}
        {this.returnRejectRequestModal(item)}
        {this.returnTrialPeriodModal(dataPost?.name,dataIs?.first_name +" "+dataIs?.last_name)}
        <Backdrop
                    style={{ zIndex: 700 }}
                    data-test-id='backdrop2'
                    open={this.state.isShowMarkMess}
                ><SnackBar
                    anchorOrigin={{
                      horizontal: "center",
                        vertical: "top",
                    }}
                    open={this.state.isShowMarkMess}
                    style={{ zIndex: 1000 }}
                    autoHideDuration={5000}
                    data-test-id='snackBarForMarkSuccess'
                    onClose={()=>this.setState({isShowMarkMess:false,markMess:''})}
                >
                        <Typography style={Allstyle.poPupstyle as React.CSSProperties}>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{minWidth:'26',minHeight:'26'}} width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
                            </svg>
                            {this.state.markMess}
                        </Typography>
                    </SnackBar>
                </Backdrop>
       
        <Backdrop
          style={{ zIndex: 700 }}
          data-test-id='backdrop2'
          open={this.state.showSuccessMessPop}

        ><SnackBar
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          open={this.state.showSuccessMessPop}
          style={{ zIndex: 1000 }}
          autoHideDuration={5000}
          data-test-id='snackBarForSuccess'
          onClose={()=>this.handleCloseFunc()}
        >
            <Typography style={Allstyle.poPupstyle as React.CSSProperties}>
              <CustomSvg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 26 26" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
              </CustomSvg>
              {this.state.SuccessMess}
            </Typography>
          </SnackBar>
        </Backdrop>
        < ParentContainer>
       
        
          <MainTopArrowHeading >
            <Typography
              style={{ ...Allstyle.hedingsStyle, cursor: "pointer" }}
              data-test-id="home"
              onClick={() => this.hanldeNavigation('LandingPageWeb')}
            >{configJSON.home} </Typography>
            {this.hanldeSvg()}
            <Typography
              style={{
                ...Allstyle.hedingsStyle,
                cursor: "pointer" 
              }}
              onClick={() => this.hanldeNavigation(this.state.historyPath=='/my-received-requests'||this.state.showReceiveReq?'MyReceivedRequests':'MySubmittedRequests')}
              data-test-id="page"
            >{this.state.historyPath=='/my-received-requests'||this.state.showReceiveReq?'My Received Requests':'My Submitted Requests'}</Typography>
            {this.hanldeSvg()}
            <Typography
              style={{
                ...Allstyle.hedingsStyle,
                color: 'var(--Neutrals-Cool-gray-900, #0F172A)',
                paddingInline: "6px",
              }} > {configJSON.Request_Details}  </Typography>
          </MainTopArrowHeading>

          <FormParentBox >
          
            <CardTopHeadingParentBox ><Typography style={Allstyle.Textstyle}>{configJSON.Request_Details} </Typography>
           
            {(this.state.user_role=='rescuer'&&item?.attributes?.status=='Pending'&&this.state.historyPath=='/my-received-requests')&&<ButtComPareBox>
            <BottonCompone variant="contained"
                data-test-id="ApproveRequestButton"
                style={{ ...Allstyle.buttonStyling }}
                onClick={()=>this.setState({isModalOpen:true})}
              >
                <img src={checkMark} style={{ marginRight: "8px" }} />
                Approve Request
              </BottonCompone>
              <BottonCompone variant="contained"
                data-test-id="RejectRequestButton"
                style={{ ...Allstyle.buttonRejectStyling }}
                onClick={()=>this.setState({isRejectModalOpen:true})}
              >
                <img src={cross_reject} style={{ marginRight: "8px" }} />
                Reject Request
              </BottonCompone>
              </ButtComPareBox>
  }
            </CardTopHeadingParentBox>
            <CardBox >
              <CardImg src={dataPost?.image?.url?`${baseUrl.baseURL}${dataPost?.image?.url}`:no_image} alt="pet" />
              <CardRightBox >
                <NameCategoryCardBox>
                  <CardHeadinDateBox>
                     <Tooltip title={dataPost?.name+" - "+dataPost?.hook_line} placement="bottom" arrow >
                    <PetNameTypography >{dataPost?.name} - {dataPost?.hook_line}</PetNameTypography>
                    
                    
                    </Tooltip>
                    <Typography style={Allstyle.submittedOn}><span style={Allstyle.submittedOnText}>Submitted on:</span> {moment(item?.attributes?.submission_date).format("DD MMM YYYY")}</Typography>
                  </CardHeadinDateBox>
                  <Typography style={Allstyle.categorySubCategoryText}>{dataPost?.category?.name}, {dataPost?.sub_category?.name}</Typography>
                </NameCategoryCardBox>
                <ChipBox><Chip>Gender - {dataPost?.gender}</Chip><Chip>Age - {this.dateConverterFormate(dataPost?.age,false)}</Chip>{this.handleShowFields(dataPost?.size)&&<Chip>Size - {dataPost?.size}</Chip>}</ChipBox>
                <CardPaperBox>
                  <Box style={Allstyle.paperSmallInnerBox}>
                    <Typography style={Allstyle.cardPaperGrayTitleText}>Request type</Typography>
                    <Typography style={{ ...Allstyle.cardPaperGrayTitleTextValue, color: 'rgba(30, 41, 59, 1)' }}>{item?.attributes?.request_type}</Typography>
                  </Box>
                  <Dot_viewImg src={dot_view} alt="" />
                  <Box style={Allstyle.paperSmallInnerBox}>
                    <Typography style={Allstyle.cardPaperGrayTitleText}>{this.returnDateText(item?.attributes?.status)}</Typography>
                    <Typography style={{ ...Allstyle.cardPaperGrayTitleTextValue, color: 'rgba(30, 41, 59, 1)' }}>{this.returnDateForm(item?.attributes?.action_on,item?.attributes?.submission_date)}</Typography>
                  </Box>
                  <Dot_viewImg src={dot_view} alt="" />
                  <Box style={Allstyle.paperSmallInnerBox}>
                    <Typography style={Allstyle.cardPaperGrayTitleText}>Request status</Typography>
                    <Typography style={{ ...Allstyle.cardPaperGrayTitleTextValue, color: this.stateReturnStatusColor(item?.attributes?.status) }}>{this.handleTextStatus(item?.attributes?.status)}</Typography>
                  </Box>

                </CardPaperBox>
              </CardRightBox>
            </CardBox>
             {this.returnRejectText(item)}
           
            <DetailFormOuterBox>
              <Typography style={Allstyle.Requester_Information}>{configJSON.Requester_Information}</Typography>
              <DetailFormBox>
                {this.returnTitleText('Name', dataIs.first_name +" "+dataIs.last_name)}
                {this.returnHowToContactFields(dataIs?.how_would_you_like_to_be_contacted)}
                {this.handleEmail_phone_WhatsApp_Number(dataIs?.email,phoneNumber,whatsapp_Number,dataIs?.how_would_you_like_to_be_contacted)}
                {this.returnTitleText('Age', date_of_birth)}
                {this.handleAddress(dataIs?.address_line_1,dataIs?.address_line_2)}
                {this.returnTitleText('City/Area', dataIs?.city)}
                {this.returnTitleText('State/Emirate', dataIs?.county)}
                {this.returnTitleText('Postal Code', dataIs.postal_code)}
                {this.returnTitleText('Country', dataIs?.country?.name)}
                {this.returnTitleText('How long can you foster for?', dataIs?.how_long_can_you_foster_for)}
                {this.returnTitleText('How long have you lived at your address?', dataIs?.how_long_you_lived_your_address)}
                {this.returnTitleText('Do you?', dataIs?.do_you)}
                {this.returnTitleText('Please describe your accommodation set up', dataIs?.accommodation)}
                {this.returnTitleText('Apartment type', dataIs?.apartment_type)}
               {this.returnFields1(item,dataIs)}
                {this.returnTitleText('What is your household activity level?', dataIs?.house_hold_activaty_level)}
                {this.returnFields4(item,dataIs)}
                
                {this.returnTitleText('Other household activity level', dataIs?.other_please_explain_activity_level)}
                {this.returnFields2(dataIs)}
                {this.returnTitleText('Other adoption reason', dataIs?.other_please_explain_reason_for_wanting_to_adopt_a_new_pet)}
                {dataIs?.living_alone&&<TitleTextBox><Typography style={Allstyle.titleText}>List all people, who will be living with the new pet</Typography><Typography style={Allstyle.valueText}>I am living alone</Typography></TitleTextBox>}
                {this.returnFields5(item,dataIs)}
                {this.returnTitleText('What is your experience as a pet owner?', dataIs?.experience_as_a_pet_owner)}
                {this.returnTitleText('Other pet owner experience', dataIs?.other_please_explain_experience_as_a_pet_owner)}
                {this.returnFields3(dataIs,item)}
               
                {this.returnTitleText('How many total hours will your pet be left alone during the day?', dataIs?.hours_in_a_day_would_the_foster_be_left_alone + " " + "hours/day")}
                {this.returnTitleText('Will your pet be allowed outside?', dataIs?.pet_allowed_outside)}
                {item?.attributes?.request_type=='Adoption'&&this.returnTitleText('Have you considered the relocation cost for your new pet?', dataIs?.have_you_considered_the_relocation_cost_for_your_new_pet ? "Yes, I did my research" : "No, I need advice on cost")}
              </DetailFormBox>
            </DetailFormOuterBox>

          </FormParentBox>


        </ParentContainer>

        
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const RejectMassegeBox = styled(Box)({
  borderRadius: '4px',
  padding: '16px',
  background: 'rgba(254, 226, 226, 1)'
})
const FormParentBox = styled(Box)({
  margin: 'auto',
  width: "772px",
  display: 'flex',
  flexDirection: 'column' as 'column',
  gap: 16,
  "@media (max-width:930px)": {
    width: 'unset'
  }
})
const ParentContainer = styled(Box)({
  margin: 'auto',
  width: "85%",
  marginBottom: '94px',
  '@media (max-width:1140px)': {
    width: "unset",
    paddingRight: '60px',
    paddingLeft: '60px'
  },

  '@media (max-width:650px)': {
    paddingRight: '25px',
    paddingLeft: '25px',
    boxSizing:'border-box'
  },
  '@media (max-width:350px)': {
    paddingLeft: 10,
    paddingRight: 10,
    boxSizing: 'border-box'
  }
})

const MainTopArrowHeading = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "52px 100px 48px 0px",
  '@media (max-width:1140px)': {
    padding: "52px 40px 48px 0px;"

  },

});

const CardBox = styled(Box)({
  paddingTop: '24px',
  paddingBottom: '24px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid rgba(226, 232, 240, 1)',
  gap: '24px',
  "@media (max-width:830px)": {
    flexDirection: 'column'
  }

})
const CardImg = styled('img')({
  width: '175px',
  height: '223px',
  borderRadius: 8,

})
const Dot_viewImg = styled('img')({
  "@media (max-width:450px)": {
    rotate: '90deg'
  }
})
const CardRightBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: "24px",

})
const CardHeadinDateBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  "@media (max-width:595px)": {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
})
const PetNameTypography= styled(Typography)({
  width: '260px',
  fontFamily: "Lato" as "Lato",
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "32px",
  letterSpacing: '-0.005em',
  color: 'rgba(15, 23, 42, 1)',
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media (max-width:595px)": {
  width:'unset',
  whiteSpace: "unset",
  overflow: "unset",
  textOverflow: "unset",
  }
})
const ChipBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  "@media (max-width:450px)": {
    flexDirection: 'column',
    alignItems: 'flex-start',
  }

})
const Chip = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '6px 16px 6px 16px',
  borderRadius: '26px',
  background: 'linear-gradient(0deg, rgba(205, 240, 238, 0.5), rgba(205, 240, 238, 0.5))',
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "22px",
  fontFamily: "Lato" as "Lato",
  color: 'rgba(7, 79, 87, 1)'

})
const CardPaperBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
  border: '1px solid rgba(241, 245, 249, 1)',
  padding: '16px 32px 16px 32px',
  gap: '32px',
  width: 'max-content',
  "@media (max-width:595px)": {
    width: 'unset',
    gap: '10px',
    padding: '8px 16px 8px 16px',
  },
  "@media (max-width:450px)": {
    flexDirection: 'column',
  }
})
const DetailFormOuterBox = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column' as 'column',
  gap: '16px'
})
const DetailFormBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column' as 'column',
  gap: '24px'
})
const TitleTextBox = styled(Box)({
  display: 'Grid',
  gridTemplateColumns: 'repeat(2,1fr)',
  gap: '24px',
  alignItems: 'flex-start',
  "@media (max-width:595px)": {
    gridTemplateColumns: 'repeat(1,1fr)',
    gap: '8px',
  }
})
const NameCategoryCardBox = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column' as 'column',
})
const BottonCompone = styled(Button)({
  "&.MuiButton-contained": {
    boxShadow: "unset"
  }
});
const ButtComPareBox = styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  "@media (max-width:479px)": {
    alignItems: 'flex-start',
    flexDirection: 'column',
  }
})
const CardTopHeadingParentBox= styled(Box)({
  display: 'flex',
   justifyContent:'space-between',
  alignItems: 'center',
  "@media (max-width:700px)": {
    flexDirection: 'column',
    alignItems:'unset',
    gap:'16px'
  }
})
const CustomizedDilog = styled(Dialog)({
  "& .MuiPaper-elevation24": {
    width: '600px',

    borderRadius: '8px 8px 32px 8px',
    opacity: '0px',
    boxShadow:'0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)'

  },
  "& .MuiDialogContent-root": {
    padding: "40px 40px",
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    gap:'12px',
    borderBottom:'1px solid rgba(226, 232, 240, 1)'
  },
})
const CustomizedForContinueTrialDilog = styled(Dialog)((disabled?:any)=>({
  "& .MuiPaper-elevation24": {
    width: '600px',
    minHeight: '380px',
    maxHeight:'596px',
    borderRadius: '8px 8px 32px 8px',
    opacity: '0px',
    boxShadow:'0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)'

  },
  "& .MuiDialogContent-root": {
    padding: "60px 40px 24px 40px",
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-start',
    gap:'12px',
    borderBottom:'1px solid rgba(226, 232, 240, 1)'
  },
  "@media (max-width:500px)": {
    "& .MuiPaper-elevation24": {
      borderRadius: '8px 8px 8px 8px',
    }
  },
  "@media (max-width:645px)": {
    "& .MuiPaper-elevation24": {
       maxHeight:'unset',
    },
    "& .MuiDialogContent-root": {
      padding: "40px 40px 24px 40px",
    }
  }
}))


const  CustomizedRejectDilog = styled(Dialog)({
  "& .MuiPaper-elevation24": {
    width: '600px',
    height: '395px',
    opacity: '0px',
    borderRadius: '8px 8px 32px 8px',
    boxShadow:'0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)'

  },

  "& .MuiDialogContent-root": {
    display:'flex',
    padding: "40px 40px 24px 40px",
    flexDirection:'column',
    gap:'12px',
    alignItems:'flex-start',
    borderBottom:'1px solid rgba(226, 232, 240, 1)'
  },
})

const CustomizedForAnswerdReceivedDilog= styled(Dialog)((disabled?:any)=>({
  "& .MuiPaper-elevation24": {
    height: '294px',
    width: '600px',
    borderRadius: '8px 8px 32px 8px',
    boxShadow:'0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
    opacity: '0px',


  },
  "& .MuiDialogContent-root": {
    display:'flex',
    padding: "60px 40px 24px 40px",
    alignItems:'flex-start',
    flexDirection:'column',
    gap:'12px',
    borderBottom:'1px solid rgba(226, 232, 240, 1)'
  },
  "@media (max-width:500px)": {
    "& .MuiPaper-elevation24": {
      borderRadius: '8px 8px 8px 8px',
      height:'unset',
    }
  }
}))



const DialogContent = styled(MuiDialogContent)({
  "& .MuiDialogContent-root": {
  },
})
const ModalTitleTypography= styled(Typography)({
  fontFamily: 'Inter' as 'Inter',
fontSize: '24px',
fontWeight: 700,
lineHeight: '32.4px',
letterSpacing: '-0.11999999731779099px',
color:'rgba(15, 23, 42, 1)',

})
const ModalTitleTypographyForContinueTrialDilog= styled(Typography)({
  fontSize: '24px',
  fontFamily: 'Inter' as 'Inter',
  lineHeight: '32.4px',
  fontWeight: 700,
  color:'rgba(15, 23, 42, 1)',
  letterSpacing: '-0.11999999731779099px',
  "@media (max-width:500px)": {
    fontSize: '20px',
  },
})
const ModalContentTypography = styled(Typography)({
  fontFamily: 'Inter' as 'Inter',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '28.8px',
color:'rgba(51, 65, 85, 1)'
})
const ButtComPareModalBox= styled(Box)({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  justifyContent:'flex-end',
  padding:'24px',
 
})
const ButtComPareModalBoxForContinueTrialDilog= styled(Box)({
  display: 'flex',
  justifyContent:'flex-end',
  gap: '16px',
  padding:'24px',
  alignItems: 'center',
  "@media (max-width:500px)": {
    justifyContent:'center',
  }
})
const BottonComponeForContinueTrialDilog = styled(Button)({
  height: '56px',
  gap: '8px',
  borderRadius: '8px',
  opacity: '0px',
  border: '1px 0px 0px 0px',
  fontFamily: 'Inter' as 'Inter',
  textTransform:'none' as 'none',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  "&.MuiButton-contained": {
    boxShadow: "unset"
  },
  "@media (max-width:500px)": {
    height:'44px'
  }
});
const CrossImgModal=styled('img')({

    position: 'absolute' as 'absolute',
    right: 16,
    top: 16,
    cursor:'pointer'

})
const SnackBar= styled(Snackbar)({
  "&.MuiSnackbar-anchorOriginTopCenter":{
    top:"113px",
  }
  })
const InputTextField = styled(TextareaAutosize)({
  width: '100%',
  minWidth: "100%",
  height: '132px',
  minHeigh: "132px",
  maxWidth: "100%",
  maxHeight: "132px",
fontFamily: 'Lato' as 'Lato',
fontSize: '16px',
fontWeight: 400,
lineHeight: '19.2px',
borderRadius:'8px',
padding:'8px',

  color: "#1E293B",
  "&:focus-visible": {
    outline: "unset",
  },
  });
  const CustomSvg=styled('svg')({
    height:'26px',
    width:"26px" ,
    minHeight:'26px',
    minWidth:'26px'
  })
const Allstyle = {
  reasonForRejectTitle: {
    fontFamily: 'Lato' as 'Lato',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    color: '#334155'

  },
  buttonStyling: {
    fontFamily: "Lato" as "Lato",
    fontSize: "16px",
    fontWeight: 700,
    color: "#FFF",
    height: "44px",
    borderRadius: "8px",
    lineHeight: "24px",
    textTransform: "inherit" as "inherit",
    background: "#03B5AA",
    padding: "10px 16px",
  },
  buttonRejectStyling: {
    fontFamily: "Lato" as "Lato",
    fontSize: "16px",
    fontWeight: 700,
    color: "#FFF",
    height: "44px",
    borderRadius: "8px",
    lineHeight: "24px",
    textTransform: "inherit" as "inherit",
    background: "rgba(255, 94, 91, 1)",
    padding: "10px 16px",
  },
  buttonCancelStyling: {
    width: '140px',
    height: '56px',
    gap: '8px',
    borderRadius: '8px',

    opacity: '0px',
    textTransform: 'none' as 'none',
    fontFamily: 'Inter' as 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',

  },
  buttonConfirmStyling: {
    height: '56px',
    gap: '8px',
    borderRadius: '8px',
    opacity: '0px',
    border: '1px 0px 0px 0px',
    fontFamily: 'Inter' as 'Inter',
    textTransform: 'none' as 'none',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',

  },
  rejectedText: {
    fontFamily: "Lato" as "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: 'rgba(220, 38, 38, 1)'
  },
  Requester_Information: {
    fontFamily: "Lato" as "Lato",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22px",
    color: 'rgba(51, 65, 85, 1)'

  },
  cardPaperGrayTitleTextValue: {
    fontFamily: "Lato" as "Lato",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "26px",
  },
  cardPaperGrayTitleText: {
    fontFamily: "Lato" as "Lato",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: 'rgba(100, 116, 139, 1)'
  },
  categorySubCategoryText: {
    fontSize: "16px",
    fontFamily: "Lato" as "Lato",
    fontWeight: 400,
    lineHeight: "19.2px",
    color: 'rgba(51, 65, 85, 1)'
  },

  submittedOn: {
    fontFamily: "Lato" as "Lato",
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "26px",
    color: 'rgba(30, 41, 59, 1)'

  },
  submittedOnText: {
    fontFamily: "Lato" as "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.2px",
    color: 'rgba(51, 65, 85, 1)'
  },
  Textstyle: {
    color: "#0F172A",
    fontFamily: "Lato" as "Lato",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
  },
  nameStyling: {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  hedingsStyle: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",

  },
  paperSmallInnerBox: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column' as 'column',
  },
  titleText: {
    fontFamily: 'Lato',
    fontsize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: 'rgba(100, 116, 139, 1)',

  },
  valueText: {
    fontWeight: 500,
    fontsize: '16px',
    lineHeight: '24px',
    fontFamily: 'Lato',
    color: 'rgba(15, 23, 42, 1)'

  },
  subtext: {
    marginLeft: '16px',
    fontFamily: 'Lato',
    fontsize: '16px',
    fontWeight: 400,
    lineHeight: '19.2px',
    color: 'rgba(51, 65, 85, 1)'
  },
  otherBox: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: '16px',

  },
  otherSubBox: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: '8px',

  },
  radioParentContainer:{
    display:'flex',
    flexDirection: 'column' as 'column',
    gap:"24px",
    marginTop:'24px',
    width:'100%'
  },
  radioParentBox:{
    display:'flex',
    alignItems:'center',
    gap:"16px"
  },
  radioText: {
    fontFamily: 'Lato' as 'Lato',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.2px',
    color: '#0A2239',
    cursor: "pointer"
  },
  errorTextStyle: {
    fontSize: "12px",
    color: "#DC2626",
    fontWeight: 400,
    fontFamily: "Lato",
    lineHeight: "18px",
  },
   poPupstyle: {
    color: "#0F172A",
    backgroundColor: "#ffffff",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    borderRadius: "8px",
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
    fontSize: "16px",
    fontWeight: 400,
    paddingLeft: "9px",
    lineHeight: "24px",
    fontFamily: "Inter" as "Inter",
    paddingRight: "9px",
    paddingTop:"9px",
     paddingBottom:"9px",
     textAlign:"center",
  },
 
}
// Customizable Area End
