import React from "react";
// Customizable Area Start
import { Box,  Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import FavouritesWebController ,{Props} from "./FavouritesWebController.web"
import { button_ } from "./assets";
import { EmptyFav } from "../../../components/src/EmptyPost.web";
const baseUrl = require("../../../framework/src/config.js")
import Loader from "../../../components/src/Loader.web";
import CommonSnackbar from '../../../components/src/CommonSnackbar.web'


// Customizable Area End

export default class FavouritesWeb extends FavouritesWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
  
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        
        <Loader loading={this.state.isLoading } />
        <CommonSnackbar data-test-id='commonSnackBar' handleCloseFunc={()=>this.setState({remoItem:false})} open={this.state.remoItem} message={this.state.removemsg} />
        <HomeGrid >
      <Box style={{ ...Allstyle.headingBoxStyle,alignItems:"center" }}>
        <Typography style={{ ...Allstyle.hedingStyle, padding: "0px 6px 0px 0px", cursor: "pointer" }}
        data-test-id="navigate"
         onClick={() => {
          this.props.navigation.navigate("LandingPageWeb")
        }}>
          Home
        </Typography>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
            fill="#64748B"
          />
        </svg>

        <Typography
          style={{
            ...Allstyle.hedingStyle,
            color: "#0F172A",
            paddingInline: "6px",
          }}
        >
         Favorites
        </Typography>
      </Box>
      <Box
        style={{
          ...Allstyle.headingBoxStyle,
          justifyContent: "space-between",
          marginTop: "24px",
          marginBottom: "48px",
          alignItems:"center"
        }}
      >
       {this.state.favlistData.length>0 && <Typography style={{ ...Allstyle.mainHedingStyle }}>
        Favorites
        </Typography>}
      { this.state.favlistData.length>0 && <Typography style={{ ...Allstyle.hedingStyle, fontFamily:"Inter",color: "#64748B" }}>{this.state.favlistData.length}{this.state.favlistData.length==1?" Post":" Posts"}</Typography>
       } 
       </Box>
        
         { 
         this.state.favlistData.length>0?
         <ItemGrid container style={{  height: "fit-content",gap:"24px" }}>
        { this.state.favlistData.map((item:any,index:number)=>{
          const {image,category,name,sub_category,gender,formatted_age,hook_line,id,account_id}=item.attributes.post.data.attributes
 return (
  <Grid xs={12} md={3} sm={3} item style={Allstyle.itemStyle}
  
   key={index}>
    <Box
      style={{ ...Allstyle.itemImageStyle, position: "relative" }}
    >
      <img src={`${baseUrl.baseURL}${image.url}`} style={Allstyle.itemImageStyle}
       data-test-id={`navigate2${index}`}
      onClick={()=>{
      this.handleNavigation(account_id,id)
      }}
       />
      <img src={ button_} 
       data-test-id={`navigate3${index}`}
      onClick={()=>{
        this.deleteItem(id)
      }}
      
      style={{position
    :"absolute",right:12,top:12
    ,
    cursor:"pointer"
    }} />
     
    </Box>
    <Box
     data-test-id={`navigate4${index}`}
    onClick={()=>{
      this.handleNavigation(account_id,id)
    }}
      style={{
        padding: "12px 16px 16px 16px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        style={{
          ...Allstyle.subHeadingItemStyle,
          marginBottom: "4px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        <span style={Allstyle.headingItemStyle}>{name} - </span>
       {hook_line}
      </Typography>
      <Typography
        style={{
          ...Allstyle.hedingStyle2,
          marginBottom: "4px",
          color: "#64748B",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {category?.name}, {sub_category.name}
      </Typography>
      <Box
        style={{
          display: "flex",
          gap: "16px",
        }}
      >
        <Typography style={{ ...Allstyle.styleText }}>
          {gender}
        </Typography>
        |

       
        <Typography style={{ ...Allstyle.styleText }}>
       {this.handleAgeFormat(formatted_age?.years,formatted_age?.months)}
        </Typography>
      </Box>
    </Box>
  </Grid>
)
      }
        )}
            </ItemGrid>:

            <EmptyFav navigation={this.props.navigation?.navigate} image={this.state.favImage} />
          }
      </HomeGrid >
        
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const ItemGrid=styled(Grid)({
    marginBottom:"134px",
    justifyContent: "center",
    '@media (max-width:1430px)': {
    marginBottom:"40px",
      justifyContent: "center",
    },
  })

export const HomeGrid = styled(Grid)({
  marginTop: "48px",
  marginInline:"auto !important",
  maxWidth: "1168px",
  minWidth: "1168px",
  minHeight: "calc(100vh - 287px)",

  '@media(max-width: 1440px)': {
    marginInline: "135px",
  },
  '@media(max-width: 1400px)': {
    maxWidth: "unset",
  marginInline: "40px !important",
  },
  '@media(max-width: 1289px)': {
    maxWidth: "100%",
    marginInline: "20px !important",
    minWidth:"unset"
  },
    '@media(max-width: 1209px)': {
    maxWidth: "100%",
  marginInline: "20px !important",
  minWidth:"unset"
  },
  '@media(max-width: 400px)': {
    maxWidth: "unset",
    marginInline: "10px !important",
  minWidth:"unset"

  },

})

export const HomeGrid2 = styled(Grid)({
  marginTop: "48px",
  marginInline:"auto",
  maxWidth: "1168px",
  minWidth: "1168px",
  '@media(max-width: 1440px)': {
    marginInline: "135px",
  },
  '@media(max-width: 1400px)': {
    maxWidth: "unset",
  marginInline: "20px",
  minWidth:"unset"

  },
  '@media(max-width: 1209px)': {
    maxWidth: "100%",
  marginInline: "20px",
  minWidth:"unset"
  },
  '@media(max-width: 400px)': {
    maxWidth: "unset",
    marginInline: "10px",
  minWidth:"unset"

  },

})
export const Allstyle = {
    itemStyle: {
        boxShadow:
          "0px 9.067px 36.269px 0px rgba(0, 0, 0, 0.06), 0px 4.534px 9.067px 0px rgba(0, 0, 0, 0.03)",
        minWidth: "274px",
        cursor:"pointer",
        minHeight: "338px",
        maxHeight: "338px",
        maxWidth:"274px",
        borderRadius: "8px",
      },
    itemImageStyle: {
        objectFit: "cover" as "cover",
        width: "274px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        height: "230px",
      },
      styleText: {
        color: "#334155",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
      },
      StrategyStyle: {
        color: "#64748B",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        marginRight: "6px",
      },
      statusStyle: {
        color: "#334155",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
        letterSpacing: "0.14px",
      },
      hedingStyle2: {
        lineHeight: "22px",
        color: "#475569",
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Inter",
      },
      headingItemStyle: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "26px",
      },
      subHeadingItemStyle: {
        color: "#334155",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
      },
  mainGridStyle: {
    marginInline: "auto",
    maxWidth: "1168px",
    marginTop: "48px",
  },
 
  menuItemStyle: {
    fontSize: "16px",
    fontFamily: "Lato",
    color: "#0F172A",
    padding: "12px 16px",
    fontWeight: 400,
  },

  optionStyle: {
    fontWeight: 400,
    lineHeight: "18px",
    color: "#FF5E5B",
    fontSize: "12px",
    fontFamily: "Lato",
  },
  clearStyle: {
    fontFamily: "Lato",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 700,
    color: "#64748B",
    lineHeight: "22px",
  },
  nameSTyle: {
    color: "#0F172A",
    fontWeight: 700,
    lineHeight: "26px",
    fontFamily: "Inter",
    fontSize: "18px",
  },
  statusSTyle: {
    padding: "2px 8px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "40px",

    fontWeight: 700,
    fontFamily: "Lato",
    fontSize: "12px",
    lineHeight: "18px",

    display: "flex",
  },
  headingBoxStyle: {
    flexDirection: "row" as "row",
    display: "flex" as "flex",
    flexWrap: "wrap" as "wrap"
  },
  hedingStyle: {
    fontSize: "14px",
    color: "#475569",
    fontWeight: 400,
    fontFamily: "Lato",
    lineHeight: "22px",
  },
  mainHedingStyle: {
    color: "#0F172A",
    fontSize: "30px",
    lineHeight: "40px",
    fontWeight: 700,
    letterSpacing: "-0.15px",
    fontFamily: "Inter",
  },
 
};
// Customizable Area End
