import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import  { isValidPhoneNumber } from "react-phone-number-input";
import { getStorageData } from "../../../framework/src/Utilities";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  isLoading:boolean

firstName:string;
lastName:string;
message:string;
phoneNumber: string;

email:string;
phoneError:boolean
emailError:boolean
firstNameError:boolean
lastNameError:boolean
messageError:boolean
emailErrorMessage:string

role:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  getUserProfileId:string="";
  createContactUsId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      isLoading:false,
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      emailErrorMessage:"",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      firstName:"",
lastName:"",
message:"",
phoneError:false,
emailError:false,
firstNameError:false,
lastNameError:false,
messageError:false,
role:""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
   const userTypeRole= await getStorageData('auth_user_role');
   
   userTypeRole!==null &&this.setState({role:userTypeRole})
   if(userTypeRole!==null){
    this.handleUserProfile()
   }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
      switch (apiRequestCallId) {
        case this.createContactUsId:
        this.handleResponseCreate(responseJson)
          break;
        case this.getUserProfileId: 
this.setState({isLoading:false})

        const {first_name,last_name,country_code,phone_number,email}=responseJson?.data?.attributes;
       this.handleResponse(first_name,last_name,country_code,phone_number,email);
        break;
        
    }
  }
    // Customizable Area End
  }

  // Customizable Area Start
  handleResponseCreate=(responseJson:any)=>{
  
    if(responseJson?.errors && responseJson?.errors[0]?.contact){
      this.setState({phoneError:true})
    }
    if(responseJson?.data?.attributes){  
      this.props.navigation.navigate("LandingPageWeb",{info:true,msg:"Thank you for getting in touch. We'll get back to you faster than a lizard running across the sand!"})
    }
  }
  handleResponse=(first_name:string,last_name:string,country_code:string,phone_number:string,email:string)=>{
    this.setState({
      firstName:first_name,
      lastName:last_name,
      phoneNumber: "+" + country_code + phone_number,
      email:email,
    })
  }
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };




  handleFisrtName=(e:any)=>{
    const inputValue = e.target.value?.trim();
    const maxLength = 20;
    if(inputValue.length==0){
      this.setState({ firstNameError: true,firstName: e.target.value })
    }
  else  if ((inputValue.length > 0 || inputValue === ' ')) {
      this.setState({ firstName:   e.target.value.substring(0, maxLength) , firstNameError: false })
  }
  
  }
  handleLastName=(e:any)=>{
    const inputValue = e.target.value?.trim();
    const maxLength = 20;
    if(inputValue.length==0){
      this.setState({ lastNameError: true,lastName: e.target.value })
    }
  else  if ((inputValue.length > 0 || inputValue === ' ')) {
      this.setState({ lastName:  e.target.value.substring(0, maxLength), lastNameError: false })
  }
  
  }
  handleMessage=(e:any)=>{
    const inputValue = e.target.value?.trim();
    const maxLength =1000;
    if(inputValue.length==0){
      this.setState({ messageError: true,message: e.target.value.substring(0, maxLength) })
    }
  else  if ((inputValue.length > 0 || inputValue === ' ')) {
      this.setState({ message:  e.target.value.substring(0, maxLength) , messageError: false })
  }
  
  }

  handleValidationSpace = (maxLength: number, value: any) => {
    const inputValue = (typeof value === 'string' || value instanceof String) ? value.trim() : '';
  
    if (inputValue.length <= maxLength && inputValue.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  handleErroMessage=(state:any)=>{
    return state&& "Please enter a value"
      }
  handleColorPhone = () => {
    return this.state.phoneError ? "#F87171" : "#CBD5E1"
  }
  hanldePlaceholder = () => {
    return this.state.phoneNumber == "" && "00000 00000"
  }
  handleEmail = (value: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(value===""){
      this.setState({
        emailError: true,
        email: value,
        emailErrorMessage:"Please enter a value"
      });
      return true
    }
    else if (!value.match(emailPattern)) {
      this.setState({
        emailError: true,
        email: value,
        emailErrorMessage:"Please enter a valid email address"
      });
      return true
    } else {
      this.setState({
        emailError: false,
        email: value,
        emailErrorMessage:""
      });
      return false
    }
  }

  handlePhoneNumber = (e:any) => {
    if (e && isValidPhoneNumber(e.toString())) {
      this.setState({ phoneNumber: e, phoneError: false });
      return false;
    } else {
      this.setState({ phoneNumber: e, phoneError: true });
      return true;
    }
  }
  handleUserProfile=async()=>{
    
    const UserprofileId=await getStorageData('auth_user_id')
    if(UserprofileId!==null&&UserprofileId!==undefined){
this.setState({isLoading:true})
      const headerToken = {
      "token": await getStorageData('auth_token')
    };
   
    const requestMessageIs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileId = requestMessageIs.messageId;

    requestMessageIs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.propfileEndPoints(UserprofileId)
    );


    requestMessageIs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerToken)
    );

    requestMessageIs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessageIs.id, requestMessageIs);
    return true;
    }
  }
  createContactUs=async()=>{
    const headerToken = {
      "Content-Type": configJSON.contactUsApiContentType,
      ...(this.state.role !== "" ? { "token": await getStorageData('auth_token') } : {})
  };
      const body = {
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          full_phone_number: this.state.phoneNumber,
          description: this.state.message,
          email: this.state.email,
      }
   
    const requestMessageIs = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createContactUsId = requestMessageIs.messageId;

    requestMessageIs.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'bx_block_contact_us/contacts'
    );

    requestMessageIs.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerToken)
    );

    requestMessageIs.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessageIs.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessageIs.id, requestMessageIs);
    return true;
    
  }

  handleSubmit=()=>{
  const errors = {
    emailError  : this.handleEmail(this.state.email),
    firstNameError  : this.handleValidationSpace(20,this.state.firstName),
    lastNameError  : this.handleValidationSpace(20,this.state.lastName),
    messageError  : this.handleValidationSpace(1000,this.state.message),
    phoneError  : this.handlePhoneNumber(this.state.phoneNumber),
  }
  this.setState(errors);
          const hasErrors = Object.values(errors).some(error => error);
          if(!hasErrors){
           this.createContactUs()
          }
  }
  // Customizable Area End
}
