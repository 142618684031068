import React from "react";
// Customizable Area Start
import { Box,  Button,  Grid, TextField, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ContactusController,{Props} from "./ContactusController";
import { Allstyle, HomeGrid } from "../../favourites/src/FavouritesWeb.web";
import PhoneInput from 'react-phone-number-input'
import Loader from "../../../components/src/Loader.web";


// Customizable Area End

export default class ContactusWeb extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  starIcon = () => {
    return <span style={{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#FF5E5B"
    }}>
      {" "}    *
    </span>
}
renderPhoneErr = () => {
  return (
    this.state.phoneError && (
      <Typography
        style={{ ...contactUsStyle.errorTextStyle }}
      >
        {"Please enter a valid phone number"}
      </Typography>
    )
  )
}
renderMessageErr = () => {
  return (
    this.state.messageError && (
      <Typography
        style={{ ...contactUsStyle.errorTextStyle }}
      >
        {"Please enter a value"}
      </Typography>
    )
  )
}
  // Customizable Area End

  render() {
  
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        
  <Loader loading={this.state.isLoading} />
       
        <HomeGrid style={{minHeight: 'calc(100vh - 247px)'}} >
      <Box style={{ ...Allstyle.headingBoxStyle,alignItems:"center" }}>
        <Typography style={{ ...Allstyle.hedingStyle, padding: "0px 6px 0px 0px", cursor: "pointer" }}
        data-test-id="navigate"
         onClick={() => {
          this.props.navigation.navigate("LandingPageWeb")
        }}>
          Home
        </Typography>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
            fill="#64748B"
          />
        </svg>

        <Typography
          style={{
            ...Allstyle.hedingStyle,
            color: "#0F172A",
            paddingInline: "6px",
          }}
        >
         Contact us
        </Typography>
      </Box>
      <Grid container style={{marginBottom:"134px",marginTop: "32px",}}>
<SubGrdid lg={6} xs={12} sm={12} md={12}>
<BoxAlignItem
        style={{
          ...Allstyle.headingBoxStyle,
          maxWidth:"473px"
        }}
      >
        <Typography style={{ ...Allstyle.mainHedingStyle ,marginBottom:"24px"}}>
        Contact us
        </Typography>
        <Typography style={{ ...contactUsStyle.subHeadingStyle }}>
        We're here to assist you. 
<br/>
<br/>
Feel free to contact us by fill out the contact form, and we'll get back to you as soon as possible.
        </Typography>
      
       </BoxAlignItem>
</SubGrdid>
<SubGrdid2 lg={6} xs={12} sm={12} md={12}>
<BoxMain style={{...contactUsStyle.boxStyle}}>

  <Box>

<Typography style={contactUsStyle.fieldStyle}>
First Name {this.starIcon()}
                        </Typography>
                        <TextFieldName
                          data-test-id="First"
                          placeholder="First Name"
                          textColor="#0F172A"
                          onChange={(e:any)=>{
                            this.handleFisrtName(e)
                          }}
                          error={this.state.firstNameError}
                          helperText={this.handleErroMessage(this.state.firstNameError)}
                          fullWidth
                          variant="outlined"

                          value={this.state.firstName}
                        />
  </Box>

  <Box>
                        <Typography style={contactUsStyle.fieldStyle}>
                        Last Name {this.starIcon()}
                        </Typography>
                        <TextFieldName
                         textColor="#0F172A"
                         error={this.state.lastNameError}
                         helperText={this.handleErroMessage(this.state.lastNameError)}
                          data-test-id="Last"
                          placeholder="Last Name"

                          value={this.state.lastName}
                          onChange={(e:any)=>{
                            this.handleLastName(e)
                          }}
                          fullWidth
                          variant="outlined"
                        />
  </Box>

  <Box>
                        <Typography style={contactUsStyle.fieldStyle}>
                        Email Address {this.starIcon()}
                        </Typography>
                        <TextFieldName
                         textColor={this.state.role==""?"#0F172A":"#64748B"}
                         error={this.state.emailError}
                         disabled={this.state.role!==""?true:false}
                          data-test-id="Email"
                          placeholder="Email Address"

                        onChange={(e:any)=>{
                          this.handleEmail(e.target.value)
                        }}
                          fullWidth
                          value={this.state.email}
                          helperText={
                            this.state.emailError &&
                            (this.state.emailErrorMessage)
                          }
                         
                          variant="outlined"
                        />

  </Box>

  <Box>

                        <Typography style={contactUsStyle.fieldStyle}>
                        Phone Number {this.starIcon()}
                        </Typography>
                        <div style={{ position: 'relative' }}>

                        <label style={{
                      position: 'absolute', top: 18, left: 100,color:"#94A3B8",
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "19px",
                    }} htmlFor="">{this.hanldePlaceholder()}</label>
                        <PhoneStyle
        data-test-id="Phone"
        borderColor={this.handleColorPhone()}
        className="custominput"
        value={this.state.phoneNumber}
        onChange={(e: any) => {

          this.handlePhoneNumber(e)
        }}
        defaultCountry="AE"
        countries={[]}
        international
      /> 
                        </div>
      {this.renderPhoneErr()}

  </Box>

  <Box >
                        <Typography style={contactUsStyle.fieldStyle}>
                        Message  {this.starIcon()}
                        </Typography>
                        <Box style={{...contactUsStyle.textArea,   border:this.state.messageError?"1px solid var(--Status-Red-400, #F87171)":"1px solid #CBD5E1",}}>
                        <TextFieldAuto
                        multiline
                        value={this.state.message}
                        placeholder="Message"
                        onChange={(e:any)=>{
                          this.handleMessage(e)
                        }}
                                                minRows={4}
                                                data-test-id="Message"
                                                id="conversation"
                                                style={{
                                                  width:"100%",
                                              }}

                        />
</Box>

{this.renderMessageErr()}

  </Box>

  <Button variant="contained" 
  data-test-id="handleSubmit"
  style={contactUsStyle.SendmessageStyle} onClick={()=>this.handleSubmit()}>
  Send message
  </Button>
</BoxMain>
</SubGrdid2>
      </Grid>
   
      </HomeGrid >
        
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const SubGrdid=styled(Grid)({
  '@media (max-width:1279px )': {
 justifyContent:"center",
 display:"flex",
 alignItems:'center'
},
})
const SubGrdid2=styled(Grid)({
  '@media (max-width:1279px )': {
    marginTop:"20px",
 justifyContent:"center",
 display:"flex"
},
})
const TextFieldAuto=styled(TextField)({
  "& .MuiInput-underline::before":{
    borderBottom:"unset !important",
  },
  "& .MuiInput-underline::after":{
    borderBottom:"unset !important",
  },
  "& .MuiOutlinedInput-input": {
    padding: "18.5px 8px",
},
  '& ::placeholder':{
    color:"#94A3B8",
    fontFamily: "Lato",
fontSize: "16px",
opacity:1,

fontWeight: 400,
lineHeight: "24px",
  },
  "& .MuiInputBase-multiline":{
    padding:"0"
  },
  "& .MuiInputBase-root": {
    color: "#0F172A !important",
    fontWeight:"400 !important",
    fontSize:"16px !important",
    fontFamily:"Lato !important",
},
})
const BoxMain=styled(Box)({
  width: "508px",
  '@media (max-width:810px )': {
 
    width: "450px",
},
'@media (max-width:530px )': {
  
    width: "400px",
   },
   '@media (max-width:450px )': {
  
    width: "350px",
   },
   '@media (max-width:400px )': {
 
    width: "300px",
   },

})
const BoxAlignItem=styled(Box)({
  '@media (max-width:1279px )': {
justifyContent:'center',
  textAlign:'center'
  }
})
const PhoneStyle = styled(PhoneInput)(({ borderColor }: any) => ({
  border: `1px solid ${borderColor || "#F87171"}`,
  borderRadius: 8,
  height: 54,
  zIndex: 0,
  position: "relative",
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 10,
  '& input': {
    border: 'none'
  },
  '& input:focus': {
    border: 'none',
    outline: 'none'
  },
  '& .PhoneInputInput': {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    background: "transparent",

    lineHeight: "24px"
  },
  '& input:focus-visible': {
    border: 'none',
    outline: 'none'
  },
  
}));
const TextFieldName = styled(TextField)(({ textColor }: any) =>({
  '& .MuiInputBase-root.Mui-disabled': {
    color:"#64748B"
  },
  '& input::placeholder': {
    color:"#94A3B8",
    fontFamily: "Lato",
    fontSize: "16px",
    opacity:1,
    fontWeight: 400,
    lineHeight: "19px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
      color: "#DC2626",
      fontSize: "12px",
      marginBottom:"2px",
      fontFamily: "Lato",
      fontWeight: 400,
      lineHeight: "18px"
  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F87171"
  },


  "& .MuiInputBase-root": {
      color: textColor,
      fontWeight:"400 !important",
      fontSize:"16px !important",
      fontFamily:"Lato !important",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
      borderRadius: "8px"
  },
  "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
          borderColor: "#CBD5E1",
      },
      "&.Mui-focused fieldset": {
          borderWidth: "1px",
          borderColor: "#CBD5E1",
      },
  },

  "& .MuiOutlinedInput-input": {
      padding: "18.5px 8px",
  },

  "& .MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px"
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      border: "1px solid #CBD5E1",
  },
  '& ::placeholder':{

    color:"#94A3B8",
    fontFamily: "Lato",
fontSize: "16px",
fontWeight: 400,
lineHeight: "24px",
  },

 
}));
const contactUsStyle={
  subHeadingStyle:{
    color: "#475569",
fontFamily: "Inter",
fontSize: "16px",
fontWeight: 400,
lineHeight: "24px"
  },
  textArea: {
    height: "118px",
    overflowY:"auto" as "auto",
    color: "var(--Neutrals-Cool-gray-900, #0F172A)",
    padding:"10px 8px",
    fontFamily:"Lato",
    fontSize:"16px",
    fontWeight:400,
    borderRadius: "10px",
  },
  boxStyle:{
    display: "flex",
padding: "32px",
flexDirection: "column" as "column",
justifyContent: "center" as "center",
gap: "16px",
borderRadius: "16px",
border: "1px solid var(--Neutrals-Cool-gray-100, #F1F5F9)",
background:" var(--0, #FFF)",
boxShadow:" 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"
  }
,
  fieldStyle:{
    color: "var(--Neutrals-Cool-gray-500, #64748B)",
fontFamily: "Inter",
fontSize: "14px",
fontWeight: 700,
marginBottom:"4px",
lineHeight: "22px" 
  },
  errorTextStyle: {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    marginTop:"2px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  SendmessageStyle:{
    height:"56px",
    marginTop:"16px",
    minWidth:"165px",
    maxWidth:"165px",
    textTransform:"inherit" as "inherit",
    color: "var(--Basic-White, var(--0, #FFF))",
fontFamily: "Lato",
borderRadius: "8px",
background: "var(--Primary-color-1, #03B5AA)",
fontSize: "16px",
fontWeight: 700,
lineHeight: "24px", 
boxShadow:"none"
  }
}

// Customizable Area End
