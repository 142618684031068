export const calender = require("../assets/calender.svg");
export const radiobuttons=require("../assets/radiobutton_.svg")
export const fillcheckBoxRadioButton=require("../assets/fillcheckBoxRadio.svg")
export const checkbox_Checked=require("../assets/checkbox_CheckBoxTrue.svg")
export const checkbox_UnChecked=require("../assets/checkbox_checkFalse.svg")
export const checkMark=require("../assets/rightIcon.svg")
export const dot_view=require("../assets/dot_view.png")
export const image_check_sort=require("../assets/image_check_sort.png")
export const image_whiteCheck=require("../assets/image_whiteCheck.png")
export const DownArrow=require("../assets/ArrowDrop.svg")
export const UpArrow=require("../assets/ArrowUp.svg")
export const CrossMark=require("../assets/Cross.svg")
export const no_requestMeet=require("../assets/no_requestMeet.png")
export const no_application=require("../assets/no_application.png")
export const cross_reject=require("../assets/cross_reject.png")
export const crossIcon=require("../assets/crossIcon.png")
export const image_Notebook=require("../assets/image_Notebook.png")
export const no_image=require("../assets/no_image.png")



