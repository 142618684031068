import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ArrowDown, ArrowUp } from "../../catalogue/src/assets";
import React from "react";
import storage from "../../../framework/src/StorageProvider";
interface YourComponentState {
  // Define your state properties here
  animalTypeOption: number[];
  breedMenuOption: number[];
  genderMenuOption: number[];
  postStatusOption: number[];
  animalOptionData:any[];
  animalTypeOfData:any[];
  breedTypeofData:any[]
  breedOptionData:any[];
  menu:any
  searchField:any
  currentPage:any
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  menu: any,
  menuItem: any,
  menuItemRf: any,
  animaltypeMenu:any;
  animaltypeMenuRef:any;
  animalTypeOption:any[];
  breedMenu:any;
  createPost:boolean
  infoMessage:string
  breedMenuRef:any;
  searchField:string;
  animalTypeOfData:any[];
  breedTypeofData:any[]
  breedMenuOption:number[];
  genderMenu:any;
  genderMenuRef:any;
  userType:string;
  isLoading:boolean
  genderMenuOption:number[];
  postStatusMenu:any;
  postStatusMenuRef:any;
  postStatusOption:number[];
  animalOptionData:any[];
  breedOptionData:any[];
  allCatalogueData:any[];
  currentPage:any;
  total_page:any
  totalNumberOfPage:any;
  emptyImage:string;
  emptySearchImage:string
  already_answer_given:boolean
  post_toast:boolean
  post_toast_msg:string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CataloguePageController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  getAnimalId:string="";
  getBreedoptionId:string=""
  getBreeddataId:string=""
  catalogueDataId:string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   already_answer_given:false,
   arrayHolder: [],
   token: "",
   emptyImage:"",
  emptySearchImage:"",
breedOptionData:[],
searchField:"",
animalTypeOfData:[],
createPost:false,
breedTypeofData:[],
allCatalogueData:[],
infoMessage:"",
menuItemRf: React.createRef(),
animaltypeMenuRef:React.createRef(),
breedMenuRef:React.createRef(),
genderMenuRef:React.createRef(),
postStatusMenuRef:React.createRef(),
currentPage:1,
total_page:"",
userType:"",
totalNumberOfPage:null,
animalOptionData:[
 {
   id: 0,
   name: "All",
 },
],
   menu: 0,
   menuItem: null,
   animaltypeMenu:false,
   animalTypeOption:[0],
   breedMenu:null,
   breedMenuOption:[0],
   genderMenu:null,
   genderMenuOption:[0],
   postStatusMenu:null,
   postStatusOption:[0],
   isLoading:true,
  post_toast:false,
  post_toast_msg:""
   // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
    getPgetProductApiCallId: any;
    roductApiCallId: any;
    
  async componentDidMount() {
    super.componentDidMount();
    const emptyImage=await storage.get("posts_screen_with_no_posts_image")
    const emptySearchImage=await storage.get("posts_screen_with_no_results_based_on_search_image")
    if(emptyImage){
      this.setState({emptyImage:emptyImage})
    }
    if(emptySearchImage){
      this.setState({emptySearchImage:emptySearchImage})
    }
    this.setState({userType:await storage.get("auth_user_role")})
  
    this.getAnimalOption()
    this.getBreedOption()
    this.getAllDataCatalouge()
    this.handlePageEmpty()
    this.getBreedData()
    const queryParams = new URLSearchParams(window.location.search);
    const delete_post = queryParams.get('delete_post');
const postName= queryParams.get('name');
if(delete_post=='true' &&postName!==""){
  setTimeout(() => {
    this.setState((state, props) => ({
      post_toast: true,
      post_toast_msg:`Thank you for the update! Congratulations on ${postName}'s adoption! Your post is now deleted.`
    }), () => {
      setTimeout(() => {
        this.setState(() => ({
          post_toast: false,
          post_toast_msg:""
        }))
      }, 5000);
    })
  }, 100);
}
  }
    // Customizable Area End

 


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleReceivedApi(message)

  
    if (responseJson && !responseJson.error) {
      const add= [{
        id: 0,
        name: "All",
      },]
      switch (apiRequestCallId) {
        case this.getAnimalId: 
        this.setState({
          animalOptionData: [...add, ...responseJson?.data],
        });
        
          break;
          case this.getBreedoptionId: 
    const compareNames = (firstName: {name: string}, lastName: {name: string}) => {
                  if (firstName.name < lastName.name) {
                      return -1;
                  }
                  if (firstName.name > lastName.name) {
                      return 1;
                  }
                  return 0;
              };
                    let sortData=responseJson?.data.sort(compareNames)
          this.setState({
            breedOptionData: [...add, ...sortData]
          });
            break;
            case this.catalogueDataId:
              if(await storage.get("createPost")){
                let messsage=await storage.get("Message")
                this.handleCommonSnackBar(messsage)
              }
this.setState({animaltypeMenu :false,isLoading:false,breedMenu:false, menuItem :false,genderMenu:false, postStatusMenu:false})
              this.setState({allCatalogueData:responseJson?.data,total_page:responseJson?.meta?.last_page,
                totalNumberOfPage:responseJson?.meta?.total_posts
              })
              break;
              case this.getBreeddataId:
                this.setState({
                  breedTypeofData: [...responseJson?.data]
                });
                break;

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  hanldeBackground=(index:number)=>{
    return this.state.menu == index
    ? "linear-gradient(0deg, rgba(205, 240, 238, 0.50) 0%, rgba(205, 240, 238, 0.50) 100%), #FFF"
    : ""
  }
  handleCommonSnackBar=(message:string)=>{
  
      this.setState((state, props) => ({
        infoMessage: message,
        createPost: true
      }))
     
        setTimeout(() => {
          this.setState(() => ({

            infoMessage: "",
            createPost: false
          }))
          storage.remove("createPost")
          storage.remove("Message")
        }, 5000);
      
   
  }
  componentDidUpdate(prevProps: any, prevState: YourComponentState) {
   
    if(prevState.animalTypeOption !== this.state.animalTypeOption ||
     prevState.breedMenuOption !== this.state.breedMenuOption ||
      prevState.genderMenuOption !== this.state.genderMenuOption||
       prevState.postStatusOption !== this.state.postStatusOption||
       prevState.menu !== this.state.menu||
       this.state.searchField!==prevState.searchField||
this.state.currentPage!==prevState.currentPage
       ){
     this.getBreedOption()

this.getAllDataCatalouge()
   }
 }
 getAnimalOption = async () => {
   const header = {
     "Content-Type": configJSON.validationApiContentType,
   };

   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getAnimalId = requestMessage.messageId

   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     `/bx_block_categories/categories`
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
   );

   runEngine.sendMessage(requestMessage.id, requestMessage);
   return true;
 };
 closeSnack=()=>{
   this.setState({createPost:false})
 }
 getBreedOption = async () => {
   const header = {
     "Content-Type": configJSON.validationApiContentType,
   };
   
const url= !this.state.animalTypeOption.includes(0) &&this.state.animalTypeOption.length!==0 ?`bx_block_categories/sub_categories?category_id=${JSON.stringify(this.state.animalTypeOption)}`:`bx_block_categories/sub_categories`
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getBreedoptionId = requestMessage.messageId

   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     url
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
   );

   runEngine.sendMessage(requestMessage.id, requestMessage);
   return true;
 };
 getBreedData = async () => {
   const header = {
     "Content-Type": configJSON.validationApiContentType,
   };
   
const url= `bx_block_categories/sub_categories`
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );

   this.getBreeddataId = requestMessage.messageId

   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     url
   );

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
   );

   runEngine.sendMessage(requestMessage.id, requestMessage);
   return true;
 };
 handleChange=(event:any,page:any)=>{
   this.setState({currentPage:page})
   window.scrollTo(0,0)
}
 handleEndpoint=()=>{
   const postStatusOptions = this.state.postStatusOption.map((index:any) => configJSON.postStatus[index].name);

   const handleStatus = !this.state.postStatusOption.includes(0) ? `&status=${JSON.stringify(postStatusOptions)}` : "";
   const genderOptions = this.state.genderMenuOption.map((index:any) => configJSON.gender[index].name);

    const  handleGender= !this.state.genderMenuOption.includes(0)?`&gender=${String(genderOptions)}`:""
    const  handleBreed= !this.state.breedMenuOption.includes(0)?`&breed=${JSON.stringify(this.state.breedMenuOption)}`:""
    const  handleAnimal= !this.state.animalTypeOption.includes(0)?`&animal_type=${JSON.stringify(this.state.animalTypeOption)}`:""
    const  handleSearch= this.state.searchField!=="" && this.state.searchField !==null && this.state.searchField!==undefined?`&keyword=${this.state.searchField}`:""
    const  handleCurrenPage= this.state.currentPage!==null?`&page=${this.state.currentPage}`:""
   const url=
   `/bx_block_posts/posts?sort_by=${configJSON.menuItem[this.state.menu].value}${handleSearch}${handleAnimal}${handleBreed}${handleGender}${handleStatus}&per_page=${String(12)}${handleCurrenPage}`
 
return url;
 }
 getAllDataCatalouge = async () => {
   const header = {
     "Content-Type": configJSON.validationApiContentType,
     "token":await storage.get("auth_token")
   };
   
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );

   this.catalogueDataId = requestMessage.messageId

   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
  this.handleEndpoint());

   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
   );

   runEngine.sendMessage(requestMessage.id, requestMessage);
   return true;
 };
 handleClick = () => {
   this.setState({ menuItem: !this.state.menuItem });
 };
 handleClickAnimal = () => {
   this.setState({ animaltypeMenu: !this.state.animaltypeMenu });
 };
 handleClickBreed = () => {
   this.setState({ breedMenu: !this.state.breedMenu });
 };
 handleClickPost = () => {
   this.setState({ postStatusMenu: !this.state.postStatusMenu });
 };
 handleClickGender = () => {
   this.setState({ genderMenu: !this.state.genderMenu });
 };

 handleStyleBackground=(value:string)=>{
return  value ?"#03B5AA":"#FFF";
 }
 handleStyleColor=(value:string)=>{
   return  value  ?"#FFF":"#334155"
     }
     handleSvg=(value:string)=>{
return  value  ? ArrowUp:ArrowDown;
     }
 handleMenu = (index:number) => {
   this.setState({ menu: index });
 };
 
 handleCloseAnimal = (e:any) => {
  if(e.target.id!='Animal'&&e.target.classList[0]!='MuiButton-label'){
   this.setState({ animaltypeMenu: false });
  }
 };
 handleCloseBreed = (e:any) => {
  if(e.target.id!='Breed'&&e.target.classList[0]!='MuiButton-label'){
   this.setState({ breedMenu: false });
  }
 };
 handleCloseMenu = () => {
   this.setState({ menuItem: false });
 };
 handleListKeyDownmenu(event: any, stateName: any) {
   if (event.key === 'Tab') {
     event.preventDefault();
     this.setState({ [stateName]: false }as Pick<any, keyof any>);
   }
  }

 handleCloseGender= (e:any) => {
  if(e.target.id!='Gender'&&e.target.classList[0]!='MuiButton-label'){
   this.setState({ genderMenu: false });
  }
 };
 handleCloseStatus= (e:any) => {
  if(e.target.id!='Status'&&e.target.classList[0]!='MuiButton-label'){
   this.setState({ postStatusMenu: false });
  }
 };
 handleMenuAnimal = (index: number) => {
   const { animalTypeOption } = this.state;
 this.setState({currentPage:1})
   if (index === 0) {
     this.setState({ animalTypeOption: [0] });
   } else {
     const updatedOptions = animalTypeOption.includes(index)
       ? animalTypeOption.filter((item: any) => item !== index)
       : [...animalTypeOption, index];
 
     if (updatedOptions.length === this.state.animalOptionData.length-1 && !updatedOptions.includes(0)) {
       this.setState({ animalTypeOption: [0] });
     } 
     else if (updatedOptions.length === 0) {
       this.setState({ animalTypeOption: [0] });
     }
     else {
       this.setState({ animalTypeOption: updatedOptions.filter((option:any) => option !== 0) });
     }
   }
 };
 handleConditionOfClear=()=>{
   return ((this.state.animalTypeOption.length > 0 && !this.state.animalTypeOption.includes(0)) ||
   (this.state.breedMenuOption.length > 0 && !this.state.breedMenuOption.includes(0)) ||
   (this.state.genderMenuOption.length > 0 && !this.state.genderMenuOption.includes(0)) ||
   (this.state.postStatusOption.length > 0 && !this.state.postStatusOption.includes(0)))
 }
 
 clearOption = (optionName: string, indexToRemove: number) => {
   const updatedOptions = this.state[optionName as keyof YourComponentState].filter((index: number) => index !== indexToRemove);
 
   if (updatedOptions.length === 0) {
     updatedOptions.push(0);
   }
 
   this.setState({ [optionName]: updatedOptions } as Pick<YourComponentState, keyof YourComponentState>);
 };

 handlePageEmpty=()=>{
 const vaild= Array.isArray(this.state.animalTypeOption) &&
   this.state.animalTypeOption.includes(0) &&
   Array.isArray(this.state.breedMenuOption) &&
   this.state.breedMenuOption.includes(0) &&
   Array.isArray(this.state.genderMenuOption) &&
   this.state.genderMenuOption.includes(0)&&
   Array.isArray(this.state.postStatusOption) &&
   this.state.postStatusOption.includes(0)&&
   this.state.menu==0
   &&this.state.searchField=="" &&this.state.allCatalogueData.length==0;
 return vaild
 }
 handlePostEmpty=()=>{
   
  const valid= (
    ( Array.isArray(this.state.animalTypeOption) &&
     !this.state.animalTypeOption.includes(0)
   ) ||
   (
     Array.isArray(this.state.breedMenuOption) &&
     !this.state.breedMenuOption.includes(0)
   ) ||
   (
     Array.isArray(this.state.genderMenuOption) &&
     !this.state.genderMenuOption.includes(0)
   ) ||
   this.state.menu === 0 ||
   (
     Array.isArray(this.state.postStatusOption) &&
     !this.state.postStatusOption.includes(0)
   ) ||
   this.state.searchField !== "" )&&
   this.state.allCatalogueData.length === 0;
       return valid
   
     
}

 
 clearAllOptions = () => {
   this.setState({
 currentPage:1,

     animalTypeOption: [0],
     breedMenuOption: [0],
     genderMenuOption: [0],
     postStatusOption: [0],
   });
 };
 
 handleMenuBreed = (index:number) => {
   const { breedMenuOption } = this.state;
   this.setState({currentPage:1})
 
   if (index === 0) {
     this.setState({ breedMenuOption: [0] });
   } else {
     const updatedOptions = breedMenuOption.includes(index)
       ? breedMenuOption.filter((item: any) => item !== index)
       : [...breedMenuOption, index];
 
     if (updatedOptions.length === this.state.breedOptionData.length-1  && !updatedOptions.includes(0)) {
       this.setState({ breedMenuOption: [0] });
     } 
     else if (updatedOptions.length === 0) {
       this.setState({ breedMenuOption: [0] });
     }
     
     else {
       this.setState({ breedMenuOption: updatedOptions.filter((option:any) => option !== 0) });
     }
   }
 };

 handleMenuGender = (index:number) => {
   const { genderMenuOption } = this.state;
   this.setState({currentPage:1})
 
   if (index === 0) {
     this.setState({ genderMenuOption: [0] });
   } else {
     const updatedOptions = genderMenuOption.includes(index)
       ? genderMenuOption.filter((item: any) => item !== index)
       : [...genderMenuOption, index];
 
     if (updatedOptions.length === 2 && !updatedOptions.includes(0)) {
       this.setState({ genderMenuOption: [0] });
     } 
     else if (updatedOptions.length === 0) {
       this.setState({ genderMenuOption: [0] });
     }
     
     else {
       this.setState({ genderMenuOption: updatedOptions.filter((option:any) => option !== 0) });
     }
   }
 
 };

 handleMenuPost = (index:number) => {
   const { postStatusOption } = this.state;
   this.setState({currentPage:1})
 
   if (index === 0) {
     this.setState({ postStatusOption: [0] });
   } else {
     const updatedOptions = postStatusOption.includes(index)
       ? postStatusOption.filter((item: any) => item !== index)
       : [...postStatusOption, index];
 
     if (updatedOptions.length === 5 && !updatedOptions.includes(0)) {
       this.setState({ postStatusOption: [0] });
     } 
     else if (updatedOptions.length === 0) {
       this.setState({ postStatusOption: [0] });
     }
     
     else {
       this.setState({ postStatusOption: updatedOptions.filter((option:any) => option !== 0) });
     }
   }
 
 }

 addTwo = (argumentOne:string, argumenyTwo:string) =>{
    const lengthOne = argumentOne.length;
    const lengthTwo = argumenyTwo.length;
    const sumOfTheTwo = lengthOne + lengthTwo;
    const perfectPixels = sumOfTheTwo * 8.2;
    return perfectPixels+"px"
 }
 handleTextStatusFunc=(status:string)=>{
  if (status == 'Archived') {return 'Reserved'
    }else{return status
}
}

 handleAlready_answer_given =()=>{
  this.setState({already_answer_given:false})
 }
 handleReceivedApi=(message:{id:string,getData:Function})=>{
  if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
  const itemData = message.getData(
    getName(MessageEnum.SessionResponseData)
  );

  itemData?.state&& this.setState({already_answer_given:itemData?.state})
 

}
 }
 



  // Customizable Area End
}
