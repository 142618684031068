import React from "react";
import {
    Button,
    // Customizable Area Start
    Typography,
    Box,
    TextField,
    Avatar,
    Grid,
    ClickAwayListener,
    Grow,
    MenuItem,
    Paper,
    Popper,
    styled,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CommonSnackbar from '../../../components/src/CommonSnackbar.web'

import { checkBoxTrue, checkBoxFalse } from "../../../blocks/email-account-registration/src/assets";

import { leftArrowIcon2 ,MsgIcon} from "./assets";
import { HomeGrid2, Allstyle } from "../../favourites/src/FavouritesWeb.web";
import CommunityForumController, {
    Props, configJSON
} from "./CommunityForumController";
import { ButtonComponent, CustomMenu, OptionGrid, PaginationComponent, POstBox,PostGrid } from "../../../blocks/email-account-login/src/CataloguePage.web";

import { Autocomplete } from "@material-ui/lab";
import { Cross } from "../../catalogue/src/assets";
import {EmptySearchCommunity,EmptyTopic} from "../../../components/src/EmptyPost.web";
import moment from "moment";
import {Loader2} from "../../../components/src/Loader.web";
import { TypographyStyle, TypographyStyle2 } from "../../../blocks/comments/src/CreateComment.web";
const PaperStyle=styled(Paper)({
    width:"240px",
})
const GridPaginationCount=styled(Grid)({
    display:"flex",justifyContent:"center",alignItems:"center",
    '@media (max-width:1279px)': {
        justifyContent:"flex-start"

    },
    '@media (max-width:599px)': {
        justifyContent:"center"

    },
})
const GridPaginationCount2=styled(Grid)({
    display:"flex",justifyContent:"flex-end",alignItems:"center",
    '@media (max-width:1279px)': {
        justifyContent:"flex-end"

    },
    '@media (max-width:599px)': {
        marginTop:"20px",
        justifyContent:"center"

    },
})
const TypographyStyle23=styled(Typography)({
    marginRight:"16px",
    '@media(max-width: 599px)': {
      marginRight:"unset"
    }
  })
// Customizable Area End

export default class CommunityFormTopic extends CommunityForumController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

   
    handleResult=()=>{
return this.state.total_topic>1?`results`:`result`
    }
    handlePagination=()=>
    { return this.state.total_page>1 && <PaginationComponent style={{ marginBottom: "unset",}}
count={this.state.total_page}
page={this.state.currentPage}
onChange={this.handleChange}
id="handlechange"
/>}
hanldeTopic=()=>{
   return this.state.getAllTopicData.length>=1&& <TypographyStyle23 style={{...webStyle.numberStyle  ,textAlign:"end"}}>{`${this.handleTotalResult()} ${this.state.total_topic} ${this.handleResult()}`}

                                </TypographyStyle23>
}
    handleTotalResult=()=>{
        if(this.state.currentPage==1){
            return `1-${this.state.getAllTopicData.length} of`
        }
        else if(this.state.currentPage>1){
            return `${(10*(this.state.currentPage-1)+1)}-${((10*(this.state.currentPage-1)+1))+(this.state.getAllTopicData.length)-1} of`
        }
        else{
            return ''
        }
            }
    handleMenuOption = (menuConfig: any) => {
        const { ref, handleListKeyDownmenu, stateName ,menuItem, closeFn, options, selectedMenu, selectedMenuItem, } = menuConfig;
        return (
          <Popper open={menuItem} anchorEl={ref.current} placement={'bottom-start'} role={undefined} disablePortal style={{ zIndex: 1111, position: "absolute",marginTop: "20px",flexDirection:"column",display:"flex",  }}>
            <OptionGrid style={{  maxHeight: "321px",  background: "#FFF" ,overflowY: "auto", borderRadius: "8px",}}>
              <ClickAwayListener onClickAway={closeFn}>
                <CustomMenu autoFocusItem={menuItem} id={`menu-list-grow${stateName}`} style={{alignItems:"flex-start",display:"flex",flexDirection:"column",}}onKeyDown={(event: any) => handleListKeyDownmenu(event, stateName)}>
                  {options.map((item: any, index: number) => {
                    return (
                      <MenuItem
                id={`menuItem${index}${stateName}`}
                        onClick={() => {
                          selectedMenu(item?.id);
                        }}
                        style={{
                          ...Allstyle.menuItemStyle,
                          fontFamily:"Inter"
                        }}
                      >
                        <span
                          style={{
                              width: "24px",
                              height: "24px",
                            marginRight: "8px",
                          }}
                        >
                            <img src={selectedMenuItem.includes(item?.id)?checkBoxTrue:checkBoxFalse} />
                        </span>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </CustomMenu>
              </ClickAwayListener>
            </OptionGrid>
          </Popper>
        );
      }
      handlePostCondition = () => {
        return (
          (!this.state.categoryTypeOptionData.includes(0) || !this.state.animalTypeOptionData.includes(0)) &&
          <POstBox style={{ marginTop: "24px", display: "flex", justifyContent: "space-between" }}>
            <PostGrid style={{ display: "flex", gap: "16px", flexWrap: 'wrap', alignItems: "center" }}>
              {!this.state.categoryTypeOptionData.includes(0) &&
                this.state.categoryTypeOptionData.map((index: any) => {
                  const search: any = this.state.getCatergoryData.find((ele: any) => ele.id === parseInt(index))
                  return (
                    <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                      <img src={Cross} style={{ cursor: "pointer" }} id="test2" onClick={() => { this.clearOption('categoryTypeOptionData', index) }} />
                      <Typography style={Allstyle.optionStyle}>
                        {search?.name}
                      </Typography>
                    </Box>
                  );
                })}
              {!this.state.animalTypeOptionData.includes(0) &&
                this.state.animalTypeOptionData.map((index: any) => {
                  const search = this.state.getAnimalData.find((ele: any) => ele.id === parseInt(index))
                  return (
                    <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                      <img src={Cross} style={{ cursor: "pointer" }} id="test1" onClick={() => { this.clearOption('animalTypeOptionData', index) }} />
                      <Typography style={Allstyle.optionStyle}>
                        {index==null ?"Other":search?.name}
                      </Typography>
                    </Box>
                  );
                })}
              {this.handleConditionOfClear() &&
                <Typography style={{...Allstyle.clearStyle,fontFamily:"Inter"}} id="test5" onClick={() => { this.clearAllOptions() }}>
                  Clear all
                </Typography>
              }
            </PostGrid>
          </POstBox>
        );
      }
      handlePaginationTopic=()=>{
         
    return    (this.state.getAllTopicData.length>10 || this.state.total_topic>0) && <Grid 
   
    container style={{marginTop:"39px",marginBottom:"120px",alignItems:"center"}}>
        <Grid xs={12} sm={12} md={12} lg={4} >

</Grid>
<GridPaginationCount xs={12} sm={6} md={6} lg={4} >
{this.handlePagination()}

</GridPaginationCount>
<GridPaginationCount2 xs={12} sm={6} md={6} lg={4} >
{this.hanldeTopic()}
</GridPaginationCount2>
        </Grid>
      }
    retunCircleSvg = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="4" height="5" viewBox="0 0 4 5" fill="none">
            <circle cx="2" cy="2.5" r="2" fill="#64748B" />
        </svg>
    }
    ariaLabelCategoryType=()=>{
        return Boolean(this.state.categorytypeMenuSelect) ? 'menu-list-growanimaltypeMenu' : undefined
    }
    ariaLabelAnimalType=()=>{
        return Boolean(this.state.animaltypeMenuSelect) ? 'menu-list-growanimaltypeMenu' : undefined
    }
    handleProfile=(item:any)=>{
          return  item.attributes?.account?.image_url ? 
            <Avatar style={{ width: "44px", height: "44px",border:"2px solid #F0E5FF" }}src={item.attributes?.account?.image_url} />
                : 
                <Avatar style={{ width: "40px", height: "40px" ,background:"white",color:"#03B5AA",border:"2px solid #CDF0EE"}} >
                {item.attributes.account.full_name[0].toUpperCase()}
                </Avatar>
                 
    }
   
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box>
                  <Loader2 loading={this.state.isLoading } />
                  <CommonSnackbar data-test-id='commonSnackBar' handleCloseFunc={()=>this.setState({createTopic:false})} open={this.state.createTopic} message={this.state.infoMessage} />
             
                
                <HomeGrid2 style={{ minHeight :(this.state.getAllTopicData.length==1)||(this.state.getAllTopicData.length==0)?'calc(100vh - 247px)':'unset',display:"block" }}>
                    <Box style={{ ...Allstyle.headingBoxStyle, alignItems: "center" }}>
                        <TypographyStyle style={{ ...Allstyle.hedingStyle, paddingLeft:"0", cursor: "pointer" }}
                            data-test-id="navigate"
                            onClick={() => {
                                this.props.navigation.navigate("LandingPageWeb")
                            }}>
                            Home
                        </TypographyStyle>

                        <img src={leftArrowIcon2} />
                        <TypographyStyle style={{ ...Allstyle.hedingStyle, cursor: "pointer" }}
                            data-test-id="navigate2"
                            onClick={() => {
                                this.props.navigation.navigate("PetootiCommunity")
                            }}>
                            Community Chat Room
                        </TypographyStyle>

                        <img src={leftArrowIcon2} />
                        <TypographyStyle2
                            style={{
                                ...Allstyle.hedingStyle,
                                color: "#0F172A",
                            }}
                        >
                            Topics
                        </TypographyStyle2>
                    </Box>
                  
{!this.handlePageEmpty() ?<Box>
    
                    <Box
                        style={{
                            ...Allstyle.headingBoxStyle,
                            justifyContent: "space-between",
                            marginTop: "24px",
                            marginBottom: "40px",
                            alignItems:"center"
                        }}
                    >
                        <Typography style={{ ...Allstyle.mainHedingStyle }}>
                            Topics
                        </Typography>
        
                        <ButtonStyle variant="contained" data-test-id='searchButton' 
                        onClick={()=>{
                            this.props.navigation.navigate("CreateNewTopic")
                        }}
                        style={{  textTransform: "inherit", borderRadius: "8px",background:"#03B5AA",color:"#FFF" }} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z" fill="white"/>
</svg>
<span style={{marginLeft:"8px",
color: "var(--Basic-White, var(--0, #FFF))",
fontFamily: "Lato",
fontSize: "16px",
fontWeight: 700,
lineHeight: "24px",
}}>

Create topic
</span>
        </ButtonStyle>

                    </Box>
                    <MainBox style={{marginBottom:"16px"}} >

                        <AutocompleteComponent
                        
                            freeSolo
                            id="free-solo"
                            data-test-id="free-solo2"
                            value={this.state.serachTopic}
                            options={[]}
                            //@ts-ignore
                            onChange={(event:any, newValue:string) => {
                                this.setState({ serachTopic: newValue });
                            }}
                            renderInput={(params) => (
                                <CustomTextField
                                    {...params}
                                    onChange={(event:any) => {
                                        this.setState({ serachTopic: event.target.value });
                                    }}
                          
                                    variant="outlined"
                                    value={this.state.serachTopic}
                                    
                                    placeholder="Search by keyword"
                                    margin="normal"
                                    data-test-id="text-input"
                                    InputProps={{
                                        ...params.InputProps, type: 'search', startAdornment: (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M15.1865 14.4713H15.9765L20.2165 18.7313C20.6265 19.1413 20.6265 19.8113 20.2165 20.2213C19.8065 20.6313 19.1365 20.6313 18.7265 20.2213L14.4765 15.9713V15.1813L14.2065 14.9013C12.8065 16.1013 10.8965 16.7213 8.86646 16.3813C6.08646 15.9113 3.86646 13.5913 3.52646 10.7913C3.00646 6.56133 6.56646 3.00133 10.7965 3.52133C13.5965 3.86133 15.9165 6.08133 16.3865 8.86133C16.7265 10.8913 16.1065 12.8013 14.9065 14.2013L15.1865 14.4713ZM5.47646 9.97133C5.47646 12.4613 7.48646 14.4713 9.97646 14.4713C12.4665 14.4713 14.4765 12.4613 14.4765 9.97133C14.4765 7.48133 12.4665 5.47133 9.97646 5.47133C7.48646 5.47133 5.47646 7.48133 5.47646 9.97133Z"
                                                    fill="#94A3B8"
                                                />
                                            </svg>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <Box style={{ display: "flex", gap: "16px" }}>
<div  style={{ position: 'relative' }}>

                            <ButtonComponent variant="contained"
                                ref={this.state.categorytypeMenuRefCommunity}
                                aria-haspopup="true"
                                data-test-id="Category"
                                aria-controls={this.ariaLabelCategoryType()}
                                style={{ ...webStyle.optionBtnStyle, background: this.handleStyleBackground(this.state.categorytypeMenuSelect), color: this.handleStyleColor(this.state.categorytypeMenuSelect) }}
                                onClick={this.handleClickCategorySelect}
                            >
                                <span style={{ marginRight: "6px" }} id="Category">Category</span>
                                {
                                    <img src={this.handleSvg(this.state.categorytypeMenuSelect)} />
                                }
                            </ButtonComponent>
                            {this.handleMenuOption({
          menuItem: this.state.categorytypeMenuSelect,
          closeFn: this.handleCloseCategory,
          options: this.state.getCatergoryData,
          selectedMenu: this.handleMenuCategory,
          selectedMenuItem: this.state.categoryTypeOptionData,
          ref: this.state.categorytypeMenuRefCommunity,
          handleListKeyDownmenu: this.handleListKeyDownmenu,
          stateName: "categorytypeMenuSelect",
        })}
</div>
<div  style={{ position: 'relative' }}>

                            <ButtonComponent variant="contained"
                                ref={this.state.animaltypeMenuRefCommunity}
                                aria-haspopup="true"
                                data-test-id="animal"

                                aria-controls={this.ariaLabelAnimalType()}
                                style={{ ...webStyle.optionBtnStyle, background: this.handleStyleBackground(this.state.animaltypeMenuSelect), color: this.handleStyleColor(this.state.animaltypeMenuSelect) }}
                                onClick={this.handleClickAnimalSelect}
                            >
                                <span style={{ marginRight: "6px" }} id="Animal">Animal Type</span>
                                {
                                    <img src={this.handleSvg(this.state.animaltypeMenuSelect)} />
                                }
                            </ButtonComponent>
                            {this.handleMenuOption({
          menuItem: this.state.animaltypeMenuSelect,
          closeFn: this.handleCloseAnimal,
          options: this.state.getAnimalData,
          selectedMenu: this.handleMenuAnimal,
          selectedMenuItem: this.state.animalTypeOptionData,
          ref: this.state.animaltypeMenuRefCommunity,
          handleListKeyDownmenu: this.handleListKeyDownmenu,
          stateName: "animaltypeMenuSelect",
        })}
                            </div>
                        </Box>
                        <Typography data-test-id="check" style={{ color: "#334155", display: "flex", gap:"8px",alignItems: "center", fontWeight: 400, fontSize: "14px", fontFamily: 'Inter', cursor: "pointer", lineHeight: "24px" }} onClick={() => {
                            this.setState({ checkedByme: !this.state.checkedByme })

                        }}>
                            <img src={this.handleCheckedMe()} style={{marginRight: "3px", width: "20px", height: "20px" }} />
                            {"Created by me"}
                        </Typography>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "end",
                                cursor: "pointer",
                            }}
                            //@ts-ignore
                            ref={this.state.sortByMenuItemRef}
                            aria-haspopup="true"
                            data-test-id="createdByme"
id="sortby"
                            onClick={this.handleClick}

                        >
                            <span
                                style={{
                                    ...webStyle.sortStyle,
                                    marginRight: "4px",
                                    fontWeight: 400,
                                }}
                            >
                                Sort by:
                            </span>
                            <Typography
                                style={{
                                    ...webStyle.sortStyle,
                                    fontWeight: 700,
                                    display: "flex",
                                    cursor: "pointer",
                                }}
                            >
                                {configJSON.sortByMenuItem[this.state.sortBymenu]?.name}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                                        fill="#334155"
                                    />
                                </svg>
                            </Typography>
                        </Box>
                        <Popper style={{zIndex:1111}}open={this.state.sortBymenuItem} anchorEl={this.state.sortByMenuItemRef.current} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: this.handlePlacement(placement) }}
                                >
                                    <PaperStyle style={{ borderRadius: "8px", background: "#FFF" }}>
                                        <ClickAwayListener onClickAway={this.handleCloseMenu}>
                                            <CustomMenu autoFocusItem={Boolean(this.state.sortBymenuItem)} id="menu-list-grow" onKeyDown={(event: any) => this.handleListKeyDownmenu(event, 'sortBymenuItem')}>
                                                {configJSON.sortByMenuItem.map((item: any, index: number) => {
                                                    return (
                                                        <MenuItem
                                                        data-test-id="sortByMenu"
                                                            onClick={() => this.handleMenu(index)}
                                                            style={{
                                                                ...Allstyle.menuItemStyle,
                                                                fontFamily:"Inter",
                                                                background:
                                                                    this.state.sortBymenu == index
                                                                        ? "linear-gradient(0deg, rgba(205, 240, 238, 0.50) 0%, rgba(205, 240, 238, 0.50) 100%), #FFF"
                                                                        : "",
                                                            }}
                                                        >
                                                            {this.state.sortBymenu == index ? (
                                                                <span
                                                                    style={{
                                                                        marginRight: "8px",
                                                                    }}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                    >
                                                                        <path
                                                                            d="M8.79457 15.8754L5.32457 12.4054C5.13774 12.2181 4.88409 12.1129 4.61957 12.1129C4.35505 12.1129 4.1014 12.2181 3.91457 12.4054C3.52457 12.7954 3.52457 13.4254 3.91457 13.8154L8.09457 17.9954C8.48457 18.3854 9.11457 18.3854 9.50457 17.9954L20.0846 7.41538C20.4746 7.02538 20.4746 6.39538 20.0846 6.00538C19.8977 5.81812 19.6441 5.71289 19.3796 5.71289C19.1151 5.71289 18.8614 5.81812 18.6746 6.00538L8.79457 15.8754Z"
                                                                            fill="#0F172A"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    style={{
                                                                        height: "24px",
                                                                        width: "24px",
                                                                        marginRight: "8px",
                                                                    }}
                                                                ></span>
                                                            )}
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </CustomMenu>
                                        </ClickAwayListener>
                                    </PaperStyle>
                                </Grow>
                            )}
                        </Popper>

                    </MainBox>
                 {this.handlePostCondition()}
                    {
                      this.state.getAllTopicData&&  this.state.getAllTopicData.length>0?  this.state.getAllTopicData.map((item:any,index:number)=>{

                  return  <Grid container style={{paddingBlock:"16px",cursor:"pointer",borderBottom:this.handleBorder(index)}}
                  onClick={()=>{
                    

                    this.props.navigation.navigate("CreateComment",{id:item?.id})
                }}
                  >
                        <Grid item xs={12} md={6} sm={6}>
                            <Box style={{ display: "flex", flexDirection: "column", gap: "6px" }}>

                                <Typography style={{
                                    fontFamily: "Inter",
                                    fontSize: "18px",
                                    fontWeight: 500,
                                    color: "#1E293B",
                                    wordBreak:"break-word",
                                    lineHeight: "150%"
                                }}>
                                 {item?.attributes?.name}
                                </Typography>
                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>

                                    <Typography style={webStyle.itemFontStyle}>
                                    {item?.attributes?.community_category?.name}
                                    </Typography >
                                    {this.retunCircleSvg()}
                                    <Typography style={{...webStyle.itemFontStyle,color:"#475569"}}>
                                    {item?.attributes?.animal_type?.name ||item?.attributes?.custom_animal_type}
                                    </Typography>
                                    {this.retunCircleSvg()}

                                    <Typography style={{...webStyle.itemFontStyle,color:"#475569"}}>
                                       {moment(item?.attributes?.created_at).format('DD MMM, YYYY')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <EndGrid item xs={12} md={6} sm={6} >
                            <Box style={{ display: "flex",borderRight:"1px solid #E2E8F0",flexDirection: "row",height:"56px", gap: "12px", width: "76px", alignItems: "center" }}>
<img src={MsgIcon} style={{width:"16px",height:"16px"}}/>
                                <Typography style={{...webStyle.itemFontStyle, width: "48px",paddingRight:"37px" }}>
                                {item?.attributes?.number_of_comments}
                                </Typography>
                            </Box>
                            <Box style={{ display: "flex",width:"180px", flexDirection: "row", gap: "12px", height:"56px",alignItems: "center",paddingLeft:"24px" }}>
{this.handleProfile(item)}
                           <NameStyle style={{...webStyle.nameStyle ,width: "96px" }}>
                         {this.handleFisrtName(item)}
                                </NameStyle>
                            </Box>
                        </EndGrid>
                    </Grid>
                        }):
                        <EmptySearchCommunity heading={"No topics meet your search and filter criteria"} image={this.state.image}
                        />
                    }
                    
                   {this.handlePaginationTopic() }
</Box>
:
<>
<Box
style={{
    ...Allstyle.headingBoxStyle,
    justifyContent: "space-between",
    marginTop: "24px",
    marginBottom: "40px",
    alignItems:"center"
}}
>
<Typography style={{ ...Allstyle.mainHedingStyle }}>
    Topics
</Typography>
</Box>
<EmptyTopic navigation={this.props.navigation?.navigate}/>
</>
}
                </HomeGrid2>
                
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const NameStyle=styled(Typography)({
    textOverflow: "ellipsis",
    overflow:"hidden",
    '@media (max-width:599px)': {
        textOverflow: "unset",
        overflow:"unset",
       },


})

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input::placeholder" :{
    color: "#94A3B8",
    opacity: 1,
  }
})
const webStyle = {
    
    card: {
        margin: 20,
        width: "100%",
        position: "relative" as "relative",
        left: 0,
    },
    numberStyle:{
        fontFamily: "Lato",
            fontSize: "12px",
            fontWeight: 400,
            color: "#64748B",
            lineHeight: "18px"
    },
nameStyle:{
    fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
        color: "#1E293B",
        lineHeight: "24px"
},
    giveaway: {
        width: "100%",
        height: "auto",
        padding: 0,
        margin: 0,
    },
    itemFontStyle: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        color: "#334155",
        lineHeight: "22px"
    },
    fixbutton: {
        bottom: 50,
        right: 50,
        position: "fixed" as "fixed",
        width: "100px",
        height: "100px",
        zIndex: 9999,
        backgroundColor: "#f6f6f6",
    },
    optionBtnStyle: {
        background: "#FFF",
        border: "1px solid #CBD5E1",
        padding: "4px 8px 4px 16px",
        height: "44px",
        borderRadius: "8px",
        textTransform: "inherit" as "inherit",
        color: "#334155",
        fontFamily: "Lato" as "Lato",
        fontSize: "16px",
        fontWeight: 400,
    },
    sortStyle: {
        color: "#334155",
        fontFamily: "Inter",
        fontSize: "14px",
        lineHeight: "22px",
    },
    popup:{
        display: "flex",
        gap:"8px",
        alignItems: "center",
        backgroundColor: "#ffffff",
        color: "#0F172A",
        borderRadius: "8px",
       textAlign:"center",
       padding:"9px 16px 9px 8px ",
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)"
    },

    tag: {
        padding: 10,
    },
};
 const ButtonStyle = styled(Button)({
    minWidth: "156px",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFF",
    height: "44px",
    textTransform: "inherit",
    backgroundColor: "#03B5AA",
    "&.MuiButton-contained": {
      boxShadow: "unset"
  },
    "&.MuiButton-contained:hover": {
      boxShadow: "unset",
      backgroundColor: "#03B5AA",
    }
  })
const EndGrid=styled(Grid)({
    display: "flex", 
    flexDirection: "row",
     justifyContent: "flex-end",
    '@media (max-width:600px)': {
     justifyContent:"flex-start",
     paddingTop:"30px"
    },

})

const AutocompleteComponent = styled(Autocomplete)({
    width: "374px",
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: "0px 0px 0px 8px"
    },
    "& .MuiFormControl-marginNormal": {
        marginTop: "unset",
        marginBottom: "unset",
    },
    "& .MuiOutlinedInput-root": {
        height: "44px",

        "&:hover fieldset": {
            borderColor: "#CBD5E1",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#CBD5E1",
        },
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px"
    },
    "& .MuiFormControl-root": {
        display: "inline",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderRadius: "8px"
    },
    
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: "10px 8px"
    },
   
    "& .MuiInputBase-root": {
        color: "#334155"
    },
   

    '@media (max-width:709px)': {
        width: "100%"

    },

})
const MainBox = styled(Box)({
    alignItems: "center",
    paddingBottom: "24px",
    borderBottom: "1px solid #CBD5E1",
    justifyContent: "space-between",
    display: "flex",

    '@media (max-width:1210px)': {
        flexWrap: "wrap",
        gap: "24px",
        justifyContent: "center",
        alignItems: "center"

    },
})

// Customizable Area End
