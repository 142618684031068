import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
    // Customizable Area Start
    searchKeyWord:string,
    faqData:Array<any>,
    isLoading:boolean,
    expanded:string,
     // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
  }

  export default class InteractiveFaqController extends BlockComponent<
  Props,
  S,
  SS
> {
// Customizable Area Start
getFaqsApiCallId:string = ""
// Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];
this.state ={
  searchKeyWord:"",
  faqData:[],
  isLoading:false,
  expanded:"panel0"
}
runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      this.handleRestApiresponse(responseJson, errorReponse, apiRequestCallId)
    }

    // Customizable Area End

  }

  handleRestApiresponse =(responseJson: any, errorReponse: any, apiRequestCallId: string)=>{
    if (apiRequestCallId === this.getFaqsApiCallId) {
    this.handleFaqResponseData(responseJson)
    }
    }
    // Customizable Area Start

  async componentDidMount() {
  
    super.componentDidMount();
    this.getAllCategoriesData()
    
    
}
componentDidUpdate(prevProps: any, prevState: any) {
  if(this.state.searchKeyWord!==prevState.searchKeyWord){
    this.getAllCategoriesData()
  }

  }

handleFaqResponseData = (response:any)=>{
    this.setState({
      isLoading:false
    })
  if(response && response.data.faqs){
    this.setState({
      faqData:response.data.faqs.reverse()
    })
    
  }
}

getAllCategoriesData = async () => {
         this.setState({
          isLoading:true
         })
  const header = {
    "Content-Type": "application/json",
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getFaqsApiCallId = requestMessage.messageId
  let endPoint = "/bx_block_interactive_faqs/interactive_faqs"
  if(this.state.searchKeyWord){
    endPoint = `${endPoint}?search=${this.state.searchKeyWord}`
  }

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );



  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
   "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};
handlerSearch =(e:any)=>{
  this.setState({
    searchKeyWord:e.target.value
  })
  if(!e.target.value){
    this.setState({
      searchKeyWord:""
    },()=>{
      this.getAllCategoriesData()
    })
  }
}

    // Customizable Area End

}
