import React from "react";

// Customizable Area Start
import { Box, Button, Snackbar, Typography, styled ,Backdrop} from "@material-ui/core";
import { PetImage } from "./assets";
import OtpInput from "react-otp-input";
import EmailOtpVerifyController, { Props } from "./EmailOtpLoginController.web";
import { MainGrid ,SecondGrid,MainBox} from "./UserRole.web";
// Customizable Area End

class EmailAccountOtpVerify extends EmailOtpVerifyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleStyle=()=>{
    const resetImage=this.state.resetImage!==""?this.state.resetImage:PetImage
    const otpImage=this.state.otpVerifyImage!==""?this.state.otpVerifyImage:PetImage
    const image=this.state.heading=="Sign up to PETOOTI"?otpImage:resetImage
    if(image!==""){
      return {
        minHeight: 'calc(100vh - 247px)',
        maxHeight: 'calc(100vh - 247px)',
        width:"100%",
        borderRadius:"0px 0px 0px 45px",
        objectFit: 'cover' as 'cover',
        objectPosition: 'top' as 'top'
      }
    }
    else{
      return { width:"335px" }
    }
  }
  handleImage=()=>{
    const otpImage=this.state.otpVerifyImage!==""?this.state.otpVerifyImage:PetImage
    const resetImage=this.state.resetImage!==""?this.state.resetImage:PetImage
   return  this.state.heading=="Sign up to PETOOTI"?otpImage:resetImage
  }
  handleReturnSnackBar = () => {

    return <Backdrop
      style={{ zIndex: 700 }}
      open={this.state.isSnackbarOpen}

    ><Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={this.state.isSnackbarOpen}
      style={{ zIndex: 1000 }}
      autoHideDuration={5000}
      data-test-id='snackbar2'
      onClose={this.closeSnackbar}
    >
        <Typography style={AllStyle.popup2 as React.CSSProperties}>
          <svg xmlns="http://www.w3.org/2000/svg" style={{ minHeight: '26', minWidth: '26' }} width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
          </svg>
          {this.state.messageSnackbar}
        </Typography>
      </Snackbar>
    </Backdrop>
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const style=this.state.inputs.length==5 ?
    "1px solid #0A2239"
    :"1px solid #D8DADC"
    
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
        {this.handleReturnSnackBar()}
        
        <MainGrid container>
          <SecondGrid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent:"center" }}
          >
            <MainBox
            >
              <Typography style={AllStyle.heading}>
                {this.state.heading}
              </Typography>
              <SecondBox >
                <TextStyle >
                  We sent you an email with the OTP code to your email {this.state.email}
                </TextStyle>
               
                <SecondBox2
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <OtpInput
                   value={this.state.inputs}
                   inputType="tel"
                   data-test-id="otpinput"
                   onChange={(code)=>{
                     this.setState({inputs:code})
                  if(code.length<5){
                    this.setState({OtpError:true})
                  }
                  else{
                    this.setState({OtpError:false})
                  }
                   }}

                   renderInput={(props) => <input {...props} />}
                   numInputs={5}
                   renderSeparator={<span style={{ width: this.handleGap()}}></span>}
                   containerStyle={{ outline: "none" }}
            
                   shouldAutoFocus={true}
                   inputStyle={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "10px",
                    marginBottom: 10,
                    backgroundColor: "white",
                    color: "#1E293B",
                    textAlign: "center",
                    fontWeight: 600,
                    fontSize:"18px",
                    fontFamily:"Lato",
                  lineHeight:"26px",


                     border:this.state.OtpError?
                     "1px solid #DC2626"
                     :style ,
                  
                     caretColor: "pink",
                   }}
          
                 
                  />
             
                </SecondBox2>
                <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "15px" }}>

                
                   <Typography style={{
                    color: "#DC2626",
                    fontSize: "12px",
                    fontFamily: "Lato",
                    fontWeight: 400,
                    lineHeight: "18px",
                    marginBottom:"15px"
                  }}> {this.state.OtpError  && "Please enter a valid code"}
                  </Typography>
                  <Typography style={{ color: "#03B5AA",lineHeight:"22px", fontFamily:"Lato",fontSize: "14px", fontWeight: 700}}>
                    {`${this.formatTime(this.state.time.minutes)}:${this.formatTime(this.state.time.seconds)}`}
                  </Typography>
                </Box>
              {this.state.time.minutes === 0 && this.state.time.seconds === 0&&  <Typography style={{ fontSize: "16px",lineHeight:"19.2px", fontFamily:"Lato",fontWeight: 400, color: "#94A3B8", textAlign: "center", marginBottom: "30px", marginTop: "10px" }}>
                  Didn't receive code?
                  <span style={{ ...AllStyle.boldStyle, cursor: "pointer" }} onClick={() => this.handleOtpFunction()} data-test-id="resettmerTest">
                    {" "}Resend
                  </span>
                </Typography>}
                <BUttonStyle variant="contained" style={AllStyle.btnStyle} data-test-id="submitId"  onClick={() => {
                 this. handleNavigation()
              
                }}>
                  Submit
                </BUttonStyle>
                <Typography style={{
                  fontFamily: "Lato",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:"19.2px",
                  color: "#0A2239"
                }}>
                  Already have an account?{" "}
                  <span style={{ ...AllStyle.boldStyle, cursor: "pointer" }} onClick={() => { this.handleNavigateLogin() }}>
                    Log in
                  </span>
                </Typography>
              </SecondBox>
            </MainBox>
          </SecondGrid>
         
        </MainGrid>
        
      </Box>
      // Customizable Area End
    );
  }
}
export default EmailAccountOtpVerify;

// Customizable Area Start
const TextStyle = styled(Typography)({
  marginBottom: "30px",
  fontWeight: 500,
  fontSize: "16px",
 color: "#0A2239",
  fontFamily:"Lato",
  lineHeight:"24px",
  "@media(max-width: 480px)": {
    textAlign: "center"
  },
})
const BUttonStyle = styled(Button)({
  minWidth: "360px",
  "@media (max-width:380px)": {
    minWidth: "200px",
  },

})
const InputField = styled("input")(({ borderColor }: any) => ({
  border: `1px solid  ${borderColor}`,
  width: "50px",
  height: "50px",
  borderRadius: "10px",
  marginBottom: 10,
  backgroundColor: "white",
  color: "#1E293B",
  textAlign: "center",
  fontSize: "18px !important",
  fontWeight: 600,
  display: "flex",
  fontFamily:"Lato",
lineHeight:"26px",
"&:focus": {
  border: "1px solid #0A2239!important",
},
"&:focus-visible": {
  outline: "none!important",
},
  "@media(max-width: 480px)": {
    display: "flex",
    width: "45px",
    height: "45px",
    fontSize: "16px !important",
    paddingBottom: 0,
  },
}));



const SecondBox2 = styled(Box)({
  gap: "27.5px",
  "@media (max-width:400px)": {
    gap: "3%",
  },
});



const SecondBox = styled(Box)({
    maxWidth:"360px",
  flexDirection: "column" as "column",
  display: "flex",
  flexWrap: "wrap" as "wrap",
  marginBlock: "30px",
  "@media (max-width:390px)":{
    paddingInline:"10px"
      },
  "@media (max-width:320px)": {
    justifyContent: "center",
  },
  
});
const AllStyle = {
  popup2: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    color: "#0F172A",
    lineHeight: "24px",
    padding:"9px 16px 9px 8px ",
    textAlign:"center",
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
  
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
 
},
  boldStyle: { color: "#03B5AA",lineHeight:"24px", fontFamily:"Lato",fontSize: "16px", fontWeight: 700 },
  btnStyle: {
    borderRadius: "8px",
    height: "56px",
    background: "#00bdab",
    textTransform: "inherit" as "inherit",
    color: "#FFFFFF",
    fontSize: "16px",
  fontFamily:"Lato",
    fontWeight: 700,
    marginBottom: "30px",
    lineHeight:"24px"
  },

  heading: {
    textAlign: "center" as "center",
    fontWeight: 700,
  fontFamily:"Lato",
lineHeight:"32px",
    fontSize: "24px",
    letterSpcaing:"-0.12px",
    color: "#0A2239",
  },
  smallHeading: {
    marginBottom: "30px",
    fontWeight: 500,
    fontSize: "16px",
    color: "#0A2239",
  fontFamily:"Lato",
  lineHeight:"24px",

  },
  popup: {
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    backgroundColor: "#ffffff",
    color: "#0F172A",
    borderRadius: "4px",
    width: "362px",
    height: "42px",
    fontWeight: 400,
  fontFamily:"Lato",
    fontSize: "16px",
    lineHeight: "24px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
};

// Customizable Area End
