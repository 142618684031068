import React from "react";
// Customizable Area Start
import { Box, Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper, Snackbar, TextField, Tooltip, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CommonSnackbar from '../../../components/src/CommonSnackbar.web'
import { Cross } from "../../catalogue/src/assets";
import Pagination from '@material-ui/lab/Pagination';
import { checkBoxFalse, checkBoxTrue } from "../../email-account-registration/src/assets";
import { BottonComponentCom } from "../../productdescription/src/CreateRescuerPostpage.web"
import {DiologComponent2} from '../../productdescription/src/ProductDescription.web'
import Loader from "../../../components/src/Loader.web";

import CataloguePageController, { Props, configJSON } from "./CataloguePageController.web";
import EmptyPost, { EmptySerach } from "../../../components/src/EmptyPost.web";


const baseUrl=require("../../../framework/src/config.js")
// Customizable Area End

export default class CataloguePage extends CataloguePageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleAgeFormat = (years:number,months:number) => {
   
  
    if (years === 0 && months === 0) {
      return `Less than a month`;
    }
  
    let result = '';
  
    if (years > 0) {
      result += `${years} ${years === 1 ? 'Year' : 'Years'}`;
    }
  
    if (months > 0) {
      if (result.length > 0) {
        result += ' ';
      }
  
      result += `${months} ${months === 1 ? 'Month' : 'Months'}`;
    }
  
    return result;
  };

  handleButton = () => {
    return <>
      <div style={{ position: 'relative' }}>
        <ButtonComponent variant="contained"
          ref={this.state.animaltypeMenuRef}
          aria-haspopup="true"
          aria-controls={Boolean(this.state.animaltypeMenu) ? 'menu-list-growanimaltypeMenu' : undefined}
          style={{ ...Allstyle.optionBtnStyle, background: this.handleStyleBackground(this.state.animaltypeMenu), color: this.handleStyleColor(this.state.animaltypeMenu) }}
          onClick={this.handleClickAnimal}
        >
          <span   id="Animal" style={{ marginRight: "6px" }}>Animal Type</span>
          {
            <img src={this.handleSvg(this.state.animaltypeMenu)} />
          }
        </ButtonComponent>
        {this.handleMenuOption({
          menuItem: this.state.animaltypeMenu,
          closeFn: this.handleCloseAnimal,
          options: this.state.animalOptionData,
          selectedMenu: this.handleMenuAnimal,
          selectedMenuItem: this.state.animalTypeOption,
          ref: this.state.animaltypeMenuRef,
          handleListKeyDownmenu: this.handleListKeyDownmenu,
          stateName: "animaltypeMenu",
        })}
      </div>
      <div style={{ position: 'relative' }}>
        <ButtonComponent
          variant="contained"
          ref={this.state.breedMenuRef}
          aria-haspopup="true"
          aria-controls={Boolean(this.state.breedMenuRef) ? 'menu-list-grow' : undefined}
          style={{ ...Allstyle.optionBtnStyle, background: this.handleStyleBackground(this.state.breedMenu), color: this.handleStyleColor(this.state.breedMenu) }}
          onClick={this.handleClickBreed}

        >
          <span  id="Breed" style={{ marginRight: "6px" }}>Breed</span>
          {
            <img src={this.handleSvg(this.state.breedMenu)} />
          }
        </ButtonComponent>
        {this.handleMenuOption({
          menuItem: this.state.breedMenu,
          closeFn: this.handleCloseBreed,
          options: this.state.breedOptionData,
          selectedMenu: this.handleMenuBreed,
          selectedMenuItem: this.state.breedMenuOption,
          ref: this.state.breedMenuRef,
          handleListKeyDownmenu: this.handleListKeyDownmenu,
          stateName: "breedMenu",
        })}
      </div>
      <div style={{ position: 'relative' }}>
        <ButtonComponent
          variant="contained"
          ref={this.state.genderMenuRef}
          aria-haspopup="true"
          aria-controls={Boolean(this.state.genderMenuRef) ? 'menu-list-grow' : undefined}
          style={{ ...Allstyle.optionBtnStyle, background: this.handleStyleBackground(this.state.genderMenu), color: this.handleStyleColor(this.state.genderMenu) }}
          onClick={this.handleClickGender}

        >
          <span id="Gender" style={{ marginRight: "6px" }}>Gender</span>
          {
            <img src={this.handleSvg(this.state.genderMenu)} />
          }
        </ButtonComponent>
        {this.handleMenuOption({
          menuItem: this.state.genderMenu,
          closeFn: this.handleCloseGender,
          options: configJSON.gender,
          selectedMenu: this.handleMenuGender,
          selectedMenuItem: this.state.genderMenuOption,
          ref: this.state.genderMenuRef,
          handleListKeyDownmenu: this.handleListKeyDownmenu,
          stateName: "genderMenu",
        })}
      </div>
      <div style={{ position: 'relative' }}>
        <ButtonComponent
          ref={this.state.postStatusMenuRef}
          aria-haspopup="true"
          aria-controls={Boolean(this.state.postStatusMenuRef) ? 'menu-list-grow' : undefined}
          variant="contained"
          style={{ ...Allstyle.optionBtnStyle, background: this.handleStyleBackground(this.state.postStatusMenu), color: this.handleStyleColor(this.state.postStatusMenu) }}
          onClick={this.handleClickPost}
          data-test-id='postStatusMenuButton'
        >
          <span style={{ marginRight: "6px" }} id="Status"> Post Status</span>
          {
            <img src={this.handleSvg(this.state.postStatusMenu)} />
          }
        </ButtonComponent>
        {this.handleMenuOption({
          menuItem: this.state.postStatusMenu,
          closeFn: this.handleCloseStatus,
          options: configJSON.postStatus,
          selectedMenu: this.handleMenuPost,
          selectedMenuItem: this.state.postStatusOption,
          ref: this.state.postStatusMenuRef,
          handleListKeyDownmenu: this.handleListKeyDownmenu,
          stateName: "postStatusMenu",
        })}
      </div>
    </>
  }
  handleMenuOption = (menuConfig: any) => {
    const { menuItem, closeFn, options, selectedMenu, selectedMenuItem, ref, handleListKeyDownmenu, stateName } = menuConfig;
    return (
      <Popper data-test-id='menuPopper' open={menuItem} anchorEl={ref.current} placement={'bottom-start'} role={undefined} disablePortal style={{ marginTop: "20px", zIndex: 1111, position: "absolute" }}>
        <OptionGrid style={{ minWidth: "240px", maxHeight: "321px", overflowY: "auto", borderRadius: "8px", background: "#FFF" }}>
          <ClickAwayListener onClickAway={closeFn}>
            <CustomMenu data-test-id='CustomMenuChild' autoFocusItem={menuItem} id={`menu-list-grow${stateName}`} onKeyDown={(event: any) => handleListKeyDownmenu(event, stateName)}>
              {options.map((item: any, index: number) => {
                return (
                  <MenuItem
                    onClick={() => {
                      selectedMenu(item?.id);
                    }}
                    style={{
                      ...Allstyle.menuItemStyle,
                    }}
                    data-test-id={`selectedMenu1${stateName}`}
                  >
                    <span
                      style={{
                        marginRight: "8px",
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      {selectedMenuItem.includes(item?.id) ? (
                        <img src={checkBoxTrue} />
                      ) : (
                        <img src={checkBoxFalse} />
                      )}
                    </span>
                    {this.handleTextStatusFunc(item.name)}
                  </MenuItem>
                );
              })}
            </CustomMenu>
          </ClickAwayListener>
        </OptionGrid>
      </Popper>
    );
  }

  handleStyleStatus = (status: string) => {
    const statusLowerCase = status.toLowerCase();

    switch (statusLowerCase) {
      case "adopted":
        return { color: "#074F57", background: "#D1FAE5" };
      case "fostered":
        return { color: "#8833FF", background: "#F0E5FF" };
      case "published":
        return { color: "#059669", background: "#D1FAE5" };
      case "pending":
        return { color: "#D97706", background: "#FEF3C7" };
      case "rejected":
        return { color: "#800000", background: "#FDD" };
      case "disabled":
        return { color: "#555555", background: "#D3D3D3" };
      case "archived":
        return { color: "#2e6ea7", background: "#93ccea" };

    }
  };

  handlePost = () => {
    return !this.handlePostEmpty() ? <MainGRID container style={{ justifyContent:"center",marginBottom: this.state.total_page > 1 ? "24px" : "110px" }}>
      {this.state.allCatalogueData.length > 0 && this.state.allCatalogueData.map((item) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={Allstyle.subImgStyle}
            onClick={()=>{
              this.props.navigation.navigate("ProductDescription",{id:item?.id,postDetails:true})
            }}
          >
            <img
              src={`${baseUrl.baseURL}${item?.attributes?.image?.url}`}
              style={{
                width: "274px",
                height: "230px",
                objectFit: "cover",
                borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px",
              }}
            />
            <Box style={{ paddingInline: "16px", paddingTop: "12px" }}>
              <Box
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                 <Typography style={{...Allstyle.nameSTyle, textOverflow:"ellipsis", whiteSpace: "nowrap", overflow:"hidden"}}>{item.attributes.name}</Typography>
                <Typography style={{ ...Allstyle.statusSTyle, ...this.handleStyleStatus(item?.attributes?.status) }}>
                  {this.handleTextStatusFunc(item?.attributes?.status)?.toUpperCase()}
                </Typography>

              </Box>
              <Typography
                style={{
                  ...Allstyle.hedingStyle,
                  color: "#64748B",
                  fontFamily: "Inter",
                  marginBottom: "12px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                {item?.attributes?.category?.name}, {item?.attributes?.sub_category?.name}
              </Typography>
              <Box
                style={{
                  display: "flex",
                  gap: "16px",
                  marginBottom: "16px",
                }}
              >
                <Typography style={{ ...Allstyle.styleText }}>
                  {item?.attributes?.gender}
                </Typography>
                |
                <Typography style={{ ...Allstyle.styleText }}>
                {this.handleAgeFormat(item?.attributes?.formatted_age?.years,item?.attributes?.formatted_age?.months)}
</Typography>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </MainGRID> :
      <EmptySerach image={this.state.emptySearchImage} heading=" No posts meet your search and filter criteria"/>
  }
  handlegrid = () => {
    return <HomeGrid >
      <Box style={{ ...Allstyle.headingBoxStyle,alignItems:"center" }}>
        <Typography style={{ ...Allstyle.hedingStyle, paddingInline: "6px", cursor: "pointer" }} onClick={() => {
          this.props.navigation.navigate("LandingPageWeb")
        }}>
          Home
        </Typography>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
            fill="#64748B"
          />
        </svg>

        <Typography
          style={{
            ...Allstyle.hedingStyle,
            color: "#0F172A",
            paddingInline: "6px",
          }}
        >
          My Posts
        </Typography>
      </Box>
      <Box
        style={{
          ...Allstyle.headingBoxStyle,
          justifyContent: "space-between",
          marginTop: "24px",
          marginBottom: "32px",
        }}
      >
        <Typography style={{ ...Allstyle.mainHedingStyle }}>
          My Posts
        </Typography>
       { this.state.userType=="rescuer" &&<BottonComponentCom 
       data-test-id="postDetails"
       variant="contained" style={{ ...Allstyle.btnStyle }} onClick={() => {
          this.props.navigation.navigate("CreateNewPostpage",{postDetails:true})
        }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
              fill="white"
            />
          </svg>
          Add new post
        </BottonComponentCom>}
      </Box>
      <AutocompleteComponent2
        freeSolo
        id="free-solo2"
        data-test-id="free-solo2"
        options={[]}
        value={this.state.searchField}
                            onChange={(event:any) => {
                              this.setState({ searchField: event.target.value,currentPage:1  });
                          }}

        renderInput={(params) => (
          <TextField
          {...params}
          value={this.state.searchField}
  
            onChange={(e: any) => {
                  
            this.setState({ searchField: e.target.value ,currentPage:1})
          }}
            margin="normal"
            placeholder="Search by keyword"
            variant="outlined"
            InputProps={{
              ...params.InputProps, type: 'search', startAdornment: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.1865 14.4713H15.9765L20.2165 18.7313C20.6265 19.1413 20.6265 19.8113 20.2165 20.2213C19.8065 20.6313 19.1365 20.6313 18.7265 20.2213L14.4765 15.9713V15.1813L14.2065 14.9013C12.8065 16.1013 10.8965 16.7213 8.86646 16.3813C6.08646 15.9113 3.86646 13.5913 3.52646 10.7913C3.00646 6.56133 6.56646 3.00133 10.7965 3.52133C13.5965 3.86133 15.9165 6.08133 16.3865 8.86133C16.7265 10.8913 16.1065 12.8013 14.9065 14.2013L15.1865 14.4713ZM5.47646 9.97133C5.47646 12.4613 7.48646 14.4713 9.97646 14.4713C12.4665 14.4713 14.4765 12.4613 14.4765 9.97133C14.4765 7.48133 12.4665 5.47133 9.97646 5.47133C7.48646 5.47133 5.47646 7.48133 5.47646 9.97133Z"
                    fill="#94A3B8"
                  />
                </svg>
              ),
            }}
          />
        )}
      />
      <MainBox
        style={{
          ...Allstyle.headingBoxStyle,

        }}
      >
        <Box>
          <AutocompleteComponent
  onChange={(event:any) => {
    this.setState({ searchField: event.target.value,currentPage:1  });
}}
options={[]}
id="free-solo"
            freeSolo
            data-test-id="free-solo"
            value={this.state.searchField}
          
            renderInput={(params) => (
              <TextField
              {...params}
             
              variant="outlined"
              placeholder="Search by keyword"
              margin="normal"
                InputProps={{
                  ...params.InputProps, type: 'search', startAdornment: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.1865 14.4713H15.9765L20.2165 18.7313C20.6265 19.1413 20.6265 19.8113 20.2165 20.2213C19.8065 20.6313 19.1365 20.6313 18.7265 20.2213L14.4765 15.9713V15.1813L14.2065 14.9013C12.8065 16.1013 10.8965 16.7213 8.86646 16.3813C6.08646 15.9113 3.86646 13.5913 3.52646 10.7913C3.00646 6.56133 6.56646 3.00133 10.7965 3.52133C13.5965 3.86133 15.9165 6.08133 16.3865 8.86133C16.7265 10.8913 16.1065 12.8013 14.9065 14.2013L15.1865 14.4713ZM5.47646 9.97133C5.47646 12.4613 7.48646 14.4713 9.97646 14.4713C12.4665 14.4713 14.4765 12.4613 14.4765 9.97133C14.4765 7.48133 12.4665 5.47133 9.97646 5.47133C7.48646 5.47133 5.47646 7.48133 5.47646 9.97133Z"
                        fill="#94A3B8"
                      />
                    </svg>
                  ),
                }}
              />
            )}
          />

        </Box>
        <OptionStyleComponent

        >
          {this.handleButton()}


        </OptionStyleComponent>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            cursor: "pointer",
          }}
          //@ts-ignore
          ref={this.state.menuItemRf}
          aria-haspopup="true"
          onClick={this.handleClick}

        >
          <span
            style={{
              ...Allstyle.sortStyle,
              marginRight: "4px",
              fontWeight: 400,
            }}
          >
            Sort by:
          </span>
          <Typography
            style={{
              ...Allstyle.sortStyle,
              fontWeight: 700,
              display: "flex",
              cursor: "pointer",
            }}
          >
            {configJSON.menuItem[this.state.menu].name}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                fill="#334155"
              />
            </svg>
          </Typography>
        </Box>
        <Popper open={this.state.menuItem} anchorEl={this.state.menuItemRf.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper style={{ width: "240px", borderRadius: "8px", background: "#FFF" }}>
                <ClickAwayListener onClickAway={this.handleCloseMenu}>
                  <CustomMenu autoFocusItem={Boolean(this.state.menuItem)} id="menu-list-grow" onKeyDown={(event: any) => this.handleListKeyDownmenu(event, 'menuItem')}>
                    {configJSON.menuItem.map((item: any, index: number) => {
                      return (
                        <MenuItem
                          onClick={() => this.handleMenu(index)}
                          style={{
                            ...Allstyle.menuItemStyle,
                            background:this.hanldeBackground(index)
                            ,
                          }}
                        >
                          {this.state.menu == index ? (
                            <span
                              style={{
                                marginRight: "8px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M8.79457 15.8754L5.32457 12.4054C5.13774 12.2181 4.88409 12.1129 4.61957 12.1129C4.35505 12.1129 4.1014 12.2181 3.91457 12.4054C3.52457 12.7954 3.52457 13.4254 3.91457 13.8154L8.09457 17.9954C8.48457 18.3854 9.11457 18.3854 9.50457 17.9954L20.0846 7.41538C20.4746 7.02538 20.4746 6.39538 20.0846 6.00538C19.8977 5.81812 19.6441 5.71289 19.3796 5.71289C19.1151 5.71289 18.8614 5.81812 18.6746 6.00538L8.79457 15.8754Z"
                                  fill="#0F172A"
                                />
                              </svg>
                            </span>
                          ) : (
                            <span
                              style={{
                                marginRight: "8px",
                                width: "24px",
                                height: "24px",
                              }}
                            ></span>
                          )}
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </CustomMenu>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

      </MainBox>


      <POstBox style={{ marginTop: "24px", marginBottom: "48px", display: "flex", justifyContent: "space-between" }}>
        <PostGrid style={{ display: "flex", gap: "16px", flexWrap: 'wrap', alignItems: "center" }}>
          {this.state.animalTypeOption && this.state.animalTypeOption.length > 0 && !this.state.animalTypeOption.includes(0) &&
            this.state.animalTypeOption.map((index: any) => {
              const search = this.state.animalOptionData.find((ele: any) => ele.id === parseInt(index))
              return <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                <img src={Cross} style={{ cursor: "pointer" }} data-test-id="test1" onClick={() => { this.clearOption('animalTypeOption', index) }} />
                <Typography style={Allstyle.optionStyle}>
                  {search?.name}
                </Typography>
              </Box>
            })
          }
          {this.state.breedMenuOption && this.state.breedMenuOption.length > 0 && !this.state.breedMenuOption.includes(0) &&
            this.state.breedMenuOption.map((index: any) => {
              const search: any = this.state.breedTypeofData.find((ele: any) => ele.id === parseInt(index))

              return <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                <img src={Cross} style={{ cursor: "pointer" }} data-test-id="test2" onClick={() => { this.clearOption('breedMenuOption', index) }} />
                <Typography style={Allstyle.optionStyle}>
                  {search?.name}
                </Typography>
              </Box>
            })
          }
          {this.state.genderMenuOption && this.state.genderMenuOption.length > 0 && !this.state.genderMenuOption.includes(0) &&
            this.state.genderMenuOption.map((index: any) => (
              <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                <img src={Cross} style={{ cursor: "pointer" }} data-test-id="test3" onClick={() => { this.clearOption('genderMenuOption', index) }} />
                <Typography style={Allstyle.optionStyle}>
                  {configJSON?.gender[index]?.name}
                </Typography>
              </Box>
            ))
          }

          {this.state.postStatusOption && this.state.postStatusOption.length > 0 && !this.state.postStatusOption.includes(0) &&
            this.state.postStatusOption.map((index: any) => (
              <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                <img src={Cross} style={{ cursor: "pointer" }} data-test-id="test4" onClick={() => { this.clearOption('postStatusOption', index) }} />
                <Typography style={Allstyle.optionStyle}>
                  {this.handleTextStatusFunc(configJSON?.postStatus[index]?.name)}
                </Typography>
              </Box>
            ))
          }

          {this.handleConditionOfClear() &&
            <Typography style={Allstyle.clearStyle} data-test-id="test5" onClick={() => { this.clearAllOptions() }}>
              Clear all
            </Typography>
          }


        </PostGrid>

        <Typography style={{ ...Allstyle.hedingStyle, color: "#64748B" }}>{this.state.totalNumberOfPage} {this.state.totalNumberOfPage==1?"Post":"Posts"}</Typography>
      </POstBox>
      {this.handlePost()}
      {this.state.total_page > 1 && <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBlock: "15px", }}>

        <PaginationComponent count={this.state.total_page}
         page={this.state.currentPage} 
          onChange={this.handleChange}

        />
      </Box>}
      <Box>
      </Box>
    </HomeGrid>
  }
  // Customizable Area End

  render() {
  
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        
        <Loader loading={this.state.isLoading } />
      <CommonSnackbar data-test-id='commonSnackBar1' handleCloseFunc={()=>this.setState({post_toast:false})} open={this.state.post_toast} message={this.state.post_toast_msg} />
        
        {this.state.already_answer_given && <DiologComponent2  data-test-id='DiologComponent2'
        success={this.state.already_answer_given } handleClose={this.handleAlready_answer_given} 
        firstHeading={"Answer Previously Received"} 
        secondHeading={'You have already replied to this request and your answer has been recorded.'
     } 
     secondbuttonname={"OKAY"} 
     mainFunction={this.handleAlready_answer_given}
     />}
        <CommonSnackbar data-test-id='commonSnackBar' handleCloseFunc={()=>this.setState({createPost:false})} open={this.state.createPost} message={this.state.infoMessage} />

        {!this.handlePageEmpty() ?
          this.handlegrid()
          :
          <EmptyPost navigation={this.props.navigation.navigate} image={this.state.emptyImage} />}
        
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
export const ButtonComponent = styled(Button)({
  '&.MuiButton-contained': {
    boxShadow: "unset"
  }
})
const MainBox = styled(Box)({
  alignItems: "center",
  paddingBottom: "24px",
  borderBottom: "1px solid #CBD5E1",
  justifyContent: "space-between",
  display: "flex",

  '@media (max-width:440px)': {
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "center",
    alignItems: "center"

  },
})

export const PostGrid = styled(Box)({
  maxWidth: "900px",
  '@media (max-width:960px)': {
    maxWidth: "600px",
  },

  '@media (max-width:700px)': {
    maxWidth: "350px",
  },
  '@media (max-width:400px)': {
    maxWidth: "250px",

  },
})

export const POstBox = styled(Box)({
  '@media (max-width:600px)': {
    flexWrap: "wrap"
  },
})
export const MainGRID = styled(Grid)({
  gap: "24px",
  '@media (max-width:1400px)': {
    justifyContent: "center"
  },
})

const ToolTipBox = styled(Box)({
  fontSize:"18px",
  lineHeight:"16px",
  backgroundBlendMode:"mix"
})
export const OptionStyleComponent = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  marginInline: "24px",

  '@media(max-width: 1424px)': {
    marginLeft: "0",
    marginRight: "auto"
  },
  '@media(max-width: 776px)': {
    marginBottom: "10px"
  },

  '@media(max-width: 570px)': {
    flexWrap: "wrap",

  },
})


export const PaginationComponent = styled(Pagination)({
  marginBottom: "120px",
  '& .MuiPaginationItem-page.Mui-selected': {
    color: "#03B5AA",
    background: "unset",
    fontWeight: 700

  },
 
  '& .MuiPaginationItem-root': {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400
  },
  '@media(max-width: 1200px)': {
    marginBottom: "60px",
  },
  '@media(max-width: 800px)': {
    marginBottom: "40px",
  },
  '@media(max-width: 600px)': {
    marginBottom: "20px",
  },
})
export const CustomMenu = styled(MenuList)({
  minWidth: "200px",
  borderRadius: "8px",
  paddingTop: "unset",
  paddingBottom: "unset",
  '@media(max-width: 470px)': {
    minWidth: "unset",

  },
})
export const SnackBarStyle = styled(Snackbar)({
"&.MuiSnackbar-anchorOriginTopCenter":{
  top:"113px"
}
})



export const AutocompleteComponent = styled(Autocomplete)({
  width: "374px",
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: "0px 0px 0px 8px"
  },
  "& .MuiFormControl-marginNormal": {
    marginTop: "unset",
    marginBottom: "unset",
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
    padding: "10px 8px"
  },
  "& .MuiOutlinedInput-root": {
    height: "44px",

    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#CBD5E1",
    },
  },
  "& .MuiInputBase-root": {
    color: "#334155"
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  "& .MuiFormControl-root": {
    display: "inline",
  },
  '@media (max-width:1424px)': {
    display: "none"
  },
})
export const OptionGrid=styled(Paper)({
  minWidth: "240px",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 5px #E2E8F0",
    borderRadius: "6px"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#94A3B8",
    borderRadius: "6px",
  },
  '@media (max-width:470px)': {
    minWidth: "unset"
  },
})
export const AutocompleteComponent2 = styled(Autocomplete)({
  width: "374px",
  display: "none",
  marginBottom: "10px",
  "& .MuiFormControl-marginNormal": {
    marginTop: "unset",
    marginBottom: "unset",
  },

  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: "0px 0px 0px 8px"
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
    padding: "10px 8px"
  },
  "& .MuiOutlinedInput-root": {
    height: "44px",

    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#CBD5E1",
    },
  },
  "& .MuiInputBase-root": {
    color: "#334155"
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  '@media (max-width:1424px)': {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
})
export const HomeGrid = styled(Grid)({
  marginTop: "48px",
  maxWidth: "1168px",
  minWidth: "1168px",
  marginInline: "auto",
  '@media(max-width: 1440px)': {
    marginInline: "135px",
  minWidth: "unset",

  },
  
  '@media(max-width: 1400px)': {
    maxWidth: "unset",
  minWidth: "unset",

  marginInline: "60px",
  },
  '@media(max-width: 1209px)': {
    maxWidth: "unset",
  minWidth: "unset",

  marginInline: "20px",
  },
  '@media(max-width: 400px)': {
    maxWidth: "unset",
  minWidth: "unset",

    marginInline: "10px",
  },

})
const Allstyle = {
  mainGridStyle: {
    marginTop: "48px",
    maxWidth: "1168px",
    marginInline: "auto"
  },
  popup: {
    display: "flex",
    gap:"8px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    color: "#0F172A",
    borderRadius: "8px",
   
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    textAlign:"center",
    padding:"9px 16px 9px 8px",
    lineHeight: "24px",
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)"
  },
  menuItemStyle: {
    color: "#0F172A",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    padding: "12px 16px"
  },
  styleText: {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  optionStyle: {
    color: "#FF5E5B",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
  },
  clearStyle: {
    color: "#64748B",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    cursor: "pointer",
    lineHeight: "22px",
  },
  nameSTyle: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  statusSTyle: {
    padding: "2px 8px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "40px",

    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  headingBoxStyle: {
    display: "flex" as "flex",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap"
  },
  hedingStyle: {
    color: "#475569",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  mainHedingStyle: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.15px",
  },
  btnStyle: {
    color: "#FFF",
    fontFamily: "Lato" as "Lato",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    height: "44px",
    width: "163px",
    borderRadius: "8px",
    padding: "10px 16px",
    background: "#03B5AA",
    textTransform: "inherit" as "inherit",
  },
  searchFieldSyle: {
  },
  optionBtnStyle: {
    background: "#FFF",
    border: "1px solid #CBD5E1",
    padding: "4px 8px 4px 16px",
    height: "44px",
    borderRadius: "8px",
    textTransform: "inherit" as "inherit",
    color: "#334155",
    fontFamily: "Lato" as "Lato",
    fontSize: "16px",
    fontWeight: 400,
  },
  sortStyle: {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "22px",
  },

  subImgStyle: {
    maxWidth: "274px",
    borderRadius: "8px",
    marginBottom: "24px",
    cursor:"pointer",
    boxShadow:
      "0px 9.067px 36.269px 0px rgba(0, 0, 0, 0.06), 0px 4.534px 9.067px 0px rgba(0, 0, 0, 0.03)",
  },
};
// Customizable Area End
