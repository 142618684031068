import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import storage from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  notificationListData: any[];
  showAction: boolean;
  anchorEl: any;
  isSnackbarMessage: string;
  isShowSnackbar: boolean;
  notificationId: {
    attributes:{id:number,is_read:boolean}
  };
  deletePopUp: boolean;
  perPage: number;
  loadMore: boolean;
  noImageUrl: string;
  imgData: any[]
  unread_count:number;
  isLoading:boolean
  emptyImg:any;
  currentPage:number;
  total_number_page:number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  notificationApiCallWebID: string = "";
  notificationReadApiCallWebID: string = "";
  notificationAllReadApiCallWebID: string = "";
  notificationDeleteApiCallWebID: string = "";
  allImgagesId: string = ""
  notificationUnReadApiCallWebID:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading:false,
      total_number_page:1,
      unread_count:0,
      currentPage:1,

      data: [],
      selectedData: null,
      emptyImg:"",
      token: "",
      notificationListData: [],
      showAction: false,
      anchorEl: '',
      isSnackbarMessage: "",
      isShowSnackbar: false,
      notificationId: {
        attributes:{
          id:5,
          is_read:false
        }
      },
      deletePopUp: false,
      perPage: 10,
      loadMore: true,
      noImageUrl: "",
      imgData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.setState({isLoading:true})
    this.getNotificationList(this.state.currentPage)
    this.getAllImagesData()
    let emptyImg= await storage.get("notifications_screen_with_no_notifications_image") ||"";
    this.setState({emptyImg:emptyImg}) 
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getNotifications();
    } else if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      apiResponse &&
        this.setState({
          data: apiResponse.data,
        });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getNotifications();
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.showAlert("Message", configJSON.deleteMessage);
      }
      this.setState({ selectedData: null });
      this.getNotifications();
    } else if (this.notificationApiCallWebID === message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({isLoading:false})
      if(apiResponse?.errors && apiResponse?.errors[0]?.message==="No notification found."){
        this.setState({ notificationListData: [], loadMore: true ,isLoading:false,unread_count:apiResponse.meta.unread_count})

      }
      if (apiResponse) {
        this.setState({ notificationListData: apiResponse.data, loadMore: true ,isLoading:false,unread_count:apiResponse.meta.unread_count,total_number_page:apiResponse.meta.last_page})
      }
    } else if (this.notificationReadApiCallWebID === message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        setTimeout(() => {
          this.setState(() => ({
            isSnackbarMessage: "",
            isShowSnackbar: false
          }), () => {
            setTimeout(() => {
              this.setState(() => ({
                isSnackbarMessage: "",
                isShowSnackbar: false
              }))
              window.location.reload();
            }, 1500);
          })
        }, 100);
        this.getNotificationList(this.state.currentPage)
      }
    } else if (this.notificationAllReadApiCallWebID ===  message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResp = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResp) {
        setTimeout(() => {
          this.setState(() => ({
            isSnackbarMessage: "",
            isShowSnackbar: false
          }), () => {
            setTimeout(() => {
              this.setState(() => ({
                isSnackbarMessage: "",
                isShowSnackbar: false
              }))
              window.location.reload();
            }, 1500);
          })
        }, 100);
        this.getNotificationList(this.state.currentPage)
      }
    } else if (this.notificationDeleteApiCallWebID === message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponses = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiResponses) {
          setTimeout(() => {
            this.setState(() => ({
              isSnackbarMessage: "NOTIFICATION VANISHED! Your notification has been successfully deleted.",
              isShowSnackbar: true
            }), () => {
              setTimeout(() => {
                this.setState(() => ({
                  isSnackbarMessage: "",
                  isShowSnackbar: false
                }))
                window.location.reload();
              }, 5000);
            })
          }, 100);
          this.getNotificationList(this.state.currentPage)
        }
      }
      else if(this.notificationUnReadApiCallWebID=== message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage))){
          
          setTimeout(() => {
            this.setState(() => ({
              isSnackbarMessage: "",
              isShowSnackbar: false
            }), () => {
              setTimeout(() => {
                this.setState(() => ({
                  isSnackbarMessage: "",
                  isShowSnackbar: false
                }))
                window.location.reload();
              }, 1500);
            })
          }, 100);
          this.getNotificationList(this.state.currentPage)
          
      }
      else if(this.allImgagesId === message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage))){
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (apiResponse?.data.length>0) {
            this.setState({imgData: apiResponse.data})
          }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  markAsUnReadNotification = () => {
 
    
    this.setState({ showAction: false })
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: localStorage.getItem("auth_token"),
    };
    const messageNotification = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationUnReadApiCallWebID = messageNotification.messageId;

    messageNotification.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.blockNotification}/${this.state.notificationId.attributes.id}/unread`
    );

    messageNotification.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    messageNotification.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.markAsReadMethod
    );
    runEngine.sendMessage(messageNotification.id, messageNotification);
    return true;
}
  getAllImagesData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("auth_token"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.allImgagesId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/images`
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }
  handleChange=(event:any,page:any)=>{
 
    
    this.setState({currentPage:page})
    this.getNotificationList(page)
    window.scrollTo(0,0)
 }
  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
  
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }

  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + "  year";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "  month";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " day";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "  h";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "  m";
    }
    return Math.floor(seconds) + "  s";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  getNotificationList = (page:number) => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("auth_token"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationApiCallWebID = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blockNotification}?page=${page}&per_page=${10}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }

  markAsReadNotification = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("auth_token"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationReadApiCallWebID = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blockNotification}/${this.state.notificationId.attributes.id}/read`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }
  
  markAllAsReadNotification = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("auth_token"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAllReadApiCallWebID = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blockNotification}/read_all`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );
    runEngine.sendMessage(message.id, message);
    return true; 
  }

  deleteNotification = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("auth_token"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationDeleteApiCallWebID = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blockNotification}/${this.state.notificationId.attributes.id}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(message.id, message);
    return true; 
  }

  handleMarkAsRead = () => {
    this.setState({ showAction: false })
    this.markAsReadNotification()
  }

  handleAction = (event: any, elm: any) => {
    
    this.setState({ showAction: true, anchorEl: event.currentTarget, notificationId: elm})
  }

  handleClose = () => {
    this.setState({ showAction: false })
  }

  openDeleteNotification = () => {
    this.setState({deletePopUp: true, showAction: false})
  }
  handleDeleteNotificationClose = () => {
  this.setState({deletePopUp: false, showAction: false})
  }
  handleSuccessDelete = () => {
    this.setState({
      deletePopUp: false
    }, () => {
      this.deleteNotification()
    })
  }
  handleNavigation = ( elm: any) => {

  this.props.navigation.navigate('NotificationDetails', {id:elm?.id})
  }

 
  manageImage = () => {
   return this?.state?.imgData[2]?.attributes?.image?.url
  }
  // Customizable Area End
}
