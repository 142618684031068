import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    MenuItem,
    Popper,
    ClickAwayListener,
    Paper,
    MenuList,
    Grow,
} from "@material-ui/core";

import { styled } from "@material-ui/core/styles";
import { no_requestMeet,CrossMark,UpArrow, DownArrow,checkbox_Checked, checkbox_UnChecked,image_Notebook} from './assets'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '@material-ui/lab/Pagination';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import RequestManagementController, {
    Props,
    configJSON,
} from "./RequestManagementController";

export default class MyAdoptedPets extends RequestManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    hanldeSvg1 = () => {
        return <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            fill="none"
            viewBox="0 0 20 20"
            height="20"
        >
            <path
                d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
                fill="#64748B"
            />
        </svg>  }
    handleToggleClickType = () => {
        this.setState({ TypeMenu: !this.state.TypeMenu,postTypeMenu:false });
    }
    handleToggleClickPostType = () => {
        this.setState({ postTypeMenu: !this.state.postTypeMenu ,TypeMenu:false});
    }
    StylBackgr = (value: any) => {
        return value ? "#03B5AA" : "#FFF";
    }
    StylColor = (value: any) => {
        return value ? "#FFF" : "#334155"
    }

    handleSvgArrow = (value: any) => {
        return value ? UpArrow : DownArrow;
    }
    handleClosePost = (e:any) => {
        if(e.target.id!='postTypeMenuButton'&&e.target.classList[0]!='MuiButton-label'){

            this.setState({ postTypeMenu: false });
        }
    };
    handleCloseType = (e:any) => {
        if(e.target.id!='TypeButton'&&e.target.classList[0]!='MuiButton-label'){
        this.setState({ TypeMenu: false });
        }
    };

    renderSelectFilter = () => {
      
        return  <FilterBox>
            <div style={{ position: 'relative' }}>
                <ButtonCompSelect  data-test-id='postTypeMenu'
                    ref={this.state.postTypeMenuRef}
                    aria-haspopup="true"
                    onClick={this.handleToggleClickPostType}
                    aria-controls={Boolean(this.state.postTypeMenu) ? 'menu-list-growanimaltypeMenu' : undefined}
                    style={{ ...Allstyle.optionButtonStyle, background: this.StylBackgr(this.state.postTypeMenu), color: this.StylColor(this.state.postTypeMenu) }}
                    variant="contained"
                    id="postTypeMenuButton"
                >
                    <span 
                    id="postTypeMenuButton"
                    style={{ marginRight: "6px" }}>
                        Animal type
                        </span>
                    {
                        <img 
                        id="postTypeMenuButton"
                        src={this.handleSvgArrow(this.state.postTypeMenu)} 
                        />
                    }
                </ButtonCompSelect>
                {this.handleMenuOptionDropDowns({menuItem1: this.state.postTypeMenu,
                    closeFn1: this.handleClosePost,
                    options1: this.state.animalTypeListForAdoptedPets,
                    selectedMenu1: this.handleMenuCategory,
                    selectedMenuItem1: this.state.postTypeOption,
                    ref1: this.state.postTypeMenuRef,
                    handleListKeyDownmenu1: this.handleListKeyDownMenu,
                    stateName1: "postTypeMenu",
                })}
            </div>

            <div style={{ position: 'relative' }}>
                <ButtonCompSelect 
                variant="contained"
                    ref={this.state.TypeMenuRef}
                    aria-controls={Boolean(this.state.TypeMenu) ? 'menu-list-growanimaltypeMenu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggleClickType}
                    style={{ ...Allstyle.optionButtonStyle, background: this.StylBackgr(this.state.TypeMenu), color: this.StylColor(this.state.TypeMenu) }}
                    data-test-id='TypeMenu'
                    id="TypeButton"
                >
                    <span style={{ marginRight: "6px" }} id="TypeButton">Breed</span>
                    {
                        <img src={this.handleSvgArrow(this.state.TypeMenu)} id="TypeButton"/>
                    }
                </ButtonCompSelect>

                {this.handleMenuOptionDropDowns({
                    menuItem1: this.state.TypeMenu,
                    closeFn1: this.handleCloseType,
                    options1: this.state.breedListForMyAdoptedPets,
                    selectedMenu1: this.handleMenuSubCategory,
                    selectedMenuItem1: this.state.TypeOption,
                    ref1: this.state.TypeMenuRef,
                    handleListKeyDownmenu1: this.handleListKeyDownMenu,
                    stateName1: "TypeMenu",
                })}
            </div>  </FilterBox>
    }
    handleMenuOptionDropDowns = (menuConfig: any) => {
        const { menuItem1, closeFn1, options1, selectedMenu1, selectedMenuItem1, ref1, handleListKeyDownmenu1, stateName1 } = menuConfig;
        return (
            <CustomPopper 
            open={menuItem1}
             anchorEl={ref1.current}  
              placement={'bottom-start'}
               data-test-id='CustomPopper' 
               role={undefined} 
               disablePortal style={{ marginTop: "20px", zIndex: 1111, position: "absolute" }}>
                <GridOption 
                style={{maxHeight: "321px", minWidth: "240px",borderRadius: "8px",
                  overflowY: "auto",  background: "#FFF" }}>
                    <ClickAwayListener
                     onClickAway={closeFn1}>
                        <CustomMenuList 
                     autoFocusItem={menuItem1} id={`menu-list-grow${stateName1}`}
                        data-test-id='CustomMenuList'  
                        onKeyDown={(event: any) => handleListKeyDownmenu1(event, stateName1)}>
                            {options1?.length > 0 && options1.map((item: any, index: number) => {
                                return (<MenuItem   data-test-id='MenuItem'
                                    style={{
                                        ...Allstyle.menuListItemStyle,
                                    }}
                                    onClick={() => {
                                        selectedMenu1(item?.id, item.name);
                                    }}
                                 
                                        key={index}
                                    >
                                        <span style={{
                                                height: "24px",
                                                marginRight: "8px",
                                                width: "24px", }}  >
                                      
                                            {selectedMenuItem1.includes(item?.id) ? (
                                                <img 
                                                src={checkbox_Checked}
                                                 />
                                            ) : (
                                                <img 
                                                src={checkbox_UnChecked} 
                                                />
                                            )}
                                        </span>
                                        {item.name} </MenuItem>
                                );
                            })}
                        </CustomMenuList>

                    </ClickAwayListener>

                </GridOption>

            </CustomPopper>
        );
    }
    handleMenuIndexForMyAdoptedPets = (index: number, value: any) => {
        this.setState({ menu: index, sortValue: value });
        this.getMyAdoptedPetsPost()
    };
    setAnchor = (event: any) => {
        this.setState({ anchorEl: event.currentTarget })
    }
    setAnchorClose = () => {
        this.setState({ anchorEl: null })
    }
    renderEmptyPage = () => {
        return <EmptyParentBox>
            <Box style={Allstyle.imageTextBoxEmpty}>
                <EmptyImg src={image_Notebook} alt="" />
                <EmptyTypography>No adopted pets yet!</EmptyTypography>
            </Box>
        </EmptyParentBox>
    }
    showChipBox = () => {
        return ((!this.state.TypeOption.includes(0)) ||
            (!this.state.postTypeOption.includes(0)) 
        )
    }
    popoverFun = (isPopover: any) => {

        return isPopover ? 'simple-popover' : undefined;
    }
    returnFilterAndSearchBox = (id: any, isPopover: any) => {
        return (
            <FilterParentContiner>

                <FilterParentBox> {this.renderSelectFilter()}
                   
                    <SortParentBox  onClick={this.setAnchor}
                        aria-controls={Boolean(this.state.sortMenuItem) ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"

                        data-test-id='SortParentBox'
                        aria-describedby={id}
                    >
                        <span style={{
                                ...Allstyle.sortByText,
                                fontWeight: 400,
                                marginRight: "4px",
                            }}
                        > Sort by:</span>
                        <Typography
                            style={{ ...Allstyle.submissionDateText,  cursor: "pointer",
                                fontWeight: 700,
                                display: "flex",
                            }}
                        >
                            {configJSON.sortMenuItemList[this.state.menu].name}
                            <svg
                                xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24"
                                height="24"
                                fill="none"
                            >

                                <path
                                    fill="#334155"
                                    d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                                />
                            </svg>
                        </Typography>
                    </SortParentBox>
                    <Popper data-test-id='sortPopper' 
                    open={isPopover} id={id} anchorEl={this.state.anchorEl}
                     role={undefined} transition 
                     disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper style={{background: "#FFF",
                                 width: "240px", borderRadius: "8px",
                                   }}>
                                    <ClickAwayListener
                                     onClickAway={this.setAnchorClose}>
                                        <CustomSortMenu 
                                        data-test-id='CustomSortMenu' 
                                        id="menu-list-grow"
                                        autoFocusItem={Boolean(this.state.sortMenuItem)} 
                                         onKeyDown={(event: any) => this.handleListKeyDownMenu(event, 'sortMenuItem')}>
                                            {configJSON.sortMenuItemList.map((item: any, index: number) => {
                                                return (
                                                    <MenuItem
                                                    key={index}
                                                    onClick={() => this.handleMenuIndexForMyAdoptedPets(index, item.value)}
                                                    data-test-id='sortMenuItemListItem'
                                                        style={{...Allstyle.menuItemSortStyle,
                                                            background:
                                                                this.state.menu == index
                                                                    ? "linear-gradient(0deg, rgba(205, 240, 238, 0.50) 0%, rgba(205, 240, 238, 0.50) 100%), #FFF"
                                                                    : "", }}
                                                       
                                                    >
                                                        {this.state.menu == index ? (
                                                            <span style={{marginRight: "8px",
                                                                }}
                                                               
                                                            >
                                                                <svg fill="none"
                                                                    width="24"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                > <path
                                                                        d="M8.79457 15.8754L5.32457 12.4054C5.13774 12.2181 4.88409 12.1129 4.61957 12.1129C4.35505 12.1129 4.1014 12.2181 3.91457 12.4054C3.52457 12.7954 3.52457 13.4254 3.91457 13.8154L8.09457 17.9954C8.48457 18.3854 9.11457 18.3854 9.50457 17.9954L20.0846 7.41538C20.4746 7.02538 20.4746 6.39538 20.0846 6.00538C19.8977 5.81812 19.6441 5.71289 19.3796 5.71289C19.1151 5.71289 18.8614 5.81812 18.6746 6.00538L8.79457 15.8754Z"
                                                                        fill="#0F172A"
                                                                    />
                                                                    
                                                                </svg>

                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{
                                                                    width: "24px",
                                                                    marginRight: "8px",
                                                                    height: "24px",
                                                                }}
                                                            ></span>
                                                        )}
                                                        {item.name}

                                                    </MenuItem>
                                                );
                                            })}
                                        </CustomSortMenu>

                                    </ClickAwayListener>
                                </Paper>

                            </Grow>
                        )}
                    </Popper>
                </FilterParentBox>
            </FilterParentContiner>
        )
    }
    returnChipsBox = () => {
        return (this.showChipBox() && !this.state.showEmtyImageForNoAdoptedPets && <PostBoxMain style={{ marginTop: "24px", marginBottom: "48px", display: "flex", justifyContent: "space-between" }}>
            <PostGridBox style={{ display: "flex", gap: "16px", flexWrap: 'wrap', alignItems: "center" }}>
                {this.state.postTypeOption && this.state.postTypeOption.length > 0 && !this.state.postTypeOption.includes(0) &&
                    this.state.postTypeOption.map((index: any) => {
                        const search = this.state.animalTypeListForAdoptedPets.find((ele: any) => ele.id === parseInt(index))
                         return search&& <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                            <img src={CrossMark} style={{ cursor: "pointer" }} data-test-id="test1" onClick={() => { this.clearOptionForAdoptedPetsFilter('postTypeOption', index) }} />
                            <Typography style={Allstyle.optionStyleForclear}>
                                {search.name}
                            </Typography>
                        </Box>
                    })
                }
                {this.state.TypeOption && this.state.TypeOption.length > 0 && !this.state.TypeOption.includes(0) &&
                    this.state.TypeOption.map((index: any) => {
                        const search = this.state.breedListForMyAdoptedPets.find((ele: any) => ele.id === parseInt(index))
                        return search&&<Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                            <img src={CrossMark} style={{ cursor: "pointer" }} data-test-id="test1" onClick={() => { this.clearOptionForAdoptedPetsFilter('TypeOption', index) }} />
                            <Typography style={Allstyle.optionStyleForclear}>
                            {search.name}
                            </Typography>
                        </Box>
                    })
                }

                {this.handleConditionOfClear() &&
                    <Typography style={Allstyle.clearStyleFilter} data-test-id="test5" onClick={() => { this.clearAllOptionsForAdoptedPets() }}>
                        Clear all
                    </Typography>
                }
            </PostGridBox>
        </PostBoxMain>)
    }
    renderEmptyPageNoFilterResult= () => {
        return <EmptyParentBox>
            <Box style={Allstyle.imageTextBoxEmpty}>
                <EmptyImg src={no_requestMeet} alt="" />
                <EmptyTypography>No results meet your filter criteria</EmptyTypography>
            </Box>
        </EmptyParentBox>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const isPopover = Boolean(this.state.anchorEl);
        const id = this.popoverFun(isPopover)
        
        return (

            <>

                <Loader loading={this.state.isLoading} />
                <Box style={Allstyle.OuterContainer}>
                    <MainTopArrowHeadingForBigScreenBox  >
                        <MainTopArrowHeading>
                            <Typography
                                style={{ ...Allstyle.hedingsStyle, cursor: "pointer" }}
                                onClick={() => this.hanldeNavigation('LandingPageWeb')}
                                data-test-id="homeNav1"
                            >{configJSON.home}
                            </Typography>
                            {this.hanldeSvg1()}
                            <Typography
                                style={{
                                    ...Allstyle.hedingsStyle,
                                    ...Allstyle.currentPageStyle
                                }}
                            >My Adopted pets
                            </Typography>
                        </MainTopArrowHeading>
                    </MainTopArrowHeadingForBigScreenBox>
                    <MainTopArrowHeadingForSmallSreenParentBox  >
                        <MainTopArrowHeadingForSmallSreen>
                            <Typography
                                onClick={() => this.hanldeNavigation('LandingPageWeb')}
                                data-test-id="homeNav2"
                                style={{ ...Allstyle.hedingsStyle, cursor: "pointer" }}
                            >{configJSON.home} </Typography>
                            {this.hanldeSvg1()}
                            <Typography
                                style={{
                                    ...Allstyle.hedingsStyle,
                                    ...Allstyle.currentPageStyle
                                }}
                            >My Adopted pets </Typography>
                        </MainTopArrowHeadingForSmallSreen>
                    </MainTopArrowHeadingForSmallSreenParentBox>
                    {!this.state.showEmtyImageForNoAdoptedPets&&this.state.myAdoptedData.length > 0&&<Typography style={Allstyle.Textstyle}>
                        My Adopted pets
                    </Typography>}
                    { !this.state.showEmtyImageForNoAdoptedPets &&this.state.myAdoptedData.length > 0&&this.returnFilterAndSearchBox(id, isPopover)}
                    { this.state.showEmpty_ForNoAdoptedPets&&this.returnFilterAndSearchBox(id, isPopover)}
                    {this.state.showEmpty_ForNoAdoptedPets&&this.renderEmptyPageNoFilterResult()}
                    {this.state.myAdoptedData.length > 0&&this.returnChipsBox()}
                    {this.state.myAdoptedData.length== 0&& this.state.showEmtyImageForNoAdoptedPets && this.renderEmptyPage()}
                    {this.state.myAdoptedData.length > 0 &&
                        <TableContainer data-test-id='TableContainer'>
                            <Table style={Allstyle.table} aria-label="simple table">
                                <TableHead >
                                    <TableRow>
                                        <TableHeadTableCell>Pet name</TableHeadTableCell>
                                        <TableHeadTableCell >Animal type</TableHeadTableCell>
                                        <TableHeadTableCell >Breed</TableHeadTableCell>
                                        <TableHeadTableCell >Adopter name</TableHeadTableCell>
                                        <TableHeadTableCell >Adoption date</TableHeadTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.myAdoptedData.map((items: any, index: any) => (


                                        <TableRow key={index}>
                                            <TableCell >
                                                <Typography style={{ ...Allstyle.tableFirstColumn, width: '182px' }}  >   {items.attributes.name}</Typography>
                                            </TableCell>
                                            <TableCell  ><Typography style={{ ...Allstyle.tableFirstColumn }}  >{items.attributes.category.name}</Typography></TableCell>
                                            <TableCell style={Allstyle.tableFirstColumn}>{items.attributes.sub_category.name}</TableCell>
                                            <TableCell ><Typography style={Allstyle.tableFirstColumn} >{items.attributes?.adopted_by?.name}</Typography> </TableCell>
                                            <TableCell style={Allstyle.tableFirstColumn} >{this.formateDate(items.attributes.adopted_at)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {this.state.myAdoptedData.length > 0 && !this.state.showEmtyImage && <ParentBoxPaginationCompstyled >
                        <PaginationComp data-test-id='paginationComp' page={this.state.currentTablePage} count={this.state.tableLastPage}
                            onChange={this.handleChangePageForAdoptedPetsTable}

                        />
                        <PaginationCompSmallScreen
                         size="small"
                         data-test-id='paginationComp'
                          page={this.state.currentTablePage} 
                          count={this.state.tableLastPage}
                            onChange={this.handleChangePageForAdoptedPetsTable}
                            siblingCount={0}
                        />
                        <CaptionText> {((this.state.currentTablePage - 1) * this.state.per_page) + 1} - {Math.min(this.state.currentTablePage * this.state.per_page, this.state.tableTotalRequest)} of {this.state.tableTotalRequest} results
                        </CaptionText>
                    </ParentBoxPaginationCompstyled>}
                </Box>

            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start


const MainTopArrowHeading = styled(Box)({
    display: "flex", alignItems: "center",
    '@media (max-width:1012px)': { display: 'none' }
});
const MainTopArrowHeadingForBigScreenBox = styled(Box)({
    display: 'flex', alignItems: 'center', 
    justifyContent: 'space-between',
     padding: "41px 0px 0px 0px",
    '@media (max-width:1012px)': {   display: 'none'}
});
const MainTopArrowHeadingForSmallSreenParentBox = styled(Box)({display: 'none',
    '@media (max-width:1012px)': {display: 'flex', alignItems: 'center',
        padding: "41px 0px 0px 0px",
        justifyContent: 'space-between',
    },
    '@media (max-width:790px)': { flexDirection: "column" as 'column',  alignItems: 'flex-start', gap: '16px'
    }

});
const MainTopArrowHeadingForSmallSreen = styled(Box)({ display: 'none',
    '@media (max-width:1011px )': {  display: 'block',
    },
    '@media (max-width:1012px )': { display: 'flex',
        alignItems: "center",
    },
});
const TableHeadTableCell = styled(TableCell)({fontSize: '14px',fontFamily: 'Inter', color: 'rgba(100, 116, 139, 1)',
    lineHeight: '22px',
    fontWeight: 700,
    letterSpacing: '0px',
    width: "100px",
})
const FilterParentContiner = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderBottom: "1px solid rgba(203, 213, 225, 1)",
    paddingBottom: '16px',
    paddingTop: '16px',
    marginBottom: '16px',
    "@media (max-width:1050px)": {  flexDirection: 'column',
        alignItems: 'unset',
        gap: '32px',
    }

})
const FilterParentBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    "@media (max-width:798px)": {alignItems: 'unset',
        gap: '32px',
        flexDirection: 'column',
    }

})

const PaginationCompSmallScreen = styled(Pagination)({
    display: 'none',
    justifyContent: 'center',
    '& .MuiPaginationItem-page.Mui-selected': { background: "unset",
    color: "#03B5AA",
    fontWeight: 700,
       
    },
    '& .MuiPaginationItem-root': {
        color: "#334155", fontSize: "16px", fontFamily: "Lato",
        fontWeight: 400,
    },
    '@media(max-width: 600px)': { width: 'unset',
        display: 'block',
    },
})
const PaginationComp = styled(Pagination)({
    justifyContent: 'center',
    width: 'max-content',
    '& .MuiPaginationItem-page.Mui-selected': {  color: "#03B5AA", background: "unset",
        fontWeight: 700,
    },
    '& .MuiPaginationItem-root': {
        fontFamily: "Lato", fontWeight: 400,
        color: "#334155", fontSize: "16px",
    },
    '@media(max-width: 600px)': { display: 'none',
    },
})
const FilterBox = styled(Box)({
    display: "flex",
    alingItems: 'center',
    gap: '16px',
    '@media (max-width: 798px)': { display: "flex",
        gap: '16px',
        alingItems: 'unset',
        flexDirection: 'row',
    },
    '@media (max-width: 430px)': {flexDirection: 'column',
    }
})
const ParentBoxPaginationCompstyled = styled(Box)({ display: 'flex',paddingBottom: '16px',
    justifyContent: 'center',
    height: 'max-content',
    paddingTop: '16px',
    alignItems: 'center',
    '@media(max-width: 1300px)': {justifyContent: 'space-between'
    },
    '@media(max-width: 772px)': {flexDirection: 'column',
    }
})
const CaptionText = styled(Typography)({fontSize: '12px',
    fontFamily: 'Lato',
    fontWeight: 400,
    lineHeight: '18px',
    color: 'rgba(100, 116, 139, 1)',
    textAlign: 'right',
    letterSpacing: '0em',
    right: '8.1%',
    position: 'absolute',
    '@media (max-width:772px)': {
        right: 'unset',
        position: 'unset',
    }

})
const SortParentBox = styled(Box)({ width: '263px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '16px',
    justifyContent: "center", cursor: "pointer",
    '@media (max-width: 798px)': { marginLeft: 'unset',
    },
    '@media (max-width: 314px)': { width: 'unset',
        flexDirection: 'column',
    }
})
const CustomPopper = styled(Popper)({
    "& .MuiPaper-rounded": {boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
        border: '1px solid rgba(245, 245, 245, 1)',
        borderRadius: '8px',
    },
})
const GridOption = styled(Paper)({
    "&::-webkit-scrollbar": { 
        width: "2px"
     },
     "&::-webkit-scrollbar-thumb": {
         borderRadius: "6px",
         backgroundColor: "#94A3B8",
     },
    "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 5px #E2E8F0",
        borderRadius: "6px",
    },
})
const ButtonCompSelect = styled(Button)({
    '&.MuiButton-contained': {minWidth: "109px", boxShadow: "unset",
    }
})
const CustomMenuList = styled(MenuList)({    minWidth: "200px",
    borderRadius: "8px",
    paddingBottom: "unset",
    paddingTop: "unset",
})
const CustomSortMenu = styled(MenuList)({  paddingTop: "unset", borderRadius: "8px",
    minWidth: "200px",
    paddingBottom: "unset"
})

const PostBoxMain = styled(Box)({   '@media (max-width:600px)': {flexWrap: "wrap"
    },
})

const PostGridBox = styled(Box)({
    maxWidth: "900px",
    '@media (max-width:400px)': { 
        maxWidth: "250px"
     },
    '@media (max-width:560px)': { maxWidth: "350px" },
})
const EmptyImg = styled('img')({
    height: '284px',
    width: '284px',
    "@media(max-width:320px)":{   width: "100%"
    }
    
})
const EmptyParentBox = styled(Box)({ display: 'flex', alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
})
const EmptyTypography = styled(Typography)({    letterSpacing: "-0.005em",   lineHeight: "33.6px", fontWeight: 700,
    fontSize: "24px",
    fontFamily: "Inter",
    color: "#1E293B",
    "@media(max-width:514px)": {
        textAlign: 'center',
    }

})



const Allstyle = {

    OuterContainer: {
        margin: 'auto',
        width: '84%',
        marginBottom: '70px',
    },

    currentPageStyle: {
        paddingInline: "6px",
        color: 'var(--Neutrals-Cool-gray-900, #0F172A)',

    },
    Textstyle: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "30px",
        fontWeight: 700,
        lineHeight: "40px",
        letterSpacing: "-0.15px",
        marginBottom: '32px',
        marginTop: '32px',

    },
   
    hedingsStyle: {
        color: "#475569",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },
    table: {
        minWidth: 650,
    },
    tableFirstColumn: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0px',
        color: 'rgba(0, 0, 0, 1)',
        fontFamily: 'Inter' as 'Inter',
        whiteSpace: 'nowrap' as 'nowrap',
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
   
    
    sortByText: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        letterSpacing: '0px',
        color: 'rgba(51, 65, 85, 1)',
    },
    submissionDateText: {
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: 700,
        letterSpacing: '0px',
        lineHeight: '22px',
        color: 'rgba(51, 65, 85, 1)',
        marginLeft: '4px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',

    },
   
    optionButtonStyle: {padding: "4px 8px 4px 16px",   border: "1px solid #CBD5E1", textTransform: "inherit" as "inherit",
        borderRadius: "8px",
        height: "44px",
        background: "#FFF",
        fontWeight: 400,
        color: "#334155",
        fontSize: "16px",
        fontFamily: "Lato" as "Lato",
    },
    menuListItemStyle: {
        color: "#0F172A",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        padding: "12px 16px"
    },

    optionStyleForclear: {fontWeight: 400, fontFamily: "Lato",fontSize: "12px",
        color: "#FF5E5B",
        lineHeight: "18px",
    },
    menuItemSortStyle: {  fontFamily: "Lato", padding: "12px 16px",
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
    },
    clearStyleFilter: { color: "#64748B",fontWeight: 700,
        fontFamily: "Lato" as "Lato",
        lineHeight: "22px",
        cursor: "pointer",
        fontSize: "14px",
    },
    imageTextBoxEmpty: {display: 'flex',flexDirection: 'column' as 'column', marginBottom:'105px',
    justifyContent: 'center',
        alignItems: 'center',
        marginTop:'105px',
        gap: '16px',
    },
    explore_pets_button_text: { fontWeight: 700,  letterSpacing: '0em',
        lineHeight: '24px',
        color: 'rgba(255, 255, 255, 1)',
        fontFamily: 'Lato' as "Lato",
        fontSize: '16px',
    },
    explore_pets_button: { textTransform: 'none' as 'none' ,padding: '10 16 10 16',borderRadius: 8,
        background: 'rgba(3, 181, 170, 1)',
    },
    
}
// Customizable Area End
