
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Product Title";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";
exports.productAPiEndPoint = "bx_block_productdescription/productdescriptions";
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";


exports. coatLength = [
  { id: 0, name: "Short" },
  { id: 1, name: "Medium" },
  { id: 2, name: "Long" },
  { id: 3, name: "Hairless" },
  
];
exports.gender2=[
  {id:0,name:"Male"},
  {id:1,name:"Female"},
]
exports.speacialNeed=[
  {id:0,name:"Yes",value:true},
  {id:1,name:"No",value:false},
]
exports.Spayed_Neutered=[
  {id:0,name:"Yes",value:true},
  {id:1,name:"No",value:false},
]
exports.VaccineStatus =[
  {id:0,name:"No vaccine administered"},
  {id:1,name:"First vaccine administered"},
  {id:2,name:"First & booster vaccine administered"},
  {id:3,name:"Fully vaccinated"},

]
exports.little_Critter_VaccineStatus=[
  {id:0,name:"No vaccines administered"},
  {id:1,name:"Vaccinated"},
]
exports.FIVStatus  =[
  {id:0,name:"Negative for both"},
  {id:1,name:"FIV+"},
  {id:2,name:"FeLV+"},
  {id:3,name:"Positive for both"},
  {id:4,name:"Not tested"},

]
exports.ehrlichia=[
  {id: 0,name: "Positive",},
  {id: 1,name: "Fully treated"},
  {id:2,name:"Negative"},
  {id:3,name:"Not tested"},
]
exports.parasite_Medication=[
  {id: 0,name: "Internal & external administered",},
  {id: 1,name: "Internal only"},
  {id:2,name:"External only"},
  {id:3,name:"None administered"},
]
exports.skin_Diseases=[
  {id: 0,name: "No known issues",},
  {id: 1,name: "Yes"},
]
exports.training=[
  {id: 0,name: "No training",},
  {id: 1,name: "Training in progress"},
  {id:2,name:"Fully trained"},
]
exports.Microchiped=[
  {id:0,name:"Yes"},
  {id:1,name:"No"},
]
exports.dewormed =[
  {id:0,name:"Yes"},
  {id:1,name:"No"},
]
exports.pbfd_test=[
  {id:0,name:"Positive"},
  {id:1,name:"Negative"},
]
exports.level=[
  {id:1,name:"Low"},
  {id:2,name:"Moderate"},
  {id:3,name:"High"},
]
exports.lifeStyle=[
  {id:1,name:"Indoor only/Catio"},
  {id:2,name:"Outdoor"},

]
exports.lifeStyle_Rabbit=[
  {id:1,name:"Indoor only"},
  {id:2,name:"Outdoor"},

]
exports.lifeStyle_bird=[
  {id:1,name:"Cage only"},
  {id:2,name:"Indoor supervised free roam"},
  {id:3,name:"Fully indoor free roam"},
  {id:4,name:"Combination of indoor and outdoor free roam"},
]
exports.lifeStyle_reptile_and_LittleCritter=[
  {id:1,name:"Habitat only"},
  {id:2,name:"Indoor supervised free roam"},
  {id:3,name:"Fully indoor free roam"},
]
exports.cage_Size_Required=[
  {id:1,name:"No cage"},
  {id:2,name:"Small"},
  {id:3,name:"Medium"},
  {id:4,name:"Large"},
  {id:5,name:"Extra Large"},
]
exports.habitat_Size_Required=[
  {id:1,name:"No habitat required"},
  {id:2,name:"Small"},
  {id:3,name:"Medium"},
  {id:4,name:"Large"},
  {id:5,name:"Extra Large"},
]
exports.noise_Level=[
  {id:1,name:"Low"},
  {id:2,name:"Moderate"},
  {id:3,name:"High"},
]
exports.size=[
  {id:1,name:"Small"},
  {id:2,name:"Medium"},
  {id:3,name:"Large"},
  {id:4,name:"Extra Large"},
]
exports.location=[
  {id:1,name:"Abu Dhabi"},
  {id:2,name:"Dubai"},
  {id:3,name:"Sharjah"},
  {id:4,name:"Ajman"},
  {id:5,name:"Ras Al Khaimah"},
  {id:6,name:"Fujairah"},
  {id:7,name:"Umm Al Quwain"},
  {id:8,name:"India"},
]
exports.goodHome=[
  {id:1,name:"Children"},
  {id:2,name:"Dogs"},
  {id:3,name:"Cats"},
  {id:4,name:"Other"},
]

exports.RehomingStrategy =[
  {id:0,name:"Local"},
  {id:1,name:"International"},
  {id:2,name:"Both"},
 
]
exports.temperament=[
  {id:1,name:"Independent"},
  {id:2,name:"Needy"},
  {id:3,name:"Gentle"},
  {id:4,name:"Feisty"},
  {id:5,name:"Shy"},
  {id:6,name:"Confident"},
  {id:7,name:"Anxious"},
  {id:8,name:"Easy going"},
  {id:9,name:"Skittish"},
  {id:10,name:"Relaxed"},
  {id:11,name:"Affectionate"},
  {id:13,name:"Reserved"},
  {id:14,name:"Curious"},
  {id:15,name:"Aloof"},
  {id:16,name:"Intelligent"},
  {id:17,name:"Not too bright"},
  {id:18,name:"Playful"},
  {id:19,name:"Lazy"},
  {id:20,name:"Fearful"},
  {id:21,name:"Fearless"},
  {id:22,name:"Submissive"},
  {id:23,name:"Dominant"},
  {id:24,name:"Friendly"},
  {id:25,name:"Antisocial"},
  {id:26,name:"Talkative"},
  {id:27,name:"Quiet"},
 
]
exports.menuItem = [
  {
    id: 0,
    name: "Created date - newest",
    value:"newest"
  },
  {
    id: 1,
    name: "Created date - oldest",
    value:"oldest"
  },
  {
    id: 2,
    name: "Age - Low to High",
    value:"age_low_to_high"
  },
  {
    id: 3,
    name: "Age - High to Low",
    value:"age_high_to_low"
  },
  {
    id: 4,
    name: "Alphabetically - A to Z ",
    value:"alphabetically_a_to_z"
  },
  {
    id: 5,
    name: "Alphabetically - Z to A ",
    value:"alphabetically_z_to_a"
  },
];
exports.animalType=[
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Dog",
  },
  {
    id: 2,
    name: "Cat",
  },
  {id:3,
  name:"Rabbit"},
  {
    id:4,
    name:"Birds"
  },
  {id:5,name:"Reptiles"},
  {id:6,name:"Little Critters"}
]
exports.Breed=[
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Affenpinscher",
  },
  {
    id: 2,
    name: "Afghan Hound",
  },
  {id:3,
  name:"Airedale Terrier"},
  {
    id:4,
    name:"Akita"
  },
  {id:5,name:"ReAlaskan Malamuteptiles"},
  {id:6,name:"American Eskimo Dog"}
]
exports.gender=[
  {
    id: 0,
    name: "All",
  },
  {
    id: 1,
    name: "Male",
  },
  {
    id: 2,
    name: "Female",
  },
]

exports.marketing_Strategy=[{id:1,name:'Locally only'},{id:2,name:'Internationally only'},{id:3,name:'Both, locally and internationally'}]
exports.toolTipText_marketing_Strategy='Choose your rehoming marketing strategy. For marketing your animal internationally, terms and conditions apply.'
exports.toolTipText_rescue_story="Describe your animal's personality, behaviour, rescue story, build an interesting profile"
exports.toolTipText_Spayed_Neutered="Remember to spay/neuter your animals and register with the relevant authorities for official ownership"
exports.toolTipText_Animal_determination="Please list any physical disabilities (e.g. missing body parts) or any behavioural issues (e.g. untouchable, bitey) that would be relevant to an adopter"
exports.toolTipText_medical_Conditions="Please list any medical conditions (e.g. lack of control over urination or defecation, diabetes, chronic kidney disease) that may need special treatment or extra vet care that would be relevant to an adopter"
exports.pleaseExplain='Please explain'
exports.infoLine="Please input the animal's microchip number. This will not appear on the animal's profile to adopters."
exports.infoLine2=" Remember to spay/neuter your animals and register with the relevant authorities for official ownership"
exports.marketing_Strategy_endPoint='bx_block_settings/settings?title=posts_marketing_strategy'


// Customizable Area End
