import React from "react";

// Customizable Area Start
import HeaderController, { Props } from "./HeaderController.web";
import {DialogContent,Dialog, Badge, Backdrop,Snackbar,Avatar, Box, Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper, Typography, styled } from "@material-ui/core";
import { logonav, notification, dropdown,crossIconForClose } from "../assets";
import { FavoriteBorder, SearchOutlined } from "@material-ui/icons";
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CancelIcon from '@material-ui/icons/Cancel';
import { ImportantUpadate, PrivacyPolicy } from "../../../../components/src/WhoAreWe";
import {  NavLink } from "react-router-dom";
// Customizable Area End

class Header extends HeaderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start



    handleUI = () => {

        return this.state.userType === "" ? <>
            <NavLink style={{...AllStyle.loginBtn as React.CSSProperties,...AllStyle.navLinkStyle}} data-test-id="login1"
            to="/login"
             >
                Login
             </NavLink>
        
            <NavLink    to="/signup-role" style={AllStyle.registeBtn as React.CSSProperties} data-test-id="signup1" 
            >Register
            </NavLink>
            <Backdrop
                style={{ zIndex: 700 }}
                open={this.state.isOpenSnack}
                onClick={(event) => event.stopPropagation()}
                data-test-id='backdrop1'

            ><SnackBarStyle
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={this.state.isOpenSnack}
                style={{ zIndex: 1000 }}
                onClick={(event) => event.stopPropagation()}
                data-test-id='snackbar1'
autoHideDuration={3000}
            >
                    <Typography style={AllStyle.popup as React.CSSProperties}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
                        </svg>
                        YOU'VE LOGGED OUT! See you next time for more adventures.
                    </Typography>
                </SnackBarStyle></Backdrop> 
        </> :
            <Box style={{ display: "flex", paddingLeft: "36px", alignItems: "center", justifyContent: "center", position: "relative" }}>

                <Box>
                    <ButtonStyle2 data-test-id="menutest"

                        ref={this.state.anchorRef1}
                        aria-controls={Boolean(this.state.menuItem) ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"

                        onClick={() => { this.setState({ menuItem: !this.state.menuItem })
                        this.handleUserProfile()
                         }} >
                            {
                                this.state.imageUrl !=="" && this.state.imageUrl !==null?
                                <Avatar style={{ width: "30px", height: "30px" ,border:"2px solid var(--Basic-White, #FFF)"}} src={this.state.imageUrl} />:
                                <Avatar style={{ width: "30px", height: "30px" ,background:"white",color:"#03B5AA",border:"2px solid #CDF0EE"}} >
                                {this.state.userName !=="" &&this.state.userName[0].toUpperCase()}
                                </Avatar>

                            }
                        <Typography style={{
                            color: "#FFF",
                            fontFamily: "Lato",
                            fontSize: "16px",
                            fontWeight: 400,
                            lineHeight: "24px",
                            paddingLeft: "4px"
                        }}>
                            Hi, {this.state.userName}
                        </Typography>

                        <img src={dropdown} style={{ cursor: "pointer" }} aria-haspopup="true" />
                    </ButtonStyle2>
                    <Popper  data-test-id='popover2' placement={"bottom-end"} style={{ borderRadius: "8px", background: "#FFF" ,zIndex:700,marginTop:"6px" }} open={this.state.menuItem} anchorEl={this.state.anchorRef1.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin:  'center bottom' }}
                            >
                                <Paper style={{borderRadius:"8px"}} >
                                    <ClickAwayListener onClickAway={() => {
                                        this.setState({ menuItem: null })
                                    }} >
                                        <CustomMenu autoFocusItem={Boolean(this.state.menuItem)} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                           <MenuList style={{borderBottom:'2px solid #F5F5F5',paddingLeft:"12px",paddingRight:"12px",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px",paddingBottom:0}}>
                                            {this.state.userType == 'rescuer' && this.state.rescuerArr.map((el:any, index:any) => (
                                                <NavLink to={el.path} style={AllStyle.navLinkStyle}>

                                                <MenuItem onClick={()=>this.setState({menuItem:false})} key={index} style={{
                                                    ...AllStyle.textStyle, color: "#334155",
                                                }}  data-test-id={'menuOption'+index} >
                                                    {el.title}
                                                </MenuItem>
                                                </NavLink>
                                            ))}
                                             {this.state.userType == 'adopter' && this.state.adopterArr.map((el:any, index:any) => (
                                                   <NavLink to={el.path} style={AllStyle.navLinkStyle} >

                                                <MenuItem onClick={()=>this.setState({menuItem:false})} key={index} style={{
                                                    ...AllStyle.textStyle, color: "#334155",
                                                    
                                                }}   >
                                                    {el.title}
                                                </MenuItem>
                                                   </NavLink>
                                            ))}
                                            </MenuList>
                                            <MenuList style={{paddingLeft:"12px",paddingRight:"12px",paddingTop:'0'}}>
                                                <NavLink  to="/" style={AllStyle.navLinkStyle} >

                                            <MenuItem style={{
                                                ...AllStyle.textStyle, color: "#DC2626",
                                            }}
                                                data-test-id="logouttest"
                                                onClick={
                                                    this.logOutFunctionality}
                                            >
                                                Logout
                                            </MenuItem>
                                                </NavLink>
                                            </MenuList>
                                        </CustomMenu>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>  
                 
            </Box>
    }
    handleUrl=()=>{
        if(this.state.imageUrl !=="" && this.state.imageUrl !==null){
            return true
        }
        return false
    }
    returnModal=()=>{
        return <>
      <CustomizedModal
        onClose={()=>this.setState({customModalOpen:false})}
        open={this.state.customModalOpen}
        data-test-id='customModalOpenId'
      >
        <CrossModalImage onClick={()=>this.setState({customModalOpen:false})} src={crossIconForClose} alt="" data-test-id='crossIconCustomModal'  />
        <DialogContent>
          <ModalTitleText >
          Whoops!
          </ModalTitleText>
          <ModalContentText >
          Please sign in as a Pet Rescuer to list your rescue. See you on the other side!
          </ModalContentText>
        </DialogContent>
        <ModalButtCompParent>
          <ModalBotton variant="contained"
            style={{
              color: 'rgba(255, 255, 255, 1)',
              width: '151px', background: 'rgba(3, 181, 170, 1)',
            }}
            data-test-id="okCloseCustomModal"
            onClick={()=>this.setState({customModalOpen:false})}
          >

            OK
          </ModalBotton>
        </ModalButtCompParent>
      </CustomizedModal>
    </>
    }
    handleReturnListaRescue = () => {
        return <MenuGrid item style={AllStyle.who}
        >
            {this.state.userType == 'adopter' ?

                <Typography data-test-id='listRescueText' onClick={() => this.setState({ customModalOpen: true })} style={AllStyle.headingStyle}>List A Rescue</Typography>

                : <NavLink to="/my-posts"
                    data-test-id="whatwedo"
                    onClick={() => {
                        this.handleUserProfile()


                    }}
                    style={AllStyle.navLinkStyle}>

                    <Typography style={AllStyle.headingStyle}>List A Rescue</Typography>
                </NavLink>
            }
        </MenuGrid>
    }
    handleReturnListRescueSmallScreen = () => {
        return <>
            {this.state.userType == 'adopter' ?

                <Typography data-test-id='customModalOpenSmallScreen' style={{ ...AllStyle.userNameStyle, color: "white", cursor: 'pointer' }} onClick={() => this.setState({ customModalOpen: true })} >List A Rescue</Typography>
                : <NavLink
                    data-test-id="whatwedo2"
                    to="/my-posts"
                    onClick={() => {
                        this.setState({ open: false })


                        this.handleUserProfile()

                    }}
                    style={{ ...AllStyle.userNameStyle, color: "white" }}
                >
                    List A Rescue
                </NavLink >
       }   </>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const open = Boolean(this.state.menuItem);
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                {this.returnModal()}
                <MainGrid container>
                    <MenuBox item xs={12} md={12} lg={4}>
                    <SecondGrid2 >
                            <div style={{ ...AllStyle.rightIcons, gap: this.state.userType !== "" ? "36px" : "0px" }}>
                                <NavLink to="/search" title="Search"
                                 onClick={()=>{
                                    this.handleUserProfile()
                                }}
                                data-test-id="searchTest" 
                                style={AllStyle.navLinkStyle} >

                                <SearchOutlined style={AllStyle.heartIcon}  
                                
                                 />
                                </NavLink>
                                {
                                    this.state.userType !== "" && 
                                    <NavLink to="/notifications"
                                    data-test-id="notification" 
                                    onClick={()=>{
                                        this.handleUserProfile()
    
                                        
                                    }}
                                     style={AllStyle.navLinkStyle} title="Notifications" >
                                        <NotificationBadge badgeContent={this?.state?.notificationCountData} color="error">
                                            <img src={notification}
                                                style={{ cursor: "pointer" }} />
                                        </NotificationBadge>
                                    </NavLink>
                                }
                                  <NavLink to="/favorites"
                                     data-test-id="favTest" title="Favorites"
                                   onClick={()=>{
                                    this.handleUserProfile()

                                    
                                }}
                                   style={AllStyle.navLinkStyle} >

                                <FavoriteBorder style={AllStyle.heartIcon} 
                                />
                                  </NavLink>
                            </div>
                        </SecondGrid2>
                      
                    <NavlinkStyle to={"/"} 
                        data-test-id="LandingPageWeb" 
                         onClick={
                            this.handleUserProfile
                        }
                        style={{...AllStyle.navLinkStyle}} >

                        <img src={logonav} 
                        
                         style={{cursor:"pointer",height:'inherit'}} />
                        </NavlinkStyle>

                        <IconButtonStyle
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={this.handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButtonStyle>
                    </MenuBox>
<NavlinkStyle2 item xs={12} sm={4} style={{justifyContent:"center"}}>
                    <NavLink to={"/"} 
                        data-test-id="LandingPageWeb" 
                         onClick={
                            this.handleUserProfile
                        }
                        style={{...AllStyle.navLinkStyle,height:"44px"}} >

                        <img src={logonav} 
                        
                         style={{cursor:"pointer",height:'inherit'}} />
                        </NavLink>
</NavlinkStyle2>
                    <SecondGrid item xs={12} sm={4} >
                     
                        {this.handleUI()
                        }

                    </SecondGrid>
                </MainGrid>
                <MainGrid2 container style={AllStyle.secondNav}>
                <MenuGrid item   style={AllStyle.who}>
                        <NavLink  style={AllStyle.navLinkStyle} 
                          data-test-id="home"
                         onClick={()=>{
                            this.handleUserProfile()

                            
                        }}
                        to="/">
                        <Typography style={AllStyle.headingStyle} 
                      >Home</Typography>
                        </NavLink>
                    </MenuGrid>
                    <MenuGrid item   style={AllStyle.who}>
                        <NavLink  style={AllStyle.navLinkStyle} 
                          data-test-id="whoarewe"
                         onClick={()=>{
                            this.handleUserProfile()

                            
                        }}
                        to="/search">

                        <Typography style={AllStyle.headingStyle} 
                      >Adopt A Pet</Typography>
                        </NavLink>
                    </MenuGrid>

                    {this.handleReturnListaRescue()}
                   
                        <MenuGrid item   style={AllStyle.who}>
                  <NavLink to="/community"  style={AllStyle.navLinkStyle}   
                  data-test-id="communitytest"
                    onClick={()=>{
                                    this.handleUserProfile()

                                    
                                }}>
                            <Typography style={AllStyle.headingStyle} 
                             >Chat Room</Typography>
                  </NavLink>
                        </MenuGrid>
                   

                        <MenuGrid item  style={{...AllStyle.who,...AllStyle.navLinkStyle}}>
             
             <p
             data-test-id="resorcesItemArr1" 
             onClick={()=>{
                this.setState({resourcesItem:true})
             }}
             style={{display:"flex",margin:0}}
             ref={this.state.anchorRefResources}
             aria-controls={Boolean(this.state.resourcesItem) ? 'menu-list-grow' : undefined}
             aria-haspopup="true"
             >

                            <Typography style={AllStyle.headingStyle} 
                             >Resources</Typography>
                               <img src={dropdown} style={{ cursor: "pointer" }} aria-haspopup="true" />
             </p>
             <Popper  data-test-id='popover' placement={"bottom-end"} style={{ borderRadius: "8px", background: "#FFF" ,zIndex:700,marginTop:"6px" }} open={this.state.resourcesItem} anchorEl={this.state.anchorRefResources.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin:'center bottom' }}
                        >
                            <Paper style={{borderRadius:"8px"}} >
                                <ClickAwayListener onClickAway={() => {
                                    

                                    this.setState({ resourcesItem: null })
                                    
                                }} data-test-id='ClickAwayListener'>
                                    <CustomMenu autoFocusItem={Boolean(this.state.resourcesItem)} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                       <MenuList style={{borderBottom:'2px solid #F5F5F5',paddingLeft:"12px",paddingRight:"12px",borderBottomLeftRadius:"12px",borderBottomRightRadius:"12px"}}>
                                        {this.state.resorcesItemArr.map((el:any, index:any) => (
                                            <NavLink to={el.path} style={AllStyle.navLinkStyle}>

                                            <MenuItem
                                            data-test-id="resourceitem"
                                             onClick={()=>this.setState({resourcesItem:false})} key={index} style={{
                                                ...AllStyle.textStyle, color: "#334155",
                                            }}>
                                                {el.name}
                                            </MenuItem>
                                            </NavLink>
                                        ))}
                                       
                                        </MenuList>
                                        
                                    </CustomMenu>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                        </MenuGrid> 
                        <MenuGrid item   style={AllStyle.who} 
                    >
                    <NavLink to="/contactus" 
                     data-test-id="contactus"
                     onClick={()=>{
                        this.handleUserProfile()

                        
                    }}
                     style={AllStyle.navLinkStyle}>

                        <Typography style={AllStyle.headingStyle}> Contact Us</Typography>
                    </NavLink> 
                    </MenuGrid>
                </MainGrid2>
                <DrawerItemStyle
                    // variant="persistent"
               
                    anchor="right"
                    open={this.state.open}

                >
                    <Grid container style={{
                        height:"100%",
                    }}>
                    <Grid  xs={8} md={8} lg={8} sm={8} item style={{
                        background:"#03B5AA",
                        paddingLeft:"20px",
                        minHeight:"100%"
                    }}>
                        {this.state.userType == "" && 
                            <Box style={{marginTop:"34px",display:"flex",gap:"18px",marginBottom:"32px"}}>
                 
                            <NavLink title="Search"
                             to="/search"
                             style={AllStyle.headingStyle2}
                             onClick={()=>{
                                 this.handleUserProfile()
                              
                                 this.setState({open:false})
                                }} 
                                data-test-id="searchAdvance21"
                                >
    
                            <Typography 
                            style={{
                                display:"flex",   
                            alignItems:"center"
                        }}
                             >
                                <SearchOutlined 
                                style={AllStyle.heartIcon2} 
                                /></Typography>
                            </NavLink>
                            <NavLink 
                            data-test-id="favTest21" title="Favorites"
                            to="/favorites"  
                            style={AllStyle.headingStyle2}

                            onClick={()=>{
                               this.handleUserProfile()
                            
   
                               this.setState({open:false})
                           }}
                            >
                            <Typography 
                               style={{display:"flex",alignItems:"center"}}
                            > <FavoriteBorder style={AllStyle.heartIcon2} /></Typography>
                                </NavLink>
                                
    </Box>
                        }

                    {this.state.userType !== "" && <Box style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop:"55px",
                    }}>
                        <Box style={{
                            display:"flex",
                            flexDirection:"row",
                            alignItems:"center",
                            gap:"9px"
                        }}>
{
                                this.handleUrl()?
                                <Avatar 
                                style={{ width: "49px", height: "49px" ,border:"1px solid var(--Basic-White, #FFF)"}} 
                                src={this.state.imageUrl??""} />:
                                <Avatar style={{ width: "49px", height: "49px" ,background:"white",color:"#03B5AA",border:"1px solid #CDF0EE"}} >
                                {this.state.userName !=="" &&this.state.userName[0].toUpperCase()}
                                </Avatar>


                            }
                        <Typography style={{ ...AllStyle.userNameStyle, color: "white" }}>Hi, {this.state.userName}</Typography>

                        </Box>
                        <Box style={{marginTop:"34px",display:"flex",gap:"18px",marginBottom:"32px"}}>
                 
                 <NavLink
                 data-test-id="searchAdvance2" title="Search"
                  to="/search"
                  onClick={()=>{
                     this.handleUserProfile()
                  

                     this.setState({open:false})
                 }} style={AllStyle.headingStyle2}>

                 <Typography 
                 style={{display:"flex",alignItems:"center"}}
                  ><SearchOutlined style={AllStyle.heartIcon2} /></Typography>
                 </NavLink>
                 <NavLink to="/favorites"  title="Favorites"
                 data-test-id="favTest2" 
                  onClick={()=>{
                     this.handleUserProfile()
                  

                     this.setState({open:false})
                 }}
                 style={AllStyle.headingStyle2}>
                 <Typography 
                    style={{display:"flex",alignItems:"center"}}
                 > <FavoriteBorder style={AllStyle.heartIcon2} /></Typography>
                     </NavLink>
                               
                                    <NavLink to="/notifications" title="Notifications" 
                                    data-test-id="notification21" 
                                    onClick={()=>{
                                        this.handleUserProfile()
                                        this.setState({open:false})
                                        
                                    }}
                                     style={AllStyle.navLinkStyle} >
                                        <NotificationBadge badgeContent={this?.state?.notificationCountData} color="error">
                                            <img src={notification}
                                                style={{ cursor: "pointer" }} />
                                        </NotificationBadge>
                                    </NavLink>
</Box>

<Box style={{display:"flex",flexDirection:"column",gap:"23px",paddingBottom:"32px",                        borderBottom:"1px solid rgb(255,255,255,0.2)"
}}>

                        {this.state.userType==='rescuer'&& this.state.rescuerArr.map((el:any,index:any)=>(
                              <NavLink to={el.path} 
                              data-test-id='menuOpt'
                              style={AllStyle.navLinkStyle}  onClick={()=>{
                             

                        this.handleUserProfile()

                        
                                this.setState({open:false})
                            }}>

                                  <Typography  key={index} 
                                  
                                  style={{ ...AllStyle.userNameStyle, color: "white" }}
                                  >{el.title}</Typography>   
                              </NavLink>
                        ))}
                        {this.state.userType==='adopter'&& this.state.adopterArr.map((el:any,index:any)=>(
                            <NavLink to={el.path} style={AllStyle.navLinkStyle} 
                            data-test-id='menuOpt1'
                             onClick={()=>{
                        this.handleUserProfile()
                     

                                this.setState({open:false})
                            }}>

                                <Typography  key={index} style={{ ...AllStyle.userNameStyle, color: "white" }}>{el.title}</Typography>   
                            </NavLink>
                        ))}
                        
                        
</Box>

                    </Box>
                    }
                    <Box style={{
                        gap: "18px",
                        marginTop:"35px",
                        display: "flex",
                        flexDirection: "column",
                        paddingBottom:"44px",
                        borderBottom:"1px solid rgb(255,255,255,0.2)"

                    }}>
                        <NavLink to="/" 
                        data-test-id="home2"
                        style={{ ...AllStyle.userNameStyle, color: "white" }}
                         onClick={()=>{
                            this.setState({open:false})
                        this.handleUserProfile()
                        }}
                          >
                        Home
                            </NavLink>
                        <NavLink to="/search" 
                        data-test-id="whoarewe2"
                        style={{ ...AllStyle.userNameStyle, color: "white" }}
                         onClick={()=>{
                            this.setState({open:false})
                         

                        this.handleUserProfile()

                        }}
                          >
                        Adopt A Pet
                            </NavLink>
                            {this.handleReturnListRescueSmallScreen()}
                               
                                    <NavLink 
                                    data-test-id="petootiCommunity2"
                                     to="/community"
                                     onClick={()=>{
                                        this.handleUserProfile()
                                     
            
                                        this.setState({open:false})
                                    }}
                                    style={{ ...AllStyle.userNameStyle, color: "white" }}
                                    > Chat Room</NavLink>
                       
                      
                            {    this.state.resorcesItemArr.map((el:any, index:any) => (
                                            <NavLink onClick={()=>{
                                                this.handleUserProfile()
                                             
            
                                                this.setState({open:false})
                                            }}
                                            data-test-id="resorcesItemArr"

                                             to={el.path} style={{...AllStyle.userNameStyle, color: "white" }}>

                                                {el.name}
                                            </NavLink>
                                        ))}
                                       
                       <NavLink 
                        data-test-id="contactUs2"
                        to="/contactus"
                         onClick={()=>{
                            this.handleUserProfile()
                         

                            this.setState({open:false})
                        }}
                        style={{ ...AllStyle.userNameStyle, color: "white" }}
                         > Contact Us
                         </NavLink>
                    </Box>
                  { this.state.userType !== "" &&  <Box style={{paddingTop:"23px",paddingBottom:"23px"}}>

                <NavLink to="/" style={{ ...AllStyle.userNameStyle, color: "white" }} data-test-id="basiclogout" onClick={() => {
                              
                           
                           this.logOutFunctionality()
                        }}>  Logout </NavLink >
                    </Box>}
                    {this.state.userType == "" && <Box style={{ padding: "23px 10px 0px 0px", flexDirection: "column", display: "flex", gap: "10px",textAlign:"center" }}>

                       
                        <NavLink style={{...AllStyle.loginBtn as React.CSSProperties}} 
                           to="/login"
                        data-test-id="login2" 
                        onClick={()=>{
                         

                            this.setState({open:false})
                        }}
                        >
                            Login
                        </NavLink>
                        <NavLink style={AllStyle.registeBtn as React.CSSProperties} data-test-id="signup2"
                          to="/signup-role" 
                          onClick={()=>{
                         

                            this.setState({open:false})
                        }}
                         >
                            Register
                            </NavLink>

                    </Box>}
                    </Grid>
                    <Grid  xs={4} md={4} lg={4} sm={4} item style={{
                        background:"#007D73",
                        minHeight:"100%"
                    }}>
 <div style={{
    display:"flex",
    justifyContent:"end"
 }}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <CancelIcon style={{color:"white"}}/>
                        </IconButton>
                    </div>
                    </Grid>
                    </Grid>
                </DrawerItemStyle>
                <Backdrop
                style={{ zIndex: 700 }}
                open={this.state.isOpenSnack}
                onClick={(event) => event.stopPropagation()}
                data-test-id='backdrop2'
            ><SnackBarStyle
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={this.state.isOpenSnack}
                style={{ zIndex: 1000 }}
                autoHideDuration={3000}
                onClick={(event) => event.stopPropagation()}
                data-test-id='snackbar2'
            >
                <Typography style={AllStyle.popup as React.CSSProperties}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
                    </svg>
                    YOU'VE LOGGED OUT! See you next time for more adventures.
                </Typography>
            </SnackBarStyle>
            </Backdrop>
            {
    this.state.importUpdatePopUp &&<ImportantUpadate
    terms_accept={this.state.importUpdatePopUp}
    handleClose={this.handleClosePopUpImportant}
    terms_acceptCheck={this.state.termsacceptCheckBox}
    checkederror={this.state.termsacceptCheckBoxError}
    handleTermsAndPolicy={this.aceeptTermsAndCondtion}
    handleTermsAndPolicyApi={this.handleTermsAndPolicyApi}
    termsAndConditonPopUp={this.state.termsAndConditonPopUp}
    privacyPolicyPopUp={this.state.privacyPolicyPopUp}
    opentermsAndConditon={this.openTerms}
    openPrivacyAndPolicy={this.openPrivacy}
    closeTerms={this.handleTermsAndConditonClose2}
    closePrivacy={this.handleprivacyAndPolicyClose2}
    />
  }
   {
                    this.state.termsAndConditonPopUp&&<PrivacyPolicy firstHeading="TERMS & CONDITIONS!" secondHeading=""privacyAndTerms={this.state.termsAndConditonPopUp} handleClose={this.handleTermsAndConditonClose2} handleCloseButton={this.handleTermsAndConditonClose2}/>
                }
                  {
                    this.state.privacyPolicyPopUp&&<PrivacyPolicy firstHeading="PRIVACY POLICY!" secondHeading=""privacyAndTerms={this.state.privacyPolicyPopUp} handleClose={this.handleprivacyAndPolicyClose2} handleCloseButton={this.handleprivacyAndPolicyClose2}/>
                }
  
            </Box>
            // Customizable Area End
        )

    }
}
export default Header

// Customizable Area Start
const NotificationBadge = styled(Badge)({
    '& .MuiBadge-anchorOriginTopRightRectangle': {
        top:"7px",
        right:"-2px"
    },
    '& .MuiBadge-badge' :{
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.5px',
        color:'#FFFFFF'
    }
  })	
export const DrawerItemStyle = styled(Drawer)({
   '& .MuiDrawer-paperAnchorRight':{
    width:"100%"
   },
    '& .MuiDrawer-paperAnchorDockedRight': {
        width: "100%",
        background: "#00a89a",
    }
})
const CustomMenu = styled(MenuList)({
    paddingTop: "unset",
    paddingBottom: "unset",
    '& :focus-visible ':{
        outline:'none'
      }
})

const MainGrid = styled(Grid)({
    padding: "24px 135px 24px 124px",
    alignItems: "center",
    maxHeight: "99px",
    background: "#03B5AA",
    '@media (max-width:1398px) and (min-width:960px)': {
        padding: "15px 40px 10px 40px;"
    },
    '@media (max-width:960px) and ( min-width:800px)': {
        padding: "15px 20px 10px 20px"
    },
    '@media (max-width:800px )': {
        padding: "15px 20px 10px 20px",
        justifyContent: "center",
        alignItems: "center"
    },
})
const MainGrid2 = styled(Grid)({
    padding: "12px 0px 12px 0px",
    background: "#03B5AA",
    '@media (max-width:1398px) and (min-width:1208px)': {
        padding: "12px 0px 12px 0px;"
    },
    '@media (max-width:1208px) and (min-width:960px)': {
        padding: "12px 0px 12px 0px;"
    },

    '@media (max-width:1279px )': {
        display: "none"
    },
})

const ButtonStyle2 = styled(Button)({
    textTransform: "inherit",
    padding: "2px 8px",
    "&.MuiButton-root:hover": {
        background: "unset"
    }
})

const IconButtonStyle = styled(IconButton)({
    display: "none",
    '@media (max-width:1279px )': {
        display: "flex",

    },
})
const MenuGrid = styled(Grid)({
    display: "flex",
    '@media (max-width:600px )': {
        display: "none"
    },
})
const SecondGrid = styled(Grid)({
    justifyContent: "end",
    display: "flex",
    alignItems: "center",
    '@media ( max-width : 1279px )': {
        display: "none"
    },

})
const SecondGrid2 = styled('div')({
    display: "flex",
    '@media ( max-width : 1279px )': {
        display: "none"
    },

})
const NavlinkStyle = styled(NavLink)({
    display: "none",
    '@media ( max-width : 1279px )': {
        display: "block",
        height:'35px',
        alignSelf:'center'
    },

})
const NavlinkStyle2 = styled(Grid)({
    display: "flex",
    '@media ( max-width : 1279px )': {
        display: "none"
    },

})
const MenuBox = styled(Grid)({
    '@media (max-width:1279px )': {
        display: "flex",
        justifyContent: "space-between"

    },
})
const AllStyle = {
    userNameStyle:{
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
        textDecoration:"none"

    },
    headingStyle: {
        cursor: "pointer",
        fontWeight: 400,
        fontFamily: "Inter",
        lineHeight: "24px",
        fontSize: "16px",
        textDecoration:"none"
    },

    textStyle: {
        padding: "12px 16px",
        cursor: "pointer",
        fontFamily: "Lato",
        textDecoration:"none",
        fontSize: " 16px",
        fontWeight: 400,
        lineHeight: "24px", 
    },
    headingStyle2: {
        cursor: "pointer",
        fontWeight: 700,
        fontFamily: "Lato",
        lineHeight: "24px",
        color: "white",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        textDecoration:"none"
    },
    rightIcons: {
        display: "flex",
        flexDirection: "row" as "row",
        padding: "0px 36px",
        textDecoration:"none",
        alignItems:"center"
    },
    heartIcon: {
        cursor: "pointer",
        margin: "8px",
        textDecoration:"none",
        color: "#ffffff"
    },
    heartIcon2: {
        cursor: "pointer",
        margin: "2px",

        textDecoration:"none"
    },
    loginBtn: {
        minWidth: "70px",
        marginInline: "20px",
        fontWeight: 400,
        fontFamily: "Lato",
        fontSize: "16px",
        lineHeight: "24px",
        textDecoration:"none",
        color: "#ffffff",
        textTransform: "none"
    },
    registeBtn: {
        minWidth: "90px",
        fontWeight: 400,
        fontSize: "16px",
        fontFamily: "Lato",
        textTransform: "none",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        color: "#ffffff",
        background: "#FF5E5B",
        borderRadius: "8px",

        height: "44px",
        lineHeight: "24px",
        textDecoration:"none"
    },
    secondNav: {
        background: "#074F57",
        textDecoration:"none",

        justifyContent:"center",
        gap:'64px'
    },
    who: {
        color: "#ffffff",
        textDecoration:"none",

        justifyContent: "center"
    },
    navLinkStyle:{
        textDecoration:"none",
        color:"white" as "white"
    },
    popup: {
        display: "flex",
        gap:"8px",
        alignItems: "center",
        backgroundColor: "#ffffff",
        color: "#0F172A",
        borderRadius: "8px",
        fontFamily: "Inter",
        fontWeight: 400,
        textDecoration:"none",
        padding:"9px 16px 9px 8px ",
        textAlign:"center",
        fontSize: "16px",
        lineHeight: "24px",
        boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
      },
}
 const SnackBarStyle = styled(Snackbar)({
    "&.MuiSnackbar-anchorOriginTopCenter":{
      top:"113px",
    }
    })
    const CustomizedModal= styled(Dialog)({
        "& .MuiPaper-elevation24": {
            width: '600px',
            height: '294px',
            boxShadow:'0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
            borderRadius: '8px 8px 32px 8px',
          opacity: '0px',
      
      
        },
        "& .MuiDialogContent-root": {
            padding: "60px 40px 24px 40px",
            display: 'flex',
            alignItems: 'flex-start',
            gap: '12px',
            borderBottom: '1px solid rgba(226, 232, 240, 1)',
            flexDirection: 'column',
        },
        "@media (max-width:500px)": {
          "& .MuiPaper-elevation24": {
              height:'unset',
            borderRadius: '8px 8px 8px 8px',
          }
        }
      })
      const CrossModalImage=styled('img')({

        position: 'absolute' as 'absolute',
        right: 16,
        top: 16,
        cursor:'pointer'
    
    })
    const ModalTitleText= styled(Typography)({
        fontSize: '24px',
        fontFamily: 'Inter' as 'Inter',
        fontWeight: 700,
        letterSpacing: '-0.11999999731779099px',
        color: 'rgba(15, 23, 42, 1)',
        lineHeight: '32.4px',
      
      })
      const ModalContentText= styled(Typography)({
        fontFamily: 'Inter' as 'Inter',
        color:'rgba(51, 65, 85, 1)',
        fontWeight: 400,
        lineHeight: '28.8px',
        fontSize: '18px',
      })
      
      const ModalButtCompParent= styled(Box)({
        display: 'flex',
        padding:'24px',
        justifyContent:'flex-end',
        gap: '16px',
        alignItems: 'center',
        "@media (max-width:500px)": { justifyContent:'center',}
      })
      
      const ModalBotton = styled(Button)({
        height: '56px',
        borderRadius: '8px',
        gap: '8px',
        border: '1px 0px 0px 0px',
        opacity: '0px',
        textTransform:'none' as 'none',
        fontFamily: 'Inter' as 'Inter',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        "&.MuiButton-contained": { boxShadow: "unset"},
         "@media (max-width:500px)": {height:'44px' }
    });
// Customizable Area End