import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Grid,
  ListItemIcon,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { leftArrowIcon2 } from "../../communityforum/src/assets";
import { Check, Close as CloseIcon, MoreVert } from "@material-ui/icons";
import { deleteImg, markAsRead, noNotification, readPng } from "./assets";
import CommonSnackbar from "../../../components/src/CommonSnackbar.web";
import Loader from "../../../components/src/Loader.web";
import { PaginationComponent } from "../../email-account-login/src/CataloguePage.web";


// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.isLoading} />
      <Box>
        <MainGrid container>
          <Container>
            <Box style={{ display: 'flex', flexDirection: 'column' }}>
              <Box style={{ display: "flex", alignItems: 'flex-start', margin: '48px 0px 24px 0px' }}>

                <TypographyStyle2 style={{ ...Allstyle.hedingStyle, cursor: "pointer", }} 
                 data-test-id="navigate"
                  onClick={() => {
                    this.props.navigation.navigate("LandingPageWeb")
                  }}>
                  Home
                </TypographyStyle2>

                <img src={leftArrowIcon2} style={{ margin: '2px' }} />
                <TypographyStyle
                  style={{
                    ...Allstyle.hedingStyle,
                    color: "#0F172A",
                    cursor: "pointer"
                  }}
                >
                  Notifications
                </TypographyStyle>
              </Box>
              {this.state.notificationListData.length>0 ? (
                <NotificationContainer>
                <NotificationHeading>
                  <Typography style={notificationStyles.heading}>Notifications</Typography>
                { this.state.unread_count!==0 && <Box data-test-id = {"markReadTestId"} style={notificationStyles.checkBox} onClick={() => this.markAllAsReadNotification()}>
                    <IconButton style={notificationStyles.icon}>
                      <Check style={{ fontSize: "20px" }} />
                    </IconButton>
                    
                    <Typography style={notificationStyles.smallHeading}>
                      Mark all as read
                    </Typography>
                  </Box>}
                </NotificationHeading>
                {this.state.notificationListData.map((elm,index:number) => {
                  
                  return (
                    <Box key={`${elm.attributes.id}${index}`} style={notificationStyles.notification}>
                      <Box data-test-id="navigation" style={notificationStyles.message} onClick={() => this.handleNavigation(elm)}>
                        <Typography style={notificationStyles.boldTypo}>
                          {elm.attributes.headings}{!elm.attributes.is_read ? <img src={readPng} style={notificationStyles.readIcon} /> : <></>}
                        </Typography>
                        <TypographyContent>
                          {elm.attributes.contents}
                        </TypographyContent>
                      </Box>
                      <Box style={notificationStyles.action}>
                        <Typography style={{ marginBottom: "20px" }}>
                          <IconButton
                            data-test-id={"handleActionTestID"}
                            style={notificationStyles.icon}
                            onClick={(event) => this.handleAction(event, elm)}
                          >
                            <MoreVert />
                          </IconButton>
                          <Selectmenu
                            id="simple-menu"
                            data-test-id={"handleCloseTestId"}
                            anchorEl={this.state.anchorEl}
                            getContentAnchorEl={null}
                            keepMounted
                            open={this.state.showAction}
                            onClose={() => this.handleClose()}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                           { this.state.notificationId.attributes.is_read ? 
                           <MenuItem
                           data-test-id={"handleMarkAsReadTestId"}
                             onClick={() =>{ 
                              this.markAsUnReadNotification()}}
                             style={{ color: "#0F172A", textAlign: "left" }}
                           >
                             <ListItemIcon style={notificationStyles.listItemIcon}>
                               <img src={markAsRead} style={{ height: '18px', width: '18px' }} />
                             </ListItemIcon>
                             
                              <MenuItemTypography>
                              Mark as unread
                               </MenuItemTypography>
                           </MenuItem>
                           :<MenuItem
                            data-test-id={"handleMarkAsReadTestId"}
                              onClick={() => this.handleMarkAsRead()}
                              style={{ color: "#0F172A", textAlign: "left" }}
                            >
                              <ListItemIcon style={notificationStyles.listItemIcon}>
                                <img src={markAsRead} style={{ height: '18px', width: '18px' }} />
                              </ListItemIcon>
                              
                               <MenuItemTypography>
                                  Mark as read
                                </MenuItemTypography>
                            </MenuItem>
                            }
                            <MenuItem
                              data-test-id={"openDeleteNotificationTest"}
                              onClick={() => this.openDeleteNotification()}
                              style={{ color: "#DC2626", textAlign: "left" }}
                            >
                              <ListItemIcon style={notificationStyles.listItemIcon}>
                                <img src={deleteImg} style={{ height: '18px', width: '18px' }} />
                              </ListItemIcon>
                              <MenuItemTypography style={{ color: '#DC2626' }}>
                                Delete
                              </MenuItemTypography>
                            </MenuItem>
                          </Selectmenu>
                        </Typography>
                        <NotificationTime>{this.timeSince(elm.attributes.created_at)}</NotificationTime>
                      </Box>
                    </Box>
                  )
                })}
        {  this.state.total_number_page>1&&         <PaginationComponent 
                   style={{marginTop:"30px",display:"flex",justifyContent:"center",marginBottom:"0px"}}
                   page={this.state.currentPage}
                   count={this.state.total_number_page}
          onChange={this.handleChange}

        />}
              </NotificationContainer>
              ) : (
                <Box style={{marginTop: '146px',marginBottom:"247px",marginInline:"auto"}}>
                  <ImageStyle src={this.state.emptyImg!==""?this.state.emptyImg:noNotification} />
                  <Typography style={{   
                    marginTop: "9px",                                   
                    fontFamily: "Lato",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "32px",
                    letterSpacing: "-0.005em",
                    textAlign: "center",
                    color: "#000000",
                    marginBottom: '8px'
                  }}>Nothing to show here!</Typography>
                  <Typography
                  style={{                                      
                    fontFamily: "Lato",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    textAlign: "center",
                    color: '#475569'
                  }}
                  >We'll be sure to let you know as soon as there's<br/>something worth your attention.</Typography>
                </Box>
              )}
            </Box>
          </Container>
        </MainGrid>
        <CommonSnackbar data-test-id='commonSnackBar' handleCloseFunc={()=>this.setState({isShowSnackbar:false})}  open={this.state.isShowSnackbar} message={this.state.isSnackbarMessage} />
      <PopUpCommon
      firstHeading={"DELETE NOTIFICATION?"}
      secondHeading={"Proceeding with this action will permanently remove the selected notification."}
      firstButtonName={"Cancel"}
      secondButtonName={"Delete"}
      openState={this.state.deletePopUp}
      onCloseFn={this.handleDeleteNotificationClose}
      firstButtonFn={this.handleDeleteNotificationClose}
      secondButtonFn={this.handleSuccessDelete}
      />
      </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const  PopUpCommon=({firstHeading,secondHeading,firstButtonName,secondButtonName,openState,onCloseFn,firstButtonFn,secondButtonFn}:any)=>{
  return    <DiologStyle
  data-test-id="closeId" 
  onClose={onCloseFn}
  aria-labelledby="customized-dialog-title"
  open={openState}
>
  <Box style={{
    display: "flex",
    padding: "16px 16px 12px 40px ",
    justifyContent: "flex-end",
    alignItems: "center",
  }}>
    <IconButton aria-label="close"
      style={{ padding: "4px", color: "#0A2239" }}
      data-test-id="closeId2"
      onClick={onCloseFn}
    >
      <CloseIcon />
    </IconButton>
  </Box>
  <Box style={{ marginInline: "40px" }}>
    <Typography style={{
      fontSize: "24px",
      color: "#0F172A",
      fontFamily: "Inter",
      marginBottom: "12px",
      letterSpacing: " -0.12px",
      fontWeight: 700,
      lineHeight: "32px",
    }}>
     {firstHeading}
    </Typography>
    <Typography style={{
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "160%",
      color: "#334155",
      fontFamily: "Inter",
      marginBottom: "24px",
    }}>
     {secondHeading}
    </Typography>
  </Box>
  <hr style={{
    width: '100%',
    height: '1px', backgroundColor: '#e2e8f0',
    border: 'none', margin: "0px",
  }} />

  <Box style={{
    padding: "24px",
    gap: "16px",
    justifyContent: "flex-end",
    display: "flex",
    flexWrap: "wrap",
  }}>
    <ButtonComponent
      onClick={firstButtonFn}
      style={{ width:"140px", height: "56px", border: "1px solid #03B5AA",
      borderRadius: "8px",
fontFamily: "Inter",
fontSize: "16px",
fontWeight: 700,
lineHeight: "24px",
textTransform:"inherit",
       color: "#03B5AA" }}
      data-test-id="closeId3"
    >
      {firstButtonName}
    </ButtonComponent>
    <Button
      style={{
        ...webstyle.BtnGreen, marginLeft: "0px",
        textTransform:"inherit",
        fontFamily: "Inter", width:  "151px", height: "56px"
      }}
      data-test-id="agree"             
      onClick={() => secondButtonFn()}
    >
      {secondButtonName}
    </Button>
  </Box>
</DiologStyle>
}
export const  PopUpCommon2=({firstHeading,secondHeading,firstButtonName,secondButtonName,openState,onCloseFn,firstButtonFn,secondButtonFn,secondButtonwidth,firstButtonWidth}:any)=>{
  return    <DiologStyle
  aria-labelledby="customized-dialog-title"
  data-test-id="closeId" 
  open={openState}

>

  <Box style={{ marginInline: "40px",marginTop:"40px" }}>
    <Typography style={{
      marginBottom: "12px",
      lineHeight: "32px",
      letterSpacing: " -0.12px",
      fontWeight: 700,
      color: "#0F172A",
      fontSize: "24px",
      fontFamily: "Inter",
    }}>
     {firstHeading}
    </Typography>
    <Typography style={{
      fontSize: "18px",
      color: "#334155",
      lineHeight: "160%",
      fontFamily: "Inter",
      marginBottom: "24px",
      fontWeight: 400,
    }}>
     {secondHeading}
    </Typography>
  </Box>
  <hr style={{
    border: 'none',
    backgroundColor: '#e2e8f0',
    width: '100%',
    height: '1px', 
     margin: "0px",
  }} />

  <Box style={{
    padding: "24px",
    gap: "16px",
    justifyContent: "flex-end",
    display: "flex",
    flexWrap: "wrap",
  }}>
    <ButtonComponent2
      onClick={firstButtonFn}
      style={{  height: "56px", border: "1px solid #03B5AA",
      borderRadius: "8px",
fontFamily: "Inter",
fontSize: "16px",
fontWeight: 700,
lineHeight: "24px",
textTransform:"inherit",
       color: "#03B5AA" }}
      data-test-id="closeId3"
    >
      {firstButtonName}
    </ButtonComponent2>
    <ButtonComponent2
      style={{
        marginLeft: "0px",
        
        textTransform:"inherit",
        fontFamily: "Inter", width: '250px', height: "56px",

        background: '#03B5AA',
        border: '1px solid #03B5AA',
        borderRadius: 8,
        padding: "10px 16px 10px 12px",
        lineHeight: '24px',
      
        fontWeight: 700,
        fontSize: 16,
       
        color: '#fff',
     
        
      }}
      data-test-id="agree"             
      onClick={() => secondButtonFn()}
    >
      {secondButtonName}
    </ButtonComponent2>
  </Box>
</DiologStyle>
}
export const TypographyStyle = styled(Typography)({
  paddingInline: "6px",
  '@media ( max-width : 550px )': {
    paddingInline: "unset"
  },
})
const ImageStyle=styled('img')({
  width: "375px",
  height: "250px",
  "@media (max-width:400px)": {
    width: "300px",
    height:"unset"
  },
})

const MainGrid = styled(Grid)({
  "@media (max-width:722px)": {
    marginTop: "20px",
  },
  minHeight: "calc(100vh - 232px)",
});


export const TypographyStyle2 = styled(Typography)({
  '@media ( max-width : 550px )': {
    paddingRight: "unset"
  },
  paddingRight: "6px",
})
const MenuItemTypography = styled(Typography)({
  fontSize: "14px",
  fontFamily: "Inter",
  fontWeight: 400,
  color: '#0F172A',
  lineHeight: "22px",
})
const ButtonComponent2 = styled(Button)({
  '@media (max-width: 518px)': {
    minWidth: "100%"
  },
})
const ButtonComponent = styled(Button)({
  '@media (max-width: 418px)': {
    minWidth: "151px"
  },
})
const NotificationContainer = styled(Box)({
  width: "772px",
  margin: 'auto',
  marginBottom: "134px",
  '@media (max-width: 800px)': {
    width: '100%',
  },
})

const NotificationTime = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    color: "#64748B",
    '@media (max-width: 790px)': {
      width: '37px',
    },
    '@media (max-width: 562px)': {
      width: '37px',
    },
    '@media (max-width: 400px)': {
      width: '37px',
    }
})

const TypographyContent  = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    paragraph: "8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "550px",    
    "@media (max-width: 651px)": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "360px",    
    },
    '@media (max-width: 430px)':{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "250px",         
    },
    '@media (max-width: 412px)': {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "250px",   
      },
   "@media (max-width: 400px)": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "250px",   
      },
})

const NotificationHeading = styled(Box)({
  height: "40px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
})

export const notificationStyles = ({
  readIcon: { margin: "7px 6px 1px 6px", width: "6px", height: '6px' },
  listItemIcon: {
    minWidth: "30px",
    cursor: "pointer"
  },
  headingBox: {
   
  },
  heading: {
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
  },
  smallHeading: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "0px",
    cursor: 'pointer'
  },
  notification: {
    height: "96px",
    padding: "16px 0px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0",
    alignItems: "center",
    cursor: "pointer"
  },
  message: {
    width: "500px",
    height: "44px",
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  action: {
    height: "64px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "end",
  },
  boldTypo: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "0px",
    marginBottom: "4px"
  },
  checkBox: {
    display: "flex",
    width: "138px",
    height: "24px",
    gap: "3px",
    alignItems: "center",
  },
  menuPaper: {
    // border: '5px solid red', // Add border color
    boxShadow: 'none', // Remove box-shadow
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
  },
});
const DiologStyle = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "600px",
    minWidth: "600px",
    borderRadius: "8px 8px 32px 8px"
  },
  "@media (max-width:722px)": {
    "& .MuiDialog-paperWidthSm": {
      minWidth: "unset",
      borderRadius: "8px 8px 32px 8px"
    },
  },
})
export const Allstyle = {
hedingStyle: {
  fontSize: "14px",
  color: "#475569",
  fontWeight: 400,
  fontFamily: "Lato",
  lineHeight: "22px",
},
};

export const webstyle = {
  BtnGreen: {
    background: '#03B5AA',
    border: '1px solid #03B5AA',
    borderRadius: 8,
    padding: "10px 16px 10px 12px",
    lineHeight: '24px',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 16,
    fontFamily: "Lato",
    color: '#fff',
    marginLeft: 15,
    minWidth: 137,

  } as React.CSSProperties,
}

const Selectmenu = styled(Menu)({
  "& .MuiPaper-elevation8": {
    boxShadow:"0px 4px 8px 0px #00000008",
    border: "1px solid #E2E8F0",
    borderRadius: '8px'
  },
 
});
// Customizable Area End
